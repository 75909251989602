import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Modal } from 'antd'

import styles from './styles.scss'

const SavingModal = props => {
  const { visible = false, onCancel = () => {}, data = [] } = props

  if (!Array.isArray(data)) return null

  return (
    <Modal title="Сохранение" footer={null} visible={visible} onCancel={onCancel}>
      <div className={styles.savingModal}>
        {data.map(row => {
          const { title, isSaving = false, status = 'ok', error = null } = row

          if (title === '') return null

          return (
            <>
              <div className={styles.row}>
                {title && <div className={styles.title}>{title}</div>}
                {status && (
                  <div className={styles.status}>
                    {/* eslint-disable no-nested-ternary */
                    isSaving ? (
                      <Icon type="loading" style={{ fontSize: 24 }} spin />
                    ) : status === 'ok' ? (
                      <Icon type="check-circle" style={{ fontSize: 24 }} />
                    ) : (
                      <Icon type="close-circle" style={{ fontSize: 24 }} />
                    )}
                  </div>
                )}
              </div>
              <div className={styles.row}>
                {status === 'error' &&
                  error &&
                  (Array.isArray(error) ? (
                    <div>
                      {error.map(err => (
                        <div key={err} className={styles.error}>
                          {err}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className={styles.error}>{error}</div>
                  ))}
              </div>
            </>
          )
        })}
      </div>
    </Modal>
  )
}

SavingModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  data: PropTypes.array
}

export default SavingModal
