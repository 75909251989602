import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Radio } from 'antd'

import styles from './index.scss'

// TODO get from env
const ORIGIN = process.env.PUBLIC_ORIGIN

const PreviewTypes = [
  {
    type: 'mobile',
    width: 375,
    height: 812,
    icon: <Icon type="mobile" />
  },
  {
    type: 'tablet',
    width: 768,
    height: 1024,
    icon: <Icon type="tablet" />
  },
  {
    type: 'desktop',
    width: 1440,
    height: 768,
    icon: <Icon type="desktop" />
  },
  {
    type: 'main',
    width: 1440,
    height: 768,
    icon: <Icon type="appstore" />
  }
]

class PagePreview extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentType: PreviewTypes[2].type
    }
  }

  onChangeType = event => {
    this.setState({
      currentType: event.target.value
    })
  }

  render() {
    const currentTypeData = PreviewTypes.find(e => e.type === this.state.currentType)
    const url =
      this.state.currentType === 'main'
        ? `${ORIGIN}?previewPostId=${this.props.postId}&token=${this.props.token}`
        : this.props.url

    if (!this.props.visible) return null

    return (
      <section className={styles.root}>
        <header className={styles.header}>
          <Radio.Group
            onChange={this.onChangeType}
            value={this.state.currentType}
            buttonStyle="solid"
          >
            {PreviewTypes.map(data => (
              <Radio.Button key={data.type} value={data.type} className={styles.action}>
                {data.icon}
              </Radio.Button>
            ))}
          </Radio.Group>
        </header>
        <div
          className={styles.iframeWrapper}
          style={{
            transform: `scale(${(window.innerHeight - 140) / 1024})`
          }}
        >
          <iframe src={url} width={currentTypeData.width} height={currentTypeData.height} />
        </div>
        <div className={styles.background} onClick={this.props.onClose} />
      </section>
    )
  }
}

PagePreview.propTypes = {
  url: PropTypes.string,
  postId: PropTypes.string,
  token: PropTypes.string,
  visible: PropTypes.bool,

  onClose: PropTypes.func
}

PagePreview.defaultProps = {
  token: ''
}

export default PagePreview
