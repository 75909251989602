import PropTypes from 'prop-types'
import React from 'react'
import { observer } from 'mobx-react'

// eslint-disable-next-line import/no-cycle
import { BadWordItem } from '~components/BadWords'
import GridWrapper from '~components/GridWrapper'
import ContentSpinner from '~components/ContentSpinner'
import EmptyContent from '~components/EmptyContent'
import { useBadWordsStore } from '~hooks/useBadWordsStore'

export const BadWordList = observer(() => {
  const { badWords, isFirstLoading, isEmptyBadWords } = useBadWordsStore()

  if (isFirstLoading) {
    return <ContentSpinner />
  }

  if (isEmptyBadWords) return <EmptyContent text="Нет запрещенных слов" />

  return (
    <GridWrapper gridSize={5} maxItemWidth={285}>
      {badWords.map(badWord => (
        <BadWordItem key={badWord._id} badWord={badWord} />
      ))}
    </GridWrapper>
  )
})

BadWordList.propTypes = {
  isFirstLoading: PropTypes.bool,
  isEmptyBadWords: PropTypes.bool,
  badWords: PropTypes.array
}
