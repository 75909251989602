import React from 'react'
import s from './styles.scss'

const AddNew = props => (
  <div className={s.add} {...props}>
    <span className={s.addIcon}>+</span>
  </div>
)

export default AddNew
