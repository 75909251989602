/* eslint-disable */

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'mobx-react'

import { ConfigProvider } from 'antd'
import ru_RU from 'antd/lib/locale-provider/ru_RU'

require('./utils/tracekit')

import stores from './store'
import App from './App'

ReactDOM.render(
  <ConfigProvider locale={ru_RU}>
    <Provider {...stores}>
      {/* <button onClick={() => methodDoesNotExist()}>Break the world</button>; */}
      <App />
    </Provider>
  </ConfigProvider>,
  document.getElementById('app')
)
