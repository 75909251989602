import { observable, action, computed, set } from 'mobx'

import { groupBy, get } from 'lodash'
import moment from 'moment'

import API from '~api'
import { role as allRoles } from '~utils/roles'

import ConfigureFetchUrlByListsStore from './configureFetchUrlByLists'
import FetchPaginationStore from './fetchPaginationStore'
import FilterParamsByListStore from './filterParamsByList'
import SortingStore from './SortingStore'
import UserStore from './UserStore'

import getIsGridValue from '~utils/getIsGridValue'

export const PUBLICATIONS_URL = '/posts'

const defaultFilters = [
  {
    label: 'Тип поста',
    type: 'select',
    name: 'filter.type',
    fix: true
  },
  {
    label: 'Статус поста',
    type: 'select',
    name: 'filter.status',
    fix: true
  },
  {
    label: 'Автор',
    type: 'select',
    name: 'filter.authors',
    fix: true
  },
  {
    label: 'Категория',
    type: 'select',
    name: 'filter.categories',
    fix: true
  },
  {
    label: 'Теги публикации',
    type: 'select',
    name: 'filter.tags'
  },
  {
    label: 'Раздел публикации',
    type: 'select',
    name: 'filter.section'
  },
  {
    label: 'Приоритет публикации',
    type: 'select',
    name: 'filter.priority'
  },
  {
    label: 'Вычитка корректора',
    type: 'select',
    name: 'filter.validatedBy.corrector'
  },
  {
    label: 'Дата создания',
    type: 'dateRange',
    name: 'filter.createdAt'
  },
  {
    label: 'Дата изменения',
    type: 'dateRange',
    name: 'filter.updatedAt'
  },
  {
    label: 'Дата публикации',
    type: 'dateRange',
    name: 'filter.publicationDate'
  },
  {
    label: 'Комментирование публикации',
    type: 'bool',
    name: 'filter.flags.commentsAllowed'
  },
  {
    label: 'Отключение рекламы',
    type: 'bool',
    name: 'filter.flags.advAllowed'
  },
  {
    label: 'Контент «18+»',
    type: 'bool',
    name: 'filter.flags.adultContent'
  },
  {
    label: 'Контент «Шок»',
    type: 'bool',
    name: 'filter.flags.shockContent'
  },
  {
    label: 'Контент «Клик»',
    type: 'bool',
    name: 'filter.flags.clickContent'
  },
  {
    label: 'Блокировка РКН',
    type: 'bool',
    name: 'filter.flags.blockedByRKN'
  },
  {
    label: 'Отправка публикации в RSS',
    type: 'bool',
    name: 'filter.flags.sendToRSS'
  }
]

class PublicationsStore {
  constructor() {
    const startUrl = new URLSearchParams(window.location.search)

    startUrl.forEach((value, key) => {
      const filterFromUrl = this.filtersItem.find(item => item.name === key && !item.fix)

      if (filterFromUrl) {
        this.setFilterItemActive(filterFromUrl)
      }
    })
  }

  initFilterParams = () => {
    const { SET_FILTER_PARAMS_FOR_BACK } = ConfigureFetchUrlByListsStore

    SET_FILTER_PARAMS_FOR_BACK({
      'filter.updatedAt': params => this.getDateRangeFilterParams(params),
      'filter.createdAt': params => this.getDateRangeFilterParams(params),
      'filter.publicationDate': params => this.getDateRangeFilterParams(params),
      'filter.title': params => `match(${params})`
    })
  }

  getDateRangeFilterParams = params => {
    const dateArray = params.split(',')

    const startDate = moment(dateArray[0])
      .startOf('day')
      .utc()
      .format()

    const endDate = moment(dateArray[1])
      .endOf('day')
      .utc()
      .format()

    return `between(["${startDate}","${endDate}"])`
  }

  @observable isFirstLoading = true

  @observable isGrid = getIsGridValue('isGridViewPublications')

  @observable viewType = localStorage.getItem('postsViewType') || 'list'

  @observable isShowFilter = this.getIsShowFilterFromUrlParams()

  @observable isLoadingMore = false

  @observable allItemsCount = 0

  @observable publications = []

  @observable filtersItem = defaultFilters

  @observable selectedFilterItems = []

  @observable dictionaries = []

  @action
  fetchDictionaries = () => {
    const promise = API.get('/dictionaries/post')

    return promise.then(res => {
      const { data } = res

      this.setDictionaries(data)
    })
  }

  @action
  getPreviewToken = id => {
    return API.get(`posts/${id}/preview`)
  }

  @action
  getIsShowFilterFromUrlParams = () => {
    const { filterParams } = FilterParamsByListStore

    const filter = Object.keys(filterParams).filter(item => item !== 'search')

    return !!filter.length
  }

  @action
  fetchPublications = (defaultFilter = '') => {
    const { offset } = FetchPaginationStore
    const { getPromiseUrl } = ConfigureFetchUrlByListsStore
    const { SET_FILTER_PARAMS_FOR_BACK, FILTER_PARAMS_FOR_BACK } = ConfigureFetchUrlByListsStore

    let customDefaultFilter = defaultFilter

    if (this.viewType === 'calendar') {
      const { filterParams } = FilterParamsByListStore

      const filterPubDate = get(filterParams, 'filter.publicationDate', null)

      if (!filterPubDate) {
        customDefaultFilter = `${customDefaultFilter}&filter.publicationDate=gt(${moment().format(
          'YYYY-MM-DD'
        )})`
      } else {
        customDefaultFilter = defaultFilter
      }
    }

    SET_FILTER_PARAMS_FOR_BACK({
      ...FILTER_PARAMS_FOR_BACK,
      'filter.validatedBy.corrector': params => {
        if (params === '1') {
          return `eq(null)&filter.validatedAt.corrector=eq(null)`
        }

        if (params === '2') {
          return `neq(null)&filter.validatedAt.corrector=neq(null)`
        }

        if (params === '3') {
          return `eq(null)&filter.validatedAt.corrector=neq(null)`
        }

        return ''
      }
    })

    const { user: { role, _id } = { role: '', _id: '' } } = UserStore
    const filterByUser = role === allRoles.INTERN ? `&filter.createdBy=${_id}` : ''

    const promiseUrl = getPromiseUrl({
      url: PUBLICATIONS_URL,
      defaultFilter: customDefaultFilter + filterByUser
    })

    const fetchPublicationsPromise = API.get(promiseUrl)

    this.setIsLoadingMore(true)
    return fetchPublicationsPromise
      .then(res => {
        const { data, meta } = res.data

        if (offset === 0) {
          this.setPublication(data)
        } else {
          this.setPublication([...this.publications, ...data])
        }

        this.setAllItemsCount(meta.count)
        this.setIsFirstLoading(false)
        this.setIsLoadingMore(false)
      })
      .catch(() => {
        this.setIsFirstLoading(false)
        this.setIsLoadingMore(false)
        this.setPublication([])
      })
  }

  @computed
  get isEmptyPublications() {
    return !this.publications.length
  }

  @computed
  get publicationLength() {
    return this.publications.length
  }

  @action
  setPublication = data => {
    this.publications = data
  }

  @action
  setIsFirstLoading = value => {
    this.isFirstLoading = value
  }

  @action
  setAllItemsCount = count => {
    this.allItemsCount = count
  }

  @action
  setIsLoadingMore = value => {
    this.isLoadingMore = value
  }

  @action
  onToggleView = () => {
    this.isGrid = !this.isGrid
    localStorage.setItem('isGridViewPublications', this.isGrid ? 'yes' : 'no')
  }

  @action
  toggleIsShowFilter = () => {
    this.isShowFilter = !this.isShowFilter
  }

  @action
  setHideFilter = () => {
    this.isShowFilter = false
  }

  @action
  setShowFilter = () => {
    this.isShowFilter = true
  }

  @action
  setFilterItemActive = item => {
    this.selectedFilterItems.push(item)
  }

  @action
  removeFileItem = item => {
    this.selectedFilterItems.remove(item)
  }

  @action
  setDictionaries = data => {
    this.dictionaries = data
  }

  @action
  setFixedFilter = item => {
    set(item, { fix: true })
  }

  @action
  resetFilters = () => {
    this.filtersItem = defaultFilters
  }

  @action
  setViewType = type => {
    this.viewType = type
    localStorage.setItem('postsViewType', type)

    this.setDefaultFilterByCalendarView()
  }

  @action
  setDefaultFilterByCalendarView = () => {
    const { options, toggleOptions } = SortingStore

    if (this.viewType === 'calendar') {
      if (!options[1].value) {
        toggleOptions(options[1])
      }
    }
  }

  @computed
  get groupedData() {
    const eventGroupByDate = groupBy(this.publications, item =>
      moment(item.publicationDate).format('DD MMM YYYY')
    )

    const resultGroupedPosts = Object.keys(eventGroupByDate).reduce((memo, key) => {
      memo.push({
        date: key,
        posts: eventGroupByDate[key]
      })

      return memo
    }, [])

    return resultGroupedPosts
  }
}

export default new PublicationsStore()
