import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Button, Upload, Spin, Icon } from 'antd'

import styles from './styles.scss'

class AvatarUpload extends Component {
  render() {
    const { value, onClearAvatar, ...props } = this.props

    const isAvatar = !!value && value.status !== 'uploading' && value.url
    const isDeleteBtnShow = isAvatar && onClearAvatar

    return (
      <div className={styles.root}>
        <Upload
          name="avatar"
          listType="picture-card"
          withCredentials
          showUploadList={false}
          action={`${process.env.API_ROOT}/files`}
          {...props}
        >
          {!value.url && value.status !== 'uploading' && (
            <Icon className={styles.addIcon} type="camera" />
          )}

          {value.status === 'uploading' && <Spin className={styles.spin} />}

          {isAvatar && (
            <div className={styles.imgWrapper}>
              <img className={styles.img} src={value.url} alt="avatar" />
            </div>
          )}
        </Upload>

        {isDeleteBtnShow && (
          <div className={styles.deleteButton} onClick={onClearAvatar}>
            <Button size="small" type="link">
              <Icon type="delete" />
            </Button>
          </div>
        )}
      </div>
    )
  }
}

export default AvatarUpload

AvatarUpload.propTypes = {
  onClearAvatar: PropTypes.func,
  value: PropTypes.object
}
