import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

import TabsItem from './TabItem'

import styles from './styles.scss'

@inject(stores => {
  const { setFilterValue, filterParams } = stores.FilterParamsByListStore

  return {
    setFilterValue,
    filterParams
  }
})
@observer
class TabsFilter extends Component {
  handleClick = item => {
    const { setFilterValue } = this.props

    setFilterValue('filter', item.type)
  }

  render() {
    const { data, filterParams } = this.props

    return (
      <div className={styles.root}>
        {data.map(item => (
          <TabsItem
            isActive={filterParams.filter === item.type}
            onClick={this.handleClick}
            key={item.type}
            data={item}
          />
        ))}
      </div>
    )
  }
}

export default TabsFilter

TabsFilter.propTypes = {
  data: PropTypes.array,
  filterParams: PropTypes.object,
  setFilterValue: PropTypes.func
}
