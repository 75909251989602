import { observable, action, set } from 'mobx'

import { get } from 'lodash'

const actualLables = [0, 1, 2, 3, 4, 5, 6, 7, 8]
const actualValues = [0, 8, 7, 6, 5, 4, 3, 2, 1]

export default class PostPriorityStore {
  @observable sectionPriority = 0

  @observable selectedCategories = []

  @observable categories = this.getFormatCategories()

  @observable selectedTags = []

  constructor(publicationStore) {
    this.publicationStore = publicationStore
    // this.init()
  }

  @action
  update({ draftPriorities = null, realPriorities = null }) {
    if (draftPriorities != null) {
      const { dictionaries, tags } = this.publicationStore

      const selectedSection = draftPriorities.section[0]
      if (selectedSection != null) {
        this.sectionPriority = actualValues[selectedSection.value]
      }

      this.selectedCategories = draftPriorities.categories.map(category => {
        const { title = '' } = dictionaries.categories.find(({ _id }) => _id === category.id)
        return { ...category, value: actualValues[category.value], title }
      })
      this.selectedTags = draftPriorities.tags.map(tag => {
        const { title = '' } = tags.find(({ _id }) => _id === tag.id)
        return { key: tag.id, value: actualValues[tag.value], label: title }
      })
    }
    if (realPriorities != null) {
      const { dictionaries, tags } = this.publicationStore

      const selectedSection = realPriorities.section[0]
      if (selectedSection != null) {
        this.sectionPriority = actualValues[selectedSection.value]
      }

      this.selectedCategories = realPriorities.categories.map(category => {
        const { title = '' } = dictionaries.categories.find(({ _id }) => _id === category.id)
        return { ...category, value: actualValues[category.value], title }
      })
      this.selectedTags = realPriorities.tags.map(tag => {
        const { title = '' } = tags.find(({ _id }) => _id === tag.id)
        return { key: tag.id, value: actualValues[tag.value], label: title }
      })
    }
  }

  @action
  init() {
    this.sectionPriority = 0
    this.selectedSection = {}
    this.selectedCategories = []
    this.selectedTags = []

    const {
      dictionaries: { categories },
      categories: selectedCategories = [],
      section,
      tags
    } = this.publicationStore

    if (section !== '') {
      this.selectedSection = { id: section }
    }

    if (categories.length > 0) {
      const categoriesToObj = {}
      categories.forEach(category => (categoriesToObj[category._id] = category))
      selectedCategories.forEach(selectedCategoryId => {
        const { _id: id, title } = categoriesToObj[selectedCategoryId]

        this.setSelectedCategory({ id, title })
      })
      // const { _id, alias } = allCategories.find(({ _id }) => _id === section)
      // this.setSelectedCategory()
    }

    if (tags.length > 0) {
      tags.forEach(({ _id, title }) => {
        this.setSelectedTags([...this.selectedTags, { key: _id, label: title }])
      })
    }
  }

  getFormatCategories = () => {
    const {
      publicationStore: { dictionariesList }
    } = this

    const categories = get(dictionariesList, 'categories', []).map(category => ({
      id: category._id,
      title: category.title,
      isSelected: false
    }))

    return categories
  }

  @action
  setSelectedSection = id => {
    this.publicationStore.setSection(id)
  }

  @action
  setSectionPriority = value => {
    this.sectionPriority = actualLables[value]
  }

  @action
  removeSelectedCategory = categoryId => {
    const categoryIndex = this.categories.findIndex(category => category.id === categoryId)

    this.selectedCategories = this.selectedCategories.filter(item => item.id !== categoryId)

    set(this.categories[categoryIndex], {
      isSelected: false
    })
  }

  @action
  setCategoryValue = ({ id, value }) => {
    const selectedCategoryIndex = this.selectedCategories.findIndex(category => category.id === id)

    set(this.selectedCategories[selectedCategoryIndex], {
      value: actualLables[value]
    })
  }

  @action
  setTagValue = ({ id, value }) => {
    const selectedTagIndex = this.selectedTags.findIndex(tag => tag.key === id)

    set(this.selectedTags[selectedTagIndex], {
      value: actualLables[value]
    })
  }

  @action
  setSelectedCategory = ({ id, title }) => {
    this.selectedCategories.push({
      id,
      title
    })

    const categoryIndex = this.categories.findIndex(category => category.id === id)

    set(this.categories[categoryIndex], {
      isSelected: true
    })
  }

  @action
  setSelectedTags = tag => {
    this.selectedTags = tag
  }

  @action
  removeTag = id => {
    const tagIndex = this.selectedTags.findIndex(item => item.key === id)

    this.selectedTags.remove(this.selectedTags[tagIndex])
  }

  @action
  savePriority = () => {
    const draftPriorities = {
      section: [{ id: this.publicationStore.section, value: actualValues[this.sectionPriority] }],
      tags: this.selectedTags.map(({ key, value }) => ({ id: key, value: actualValues[value] })),
      categories: this.selectedCategories.map(({ id, value }) => ({
        id,
        value: actualValues[value]
      }))
    }

    this.publicationStore.setDraftPrioritiesToServer(draftPriorities)
    this.publicationStore.hideModalPriority()
  }

  @action
  resetPriority = () => {
    this.sectionPriority = 0
  }
}
