import { observable, action, set } from 'mobx'

import { compact, get } from 'lodash'

import FilterParamsByListStore from './filterParamsByList'

class SortingStore {
  @observable options = []

  @action
  setOptions = data => {
    this.options = data

    const { filterParams } = FilterParamsByListStore

    const sortStringToArray = get(filterParams, 'sort', '').split(',')

    this.options.forEach(item => {
      if (item.value) this.toggleOptions(item)

      sortStringToArray.forEach(option => {
        if (option.slice(1) === item.type)
          set(item, {
            value: option[0]
          })
      })
    })
  }

  @action
  resetOptions = () => {
    this.options.forEach(item => {
      set(item, {
        value: null
      })
    })
  }

  @action
  toggleOptions = (item, value) => {
    const { setFilterValue } = FilterParamsByListStore

    const index = this.options.indexOf(item)

    const currentItem = this.options[index]

    set(currentItem, {
      // eslint-disable-next-line no-nested-ternary
      value: typeof value !== 'undefined' ? value : currentItem.value === '+' ? '-' : '+'
    })

    const formatSortToString = compact(
      this.options.map(optionsItem => {
        if (!optionsItem.value) {
          return null
        }
        if (optionsItem.value) {
          return encodeURIComponent(`${optionsItem.value}${optionsItem.type}`)
        }

        return null
      })
    ).join(',')

    setFilterValue('sort', formatSortToString)
  }
}

export default new SortingStore()
