import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import WhiteBlockWithShadow from '~components/WhiteBlockWithShadow'
import AttachmentFileLink from '~components/AttachmentFileLink'
import FeedbackDelete from '~components/Feedbacks/FeedbackDelete'
import ReplyArrowIcon from '~assets/icons/replyArrow.svg'

import styles from './styles.scss'

class FeedbackItem extends Component {
  render() {
    const { dictionaries, feedback } = this.props
    const { type, subject, content, attachments, senderName, senderEmail, createdAt } = feedback
    const feedbackType = dictionaries.find(({ id }) => id === type)

    return (
      <WhiteBlockWithShadow>
        <div className={styles.root}>
          <a href={`mailto:${senderEmail}`} className={styles.replyLink}>
            <ReplyArrowIcon className={styles.replyIcon} />
          </a>
          <FeedbackDelete feedback={feedback} />
          <div className={styles.left}>
            <div className={styles.type}>{feedbackType && feedbackType.ru}</div>
            <h3 className={styles.subject}>{subject}</h3>
            <p className={styles.content}>{content}</p>
            {attachments && (
              <div className={styles.attachments}>
                {attachments.map((item, i) => (
                  <AttachmentFileLink key={i} file={item} />
                ))}
              </div>
            )}
          </div>
          <div className={styles.right}>
            <div>
              <div className={styles.senderName}>{senderName}</div>
              <div className={styles.senderEmail}>{senderEmail}</div>
            </div>
            <div className={styles.createdAt}>
              {moment(createdAt).format('MM.DD.YYYY HH:mm:ss')}
            </div>
          </div>
        </div>
      </WhiteBlockWithShadow>
    )
  }
}

FeedbackItem.propTypes = {
  FeedbackStore: PropTypes.any,
  feedback: PropTypes.object,
  setEditableEmployee: PropTypes.func,
  dictionaries: PropTypes.array
}

export default FeedbackItem
