import { noop } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import { Input, Upload, message, Checkbox, Button, Form, Radio } from 'antd'

import DeleteButton from '~components/DeleteButton'
import ItemWithLabel from '~components/ItemWithLabel'
import Modal from '~components/Modal'
import { useSpecProjectsStore } from '~hooks/useSpecProjectsStore'
import s from './styles.scss'

const CREATE_TITLE = 'Создание спец.проекта'
const UPDATE_TITLE = 'Редактирование спец.проекта'

export const SpecProjectsModal = Form.create({ name: 'specProjectForm' })(
  observer(props => {
    const [fileList, setFileList] = useState([])
    const {
      form: {
        setFieldsValue = noop,
        getFieldDecorator = noop,
        getFieldsValue = noop,
        validateFields = noop
      } = {}
    } = props
    const {
      setHideModal,
      isShowModal,
      resetEditableSpecProjects,
      editableSpecProjects,
      isActionModal,
      deleteSpecProjects,
      dictionaries,
      updateSpecProjects,
      createSpecProjects
    } = useSpecProjectsStore()

    const coverValue = useMemo(() => editableSpecProjects?.cover, [editableSpecProjects])

    /**
     * Обработка загруженнего файла обложки
     */
    useEffect(() => {
      if (coverValue) {
        setFieldsValue({
          cover: coverValue
        })
        setFileList([
          {
            uid: coverValue?._id,
            name: coverValue?.name,
            url: coverValue?.url
          }
        ])
      }
      return () => {
        setFieldsValue({
          cover: null
        })
        setFileList([])
      }
    }, [coverValue])

    const ModalTitle = editableSpecProjects ? UPDATE_TITLE : CREATE_TITLE

    const fileUploadProps = {
      name: 'file',
      action: `${process.env.API_ROOT}/files`,
      withCredentials: true,
      multiple: false,
      onChange: info => {
        if (info.file.status === 'done') {
          message.success(`${info.file.name} успешно загружен.`)
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} не получилось загрузить файл.`)
        }
        setFileList(info?.fileList?.slice(-1))
      },
      fileList
    }

    const handleSubmit = async e => {
      e.preventDefault()

      const formData = getFieldsValue()
      await validateFields()

      try {
        formData.cover = formData.cover._id || formData.cover.file.response.data._id
      } catch (e) {
        message.error('Обложка обязательна!')
        return
      }

      const category = { data: { ...formData } }
      const action = editableSpecProjects ? updateSpecProjects : createSpecProjects

      action(category)
    }

    return (
      <Modal
        destroyOnClose
        width={590}
        onClose={setHideModal}
        isVisible={isShowModal}
        title={ModalTitle}
        afterClose={resetEditableSpecProjects}
      >
        <Form onSubmit={handleSubmit}>
          <ItemWithLabel label="Название спец.проекта">
            <Form.Item>
              {getFieldDecorator('title', {
                initialValue: editableSpecProjects && editableSpecProjects.title,
                rules: [{ required: true, message: 'Обязательное поле!' }]
              })(<Input placeholder="Введите название" disabled={isActionModal} />)}
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="Описание спец.проекта">
            <Form.Item>
              {getFieldDecorator('description', {
                initialValue: editableSpecProjects && editableSpecProjects.description,
                rules: [{ required: true, message: 'Обязательное поле!' }]
              })(<Input.TextArea />)}
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="Тип материала">
            <Form.Item>
              {getFieldDecorator('type', {
                initialValue: editableSpecProjects?.type,
                valuePropName: 'value',
                rules: [{ required: true, message: 'Обязательное поле!' }]
              })(
                <Radio.Group>
                  {dictionaries.map(item => (
                    <Radio value={item?.id} key={item?.id}>
                      {item?.ru}
                    </Radio>
                  ))}
                </Radio.Group>
              )}
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="Ссылка на спец.проект">
            <Form.Item>
              {getFieldDecorator('url', {
                initialValue: editableSpecProjects && editableSpecProjects.url,
                rules: [{ required: true, message: 'Обязательное поле!' }]
              })(<Input placeholder="Ссылка на спец.проект" disabled={isActionModal} />)}
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="Обложка">
            <Form.Item>
              {getFieldDecorator('cover', {
                rules: [{ required: true, message: 'Обязательное поле!' }]
              })(
                <Upload {...fileUploadProps}>
                  <Button>Кликните чтобы загрузить файл</Button>
                </Upload>
              )}
            </Form.Item>
          </ItemWithLabel>

          <Form.Item>
            {getFieldDecorator('visible', {
              initialValue: (editableSpecProjects && editableSpecProjects.visible) || false,
              valuePropName: 'checked'
            })(<Checkbox>Виден на сайте</Checkbox>)}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator('flags.isMain', {
              initialValue: editableSpecProjects?.flags?.isMain || false,
              valuePropName: 'checked'
            })(<Checkbox>Главный проект</Checkbox>)}
          </Form.Item>

          <div className={s.modalControls}>
            <Button htmlType="submit" type="primary" disabled={isActionModal}>
              Сохранить изменения
            </Button>
            {editableSpecProjects && (
              <div className={s.deleteBtnWrapper}>
                <DeleteButton
                  text="Удалить спец.проект"
                  disabled={isActionModal}
                  onDelete={deleteSpecProjects}
                />
              </div>
            )}
          </div>
        </Form>
      </Modal>
    )
  })
)
