export default function getError(err) {
  let message = err.message || JSON.stringify(err)

  if (err.response) {
    message = `Статус ${err.response?.status}: \n${err.response?.data?.error?.message ??
      JSON.stringify(err.response?.data)}`
  }

  return message
}
