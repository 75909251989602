import { MobXProviderContext } from 'mobx-react'
import React from 'react'
// eslint-disable-next-line no-unused-vars
import { FetchPaginationStore } from '~store/fetchPaginationStore'

/**
 *
 * @returns {FetchPaginationStore}
 */
export const useFetchPaginationStore = () =>
  React.useContext(MobXProviderContext)?.FetchPaginationStore || {}
