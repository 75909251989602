import { action, observable, reaction, toJS } from 'mobx'

// service
import FetchPaginationStore from './fetchPaginationStore'
import FilterParamsByListStore from './filterParamsByList'

class ConfigureFetchUrlByListsStore {
  @observable FILTER_PARAMS_FOR_BACK = {
    'filter.updatedAt': params => {
      return `day(${params})`
    },
    'filter.createdAt': params => {
      return `day(${params})`
    },
    'filter.publicationDate': params => {
      return `day(${params})`
    },
    'filter.title': params => `match(${params})`
  }

  constructor() {
    const { filterParams, setFilterValue } = FilterParamsByListStore

    const startUrl = new URLSearchParams(window.location.search)

    startUrl.forEach((value, key) => {
      setFilterValue(key, value)
    })

    reaction(
      () => toJS(filterParams) || Object.keys(filterParams).length,
      () => {
        const filterUrlParams = Object.keys(filterParams)
          .map(key => `${key}=${filterParams[key]}`)
          .join('&')

        let newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`
        if (filterUrlParams) {
          newUrl = `?${filterUrlParams}`
        }

        window.history.replaceState({ path: newUrl }, '', newUrl)
      }
    )
  }

  @action
  SET_FILTER_PARAMS_FOR_BACK = params => {
    this.FILTER_PARAMS_FOR_BACK = params
  }

  @action
  getPromiseUrl = ({ url, defaultFilter }) => {
    const { offset, limit } = FetchPaginationStore
    const { filterParams } = FilterParamsByListStore

    let pagUrlParams = `limit=${limit}&offset=${offset * limit}`

    if (defaultFilter) {
      pagUrlParams = `${pagUrlParams}&${defaultFilter}`
    }

    const filterUrlParams = Object.keys(filterParams)
      .map(key => {
        const paramsValue = filterParams[key]
        const actionGetValue = this.FILTER_PARAMS_FOR_BACK[key]

        const params = actionGetValue ? actionGetValue(paramsValue) : paramsValue

        return `${key}=${params}`
      })
      .join('&')

    let fetchUrlWithParams = `${url}?${pagUrlParams}`

    if (filterUrlParams) {
      fetchUrlWithParams = `${fetchUrlWithParams}&${filterUrlParams}`
    }

    return fetchUrlWithParams
  }
}

export default new ConfigureFetchUrlByListsStore()
