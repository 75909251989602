import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { observer, inject } from 'mobx-react'

import ContentWrapper from '~components/ContentWrapper'
import { DepartmentList, DepartmentsModal } from '~components/Departments'
import HeaderPageSearchBar from '~components/HeaderPageSearchBar'
import InfinityScroll from '~components/InfinityScroll'

const PAGE_TITLE = 'Все отделы'
const COUNT_DECLINATIONS = ['отдел', 'отдела', 'отделов']
const SEARCH_BTN_TEXT = 'Найти в отделах'
const CREATE_BTN_TEXT = 'Добавить отдел'

@inject('DepartmentsStore', 'FilterParamsByListStore', 'FetchPaginationStore')
@observer
class DepartmentsPage extends Component {
  componentWillUnmount() {
    const { FilterParamsByListStore, FetchPaginationStore } = this.props

    FilterParamsByListStore.setFilterParams()
    FetchPaginationStore.resetPaginationParams()
  }

  handleSearchPublications = () => {
    const { fetchDepartments, setIsFirstLoading } = this.props.DepartmentsStore

    setIsFirstLoading(true)
    fetchDepartments()
  }

  render() {
    const { DepartmentsStore } = this.props
    const {
      setShowModal,
      fetchDepartments,
      allItemsCount,
      departmentsLength,
      isFirstLoading,
      isLoadingMore
    } = DepartmentsStore

    return (
      <Fragment>
        <HeaderPageSearchBar
          searchButtonText={SEARCH_BTN_TEXT}
          createButtonText={CREATE_BTN_TEXT}
          onCreateElement={setShowModal}
          onSearch={this.handleSearchPublications}
        />
        <ContentWrapper
          title={PAGE_TITLE}
          countItems={allItemsCount}
          countDeclinations={COUNT_DECLINATIONS}
        >
          <InfinityScroll
            loadItems={fetchDepartments}
            isEndLoading={allItemsCount <= departmentsLength}
            isFirstLoading={isFirstLoading}
            isLoadMore={isLoadingMore}
          >
            <DepartmentList />
          </InfinityScroll>
        </ContentWrapper>

        <DepartmentsModal />
      </Fragment>
    )
  }
}

export default DepartmentsPage

DepartmentsPage.propTypes = {
  DepartmentsStore: PropTypes.any,
  FetchPaginationStore: PropTypes.any,
  FilterParamsByListStore: PropTypes.any
}
