import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Modal as AntModal } from 'antd'

import styles from './styles.scss'

class Modal extends Component {
  render() {
    const { isVisible, onClose, children, title, ...props } = this.props
    return (
      <AntModal {...props} footer={null} visible={isVisible} onCancel={onClose}>
        {title && <div className={styles.title}>{title}</div>}
        {children}
      </AntModal>
    )
  }
}

export default Modal

Modal.propTypes = {
  children: PropTypes.element,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string
}
