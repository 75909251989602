import React, { Fragment } from 'react'
import { observer } from 'mobx-react'
import { BadWordList, BadWordModal } from '~components/BadWords'
import { useBadWordsStore, useClearHandBooksStores } from '~hooks'

import ContentWrapper from '~components/ContentWrapper'
import HeaderPageSearchBar from '~components/HeaderPageSearchBar'
import InfinityScroll from '~components/InfinityScroll'

const PAGE_TITLE = 'Запрещенные слова'
const COUNT_DECLINATIONS = ['запрещенное слово', 'запрещенных слова', 'запрещенных слов']
const SEARCH_BTN_TEXT = 'Найти в запрещенных словах'
const CREATE_BTN_TEXT = 'Добавить запрещенное слово'

export const BadWordsPage = observer(() => {
  useClearHandBooksStores()

  const {
    setShowModal,
    isLoadingMore,
    isFirstLoading,
    allItemsCount,
    fetchBadWords,
    badWordsLength,
    setIsFirstLoading
  } = useBadWordsStore()

  const handleSearchPublications = () => {
    setIsFirstLoading(true)
    fetchBadWords()
  }

  return (
    <Fragment>
      <HeaderPageSearchBar
        searchButtonText={SEARCH_BTN_TEXT}
        createButtonText={CREATE_BTN_TEXT}
        onCreateElement={setShowModal}
        onSearch={handleSearchPublications}
      />
      <ContentWrapper
        title={PAGE_TITLE}
        countItems={allItemsCount}
        countDeclinations={COUNT_DECLINATIONS}
      >
        <InfinityScroll
          loadItems={fetchBadWords}
          isEndLoading={allItemsCount <= badWordsLength}
          isFirstLoading={isFirstLoading}
          isLoadMore={isLoadingMore}
        >
          <BadWordList />
        </InfinityScroll>
      </ContentWrapper>

      <BadWordModal />
    </Fragment>
  )
})
