import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { Checkbox } from 'antd'

import GridWrapper from '~components/GridWrapper'
import ContentSpinner from '~components/ContentSpinner'

import styles from './styles.scss'

@inject('SettingsGlobalStore')
@observer
class Advertising extends Component {
  constructor(props) {
    super(props)
    this.advDisabled = React.createRef()
  }

  componentDidMount() {
    this.props.SettingsGlobalStore.fetchGlobalSettings()
  }

  handleChange = e => {
    const { name, checked } = e.target

    this.props.SettingsGlobalStore.updateGlobalSettings(name, checked)
  }

  render() {
    const {
      SettingsGlobalStore: { globalSettings, isFirstLoading }
    } = this.props

    if (isFirstLoading) {
      return <ContentSpinner />
    }

    return (
      <GridWrapper gridSize={2}>
        <div className={styles.item}>
          <Checkbox
            name="advDisabled"
            checked={globalSettings.advDisabled}
            onChange={this.handleChange}
          >
            Отключить рекламу на сайте
          </Checkbox>
        </div>
      </GridWrapper>
    )
  }
}

Advertising.propTypes = {
  SettingsGlobalStore: PropTypes.any
}

export default Advertising
