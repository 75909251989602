import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'

import { Input, Button, Form } from 'antd'

import { get } from 'lodash'
import Modal from '~components/Modal'
import DeleteButton from '~components/DeleteButton'
import FormItem from './formItem'
import SelectRender from '~components/Publications/Filter/SelectRender'
import AvatarUpload from '~components/AvatarUpload'

import styles from './styles.scss'

const CREATE_TITLE = 'Создание нового автора'
const UPDATE_TITLE = 'Редактирование автора'

const { TextArea } = Input

@inject(stores => {
  const {
    isShowModalEdit,
    setHideModalEdit,
    editableAuthor,
    resetEditableAuthor,
    updateAuthor,
    createAuthor,
    deleteAuthor,
    isActionModal
  } = stores.AuthorsStore

  return {
    isShowModalEdit,
    setHideModalEdit,
    editableAuthor,
    resetEditableAuthor,
    updateAuthor,
    createAuthor,
    deleteAuthor,
    isActionModal
  }
})
@observer
class AuthorModal extends Component {
  handleSubmit = e => {
    e.preventDefault()

    const { form, updateAuthor, editableAuthor, createAuthor } = this.props
    const formData = form.getFieldsValue()

    const author = {
      data: {
        ...formData,
        department: formData.department._id,
        avatar: formData.avatar._id || null
      }
    }

    const action = editableAuthor ? updateAuthor : createAuthor

    action(author)
  }

  normFile = e => {
    const file = get(e, 'file.response.data', null)
    const status = get(e, 'file.status', null)

    return {
      ...file,
      status
    }
  }

  handleClearAvatar = () => {
    const { form } = this.props
    const { setFieldsValue } = form

    setFieldsValue({
      avatar: {
        url: ''
      }
    })
  }

  render() {
    const {
      isShowModalEdit,
      setHideModalEdit,
      editableAuthor,
      resetEditableAuthor,
      form,
      isActionModal,
      deleteAuthor
    } = this.props
    const { getFieldDecorator } = form

    const modalTitle = editableAuthor ? UPDATE_TITLE : CREATE_TITLE

    return (
      <Modal
        destroyOnClose
        width={770}
        onClose={setHideModalEdit}
        isVisible={isShowModalEdit}
        title={modalTitle}
        afterClose={resetEditableAuthor}
      >
        <div className={styles.root}>
          <Form onSubmit={this.handleSubmit}>
            <div className={styles.forms}>
              <div className={styles.formItemWrapper}>
                <Form.Item>
                  {getFieldDecorator('avatar', {
                    initialValue: { url: get(editableAuthor, 'avatar.url', '') },
                    getValueFromEvent: this.normFile
                  })(<AvatarUpload onClearAvatar={this.handleClearAvatar} />)}
                </Form.Item>
              </div>

              <div className={styles.formItemWrapper} />

              <div className={styles.formItemWrapper}>
                <FormItem
                  label="Имя и фамилия автора"
                  getFieldDecorator={getFieldDecorator}
                  fieldName="name"
                  required
                >
                  <Input />
                </FormItem>
              </div>

              <div className={styles.formItemWrapper}>
                <FormItem label="E-mail" getFieldDecorator={getFieldDecorator} fieldName="email">
                  <Input />
                </FormItem>
              </div>

              <div className={styles.formItemWrapper}>
                <FormItem
                  label="Выбрать отдел"
                  getFieldDecorator={getFieldDecorator}
                  fieldName="department._id"
                  required
                >
                  <SelectRender
                    item={{
                      label: 'Статус поста',
                      type: 'select',
                      name: 'filter.department'
                    }}
                  />
                </FormItem>
              </div>

              <div className={styles.formItemWrapper}>
                <FormItem
                  label="Вконтакте"
                  getFieldDecorator={getFieldDecorator}
                  fieldName="socials.vk"
                >
                  <Input />
                </FormItem>
              </div>

              <div className={styles.formItemWrapper}>
                <FormItem
                  label="Фейсбук"
                  getFieldDecorator={getFieldDecorator}
                  fieldName="socials.facebook"
                >
                  <Input />
                </FormItem>
              </div>

              <div className={styles.formItemWrapper}>
                <FormItem
                  label="Твитер"
                  getFieldDecorator={getFieldDecorator}
                  fieldName="socials.twitter"
                >
                  <Input />
                </FormItem>
              </div>

              <div className={styles.formItemWrapper}>
                <FormItem
                  label="Телеграм"
                  getFieldDecorator={getFieldDecorator}
                  fieldName="socials.telegram"
                >
                  <Input />
                </FormItem>
              </div>

              <div className={styles.formItemWrapper}>
                <FormItem
                  label="Инстаграм"
                  getFieldDecorator={getFieldDecorator}
                  fieldName="socials.instagram"
                >
                  <Input />
                </FormItem>
              </div>

              <div className={styles.formItemWrapper}>
                <FormItem
                  label="Одноклассники"
                  getFieldDecorator={getFieldDecorator}
                  fieldName="socials.ok"
                >
                  <Input />
                </FormItem>
              </div>
            </div>

            <FormItem
              label="Биография автора"
              getFieldDecorator={getFieldDecorator}
              fieldName="bio"
            >
              <TextArea />
            </FormItem>

            <div className={styles.modalControls}>
              <Button
                loading={isActionModal}
                disabled={isActionModal}
                htmlType="submit"
                type="primary"
              >
                Сохранить изменения
              </Button>

              {editableAuthor && (
                <div className={styles.deleteBtnWrapper}>
                  <DeleteButton text="Удалить автора" onDelete={deleteAuthor} />
                </div>
              )}
            </div>
          </Form>
        </div>
      </Modal>
    )
  }
}

const WrappedTAuthorForm = Form.create({ name: 'authorForm' })(AuthorModal)

export default WrappedTAuthorForm

AuthorModal.propTypes = {
  editableAuthor: PropTypes.any,
  form: PropTypes.any,
  isActionModal: PropTypes.bool,
  isShowModalEdit: PropTypes.bool,
  resetEditableAuthor: PropTypes.func,
  setHideModalEdit: PropTypes.func,
  updateAuthor: PropTypes.func,
  createAuthor: PropTypes.func,
  deleteAuthor: PropTypes.func
}
