import { observable, action, computed, toJS } from 'mobx'
import moment from 'moment'
import videoApiLayer from '../services/videoApi'

export const constants = {
  statuses: {
    published: 'VIDEO_STATUS_PUBLISHED',
    draft: 'VIDEO_STATUS_DRAFT'
  },
  types: {
    video: 'video',
    stream: 'stream'
  }
}

const DEFAULT_FLAGS = {
  isMain: false,
  isMainSide: false,
  visible: true,
  blockedByRKN: false,
  darkSide: false
}
const falgsKeys = Object.keys(DEFAULT_FLAGS)

const makeYoutubeEmbedLinkById = id => `https://www.youtube.com/embed/${id}`

class VideoStore {
  constants = constants

  apiLayer = null

  @observable isLoading = false

  @observable startDate = null

  @observable finishDate = null

  @observable initialized = false

  @observable flags = { ...DEFAULT_FLAGS }

  @observable id = null

  @observable title = ''

  @observable description = ''

  @observable priority = 0

  @observable cover = ''

  @observable coverTitle = ''

  @observable availablePlaylists = []

  @observable availableStreams = []

  @observable playlists = []

  @observable type = 'video'

  @observable videoFile = null

  @observable embedLink = ''

  @observable streamStatus = ''

  @observable videoStream = ''

  @observable streamId = ''

  @observable status = constants.statuses.draft

  constructor(apiLayer) {
    this.apiLayer = apiLayer
  }

  async init(id) {
    const promises = [this.fetchPlaylists(), this.fetchStreams(), this.fetchDictionaries()]

    if (id != null) {
      promises.push(this.fetchVideo(id))
    } else {
      promises.push(this.createNewVideo())
    }

    return Promise.all(promises)
  }

  @computed
  get isPublished() {
    return this.status && this.status.id === this.constants.statuses.published
  }

  @computed get flagsArray() {
    return Object.keys(this.flags).filter(flag => this.flags[flag])
  }

  getDataForServer() {
    const data = {
      title: this.title,
      priority: this.priority,
      cover: this.cover ? this.cover._id : null,
      coverTitle: this.coverTitle,
      description: this.description,
      videoFile: this.videoFile ? this.videoFile._id : null,
      videoStream: this.videoStream,
      embedLink: this.embedLink,
      streamId: this.streamId,
      playlists: this.playlists,
      type: this.type,
      flags: this.flags
    }

    if (this.type === 'VIDEO_TYPE_STREAM') {
      if (this.startDate) {
        data.startDate = this.startDate.toISOString()
      }

      if (this.finishDate) {
        console.log(this.finishDate)
        data.finishDate = this.finishDate.toISOString()
      }
    }

    if (this.videoFileTitle) {
      data.videoFileTitle = this.videoFileTitle
    }

    return data
  }

  @action updateFromServer(data) {
    const flags = {}
    Object.keys(data.flags).forEach(key => {
      if (falgsKeys.indexOf(key) > -1) {
        flags[key] = data.flags[key]
      }
    })

    this.id = data._id
    this.status = data.status
    this.title = data.title
    this.priority = data.priority
    this.coverTitle = data.coverTitle
    this.cover = data.cover
    this.description = data.description
    this.visible = data.visible
    this.flags = flags
    this.type = data.type
    this.videoFile = data.videoFile
    this.videoFileTitle = data.videoFileTitle
    this.videoStream = data.videoStream
    this.embedLink = data.embedLink
    this.streamId = data.streamId
    this.playlists = data.playlists
    this.startDate = data.startDate ? moment(data.startDate) : null
    this.finishDate = data.finishDate ? moment(data.finishDate) : null
    this.streamStatus = data.streamStatus
  }

  @action setLoading(value) {
    this.isLoading = value
  }

  // Events
  @action onChangeTitle = ({ text }) => {
    this.title = text
  }

  @action onChangeDescription = ({ text }) => {
    this.description = text
  }

  @action onChangeCover = cover => {
    this.cover = cover
  }

  @action onChangeCoverTitle = coverTitle => {
    this.coverTitle = coverTitle
  }

  @action onChangeVideo = data => {
    this.videoFileTitle = data.alt
    this.videoFile = { ...toJS(this.videoFile), ...data }
  }

  @action onChangeFlags = flags => {
    const newFlags = { ...DEFAULT_FLAGS }

    Object.keys(newFlags).forEach(key => {
      newFlags[key] = flags.indexOf(key) > -1
    })

    this.flags = newFlags
  }

  @action onChangeVideoStream = value => {
    this.streamId = value
  }

  @action onChangeEmbedLink = event => {
    let value = typeof event === 'string' ? event : event.target.value
    const youtubeFullLinkRegexp = /youtube\.com\/watch\?v=([^&]+)/
    const youtubeMatch = youtubeFullLinkRegexp.exec(value)

    if (youtubeMatch && youtubeMatch[1]) {
      value = makeYoutubeEmbedLinkById(youtubeMatch[1])
    }

    this.embedLink = value
  }

  @action clearVideoStream = () => {
    this.streamId = ''
  }

  @action
  setInitialized = () => {
    this.initialized = true
  }

  @action clear = () => {
    this.id = ''
    this.status = constants.statuses.draft
    this.title = ''
    this.coverTitle = ''
    this.cover = null
    this.description = ''
    this.flags = { ...DEFAULT_FLAGS }
    this.type = constants.types.video
    this.videoFile = null
    this.videoFileTitle = ''
    this.videoStream = ''
    this.embedLink = ''
    this.streamId = ''
    this.playlists = []
    this.availablePlaylists = []
    this.availableStreams = []
    this.initialized = false
  }

  @action setPlaylists = value => {
    this.playlists = value
  }

  @action setPriority = value => {
    this.priority = value
  }

  @action setType = value => {
    this.type = value
  }

  // API
  @action fetchVideo(id) {
    this.setLoading(true)

    return this.apiLayer
      .fetchVideo(id)
      .then(video => {
        this.updateFromServer(video)
        this.initialized = true
      })
      .catch(err => {
        throw err
      })
  }

  @action async fetchDictionaries() {
    const dictionaries = await this.apiLayer.getDictionaries()

    this.dictionaries = dictionaries
  }

  @action async fetchPlaylists() {
    const playlists = await this.apiLayer.getPlaylists()

    this.availablePlaylists = playlists
  }

  @action async fetchStreams() {
    const streams = await this.apiLayer.getStreams()

    this.availableStreams = streams.map(e => ({
      publicPath: `/streams/${e._id}/index.m3u8`,
      ...e
    }))
  }

  @action createNewVideo() {
    this.setLoading(true)

    return this.apiLayer
      .createVideo()
      .then(video => {
        return this.updateFromServer(video)
      })
      .then(() => {
        this.setLoading(false)
        this.setInitialized()
      })
  }

  @action save = () => {
    const data = this.getDataForServer()
    return this.apiLayer.save({
      id: this.id,
      data
    })
  }

  @action publish = async () => {
    const res = await this.apiLayer.publish(this.id)

    this.status = res.status
  }

  @action unpublish = async () => {
    const res = await this.apiLayer.unpublish(this.id)

    this.status = res.status
  }

  @action delete = async () => {
    const res = await this.apiLayer.delete(this.id)

    return res
  }

  @action
  startStream = () => {
    return this.save().then(() => {
      return this.apiLayer.startStream(this.id).then(data => {
        this.streamStatus = data.streamStatus
        this.type = data.type
      })
    })
  }

  @action
  stopStream = () => {
    return this.apiLayer.stopStream(this.id).then(data => {
      this.streamStatus = data.streamStatus
      this.type = data.type
    })
  }

  @action
  setStartDate = value => {
    this.startDate = value
  }

  @action
  setFinishDate = value => {
    this.finishDate = value
  }
}

export default new VideoStore(videoApiLayer)
