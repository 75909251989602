import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { inject, observer } from 'mobx-react'

import ContentSpinner from '~components/ContentSpinner'

import styles from './styles.scss'

@inject(stores => ({
  isVisible: stores.OverlayStore.isVisible,
  text: stores.OverlayStore.text
}))
@observer
class Overlay extends Component {
  render() {
    const { isVisible, text } = this.props

    return (
      <>
        <div className={cn(styles.overlay, { [styles.hidden]: !isVisible })}>
          <ContentSpinner />
          <h2 className={styles.text}>{text}</h2>
        </div>
        {this.props.children}
      </>
    )
  }
}

Overlay.propTypes = {
  children: PropTypes.element,
  isVisible: PropTypes.bool,
  text: PropTypes.string
}

Overlay.defaultProps = {
  isVisible: false,
  text: 'Сохранение'
}

export default Overlay
