import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

import { Button, Dropdown } from 'antd'

import GridWrapper from '~components/GridWrapper'
import FilterDropDown from '../FilterDropDown'
import DynamicField from './dynamicField'

import styles from '~components/Publications/Filter/styles.scss'

@inject(stores => {
  const {
    filtersItem,
    selectedFilterItems,
    removeFileItem,
    fetchVideos,
    setIsFirstLoading,
    fetchDictionaries
  } = stores.VideosStore

  const { resetPaginationParams } = stores.FetchPaginationStore
  const { setFilterParams } = stores.FilterParamsByListStore
  const { resetOptions } = stores.SortingStore

  return {
    filtersItem,
    selectedFilterItems,
    removeFileItem,
    resetPaginationParams,
    fetchVideos,
    setFilterParams,
    setIsFirstLoading,
    resetOptions,
    fetchDictionaries
  }
})
@observer
class VideoFilter extends Component {
  componentDidMount() {
    const { fetchDictionaries } = this.props

    fetchDictionaries()
  }

  handleSetFilter = () => {
    const { resetPaginationParams, fetchVideos, setIsFirstLoading } = this.props

    setIsFirstLoading(true)
    resetPaginationParams()
    fetchVideos()
  }

  handleResetFilter = () => {
    const {
      setFilterParams,
      setIsFirstLoading,
      fetchVideos,
      resetPaginationParams,
      resetOptions
    } = this.props

    setFilterParams()
    resetPaginationParams()
    resetOptions()
    setIsFirstLoading(true)
    fetchVideos()
  }

  render() {
    const { selectedFilterItems, removeFileItem, filtersItem } = this.props

    return (
      <div className={styles.root}>
        <GridWrapper gridSize={5} maxItemWidth={285}>
          {filtersItem
            .filter(item => !!item.fix)
            .map((item, index) => (
              <DynamicField key={index} item={item} />
            ))}

          {selectedFilterItems
            .filter(item => item.type !== 'bool')
            .map((item, index) => (
              <DynamicField onRemove={removeFileItem} key={index} item={item} />
            ))}
        </GridWrapper>

        <div className={styles.boolFilterWrapper}>
          {selectedFilterItems
            .filter(item => item.type === 'bool')
            .map((item, index) => (
              <DynamicField onRemove={removeFileItem} key={index} item={item} bool />
            ))}
        </div>

        <div className={styles.filterControls}>
          <Dropdown trigger={['click']} placement="bottomLeft" overlay={<FilterDropDown />}>
            <div className={styles.addNewItem}>
              <Button shape="circle" icon="plus" size="small" type="primary" />

              <span className={styles.addBtnText}>Добавить фильтр</span>
            </div>
          </Dropdown>

          <div className={styles.serviceControl}>
            <Button type="ghost" onClick={this.handleResetFilter}>
              Отменить фильтрацию
            </Button>
            <Button type="primary" onClick={this.handleSetFilter}>
              Применить
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default VideoFilter

VideoFilter.propTypes = {
  dictionaries: PropTypes.any,
  fetchDictionaries: PropTypes.func,
  fetchVideos: PropTypes.func,
  filtersItem: PropTypes.object,
  removeFileItem: PropTypes.func,
  resetPaginationParams: PropTypes.func,
  selectedFilterItems: PropTypes.array,
  setFilterParams: PropTypes.func,
  resetOptions: PropTypes.func,
  setIsFirstLoading: PropTypes.func,
  defaultFilter: PropTypes.string
}
