import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'

import { Checkbox, Select, DatePicker } from 'antd' // TODO: вернуть если запустим стримы

import Labeled from '~components/ItemWithLabel'

import styles from './styles.scss'

const { Group: CheckboxGroup } = Checkbox

const { Option } = Select
const inputStyle = {
  width: '250px'
}

const flagTitle = {
  isMain: 'Главный стрим',
  isMainSide: 'Видео для 1С',
  visible: 'Видимость на сайте',
  blockedByRKN: 'Блокировка РКН',
  darkSide: 'Тёмная сторона'
  // commentsAllowed: 'Комментарии в посте',
}
const flagsOptions = Object.entries(flagTitle).map(([key, value]) => ({ value: key, label: value }))

@inject('VideoStore')
@observer
class Settings extends React.Component {
  render() {
    const {
      availablePlaylists,
      dictionaries,
      onChangeFlags,
      setPlaylists,
      setPriority,
      setType,
      playlists,
      flagsArray,
      priority,
      type,
      startDate, // TODO: вернуть если запустим стримы
      finishDate, // TODO: вернуть если запустим стримы
      setStartDate, // TODO: вернуть если запустим стримы
      setFinishDate, // TODO: вернуть если запустим стримы
      streamStatus // TODO: вернуть если запустим стримы
    } = this.props.VideoStore

    const dictVideoPriorities =
      dictionaries && dictionaries.videoPriorities ? dictionaries.videoPriorities : []
    const dictVideoTypes = dictionaries && dictionaries.videoTypes ? dictionaries.videoTypes : []

    return (
      <div className={styles.wrapper}>
        <Labeled label={'Плейлист'} outerClass={styles.field}>
          <Select
            mode="multiple"
            style={{ width: '400px' }}
            value={playlists}
            placeholder={'Выберите плейлист'}
            defaultValue={undefined}
            onChange={setPlaylists}
          >
            {availablePlaylists.map(({ title, _id }) => (
              <Option key={_id} value={_id}>
                {title}
              </Option>
            ))}
          </Select>
        </Labeled>

        <Labeled label={'Приоритет видео'} outerClass={styles.field}>
          <Select
            style={inputStyle}
            placeholder={'Выберите приоритет'}
            defaultValue={0}
            value={priority}
            onChange={setPriority}
          >
            {dictVideoPriorities.map(priority => (
              <Option key={priority.id} value={priority.id}>
                {priority.id}
              </Option>
            ))}
          </Select>
        </Labeled>

        <Labeled label={'Тип видео'} outerClass={styles.field}>
          <Select
            style={inputStyle}
            placeholder={'Выберите тип'}
            defaultValue={'video'}
            value={type}
            onChange={setType}
          >
            {dictVideoTypes.map(type => (
              <Option key={type.id} value={type.id}>
                {type.ru}
              </Option>
            ))}
          </Select>
        </Labeled>
        {/* TODO: вернуть если запустим стримы */}
        {type === 'VIDEO_TYPE_STREAM' && (
          <div className={styles.streamDateWrapper}>
            <Labeled label="Начать Запись" outerClass={styles.field}>
              <DatePicker
                format="DD.MM.YYYY в HH:mm:ss"
                onChange={setStartDate}
                value={startDate}
                placeholder="Дата и время"
                showTime
                style={inputStyle}
                disabled={streamStatus === 'STREAM_STATUS_FINISHED'}
              />
            </Labeled>

            <Labeled label="Закончить запись" outerClass={styles.field}>
              <DatePicker
                format="DD.MM.YYYY в HH:mm:ss"
                onChange={setFinishDate}
                value={finishDate}
                placeholder="Дата и время"
                showTime
                style={inputStyle}
                disabled={streamStatus === 'STREAM_STATUS_FINISHED'}
              />
            </Labeled>
          </div>
        )}
        {/* TODO: вернуть если запустим стримы */}
        <CheckboxGroup
          className={styles.row}
          options={flagsOptions}
          onChange={onChangeFlags}
          value={flagsArray}
        />
      </div>
    )
  }
}

Settings.propTypes = {
  VideoStore: PropTypes.object
}

export default Settings
