import React, { Component } from 'react'
import { Tabs } from 'antd'

import SettingsNavigation from '~components/SettingsNavigation'
import ContentWrapper from '~components/ContentWrapper'
import Advertising from '~components/Advertising'
import SettingInputPostId from '~components/SettingInputPostId'
import Rss from '~components/Rss'
import s from './styles.scss'

const PAGE_TITLE = 'Общие настройки'
const { TabPane } = Tabs

class SettingsCategoriesSectionsPage extends Component {
  render() {
    return (
      <>
        <SettingsNavigation />
        <ContentWrapper title={PAGE_TITLE}>
          <SettingInputPostId />
          <div className={s.title}>Выберете раздел</div>
          <Tabs type="card">
            <TabPane tab="Реклама" key="1">
              <Advertising />
            </TabPane>
            <TabPane tab="RSS" key="2">
              <Rss />
            </TabPane>
          </Tabs>
        </ContentWrapper>
      </>
    )
  }
}

export default SettingsCategoriesSectionsPage
