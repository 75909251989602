import { observable, action, runInAction, computed } from 'mobx'
import { message } from 'antd'

import API from '~api'
import ConfigureFetchUrlByListsStore from './configureFetchUrlByLists'
import FetchPaginationStore from './fetchPaginationStore'

import { PUBLICATIONS_URL } from './PublicationsStore'

const FLOWS_URL = '/flows'

const getIdFromLink = link => {
  let id = link
  ;[id] = id.split('?')

  if (link.indexOf('/') === -1) {
    return id
  }

  if (link.split('').pop() === '/') {
    link.split('').pop()
    id = link
  }

  return id.split('/').slice(-1)[0]
}

export class FlowsStore {
  @observable isShowModal = false

  @observable isFirstLoading = true

  @observable isActionModal = false

  @observable editableFlow = null

  @observable editableFlowPosts = null

  @observable editableFlowPostsLoading = false

  @observable toPostsFromFlowDelete = []

  @observable allItemsCount = 0

  @observable isLoadingMore = false

  @observable flows = []

  @observable flowsSearches = []

  @observable tempFlow = { id: '', posts: [] }

  @observable searchTerm = ''

  @action
  setSearchTerm = ({ target: { value: term } }) => {
    this.searchTerm = term
  }

  @action
  onSearch = search => {
    const id = getIdFromLink(search)

    if (!id) {
      message.error('Поле не может быть пустым!')
      return
    }

    const findInAdded = (this.editableFlowPosts || []).findIndex(
      p => p._id === id || p.alias === id || String(p.index) === id
    )

    if (findInAdded > -1) {
      message.info('Уже добавлена!')
      return
    }

    message.warn('Добавление...')
    API.get(`${PUBLICATIONS_URL}/${id}`)
      .then(
        ({
          data: {
            data: { cover, title, _id, alias, index }
          }
        }) => {
          message.success('Успешно')

          API.put(`${PUBLICATIONS_URL}/${id}/flow/${this.editableFlow._id}`).catch(e => {
            const data = e?.response?.data
            const errorMessage = data?.error?.message
            message.error(
              `А нет, все-таки ошибка :( Сам пост нашли, но привязать его не удалось: ${errorMessage ??
                e.message ??
                'неизвестная ошибка'}`,
              5
            )
          })

          runInAction(() => {
            this.searchTerm = ''
            this.editableFlowPosts = [
              ...(this.editableFlowPosts || []),
              { cover, title, _id, alias, index }
            ]
          })
        }
      )
      .catch(e => {
        console.error(e)
      })
  }

  @action
  deletePost = (id, index) => {
    message.warn('Удаление')
    const postIndex = this.editableFlowPosts.findIndex(({ _id }) => _id === id)

    if (postIndex > -1) {
      API.delete(`${PUBLICATIONS_URL}/${id}/flow/${this.editableFlow._id}`)
        .then(() => {
          message.success('Успешно')
          this.toPostsFromFlowDelete = [...this.toPostsFromFlowDelete, id]
          const temp = this.editableFlowPosts.slice()
          temp.splice(index, 1)
          this.editableFlowPosts = temp
        })
        .catch(e => {
          message.error('Что-то пошло не так при отвязке постов: ', e)
        })
    }
  }

  @action getFlowPosts = flowId => {
    this.editableFlowPostsLoading = true
    API.get(
      `${PUBLICATIONS_URL}?filter.resourceType=RESOURCE_TYPE_PUBLICATION&filter.flows=${flowId}`
    )
      .then(({ data: { data } }) => {
        runInAction(() => {
          this.editableFlowPosts = data
          this.editableFlowPostsLoading = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.editableFlowPostsLoading = false
        })
      })
  }

  @action
  fetchFlowsBySearch = term => {
    let url = FLOWS_URL

    if (term) {
      url = `${url}?search=${term}`
    }

    return API.get(url).then(res => {
      const { data } = res.data

      runInAction(() => {
        this.flowsSearches = data
      })

      return data
    })
  }

  @action
  fetchFlows = () => {
    const { offset } = FetchPaginationStore
    const { getPromiseUrl } = ConfigureFetchUrlByListsStore

    const promiseUrl = getPromiseUrl({
      url: FLOWS_URL
    })

    const fetchFlowsPromise = API.get(promiseUrl)

    this.setIsLoadingMore(true)
    return fetchFlowsPromise
      .then(res => {
        const { data, meta } = res.data

        if (offset === 0) {
          this.setFlows(data)
        } else {
          this.setFlows([...this.flows, ...data])
        }

        this.setAllItemsCount(meta.count)
        this.setIsFirstLoading(false)
        this.setIsLoadingMore(false)
      })
      .catch(() => {
        this.setIsFirstLoading(false)
        this.setIsLoadingMore(false)
      })
  }

  @action
  updateFlow = data => {
    message.warn('Сохранение')
    runInAction(() => {
      this.isActionModal = true
    })

    const editableFlowIndex = this.flows.indexOf(this.editableFlow)

    API.patch(`${FLOWS_URL}/${this.editableFlow._id}`, { data })
      .then(() => {
        message.success('Успешно')
        runInAction(() => {
          this.isActionModal = false
          this.flows[editableFlowIndex] = {
            ...this.flows[editableFlowIndex],
            ...data
          }
        })
        this.setHideModal()
      })
      .catch(e => {
        const data = e?.response?.data
        const errorMessage = data?.error?.message
        if (data.error.message === 'E_FLOW_UNIQUE') {
          message.error(`Поле "${data.error.meta.field}" не уникально!`)
          return
        }
        message.error(
          `Ошибка обновления потока: ${errorMessage ?? e.message ?? 'неизвестная ошибка'}`
        )

        runInAction(() => {
          this.isActionModal = false
        })
      })

    // Promise.all(
    //   this.editableFlowPosts.map(({ _id }) => API.put(`${PUBLICATIONS_URL}/${_id}/flow/${flowId}`))
    // ).catch(() => {
    //   message.error('Что-то пошло не так при привязке постов')
    // })

    // if (this.toPostsFromFlowDelete.length > 0) {
    //   Promise.all(
    //     this.toPostsFromFlowDelete.map(_id =>
    //       API.delete(`${PUBLICATIONS_URL}/${_id}/flow/${flowId}`)
    //     )
    //   ).catch(() => {
    //     message.error('Что-то пошло не так при отвязке постов')
    //   })
    // }

    this.isActionModal = false
  }

  @action
  createFlow = data => {
    runInAction(() => {
      this.isActionModal = true
      this.toPostsFromFlowDelete = []
    })

    message.warn('Создание')

    API.post(`${FLOWS_URL}`, { data })
      .then(({ data: { data } }) => {
        runInAction(() => {
          this.isActionModal = false
          this.flows = [data, ...this.flows]
          this.allItemsCount += 1
        })

        const flowId = data._id

        Promise.all(
          (this.editableFlowPosts || [])
            .slice()
            .map(({ _id }) => API.put(`${PUBLICATIONS_URL}/${_id}/flow/${flowId}`))
        ).catch(() => {
          message.error('Что-то пошло не так при привязке постов')
        })
        this.setHideModal()
        message.success('Успешно')
      })
      .catch(e => {
        const data = e?.response?.data
        const errorMessage = data?.error?.message
        if (errorMessage === 'E_FLOW_UNIQUE') {
          message.error(`Поле "${data.error.meta.field}" не уникально!`)
          return
        }
        message.error(
          `Ошибка создания потока: ${errorMessage ?? e.message ?? 'неизвестная ошибка'}`
        )

        runInAction(() => {
          this.isActionModal = false
        })
      })

    this.isActionModal = false
  }

  @action
  deleteFlow = () => {
    runInAction(() => {
      this.isActionModal = true
    })

    const { _id } = this.editableFlow

    const deleteAuthorPromise = API.delete(`${FLOWS_URL}/${_id}`)

    return deleteAuthorPromise
      .then(() => {
        this.flows.remove(this.editableFlow)

        this.resetEditableFlow()
        this.setHideModal()
        this.setAllItemsCount(this.allItemsCount - 1)

        runInAction(() => {
          this.isActionModal = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @computed
  get isEmptyFlows() {
    return !this.flows.length
  }

  @action
  setEditableFlow = value => {
    this.editableFlow = value

    this.setShowModal()
  }

  @action
  resetEditableFlow = () => {
    this.editableFlow = null
  }

  @action
  setShowModal = () => {
    this.isShowModal = true
    if (this.editableFlow) {
      this.getFlowPosts(this.editableFlow._id)
    }
  }

  @action
  setHideModal = () => {
    this.isShowModal = false
    this.editableFlowPosts = null
    this.toPostsFromFlowDelete = []
  }

  @action
  setIsFirstLoading = value => {
    this.isFirstLoading = value
  }

  @action
  setIsLoadingMore = value => {
    this.isLoadingMore = value
  }

  @action
  setFlows = data => {
    this.flows = data
  }

  @action
  setAllItemsCount = count => {
    this.allItemsCount = count
  }

  @computed
  get flowsLength() {
    return this.flows.length
  }
}

export default new FlowsStore()
