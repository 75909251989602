import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { observer, inject } from 'mobx-react'

import ContentWrapper from '~components/ContentWrapper'
import { EmployeeList, EmployeesModal } from '~components/Employees'
import HeaderPageSearchBar from '~components/HeaderPageSearchBar'
import InfinityScroll from '~components/InfinityScroll'

const PAGE_TITLE = 'Все сотрудники'
const COUNT_DECLINATIONS = ['сотрудник', 'сотрудника', 'сотрудников']
const SEARCH_BTN_TEXT = 'Найти в сотрудниках'
const CREATE_BTN_TEXT = 'Добавить сотрудника'

@inject('EmployeesStore', 'FilterParamsByListStore', 'FetchPaginationStore')
@observer
class EmployeesPage extends Component {
  componentDidMount() {
    const { EmployeesStore } = this.props

    EmployeesStore.fetchEmployeePositions()
  }

  componentWillUnmount() {
    const { FilterParamsByListStore, FetchPaginationStore } = this.props

    FilterParamsByListStore.setFilterParams()
    FetchPaginationStore.resetPaginationParams()
  }

  handleSearchPublications = () => {
    const { fetchEmployees, setIsFirstLoading } = this.props.EmployeesStore

    setIsFirstLoading(true)
    fetchEmployees()
  }

  render() {
    const { EmployeesStore } = this.props
    const {
      setShowModal,
      fetchEmployees,
      allItemsCount,
      employeesLength,
      isFirstLoading,
      isLoadingMore
    } = EmployeesStore

    return (
      <Fragment>
        <HeaderPageSearchBar
          searchButtonText={SEARCH_BTN_TEXT}
          createButtonText={CREATE_BTN_TEXT}
          onCreateElement={setShowModal}
          onSearch={this.handleSearchPublications}
        />
        <ContentWrapper title={PAGE_TITLE} countItems={20} countDeclinations={COUNT_DECLINATIONS}>
          <InfinityScroll
            loadItems={fetchEmployees}
            isEndLoading={allItemsCount <= employeesLength}
            isFirstLoading={isFirstLoading}
            isLoadMore={isLoadingMore}
          >
            <EmployeeList />
          </InfinityScroll>
        </ContentWrapper>

        <EmployeesModal />
      </Fragment>
    )
  }
}

export default EmployeesPage

EmployeesPage.propTypes = {
  EmployeesStore: PropTypes.any,
  FetchPaginationStore: PropTypes.any,
  FilterParamsByListStore: PropTypes.any
}
