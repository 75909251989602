import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { noop } from 'lodash'
import { withRouter } from 'react-router-dom'
import cn from 'classnames'
import { Button, Icon, Modal, Alert } from 'antd'

import Authorized from '~components/Authorized/Authorized'
import { getRoleExceptSome, role } from '~utils/roles'

import PagePreview from '~components/PagePreview'
import PostponeInput from '~pages/EditorPage/components/PostponeInput'
// import IconClose from '~assets/icons/close.svg'

import styles from './styles.scss'

@inject('PublicationStore')
@observer
class Actions extends React.Component {
  state = {
    postponeModal: false,
    url: ''
  }

  openPostponeModal = () => {
    const { lockField = noop } = this.props.PublicationStore
    this.setState({ postponeModal: true })
    lockField({ field: 'settings' })
  }

  closePostponeModal = () => {
    const { unlockField = noop } = this.props.PublicationStore
    this.setState({ postponeModal: false })
    unlockField({ field: 'settings' })
  }

  onClickGuestLink = () => {
    this.props.PublicationStore.previewPublication().then(url => {
      window.open(url, '_blank')
    })
  }

  onClickPreview = () => {
    this.props.PublicationStore.previewPublication().then(url => {
      this.setState({ url })
    })
  }

  closePreview = () => {
    this.setState({ url: '' })
  }

  onClickPublish = () => {
    const {
      PublicationStore: { status, publishPublication, unpublishPublication }
    } = this.props

    if (status.id !== 'POST_STATUS_PUBLISHED') {
      publishPublication()
    } else {
      Modal.confirm({
        title: 'Вы действительно хотите снять с публикации?',
        // content: 'When clicked the OK button, this dialog will be closed after 1 second',
        onOk: () => {
          unpublishPublication()
        },
        onCancel() {}
      })
    }
  }

  onClickPostpone = () => {
    const {
      PublicationStore: { /* status, */ postponePublication }
    } = this.props

    postponePublication().then(() => {
      this.setState({ postponeModal: false })
    })
  }

  onClickDelete = () => {
    Modal.confirm({
      title: 'Вы действительно хотите удалить публикацию?',
      // content: 'When clicked the OK button, this dialog will be closed after 1 second',
      onOk: () => {
        const { PublicationStore, history } = this.props
        return PublicationStore.delete().then(history.goBack)
      },
      onCancel() {}
    })
  }

  render() {
    const {
      PublicationStore: {
        // saving,
        status: { id: statusId },
        id,
        token,
        savePublication,
        isShowNotifyPlannedPost,
        showSettingsDrawer,
        isFetching,
        fieldLocked
      },
      buttonSize,
      type,
      noMargin
    } = this.props

    const linkBtnType = type !== 'fixed' ? 'link' : 'ghost'

    const { blocked: isBlocked } = (fieldLocked && fieldLocked.settings) || {}

    return (
      <Fragment>
        <div className={cn(styles.buttons, noMargin && styles.noMargin, styles.desktop)}>
          <Authorized authority={getRoleExceptSome(role.INTERN)} noMatch={null}>
            <Button
              disabled={isFetching}
              size={buttonSize}
              type="primary"
              className={cn(styles.button, styles.publish)}
              onClick={this.onClickPublish}
            >
              {statusId === 'POST_STATUS_PUBLISHED' ? 'Снять с публикации' : 'Опубликовать'}
            </Button>
          </Authorized>

          {statusId !== 'POST_STATUS_PUBLISHED' && (
            <Authorized authority={getRoleExceptSome(role.INTERN)} noMatch={null}>
              <Button
                disabled={isFetching || isBlocked}
                size={buttonSize}
                type="primary"
                className={cn(styles.button, styles.postpone)}
                onClick={this.openPostponeModal}
              >
                Отложить
              </Button>
            </Authorized>
          )}

          <Button
            type={linkBtnType}
            size={buttonSize}
            className={cn(styles.button, styles.delete, type === 'fixed' && styles.deleteFixed)}
            disabled={isFetching}
            onClick={this.onClickDelete}
          >
            <Icon type="close" />
            {type !== 'fixed' && 'Удалить'}
          </Button>

          <Button
            size={buttonSize}
            type={linkBtnType}
            disabled={isFetching}
            className={cn(styles.button, styles.guest)}
            onClick={this.onClickGuestLink}
          >
            <Icon type="link" />
            {type !== 'fixed' && 'Гостевая ссылка'}
          </Button>
          <Button
            size={buttonSize}
            type={linkBtnType}
            className={cn(
              styles.button,
              styles.buttonNoBorder,
              type === 'fixed' && styles.fixedPreviewBtn
            )}
            disabled={isFetching}
            onClick={this.onClickPreview}
          >
            <Icon type="eye" />
            {type !== 'fixed' && 'Предпросмотр'}
          </Button>
          <Button
            disabled={isFetching}
            type="primary"
            size={buttonSize}
            className={cn(styles.button, styles.save)}
            onClick={savePublication}
          >
            Сохранить
          </Button>

          <Modal
            title="Выберите дату публикации"
            visible={this.state.postponeModal}
            onOk={this.onClickPostpone}
            onCancel={this.closePostponeModal}
            okText="Отложить"
          >
            <PostponeInput />

            {isShowNotifyPlannedPost && (
              <div className={styles.timeAlertWrapprt}>
                <Alert
                  showIcon
                  message="Внимание. При нажатии на кнопку 'Отложить' вы опубликуете данный материал с датой предшествующей текущей."
                  type="warning"
                />
              </div>
            )}
          </Modal>

          <PagePreview
            postId={id}
            token={token}
            url={this.state.url}
            visible={this.state.url !== ''}
            onClose={this.closePreview}
          />
        </div>

        <div className={cn(styles.mobile)}>
          {statusId === 'POST_STATUS_PUBLISHED' && (
            <div style={{ width: '100%' }}>
              <Button
                disabled={isFetching}
                icon={statusId === 'POST_STATUS_PUBLISHED' ? 'stop' : 'arrow-up'}
                size="small"
                type="link"
                className={styles.mobileCancelButton}
                onClick={this.onClickPublish}
              >
                Снять с публикации
              </Button>
            </div>
          )}

          <div className={styles.buttons}>
            {statusId !== 'POST_STATUS_PUBLISHED' && (
              <Button
                disabled={isFetching}
                size={buttonSize}
                type="primary"
                className={cn(styles.button, styles.publish)}
                onClick={this.onClickPublish}
              >
                Опубликовать
              </Button>
            )}

            {statusId !== 'POST_STATUS_PUBLISHED' && (
              <Button
                disabled={isFetching}
                size={buttonSize}
                type="primary"
                className={cn(styles.button, styles.postpone)}
                onClick={this.openPostponeModal}
              >
                Отложить
              </Button>
            )}

            <Button
              size={buttonSize}
              type={linkBtnType}
              className={cn(
                styles.button,
                styles.buttonNoBorder,
                type === 'fixed' && styles.fixedPreviewBtn
              )}
              disabled={isFetching}
              onClick={this.onClickPreview}
            >
              <Icon type="eye" />
              {type !== 'fixed' && 'Предпросмотр'}
            </Button>

            <Button
              size={buttonSize}
              type={linkBtnType}
              disabled={isFetching}
              className={cn(styles.button, styles.guest)}
              onClick={this.onClickGuestLink}
            >
              <Icon type="link" />
              {type !== 'fixed' && 'Гостевая ссылка'}
            </Button>

            <Button
              type={linkBtnType}
              size={buttonSize}
              className={cn(styles.button, styles.delete, type === 'fixed' && styles.deleteFixed)}
              disabled={isFetching}
              onClick={this.onClickDelete}
            >
              <Icon type="close" />
              {type !== 'fixed' && 'Удалить'}
            </Button>

            <Button
              disabled={isFetching}
              type="primary"
              size={buttonSize}
              className={cn(styles.button, styles.save)}
              onClick={savePublication}
            >
              Сохранить
            </Button>
          </div>

          <div>
            <Button
              className={styles.mobileSettingButton}
              icon="setting"
              size="small"
              type="link"
              onClick={showSettingsDrawer}
            >
              Настройки публикации
            </Button>
          </div>
        </div>
      </Fragment>
    )
  }
}

Actions.propTypes = {
  PublicationStore: PropTypes.object,
  buttonSize: PropTypes.any,
  history: PropTypes.object,
  noMargin: PropTypes.any,
  type: PropTypes.any
}

export default withRouter(Actions)
