import { observable, action, runInAction } from 'mobx'
import { find, findIndex } from 'lodash'
import API from '~api'

const SECTIONS_URL = '/sections'

class SectionsStore {
  @observable sections = []

  @observable isFirstLoading = true

  @observable isActionModal = false

  @observable isShowModal = false

  @observable editableSection = null

  @action
  fetchSections = () => {
    const fetchSectionsPromise = API.get(`${SECTIONS_URL}?sort=+order`)

    return fetchSectionsPromise
      .then(res => {
        const { data } = res.data

        runInAction(() => {
          this.sections = data.sort(({ order: a }, { order: b }) => a - b)
          this.isFirstLoading = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isFirstLoading = false
        })
      })
  }

  @action
  changeOrdering = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return

    const current = find(this.sections, { order: oldIndex })
    const target = find(this.sections, { order: newIndex })

    const currentId = find(this.sections, { order: oldIndex })._id
    const targetId = findIndex(this.sections, ({ order }) => order === newIndex)
    let targetPlacement = this.sections[targetId + 1]._id

    if (oldIndex > newIndex) {
      targetPlacement = find(this.sections, { order: newIndex })._id
    }

    const changeOrderingPromise = API.post(`${SECTIONS_URL}/${currentId}/placement`, {
      meta: {
        before: targetPlacement
      }
    })

    current.order = newIndex
    target.order = newIndex + 0.5

    this.sections = this.sections.slice().sort(({ order: a }, { order: b }) => a - b)

    changeOrderingPromise.then(() => {
      this.fetchSections()
    })
  }

  @action
  createSection = section => {
    runInAction(() => {
      this.isActionModal = true
    })

    const createSectionPromise = API.post(SECTIONS_URL, section)

    return createSectionPromise
      .then(res => {
        const { data } = res.data
        this.setHideModal()

        runInAction(() => {
          this.isActionModal = false
          this.sections.unshift(data)
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @action
  updateSection = section => {
    runInAction(() => {
      this.isActionModal = true
    })

    const { _id } = this.editableSection
    const updateSectionPromise = API.patch(`${SECTIONS_URL}/${_id}`, section)

    return updateSectionPromise
      .then(res => {
        const { data } = res.data
        const editableSectionIndex = this.sections.indexOf(this.editableSection)

        this.setHideModal()

        runInAction(() => {
          this.isActionModal = false
          this.sections[editableSectionIndex] = data
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @action
  deleteSection = () => {
    runInAction(() => {
      this.isActionModal = true
    })

    const { _id } = this.editableSection
    const deleteSectionPromise = API.delete(`${SECTIONS_URL}/${_id}`)

    return deleteSectionPromise
      .then(() => {
        this.sections.remove(this.editableSection)

        this.resetEditableSection()
        this.setHideModal()

        runInAction(() => {
          this.isActionModal = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @action
  setEditableSection = value => {
    this.editableSection = { seo: { title: '', description: '' }, ...value }

    this.setShowModal()
  }

  @action
  resetEditableSection = () => {
    this.editableSection = null
  }

  @action
  setShowModal = () => {
    this.isShowModal = true
  }

  @action
  setHideModal = () => {
    this.isShowModal = false
  }
}

export default new SectionsStore()
