import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'

import { withErrorBoundary } from '~components/ErrorBoundary'
import BlockGroup from '../BlockGroup'
import AddIcon from '~assets/icons/add.svg'

import styles from '../../styles.scss'

@inject('PublicationStore')
@observer
class EditorTextTranslation extends Component {
  render() {
    const {
      onEditImage,
      onKeyDown,
      PublicationStore: { blockGroups, createBlockGroup, symbols }
    } = this.props

    return (
      <>
        <div className={styles.addBtn}>
          <AddIcon onClick={createBlockGroup} />
        </div>
        {React.Children.toArray(
          blockGroups.map(group => (
            <BlockGroup
              key={group._id}
              group={group}
              symbols={symbols}
              onEditImage={onEditImage}
              onKeyDown={onKeyDown}
            />
          ))
        )}
      </>
    )
  }
}

EditorTextTranslation.propTypes = {
  onEditImage: PropTypes.func,
  onKeyDown: PropTypes.func,
  PublicationStore: PropTypes.any
}

EditorTextTranslation.defaultProps = {
  onEditImage: () => {},
  onKeyDown: () => {}
}

export default withErrorBoundary(EditorTextTranslation)
