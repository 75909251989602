import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

import EmployeeItem from '~components/Employees/EmployeeItem'
import GridWrapper from '~components/GridWrapper'
import ContentSpinner from '~components/ContentSpinner'
import EmptyContent from '~components/EmptyContent'

@inject(stores => {
  const { employees, isFirstLoading, isEmptyEmployees } = stores.EmployeesStore

  return {
    employees,
    isFirstLoading,
    isEmptyEmployees
  }
})
@observer
class EmployeeList extends Component {
  render() {
    const { employees, isFirstLoading, isEmptyEmployees } = this.props

    if (isFirstLoading) {
      return <ContentSpinner />
    }

    if (isEmptyEmployees) return <EmptyContent text="Нет сотрудников" />

    return (
      <GridWrapper gridSize={2}>
        {employees.map(employee => (
          <EmployeeItem key={employee._id} employee={employee} />
        ))}
      </GridWrapper>
    )
  }
}

export default EmployeeList

EmployeeList.propTypes = {
  isFirstLoading: PropTypes.bool,
  isEmptyEmployees: PropTypes.bool,
  employees: PropTypes.array
}
