import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'

import ArrowRight from '~assets/icons/arrowRight.svg'

import styles from './styles.scss'

// eslint-disable-next-line react/prop-types
const ItemWrapper = ({ index, label, children }) => {
  return (
    <div className={styles.root}>
      <div className={styles.title}>
        {index + 1}. {label}
      </div>

      <div className={styles.changes}>{children}</div>
    </div>
  )
}

const getPrettyString = changeValue => {
  try {
    const parsedJson = JSON.parse(changeValue)
    if (parsedJson.content) {
      return JSON.stringify(JSON.parse(parsedJson.content), null, 2)
    }
    return JSON.stringify(parsedJson, null, 2)
  } catch (e) {
    return changeValue
  }
}

class ChangeSection extends Component {
  render() {
    const { index, data, type } = this.props
    const { field, newValue, oldValue } = data

    if (type === 'HISTORY_CHANGE_TYPE_UPDATE') {
      return (
        <ItemWrapper index={index} label={field}>
          <div className={classNames(styles.text, styles.old)}>
            <pre>{getPrettyString(oldValue)}</pre>
          </div>

          <div className={styles.arrow}>
            <ArrowRight fill="#9A9A9A" />
          </div>

          <div className={classNames(styles.text, styles.new)}>
            <pre>{getPrettyString(newValue)}</pre>
          </div>
        </ItemWrapper>
      )
    }

    if (type === 'HISTORY_CHANGE_TYPE_CREATE') {
      return (
        <ItemWrapper index={index} label={field}>
          <div className={classNames(styles.text, styles.new)}>
            <pre>{getPrettyString(newValue)}</pre>
          </div>
        </ItemWrapper>
      )
    }

    return (
      <ItemWrapper index={index} label={field}>
        <div className={classNames(styles.text, styles.old)}>
          <pre>{getPrettyString(oldValue)}</pre>
        </div>
      </ItemWrapper>
    )
  }
}

export default ChangeSection

ItemWrapper.propTypes = {
  index: PropTypes.number,
  label: PropTypes.any,
  children: PropTypes.any
}

ChangeSection.propTypes = {
  changeLogs: PropTypes.any,
  data: PropTypes.array,
  index: PropTypes.number,
  isEmptyTags: PropTypes.bool,
  isFirstLoading: PropTypes.bool,
  type: PropTypes.any
}

ItemWrapper.propTypes = {
  children: PropTypes.any,
  index: PropTypes.number,
  label: PropTypes.string
}
