import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { sortableContainer, sortableElement } from 'react-sortable-hoc'

import GridWrapper from '~components/GridWrapper'
import ContentSpinner from '~components/ContentSpinner'
import SpecProjectsItem from '~components/SpecProjects/SpecProjectsItem'
import AddNew from '~components/AddNew'

const SortableItem = sortableElement(({ project }) => <SpecProjectsItem data={project} />)

const SortableContainer = sortableContainer(({ children }) => {
  return (
    <GridWrapper gridSize={3} maxItemWidth={285}>
      {children}
    </GridWrapper>
  )
})

@inject('SpecProjectsStore')
@observer
class SpecProjectsList extends Component {
  componentDidMount() {
    const {
      SpecProjectsStore: { fetchSpecProjects }
    } = this.props

    fetchSpecProjects()
  }

  render() {
    const {
      SpecProjectsStore: { specProjects, setShowModal, isFirstLoading, changeOrdering }
    } = this.props

    if (isFirstLoading) {
      return <ContentSpinner />
    }

    return (
      <SortableContainer distance={1} axis="xy" onSortEnd={changeOrdering}>
        {specProjects.map(project => (
          <SortableItem key={project._id} project={project} index={project.order} />
        ))}
        <AddNew onClick={setShowModal} />
      </SortableContainer>
    )
  }
}

SpecProjectsList.propTypes = {
  SpecProjectsStore: PropTypes.any
}

export default SpecProjectsList
