// TODO: json-mobx for editor undo/redo?
// import { observable } from 'mobx'
import UserStore from './UserStore'
import PublicationsStore from './PublicationsStore'
import TagsStore from './TagsStore'
import FlowsStore from './FlowsStore'
import FeedbackStore from './FeedbackStore'
import VideoStore from './VideoStore'
import VideosStore from './VideosStore'
import AuthorsStore from './AuthorsStore'
import DepartmentsStore from './DepartmentsStore'
import ChangeLogStore from './ChangeLogStore'
import EmployeesStore from './EmployeesStore'
import FetchPaginationStore from './fetchPaginationStore'
import FilterParamsByListStore from './filterParamsByList'
import SocialsStore from './SocialsStore'
import EmailsStore from './EmailsStore'
import CategoriesStore from './CategoriesStore'
import SectionsStore from './SectionsStore'
import SortingStore from './SortingStore'
import ScheduleStore from './ScheduleStore'
import TimersStore from './TimersStore'
import SymbolsStore from './SymbolsStore'
import PlaylistsStore from './PlaylistsStore'
import InfoPagesStore from './InfoPagesStore'
import ConfigureFetchUrlByListsStore from './configureFetchUrlByLists'
import SpecProjectsStore from './SpecProjectsStore'
import SettingsGlobalStore from './SettingsGlobalStore'
import RssStore from './RssStore'
import CommentsStore from './CommentsStore'
import OverlayStore from './OverlayStore'
import BadWordsStore from './BadWordsStore'

import PublicationStore from './PublicationStore'

export default {
  UserStore,
  PublicationsStore,
  TagsStore,
  FlowsStore,
  FeedbackStore,
  AuthorsStore,
  DepartmentsStore,
  ChangeLogStore,
  EmployeesStore,
  FetchPaginationStore,
  FilterParamsByListStore,
  SocialsStore,
  EmailsStore,
  CategoriesStore,
  SectionsStore,
  SortingStore,
  ScheduleStore,
  TimersStore,
  ConfigureFetchUrlByListsStore,
  SymbolsStore,
  InfoPagesStore,
  PlaylistsStore,
  PublicationStore,
  VideoStore,
  VideosStore,
  SpecProjectsStore,
  SettingsGlobalStore,
  RssStore,
  CommentsStore,
  OverlayStore,
  BadWordsStore
}
