import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { observer, inject } from 'mobx-react'

import ContentWrapper from '~components/ContentWrapper'
import { FlowList, FlowsModal } from '~components/Flows'
import HeaderPageSearchBar from '~components/HeaderPageSearchBar'
import InfinityScroll from '~components/InfinityScroll'

const PAGE_TITLE = 'Потоки'
const COUNT_DECLINATIONS = ['поток', 'потока', 'потоков']
const SEARCH_BTN_TEXT = 'Найти в потоках'
const CREATE_BTN_TEXT = 'Добавить поток'

@inject('FlowsStore', 'FilterParamsByListStore', 'FetchPaginationStore')
@observer
class FlowsPage extends Component {
  componentWillUnmount() {
    const { FilterParamsByListStore, FetchPaginationStore } = this.props

    FilterParamsByListStore.setFilterParams()
    FetchPaginationStore.resetPaginationParams()
  }

  handleSearchPublications = () => {
    const { fetchFlows, setIsFirstLoading } = this.props.FlowsStore

    setIsFirstLoading(true)
    fetchFlows()
  }

  render() {
    const { FlowsStore } = this.props
    const {
      setShowModal,
      isLoadingMore,
      isFirstLoading,
      allItemsCount,
      fetchFlows,
      flowsLength,
      editableFlowPosts
    } = FlowsStore

    return (
      <Fragment>
        <HeaderPageSearchBar
          searchButtonText={SEARCH_BTN_TEXT}
          createButtonText={CREATE_BTN_TEXT}
          onCreateElement={setShowModal}
          onSearch={this.handleSearchPublications}
        />
        <ContentWrapper
          title={PAGE_TITLE}
          countItems={allItemsCount}
          countDeclinations={COUNT_DECLINATIONS}
        >
          <InfinityScroll
            loadItems={fetchFlows}
            isEndLoading={allItemsCount <= flowsLength}
            isFirstLoading={isFirstLoading}
            isLoadMore={isLoadingMore}
          >
            <FlowList />
          </InfinityScroll>
        </ContentWrapper>

        <FlowsModal editableFlowPosts={editableFlowPosts} />
      </Fragment>
    )
  }
}

export default FlowsPage

FlowsPage.propTypes = {
  FetchPaginationStore: PropTypes.any,
  FilterParamsByListStore: PropTypes.any,
  FlowsStore: PropTypes.any
}
