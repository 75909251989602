import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'

import { Tooltip, Icon } from 'antd'

import { withErrorBoundary } from '~components/ErrorBoundary'
import ArrowRight from '~assets/icons/arrowRight.svg'

import styles from './styles.scss'

const UserOnPage = ({ firstName, lastName, avatar }) => (
  <Tooltip placement="bottom" title={`${firstName} ${lastName}`} arrowPointAtCenter>
    <div className={styles.user}>
      {avatar ? (
        <img src={avatar.url} alt={`${firstName} ${lastName}`} className={styles.userAvatar} />
      ) : (
        <span className={styles.userLetter}>{firstName[0]}</span>
      )}
    </div>
  </Tooltip>
)

const CenterControl = ({ iconType, label, onClick }) => {
  return (
    <div className={styles.centerControlItem} onClick={onClick}>
      {iconType && (
        <Fragment>
          <Icon type="message" />{' '}
        </Fragment>
      )}
      {label}
    </div>
  )
}

UserOnPage.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  avatar: PropTypes.object
}

const Header = inject(stores => {
  const { previewPublication, usersOnPage, openCommentsModal } = stores.PublicationStore
  return { previewPublication, usersOnPage, openCommentsModal }
})(
  observer(props => {
    const {
      history,
      match: { path },
      usersOnPage,
      openCommentsModal
    } = props

    const onClickExit = () => {
      history.push(path.slice(0, -4))
    }

    return (
      <div className={styles.editorHeader}>
        <div className={styles.wrapper}>
          <div className={styles.backButton} onClick={onClickExit}>
            <ArrowRight fill={'currentColor'} style={{ transform: 'rotate(180deg) scale(.5)' }} />
            <span>Выйти из редактирования</span>
          </div>

          <div className={styles.centerControl}>
            <CenterControl iconType="message" label="Комментарии" onClick={openCommentsModal} />
          </div>

          <div className={styles.users}>
            {/* eslint-disable-next-line react/jsx-key */}
            {React.Children.toArray(usersOnPage.map(user => <UserOnPage {...user} />))}
          </div>
        </div>
      </div>
    )
  })
)

Header.propTypes = {
  previewPublication: PropTypes.func,
  openCommentsModal: PropTypes.func,
  usersOnPage: PropTypes.array,
  history: PropTypes.any,
  match: PropTypes.any
}

CenterControl.propTypes = {
  iconType: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func
}

export default withRouter(withErrorBoundary(Header))
