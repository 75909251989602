import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { Result } from 'antd'

import check from './сheckPermissions'

const Authorized = inject('UserStore')(
  observer(
    ({
      role = null,
      UserStore,
      children,
      authority = '',
      noMatch = <Result status="403" title="403" subTitle="У вас нет доступа к этой странице." />
    }) => {
      const childrenRender = typeof children === 'undefined' ? null : children

      const dom = check(authority, role || UserStore.user.role, childrenRender, noMatch)
      return <>{dom}</>
    }
  )
)

Authorized.propTypes = {
  role: null,
  children: PropTypes.any,
  authority: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  noMatch: PropTypes.element
}

export default Authorized
