import React from 'react'
import cn from 'classnames'

import { Button } from 'antd'

import styles from './styles.scss'

const Btn = props => <Button {...props} className={cn(styles.button, props.className)} />

Btn.propTypes = {
  ...Button.propTypes
}

Btn.displayName = 'Button'

export default Btn
