import PropTypes from 'prop-types'
import React from 'react'

import CloseIcon from '~assets/icons/close.svg'

import styles from './styles.scss'

const ItemWithLabel = ({ label, children, onRemove, outerClass }) => {
  return (
    <div className={outerClass}>
      <span className={styles.label}>
        {onRemove && (
          <div onClick={onRemove} className={styles.deleteButton}>
            <CloseIcon width={12} fill="#9A9A9A" />
          </div>
        )}
        {label}
      </span>

      {children}
    </div>
  )
}

export default ItemWithLabel

ItemWithLabel.propTypes = {
  children: PropTypes.element,
  label: PropTypes.string,
  outerClass: PropTypes.string,
  onRemove: PropTypes.func
}
