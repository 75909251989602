import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { Input, Checkbox, Button, Form } from 'antd'

import DeleteButton from '~components/DeleteButton'
import ItemWithLabel from '~components/ItemWithLabel'
import Modal from '~components/Modal'
import s from './styles.scss'

const CREATE_TITLE = 'Создание новой страницы'
const UPDATE_TITLE = 'Редактирование страницы'

@inject('InfoPagesStore')
@observer
class InfoPagesModal extends Component {
  handleSubmit = e => {
    e.preventDefault()

    const {
      InfoPagesStore: { editablePage, updateInfoPage, createInfoPage },
      form
    } = this.props

    const formData = form.getFieldsValue()
    const category = { data: { ...formData } }
    const action = editablePage ? updateInfoPage : createInfoPage

    action(category)
  }

  render() {
    const {
      InfoPagesStore: {
        setHideModal,
        isShowModal,
        resetEditablePage,
        editablePage,
        deleteInfoPage
      },
      form
    } = this.props
    const { getFieldDecorator } = form
    const ModalTitle = editablePage ? UPDATE_TITLE : CREATE_TITLE

    return (
      <Modal
        destroyOnClose
        width={590}
        onClose={setHideModal}
        isVisible={isShowModal}
        title={ModalTitle}
        afterClose={resetEditablePage}
      >
        <Form onSubmit={this.handleSubmit}>
          <ItemWithLabel label="Название">
            <Form.Item>
              {getFieldDecorator('title', {
                initialValue: editablePage && editablePage.title,
                rules: [{ required: true, message: 'Обязательное поле!' }]
              })(<Input placeholder="Название страницы" />)}
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="URL">
            <Form.Item>
              {getFieldDecorator('url', {
                initialValue: editablePage && editablePage.url,
                rules: [{ required: true, message: 'Обязательное поле!' }]
              })(<Input placeholder="Введите адрес" />)}
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="Код страницы">
            <Form.Item>
              {getFieldDecorator('content', {
                initialValue: editablePage && editablePage.content,
                rules: [{ required: true, message: 'Обязательное поле!' }]
              })(<Input.TextArea />)}
            </Form.Item>
          </ItemWithLabel>

          <Form.Item>
            {getFieldDecorator('visible.onSite', {
              initialValue: (editablePage && editablePage.visible.onSite) || false,
              valuePropName: 'checked'
            })(<Checkbox>Видимость на сайте</Checkbox>)}
            {getFieldDecorator('visible.inSearch', {
              initialValue: (editablePage && editablePage.visible.inSearch) || false,
              valuePropName: 'checked'
            })(<Checkbox>Видимость в поисковых системах</Checkbox>)}
          </Form.Item>

          <div className={s.modalControls}>
            <Button htmlType="submit" type="primary">
              Сохранить изменения
            </Button>
            {editablePage && (
              <div className={s.deleteBtnWrapper}>
                <DeleteButton text="Удалить страницу" onDelete={deleteInfoPage} />
              </div>
            )}
          </div>
        </Form>
      </Modal>
    )
  }
}

InfoPagesModal.propTypes = {
  InfoPagesStore: PropTypes.any,
  form: PropTypes.any
}

export default Form.create({ name: 'infoPagesForm' })(InfoPagesModal)
