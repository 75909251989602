import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

import { noop, get, debounce } from 'lodash'

import { Select } from 'antd'

const { Option } = Select

@inject(stores => {
  const { dictionariesVideo } = stores.VideosStore

  return {
    dictionariesVideo
  }
})
@observer
class SelectRender extends Component {
  componentDidMount() {
    const { filter } = this.props
    const { fetch } = this.getFetchDataByType()

    fetch(false, filter)
  }

  handleSearch = debounce(term => {
    const { filter } = this.props
    const { fetch } = this.getFetchDataByType()

    fetch(term, filter)
  }, 300)

  getFetchDataByType = () => {
    const { item, dictionariesVideo } = this.props

    if (item.name === 'filter.priority') {
      return {
        fetch: noop,
        options: get(dictionariesVideo, 'videoPriorities', []),
        key: 'id',
        label: 'id'
      }
    }

    if (item.name === 'filter.status') {
      return {
        fetch: noop,
        options: get(dictionariesVideo, 'videoStatuses', []),
        key: 'id',
        label: 'ru'
      }
    }

    return {}
  }

  handleSelectChange = value => {
    const { onChange } = this.props
    const { fetch } = this.getFetchDataByType()

    onChange(value)

    if (!value) {
      fetch()
    }
  }

  render() {
    const { onChange, value, optionsChildren, ...props } = this.props

    const { options, key, label } = this.getFetchDataByType()

    return (
      <Select
        style={{
          width: '100%'
        }}
        showSearch
        allowClear
        optionFilterProp="children"
        onChange={this.handleSelectChange}
        value={value}
        placeholder="Выбрать"
        onSearch={this.handleSearch}
        {...props}
      >
        {options.map(option => {
          return (
            <Option key={option[key]}>
              {optionsChildren ? optionsChildren(option) : option[label]}
            </Option>
          )
        })}
      </Select>
    )
  }
}

export default SelectRender

SelectRender.propTypes = {
  dictionariesVideo: PropTypes.array,
  filter: PropTypes.any,
  item: PropTypes.any,
  onChange: PropTypes.bool,
  optionsChildren: PropTypes.any,
  value: PropTypes.any
}
