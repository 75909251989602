import React from 'react'
// import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import moment from 'moment'

import Editor, { typeConstants } from '@dev/tabo-editor'

import CloseIcon from '~assets/icons/close.svg'
import User from '~components/User'

import styles from './styles.scss'

const BlockGroup = observer(props => {
  const {
    symbols,
    onKeyDown,
    group: {
      author,
      lockBlock,
      unlockBlock,
      createBlock,
      updateBlock,
      removeBlock,
      changeBlockPosition,
      blocksArray,
      destroy,
      createdAt
    }
  } = props

  const handleFocusBlock = ({ id }) => {
    lockBlock(id)
  }

  const handleUnlockBlock = ({ id }) => {
    unlockBlock(id)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.headerItem}>
          <div>Дата публикации</div>
          <div className={styles.date}>{moment(createdAt).format('DD.MM.YYYY HH:mm')}</div>
        </div>
        <div className={styles.headerItem}>
          <div>Автор</div>
          <div>
            <User
              person={{
                avatar: `http://life.li2.tabolabs.com:8080/files/${author.avatar}/content?height=100&width=100`,
                name: author.name
              }}
            />
          </div>
        </div>
        <div className={styles.delete} onClick={destroy}>
          <CloseIcon /> <span>Удалить</span>
        </div>
      </div>
      <Editor
        blocks={blocksArray}
        onFocusBlock={handleFocusBlock}
        handleUnblock={handleUnlockBlock}
        onCreateBlock={createBlock}
        onChangeBlock={updateBlock}
        onDeleteBlock={removeBlock}
        onKeyDown={onKeyDown}
        onBlurBlock={() => {}}
        onDrag={changeBlockPosition}
        uploadPlaceholder={require('~assets/uploadPlaceholder.jpg')}
        uploadUrl={`${process.env.API_ROOT}/files`}
        maxSize={{
          [typeConstants.HEADER_BLOCK]: symbols.title,
          [typeConstants.IMAGE_BLOCK]: symbols.mediaCaption,
          [typeConstants.VIDEO_BLOCK]: symbols.mediaCaption
        }}
      />
    </div>
  )
})

BlockGroup.propTypes = {}

export default BlockGroup
