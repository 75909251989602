import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { Input, Checkbox, Radio, Button, Form } from 'antd'

import DeleteButton from '~components/DeleteButton'
import ItemWithLabel from '~components/ItemWithLabel'
import Modal from '~components/Modal'
import s from './styles.scss'

const CREATE_TITLE = 'Создание нового E-mail адреса'
const UPDATE_TITLE = 'Редактирование E-mail адреса'

@inject('EmailsStore')
@observer
class EmailsModal extends Component {
  componentDidMount() {
    const {
      EmailsStore: { fetchDictionaries }
    } = this.props

    fetchDictionaries()
  }

  handleSubmit = e => {
    e.preventDefault()

    const {
      EmailsStore: { editableEmail, updateEmail, createEmail },
      form
    } = this.props

    const formData = form.getFieldsValue()
    const category = { data: { ...formData } }
    const action = editableEmail ? updateEmail : createEmail

    action(category)
  }

  render() {
    const {
      EmailsStore: {
        dictionaries,
        setHideModal,
        isShowModal,
        resetEditableEmail,
        editableEmail,
        deleteEmail
      },
      form
    } = this.props
    const { getFieldDecorator } = form
    const ModalTitle = editableEmail ? UPDATE_TITLE : CREATE_TITLE

    return (
      <Modal
        destroyOnClose
        width={590}
        onClose={setHideModal}
        isVisible={isShowModal}
        title={ModalTitle}
        afterClose={resetEditableEmail}
      >
        <Form onSubmit={this.handleSubmit}>
          <ItemWithLabel label="Назначение">
            <Form.Item>
              {getFieldDecorator('description', {
                initialValue: editableEmail && editableEmail.description,
                rules: [{ required: true, message: 'Обязательное поле!' }]
              })(<Input placeholder="Описание адреса" />)}
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="Адрес электронной почты">
            <Form.Item>
              {getFieldDecorator('address', {
                initialValue: editableEmail && editableEmail.address,
                rules: [{ required: true, message: 'Обязательное поле!' }]
              })(<Input placeholder="Введите адрес" />)}
            </Form.Item>
          </ItemWithLabel>

          <Form.Item>
            {getFieldDecorator('visibility', {
              initialValue: (editableEmail && editableEmail.visibility) || false,
              valuePropName: 'checked'
            })(<Checkbox>Видимость на сайте</Checkbox>)}
          </Form.Item>

          <ItemWithLabel label="Тематика обращения">
            <Form.Item>
              {getFieldDecorator('type', {
                initialValue: editableEmail && editableEmail.type,
                valuePropName: 'value'
              })(
                <Radio.Group>
                  {dictionaries.map(item => (
                    <Radio value={item.id} key={item.id}>
                      {item.ru}
                    </Radio>
                  ))}
                </Radio.Group>
              )}
            </Form.Item>
          </ItemWithLabel>

          <div className={s.modalControls}>
            <Button htmlType="submit" type="primary">
              Сохранить изменения
            </Button>
            {editableEmail && (
              <div className={s.deleteBtnWrapper}>
                <DeleteButton text="Удалить E-mail" onDelete={deleteEmail} />
              </div>
            )}
          </div>
        </Form>
      </Modal>
    )
  }
}

EmailsModal.propTypes = {
  EmailsStore: PropTypes.any,
  form: PropTypes.any
}

export default Form.create({ name: 'emailsForm' })(EmailsModal)
