import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import declOfNum from '~utils/declOfNum'
import styles from './styles.scss'

const ContentWrapper = ({
  title,
  children,
  countDeclinations,
  countItems,
  rightContent,
  hideMobileTitle
}) => {
  const countDeclination = declOfNum(countItems, countDeclinations)

  return (
    <div className={styles.root}>
      {title && (
        <div className={cn(styles.header, hideMobileTitle && styles.hideMobileTitle)}>
          <div className={styles.titleWrapper}>
            <div className={styles.title}>{title}</div>

            {!!countItems && (
              <div className={styles.count}>
                {countItems} {countDeclination}
              </div>
            )}
          </div>

          <div className={styles.rightContent}>{rightContent}</div>
        </div>
      )}

      <div className={cn({ [styles.content]: title })}>{children}</div>
    </div>
  )
}

export default ContentWrapper

ContentWrapper.propTypes = {
  children: PropTypes.element,
  countDeclinations: PropTypes.array,
  countItems: PropTypes.number,
  hideMobileTitle: PropTypes.bool,
  rightContent: PropTypes.any,
  title: PropTypes.string.isRequired
}

ContentWrapper.defaultProps = {
  countDeclinations: []
}
