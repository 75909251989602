import PropTypes from 'prop-types'
import React from 'react'

import { get } from 'lodash'

import { Avatar } from 'antd'

import styles from './styles.scss'

const AuthorInfo = ({ data }) => {
  const { name, avatar, index, email, department } = data

  return (
    <div className={styles.person}>
      <div className={styles.authorLeft}>
        <Avatar size={48} src={get(avatar, 'url', '')}>
          {name[0]}
        </Avatar>

        <div className={styles.authorInfo}>
          <div className={styles.authorName}>
            {name}
            <div className={styles.authorId}>ID {index}</div>
          </div>

          <div className={styles.authorEmail}>{email}</div>
        </div>
      </div>

      <div className={styles.authorRight}>
        <div className={styles.authorType}>Кореспондент</div>

        {department && <div className={styles.authorDepartament}>Отдел «{department.name}»</div>}
      </div>
    </div>
  )
}

export default AuthorInfo

AuthorInfo.propTypes = {
  data: PropTypes.object
}
