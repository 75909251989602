import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'

import WhiteBlockWithShadow from '~components/WhiteBlockWithShadow'

import styles from './styles.scss'

import Pencil from '~assets/icons/pencil.svg'

@inject(stores => {
  const { setEditableDepartment } = stores.DepartmentsStore

  return {
    setEditableDepartment
  }
})
@observer
class DepartmentItem extends Component {
  handleClickItem = () => {
    const { department, setEditableDepartment } = this.props

    setEditableDepartment(department)
  }

  render() {
    const { department } = this.props
    const { name, index } = department

    return (
      <WhiteBlockWithShadow>
        <div className={styles.root} onClick={this.handleClickItem}>
          <div className={styles.ico}>
            <Pencil fill="#D3D3D3" />
          </div>

          <div className={styles.title}>{name}</div>

          <div className={styles.id}>ID {index}</div>
        </div>
      </WhiteBlockWithShadow>
    )
  }
}

export default DepartmentItem

DepartmentItem.propTypes = {
  setEditableDepartment: PropTypes.func,
  department: PropTypes.object
}
