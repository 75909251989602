import API from '~api'

const API_URL_STREAMS = '/streams'
const API_URL_VIDEOS = '/videos'
const API_URL_PLAYLISTS = '/playlists'
const API_URL_DICTIONARIES = '/dictionaries'

class VideoTransportLayer {
  id = ''

  async getStreams() {
    return API.get(`${API_URL_STREAMS}`).then(({ data: { data } }) => {
      return data
    })
  }

  async getDictionaries() {
    return API.get(`${API_URL_DICTIONARIES}/video`).then(({ data }) => {
      return data
    })
  }

  getPlaylists() {
    return API.get(API_URL_PLAYLISTS).then(({ data: { data } }) => {
      return data
    })
  }

  createVideo() {
    return API.post(API_URL_VIDEOS, {}).then(({ data: { data } }) => {
      window.history.replaceState(null, '', `/broadcasts/video/edit/${data._id}`)
      return data
    })
  }

  fetchVideo(id) {
    return API.get(`${API_URL_VIDEOS}/${id}`).then(({ data: { data } }) => {
      return data
    })
  }

  delete(id) {
    return API.delete(`${API_URL_VIDEOS}/${id}`)
  }

  save({ id, data }) {
    return API.patch(`${API_URL_VIDEOS}/${id}`, { data })
  }

  publish(id) {
    return API.post(`${API_URL_VIDEOS}/${id}/publish`)
  }

  unpublish(id) {
    return API.post(`${API_URL_VIDEOS}/${id}/unpublish`)
  }

  startStream(id) {
    return API.post(`${API_URL_VIDEOS}/${id}/startRecord`).then(({ data: { data } }) => {
      return data
    })
  }

  stopStream(id) {
    return API.post(`${API_URL_VIDEOS}/${id}/finishRecord`).then(({ data: { data } }) => {
      return data
    })
  }
}

export default new VideoTransportLayer()
