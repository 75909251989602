import { observable, action, runInAction, computed } from 'mobx'

import API from '~api'
import ConfigureFetchUrlByListsStore from './configureFetchUrlByLists'
import FetchPaginationStore from './fetchPaginationStore'

const DEPARTMENTS_URL = '/departments'

class DepartmentsStore {
  @observable isShowModal = false

  @observable isFirstLoading = true

  @observable isLoadingMore = false

  @observable isActionModal = false

  @observable editableDepartment = null

  @observable departments = []

  @observable allItemsCount = 0

  @observable departmentsSearches = []

  @action
  fetchDepartmentBySearch = term => {
    let url = DEPARTMENTS_URL

    if (term) {
      url = `${url}?search=${term})`
    }

    return API.get(url).then(res => {
      const { data } = res.data

      runInAction(() => {
        this.departmentsSearches = data
      })

      return data
    })
  }

  @action
  fetchDepartments = () => {
    const { offset } = FetchPaginationStore
    const { getPromiseUrl } = ConfigureFetchUrlByListsStore

    const promiseUrl = getPromiseUrl({
      url: DEPARTMENTS_URL
    })

    const fetchDepartmentsPromise = API.get(promiseUrl)

    this.setIsLoadingMore(true)
    return fetchDepartmentsPromise
      .then(res => {
        const { data, meta } = res.data

        if (offset === 0) {
          this.setDepartments(data)
        } else {
          this.setDepartments([...this.departments, ...data])
        }

        this.setAllItemsCount(meta.count)
        this.setIsFirstLoading(false)
        this.setIsLoadingMore(false)
      })
      .catch(() => {
        this.setIsFirstLoading(false)
        this.setIsLoadingMore(false)
      })
  }

  @action
  updateDepartment = department => {
    runInAction(() => {
      this.isActionModal = true
    })

    const { _id } = this.editableDepartment

    const updateDepartmentPromise = API.patch(`${DEPARTMENTS_URL}/${_id}`, department)

    return updateDepartmentPromise
      .then(res => {
        const { data } = res.data

        const editableDepartmentIndex = this.departments.indexOf(this.editableDepartment)

        this.setHideModal()
        runInAction(() => {
          this.isActionModal = false
          this.departments[editableDepartmentIndex] = data
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @action
  createDepartment = author => {
    runInAction(() => {
      this.isActionModal = true
    })

    const createDepartmentPromise = API.post(DEPARTMENTS_URL, author)

    return createDepartmentPromise
      .then(res => {
        const { data } = res.data
        this.setHideModal()

        this.setAllItemsCount(this.allItemsCount + 1)

        runInAction(() => {
          this.isActionModal = false
          this.departments.unshift(data)
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @action
  deleteDepartment = () => {
    runInAction(() => {
      this.isActionModal = true
    })

    const { _id } = this.editableDepartment

    const deleteAuthorPromise = API.delete(`${DEPARTMENTS_URL}/${_id}`)

    return deleteAuthorPromise
      .then(() => {
        this.departments.remove(this.editableDepartment)

        this.resetEditableDepartment()
        this.setHideModal()
        this.setAllItemsCount(this.allItemsCount - 1)

        runInAction(() => {
          this.isActionModal = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @computed
  get isEmptyDepartments() {
    return !this.departments.length
  }

  @action
  setEditableDepartment = value => {
    this.editableDepartment = value

    this.setShowModal()
  }

  @action
  resetEditableDepartment = () => {
    this.editableDepartment = null
  }

  @action
  setShowModal = () => {
    this.isShowModal = true
  }

  @action
  setHideModal = () => {
    this.isShowModal = false
  }

  @action
  setIsFirstLoading = value => {
    this.isFirstLoading = value
  }

  @action
  setIsLoadingMore = value => {
    this.isLoadingMore = value
  }

  @action
  setDepartments = data => {
    this.departments = data
  }

  @action
  setAllItemsCount = count => {
    this.allItemsCount = count
  }

  @computed
  get departmentsLength() {
    return this.departments.length
  }
}

export default new DepartmentsStore()
