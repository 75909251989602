import React, { Component } from 'react'
import SettingsNavigation from '~components/SettingsNavigation'
import ContentWrapper from '~components/ContentWrapper'
import { SpecProjectsModal, SpecProjectsList } from '~components/SpecProjects'

const PAGE_TITLE = 'Специальные проекты'

class SettingsSpecProjectsPage extends Component {
  render() {
    return (
      <>
        <SettingsNavigation />
        <ContentWrapper title={PAGE_TITLE}>
          <SpecProjectsList />
        </ContentWrapper>
        <SpecProjectsModal />
      </>
    )
  }
}

export default SettingsSpecProjectsPage
