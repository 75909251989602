import { observable, action, runInAction } from 'mobx'
import { find, findIndex } from 'lodash'
import API from '~api'

const SPEC_PROJECTS_URL = '/specialProjects'

export class SpecProjectsStore {
  @observable specProjects = []

  @observable isFirstLoading = true

  @observable isActionModal = false

  @observable isShowModal = false

  @observable editableSpecProjects = null

  @observable dictionaries = []

  @action
  fetchDictionaries = () => {
    const promise = API.get('/dictionaries/settings')

    return promise.then(res => {
      const { data } = res

      runInAction(() => {
        this.dictionaries = data?.specialProjectTypes
      })
    })
  }

  @action
  fetchSpecProjects = () => {
    const fetchSpecProjectsPromise = API.get(SPEC_PROJECTS_URL)

    this.fetchDictionaries()

    return fetchSpecProjectsPromise
      .then(res => {
        const { data } = res.data

        runInAction(() => {
          this.specProjects = data.sort(({ order: a }, { order: b }) => a - b)
          this.isFirstLoading = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isFirstLoading = false
        })
      })
  }

  @action
  changeOrdering = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return

    const current = find(this.specProjects, { order: oldIndex })
    const target = find(this.specProjects, { order: newIndex })

    const currentId = find(this.specProjects, { order: oldIndex })._id
    const targetId = findIndex(this.specProjects, ({ order }) => order === newIndex)
    let targetPlacement = this.specProjects[targetId + 1]._id

    if (oldIndex > newIndex) {
      targetPlacement = find(this.specProjects, { order: newIndex })._id
    }

    const changeOrderingPromise = API.post(`${SPEC_PROJECTS_URL}/${currentId}/placement`, {
      meta: {
        before: targetPlacement
      }
    })

    current.order = newIndex
    target.order = newIndex + 0.5

    this.specProjects = this.specProjects.slice().sort(({ order: a }, { order: b }) => a - b)

    changeOrderingPromise.then(() => {
      this.fetchSpecProjects()
    })
  }

  @action
  createSpecProjects = project => {
    runInAction(() => {
      this.isActionModal = true
    })

    const createSpecProjectsPromise = API.post(SPEC_PROJECTS_URL, project)

    return createSpecProjectsPromise
      .then(res => {
        const { data } = res.data
        this.setHideModal()

        runInAction(() => {
          this.isActionModal = false
          this.specProjects.unshift(data)
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @action
  updateSpecProjects = project => {
    runInAction(() => {
      this.isActionModal = true
    })

    const { _id } = this.editableSpecProjects
    const updateSpecProjectsPromise = API.patch(`${SPEC_PROJECTS_URL}/${_id}`, project)

    return updateSpecProjectsPromise
      .then(res => {
        const { data } = res.data
        const editableSpecProjectsIndex = this.specProjects.indexOf(this.editableSpecProjects)

        this.setHideModal()

        runInAction(() => {
          this.isActionModal = false
          this.specProjects[editableSpecProjectsIndex] = data
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @action
  deleteSpecProjects = () => {
    runInAction(() => {
      this.isActionModal = true
    })

    const { _id } = this.editableSpecProjects
    const deleteSpecProjectsPromise = API.delete(`${SPEC_PROJECTS_URL}/${_id}`)

    return deleteSpecProjectsPromise
      .then(() => {
        this.specProjects.remove(this.editableSpecProjects)

        this.resetSpecProjectsCategory()
        this.setHideModal()

        runInAction(() => {
          this.isActionModal = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @action
  setEditableSpecProjects = value => {
    this.editableSpecProjects = value

    this.setShowModal()
  }

  @action
  resetEditableSpecProjects = () => {
    this.editableSpecProjects = {}
  }

  @action
  setShowModal = () => {
    this.isShowModal = true
  }

  @action
  setHideModal = () => {
    this.isShowModal = false
  }
}

export default new SpecProjectsStore()
