import { MobXProviderContext } from 'mobx-react'
import React from 'react'
// eslint-disable-next-line no-unused-vars
import { SpecProjectsStore } from '~store/SpecProjectsStore'

/**
 * @returns {SpecProjectsStore}
 */
export const useSpecProjectsStore = () =>
  React.useContext(MobXProviderContext)?.SpecProjectsStore || {}
