import React from 'react'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazy-load'

const Image = props => {
  const { url, alt, ...rest } = props
  return (
    <LazyLoad {...rest}>
      <img src={url} alt={alt} />
    </LazyLoad>
  )
}

Image.propTypes = {
  url: PropTypes.string,
  alt: PropTypes.string
}

export default Image
