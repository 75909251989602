import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles.scss'

import SOCIALS_TYPE from '../config'

import VKIcon from '~assets/icons/vk.svg'
import FBIcon from '~assets/icons/fb.svg'
import TGIcon from '~assets/icons/tg.svg'
import OKIcon from '~assets/icons/ok.svg'
import INSTIcon from '~assets/icons/instagram.svg'
import TWIcon from '~assets/icons/twitter.svg'

const ICON_FILL = '#D3D3D3'

const getSocialIconByType = type => {
  let icon

  switch (type) {
    case SOCIALS_TYPE.VKONTAKTE:
      icon = <VKIcon fill={ICON_FILL} />
      break
    case SOCIALS_TYPE.ODNOKLASSNIKI:
      icon = <OKIcon fill={ICON_FILL} />
      break
    case SOCIALS_TYPE.FACEBOOK:
      icon = <FBIcon fill={ICON_FILL} />
      break
    case SOCIALS_TYPE.INSTAGRAM:
      icon = <INSTIcon fill={ICON_FILL} />
      break
    case SOCIALS_TYPE.TELEGRAM:
      icon = <TGIcon fill={ICON_FILL} />
      break
    default:
      icon = <TWIcon fill={ICON_FILL} />
  }

  return icon
}

const Socials = ({ data = {}, onClick }) => {
  return (
    <div className={styles.root}>
      {Object.keys(data).map(
        (item, idx) =>
          !!data[item].length && (
            <span key={idx} className={styles.socialIco} onClick={onClick}>
              <a href={data[item]} target="_blank" rel="noreferrer noopener">
                {getSocialIconByType(item)}
              </a>
            </span>
          )
      )}
    </div>
  )
}

export default Socials

Socials.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func
}
