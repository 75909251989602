import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { get } from 'lodash'

import { Avatar } from 'antd'
import classNames from 'classnames'

import WhiteBlockWithShadow from '~components/WhiteBlockWithShadow'

import styles from './styles.scss'

import Pencil from '~assets/icons/pencil.svg'

@inject(stores => {
  const { setEditableEmployee } = stores.EmployeesStore

  return {
    setEditableEmployee
  }
})
@observer
class EmployeeItem extends Component {
  handleClickItem = () => {
    const { employee, setEditableEmployee } = this.props

    setEditableEmployee(employee)
  }

  render() {
    const { employee } = this.props
    const { firstName, lastName, email, avatar, department, position, active, index } = employee

    return (
      <WhiteBlockWithShadow>
        <div
          className={classNames(styles.root, !active && styles.disable)}
          onClick={this.handleClickItem}
        >
          <div className={styles.ico}>
            <Pencil fill="#D3D3D3" />
          </div>

          <div className={styles.person}>
            <Avatar src={get(avatar, 'url', '')} size={48}>
              {firstName[0]}
            </Avatar>

            <div className={styles.personInfo}>
              <div className={styles.personName}>
                {firstName} {lastName}
                <span className={styles.personId}>ID {index}</span>
              </div>

              <div className={styles.personEmail}>{email}</div>
            </div>
          </div>

          <div className={styles.authorRight}>
            {position && <div className={styles.personType}>{position.ru}</div>}
            {department && (
              <div className={styles.personDepartament}>Отдел «{department.name}»</div>
            )}
          </div>
        </div>
      </WhiteBlockWithShadow>
    )
  }
}

export default EmployeeItem

EmployeeItem.propTypes = {
  employee: PropTypes.object,
  setEditableEmployee: PropTypes.func
}
