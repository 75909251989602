import React from 'react'
import axios from 'axios'
import { message } from 'antd'
import { inject } from 'mobx-react'
import PropTypes from 'prop-types'
import { BadWordsPage } from '~pages/BadWordsPage'

import LoginPage from '~pages/LoginPage'
import TagsPage from '~pages/TagsPage'
import FlowsPage from '~pages/FlowsPage'
import FeedbackPage from '~pages/FeedbackPage'
import DepartmentsPage from '~pages/DepartmentsPage'
import AuthorsPage from '~pages/AuthorsPage'
import ChangeLogPage from '~pages/ChangeLogPage'
import EmployeesPage from '~pages/EmployeesPage'
import PublicationsPage from '~pages/PublicationsPage'
import SettingsPage from '~pages/SettingsPage'
import SettingsEmailsPage from '~pages/SettingsEmailsPage'
import SchedulePage from '~pages/SchedulePage'
import SettingsSocialNetworksPage from '~pages/SettingsSocialNetworksPage'
import SettingsCategoriesSectionsPage from '~pages/SettingsCategoriesSectionsPage'
import SettingsTimersPage from '~pages/SettingsTimersPage'
import SettingsSymbolsPage from '~pages/SettingsSymbolsPage'
import SettingsInfoPagesPage from '~pages/SettingsInfoPagesPage'
import SettingsPlaylistsPage from '~pages/SettingsPlaylistsPage'
import SettingsSpecProjectsPage from '~pages/SettingsSpecProjectsPage'
import SettingsCommonPage from '~pages/SettingsCommonPage'
import VideosPage from '~pages/VideosPage'
import VideoPage from '~pages/VideoPage'

import EditorPage from '~pages/EditorPage'

import { getRoleExceptSome, role } from '~utils/roles'

const rolesExceptIntern = getRoleExceptSome(role.INTERN)

@inject('UserStore')
class OAuthCallback extends React.Component {
  componentDidMount() {
    console.log('OAuthCallback render', new Date())

    if (typeof window !== 'undefined') {
      axios({
        url: `${process.env.API_ROOT}/auth/oauthorize`,
        withCredentials: true,
        method: 'POST'
      })
        .then(() => {
          this.props.UserStore.getUserInfo().then(() => {
            this.props.history.replace('/publications')
          })
        })
        .catch(err => {
          message.error(`Произошла ошибка авторизации!${JSON.stringify(err, null, 2)}`, 4)
          this.props.history.replace('/')
          this.props.UserStore.getUserInfo()
        })
    }
  }

  render() {
    return <div>Авторизиаця ...</div>
  }
}

OAuthCallback.propTypes = {
  UserStore: PropTypes.object,
  history: PropTypes.object
}

const routes = {
  public: {
    OAuthRedirect: {
      path: '/connect/life/callback',
      component: function OAuthRedirect(props) {
        const redirectUrl = `${process.env.API_ROOT}/connect/life/callback${props.location.search}`

        console.log('OAuthRedirect render', new Date())
        console.log(redirectUrl)

        if (window && !window.sent) {
          window.sent = true

          setTimeout(function() {
            window.location = redirectUrl
          }, 250)
        }

        return null
      }
    },
    OAuthCallback: {
      path: '/oauth/callback',
      component: OAuthCallback
    },
    Test: {
      path: '/test',
      component: function TEST() {
        return <div>HELP!</div>
      }
    },
    Login: {
      component: LoginPage,
      path: '/login'
    }
  },
  private: {
    Index: {
      component: PublicationsPage,
      path: '/publications',
      title: 'Публикации',
      icon: 'edit',
      inMenu: true,
      defaultFilter: 'filter.resourceType=RESOURCE_TYPE_PUBLICATION',
      authority: getRoleExceptSome([]),
      submenu: {
        Employees: {
          component: FlowsPage,
          path: '/flows',
          title: 'Потоки',
          inMenu: true,
          authority: rolesExceptIntern
        }
      }
    },
    Editor: {
      component: EditorPage,
      path: '/publications/:id',
      title: 'Редактирование публикации',
      showPostsHeader: true,
      authority: getRoleExceptSome([])
    },
    TextBroadcastEditor: {
      component: EditorPage,
      path: '/broadcasts/text/:id',
      title: 'Редактирование текстовой трансляции',
      authority: rolesExceptIntern
    },
    Directory: {
      component: EmployeesPage,
      path: '/employees',
      title: 'Справочники',
      icon: 'unordered-list',
      inMenu: true,
      submenu: {
        Employees: {
          component: EmployeesPage,
          path: '/employees',
          title: 'Сотрудники',
          inMenu: true,
          authority: rolesExceptIntern
        },
        Authors: {
          component: AuthorsPage,
          path: '/authors',
          title: 'Авторы',
          inMenu: true,
          authority: rolesExceptIntern
        },
        Departments: {
          component: DepartmentsPage,
          path: '/departments',
          title: 'Отделы',
          inMenu: true,
          authority: rolesExceptIntern
        },
        Tags: {
          component: TagsPage,
          path: '/tags',
          title: 'Теги',
          inMenu: true,
          authority: rolesExceptIntern
        },
        BadWords: {
          component: BadWordsPage,
          path: '/bad-words',
          title: 'Запрещенные слова',
          inMenu: true,
          authority: rolesExceptIntern
        }
      }
    },
    VideoBroadcastsEdit: {
      component: VideoPage,
      path: '/broadcasts/video/edit/:id',
      title: 'Редактирование Видео',
      authority: rolesExceptIntern
    },
    VideoBroadcastsCreate: {
      component: VideoPage,
      path: '/broadcasts/video/new',
      title: 'Создание Видео',
      authority: rolesExceptIntern
    },
    Broadcasts: {
      component: PublicationsPage,
      path: '/broadcasts/text',
      title: 'Трансляции',
      icon: 'notification',
      inMenu: true,
      submenu: {
        TextBroadcasts: {
          component: PublicationsPage,
          path: '/broadcasts/text',
          title: 'Текстовые',
          inMenu: true,
          defaultFilter: 'filter.resourceType=RESOURCE_TYPE_TEXT_STREAM',
          hideFilters: ['filter.type'],
          authority: rolesExceptIntern
        },
        VideoBroadcasts: {
          component: VideosPage,
          path: '/broadcasts/video',
          title: 'Видео',
          inMenu: true,
          authority: rolesExceptIntern
        }
      }
    },
    Schedule: {
      component: SchedulePage,
      path: '/schedule',
      title: 'Расписание съёмок',
      icon: 'schedule',
      inMenu: true,
      authority: rolesExceptIntern
    },
    Feedback: {
      component: FeedbackPage,
      path: '/feedback',
      title: 'Обратная связь',
      icon: 'message',
      inMenu: true,
      authority: rolesExceptIntern
    },
    ChangeLog: {
      component: ChangeLogPage,
      path: '/changelog',
      title: 'Журнал изменений',
      icon: 'history',
      inMenu: true,
      authority: rolesExceptIntern
    },
    Settings: {
      component: SettingsPage,
      path: '/settings/common',
      title: 'Настройки',
      icon: 'setting',
      inMenu: true,
      submenu: {
        SettingsEmails: {
          component: SettingsEmailsPage,
          path: '/settings/emails',
          title: 'Настройка адресов электронной почты',
          authority: rolesExceptIntern
        },
        SettingsCategoriesSectionsPage: {
          component: SettingsCategoriesSectionsPage,
          path: '/settings/categories_and_sections',
          title: 'Настройка разделов и категорий на сайте',
          authority: rolesExceptIntern
        },
        SettingsSocialNetworksPage: {
          component: SettingsSocialNetworksPage,
          path: '/settings/social',
          title: 'Настройка ссылок на аккаунты в соцсетях и мессенджерах',
          authority: rolesExceptIntern
        },
        SettingsTimersPage: {
          component: SettingsTimersPage,
          path: '/settings/timers',
          title: 'Настройка таймеров',
          authority: rolesExceptIntern
        },
        SettingsSymbolsPage: {
          component: SettingsSymbolsPage,
          path: '/settings/symbols',
          title: 'Настройка количества символов блоков публикации',
          authority: rolesExceptIntern
        },
        SettingsInfoPagesPage: {
          component: SettingsInfoPagesPage,
          path: '/settings/infopages',
          title: 'Настройки управлению информационными страницами',
          authority: rolesExceptIntern
        },
        SettingsPlaylistsPage: {
          component: SettingsPlaylistsPage,
          path: '/settings/playlists',
          title: 'Настройки плейлистов',
          authority: rolesExceptIntern
        },
        SettingsSpecProjectsPage: {
          component: SettingsSpecProjectsPage,
          path: '/settings/specprojects',
          title: 'Специальные проекты',
          authority: rolesExceptIntern
        },
        SettingsCommonPage: {
          component: SettingsCommonPage,
          path: '/settings/common',
          title: 'Общие настройки',
          authority: rolesExceptIntern
        }
      }
    }
  }
}

export const leftMenu = [
  'Index',
  'Directory',
  'Employees',
  'Authors',
  'Departments',
  'Tags',
  'Broadcasts',
  'TextBroadcasts',
  'VideoBroadcasts',
  'Schedule',
  'Feedback',
  'ChangeLog',
  'Settings'
]

export default routes
