import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'

import { Modal } from 'antd'

import ModalWrapper from './wrapper'

@inject(stores => {
  const {
    isShowModal,
    setHideModal,
    openedPostComment,
    resetOpenedPostComment
  } = stores.CommentsStore

  return {
    isShowModal,
    setHideModal,
    openedPostComment,
    resetOpenedPostComment
  }
})
@observer
class CommentsModal extends Component {
  render() {
    const { setHideModal, isShowModal } = this.props

    return (
      <Modal
        destroyOnClose
        width={960}
        footer={null}
        onCancel={setHideModal}
        visible={isShowModal}
        centered
      >
        <ModalWrapper />
      </Modal>
    )
  }
}

export default CommentsModal

CommentsModal.propTypes = {
  isShowModal: PropTypes.bool,
  openedPostComment: PropTypes.object,
  resetOpenedPostComment: PropTypes.func,
  setHideModal: PropTypes.func
}
