import { observable, action, runInAction } from 'mobx'
import { find, findIndex } from 'lodash'
import API from '~api'

const CATEGORIES_URL = '/categories'

class CategoriesStore {
  @observable categories = []

  @observable isFirstLoading = true

  @observable isActionModal = false

  @observable isShowModal = false

  @observable editableCategory = null

  @action
  fetchCategories = () => {
    // TODO: FIXME: when backend realese sort by order
    const fetchCategoriesPromise = API.get(`${CATEGORIES_URL}?sort=+order`)

    return fetchCategoriesPromise
      .then(res => {
        const { data } = res.data

        runInAction(() => {
          this.categories = data.sort(({ order: a }, { order: b }) => a - b)
          this.isFirstLoading = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isFirstLoading = false
        })
      })
  }

  @action
  createCategory = category => {
    runInAction(() => {
      this.isActionModal = true
    })

    const createCategoryPromise = API.post(CATEGORIES_URL, category)

    return createCategoryPromise
      .then(res => {
        const { data } = res.data
        this.setHideModal()

        runInAction(() => {
          this.isActionModal = false
          this.categories.unshift(data)
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @action
  changeOrdering = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return

    const current = find(this.categories, { order: oldIndex })
    const target = find(this.categories, { order: newIndex })

    const currentId = find(this.categories, { order: oldIndex })._id
    const targetId = findIndex(this.categories, ({ order }) => order === newIndex)
    let targetPlacement = this.categories[targetId + 1]._id

    if (oldIndex > newIndex) {
      targetPlacement = find(this.categories, { order: newIndex })._id
    }

    const changeOrderingPromise = API.post(`${CATEGORIES_URL}/${currentId}/placement`, {
      meta: {
        before: targetPlacement
      }
    })

    current.order = newIndex
    target.order = newIndex + 0.5

    this.categories = this.categories.slice().sort(({ order: a }, { order: b }) => a - b)

    changeOrderingPromise.then(() => {
      this.fetchCategories()
    })
  }

  @action
  updateCategory = category => {
    runInAction(() => {
      this.isActionModal = true
    })

    const { _id } = this.editableCategory || category
    const updateCategoryPromise = API.patch(`${CATEGORIES_URL}/${_id}`, category)

    return updateCategoryPromise
      .then(res => {
        const { data } = res.data
        const editableCategoryIndex = this.categories.indexOf(this.editableCategory)

        this.setHideModal()

        runInAction(() => {
          this.isActionModal = false
          this.categories[editableCategoryIndex] = data
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @action
  deleteCategory = () => {
    runInAction(() => {
      this.isActionModal = true
    })

    const { _id } = this.editableCategory
    const deleteCategoryPromise = API.delete(`${CATEGORIES_URL}/${_id}`)

    return deleteCategoryPromise
      .then(() => {
        this.categories.remove(this.editableCategory)

        this.resetEditableCategory()
        this.setHideModal()

        runInAction(() => {
          this.isActionModal = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @action
  setEditableCategory = value => {
    this.editableCategory = { seo: { title: '', description: '' }, ...value }

    this.setShowModal()
  }

  @action
  resetEditableCategory = () => {
    this.editableCategory = null
  }

  @action
  setShowModal = () => {
    this.isShowModal = true
  }

  @action
  setHideModal = () => {
    this.isShowModal = false
  }
}

export default new CategoriesStore()
