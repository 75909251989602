import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { Button, Select, Input, Spin } from 'antd'

// components
import { HeadingBlock, CoverBlock, BlockWrapper, VideoBlock } from '@dev/tabo-editor'

import ImageEditor from '~components/ImageEditor'

// assets
import styles from './styles.scss'

const VIDEO_EMBED_TYPE = 'VIDEO_TYPE_EMBED'
const VIDEO_STREAM_TYPE = 'VIDEO_TYPE_STREAM'

@inject('VideoStore')
@observer
class VideoEditor extends React.Component {
  state = {
    isOpenImageEditor: false,
    editableImage: '',
    editHandler: () => {}
  }

  openImageEditor = (url, editHandler) => {
    this.setState({ isOpenImageEditor: true, editableImage: url, editHandler })
  }

  closeImageEditor = () => {
    this.setState({ isOpenImageEditor: false, editableImage: '' })
  }

  renderContent = () => {
    const {
      type,
      videoFile,
      videoFileTitle,
      streamId,
      embedLink,
      availableStreams,
      onChangeVideo,
      onChangeEmbedLink,
      onChangeVideoStream,
      clearVideoStream
    } = this.props.VideoStore

    if (type === VIDEO_EMBED_TYPE) {
      return (
        <BlockWrapper title="Ссылка для вставки">
          <div className={styles.embed}>
            <Input
              placeholder="Укажите ссылку для вставки"
              value={embedLink}
              onChange={onChangeEmbedLink}
            />

            {embedLink && (
              <div className={styles.embedIframeWrapper}>
                <iframe src={embedLink} className={styles.embedIframe} />
              </div>
            )}
          </div>
        </BlockWrapper>
      )
    }

    if (type === VIDEO_STREAM_TYPE) {
      return (
        <BlockWrapper title="Стрим">
          <div className={styles.streams}>
            <Select
              style={{ width: 300, marginRight: '20px' }}
              onChange={onChangeVideoStream}
              value={streamId}
            >
              {availableStreams.map(stream => (
                <Select.Option key={stream.streamId} value={stream._id}>
                  {stream.streamId}
                </Select.Option>
              ))}
            </Select>
            <Button onClick={clearVideoStream}>Очистить</Button>
          </div>
          <div className={styles.streamsUrl}>Public Стрим ID: {streamId || ''}</div>
        </BlockWrapper>
      )
    }

    return (
      <VideoBlock
        onChange={onChangeVideo}
        uploadUrl={`${process.env.API_ROOT}/files`}
        {...(videoFile || {})}
        alt={videoFileTitle}
        footer={false}
      />
    )
  }

  render() {
    const {
      initialized,
      cover,
      coverTitle,
      title,
      description,
      onChangeTitle,
      onChangeCover,
      onChangeCoverTitle,
      onChangeDescription
    } = this.props.VideoStore

    const changeCover = data => {
      if (data._id && data.url !== cover) {
        return onChangeCover(data)
      }
      if (data.alt) {
        return onChangeCoverTitle(data.alt)
      }

      return null
    }

    if (!initialized) {
      return (
        <div>
          <Spin /> Loading...
        </div>
      )
    }

    return (
      <>
        <HeadingBlock
          richEditor={false}
          id={'title'}
          level={1}
          text={title}
          onChange={onChangeTitle}
        />
        <HeadingBlock
          id={'description'}
          placeholder={'Добавить описание'}
          text={description}
          onChange={onChangeDescription}
          richEditor={false}
          blockProps={{
            title: 'Описание'
          }}
        />
        <CoverBlock
          blockProps={{ title: 'Обложка' }}
          uploadUrl={`${process.env.API_ROOT}/files`}
          url={cover && cover.url}
          alt={coverTitle}
          onChange={changeCover}
          onEdit={this.openImageEditor}
        />

        {this.renderContent()}

        <ImageEditor
          shown={this.state.isOpenImageEditor}
          src={this.state.editableImage}
          onClose={this.closeImageEditor}
          onChange={this.state.editHandler}
        />
      </>
    )
  }
}

VideoEditor.propTypes = {
  VideoStore: PropTypes.object
}

export default withRouter(VideoEditor)
