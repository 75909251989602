import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { observer, inject } from 'mobx-react'

import ContentWrapper from '~components/ContentWrapper'
import { ChangeLogList, Filter, FilterControl } from '~components/ChangeLogs'
import HeaderPageSearchBar from '~components/HeaderPageSearchBar'
import InfinityScroll from '~components/InfinityScroll'
import Sorting from '~components/Sorting'

const PAGE_TITLE = 'Журнал изменений'
const SEARCH_BTN_TEXT = 'Найти в журнале'
const CREATE_BTN_TEXT = 'Добавить тег'

const SORTING = [
  {
    label: 'По дате создания',
    type: 'createdAt'
  }
]

@inject('ChangeLogStore', 'FilterParamsByListStore', 'FetchPaginationStore', 'UserStore')
@observer
class ChangeLogPage extends Component {
  componentWillUnmount() {
    const { FilterParamsByListStore, FetchPaginationStore } = this.props

    FilterParamsByListStore.setFilterParams()
    FetchPaginationStore.resetPaginationParams()
  }

  handleSearchPublications = () => {
    const { fetchChangeLog, setIsFirstLoading } = this.props.ChangeLogStore
    const { resetPaginationParams } = this.props.FetchPaginationStore

    setIsFirstLoading(true)
    resetPaginationParams()
    fetchChangeLog()
  }

  handleToggleMyPost = () => {
    const { defaultFilter } = this.props
    const { fetchChangeLog, setIsFirstLoading } = this.props.ChangeLogStore
    const { resetPaginationParams } = this.props.FetchPaginationStore
    const { setFilterValue, setFilterParams, filterParams } = this.props.FilterParamsByListStore
    const { user } = this.props.UserStore

    const isAllPost = filterParams['filter.employee'] === user._id

    setIsFirstLoading(true)
    setFilterParams()
    resetPaginationParams()

    if (isAllPost) {
      setFilterValue('filter.employee')
    } else {
      setFilterValue('filter.employee', user._id)
    }

    fetchChangeLog(defaultFilter)
  }

  /* eslint-disable class-methods-use-this */
  render() {
    const { ChangeLogStore, FilterParamsByListStore, UserStore } = this.props
    const {
      fetchChangeLog,
      allItemsCount,
      ChangeLogLength,
      isFirstLoading,
      isLoadingMore,
      isShowFilter,
      toggleIsShowFilter
    } = ChangeLogStore

    const { filterParams } = FilterParamsByListStore
    const { user } = UserStore

    return (
      <Fragment>
        <HeaderPageSearchBar
          searchButtonText={SEARCH_BTN_TEXT}
          createButtonText={CREATE_BTN_TEXT}
          onSearch={this.handleSearchPublications}
        />
        <ContentWrapper
          title={PAGE_TITLE}
          countItems={null}
          rightContent={
            <FilterControl
              onToggleMyPost={this.handleToggleMyPost}
              isShowFilter={isShowFilter}
              onToggleShowFilter={toggleIsShowFilter}
              isAllPost={filterParams['filter.employee'] === user._id}
            />
          }
        >
          {isShowFilter && <Filter />}

          <Sorting onSort={this.handleSearchPublications} data={SORTING} />

          <InfinityScroll
            loadItems={fetchChangeLog}
            isEndLoading={allItemsCount <= ChangeLogLength}
            isFirstLoading={isFirstLoading}
            isLoadMore={isLoadingMore}
          >
            <ChangeLogList />
          </InfinityScroll>
        </ContentWrapper>
      </Fragment>
    )
  }
}

export default ChangeLogPage

ChangeLogPage.propTypes = {
  ChangeLogStore: PropTypes.any,
  FetchPaginationStore: PropTypes.any,
  FilterParamsByListStore: PropTypes.any,
  UserStore: PropTypes.any,
  defaultFilter: PropTypes.any
}
