import API from '~api'

// eslint-disable-next-line import/no-extraneous-dependencies
const faker = require('faker')

faker.locale = 'ru'

const API_URL_DICTIONARIES = '/dictionaries/post'
const API_URL_CATEGORIES = '/categories'
const API_URL_SECTIONS = '/sections'

const API_URL_POSTS = '/posts'
const API_URL_BLOCKS = '/blocks'
const API_URL_GROUP_BLOCKS = '/blockgroups'

const resourceTypeFromAlias = type => {
  switch (type) {
    case '/broadcasts/text':
      return 'RESOURCE_TYPE_TEXT_STREAM'
    case '/publications':
    default:
      return 'RESOURCE_TYPE_PUBLICATION'
  }
}

class PublicationTransportLayer {
  id = ''

  getDictionaries() {
    const promises = [
      API.get(API_URL_DICTIONARIES).then(({ data }) => ({
        ...data,
        postPriorities: data.postPriorities.filter(({ id }) => id !== 'POST_PRIORITY_MAIN_LENTA')
      })),
      API.get(`${API_URL_CATEGORIES}?limit=999`).then(({ data: { data } }) => data),
      API.get(`${API_URL_SECTIONS}?limit=999`).then(({ data: { data } }) => data)
    ]

    return Promise.all(promises).then(([dictionaries, categories, sections]) => ({
      dictionaries,
      categories,
      sections
    }))
  }

  getPostPriority(id) {
    return API.get(`${API_URL_POSTS}/${id}/priority`).then(({ data: { data } }) => data)
  }

  getPostPriorities(id) {
    return API.get(`${API_URL_POSTS}/${id}/priorities`).then(({ data: { data } }) => data)
  }

  getEmployeesOnPage() {
    return API.get(`${API_URL_POSTS}/${this.id}/employees`).then(({ data: { data } }) => data)
  }

  async createPublication(type) {
    const author = await API.get('employees/me').then(
      ({ data: { authors = [{ _id: '' }] } }) => (authors[0] || { _id: '' })._id
    )
    const rss = await API.get('settings/rss').then(({ data: { data } }) => data)

    const data = {
      resourceType: resourceTypeFromAlias(type),
      authors: [author],
      flags: { commentsAllowed: true, RSS: rss }
    }

    return API.post(API_URL_POSTS, { data }).then(({ data: { data } }) => {
      this.id = data._id
      window.history.replaceState(null, '', `${type}/${data._id}`)
      return data
    })
  }

  deletePublication(id) {
    return API.delete(`${API_URL_POSTS}/${id}`)
  }

  fetchPublication(id) {
    return API.get(`${API_URL_POSTS}/${id}`).then(({ data: { data } }) => data)
  }

  save({ data }) {
    return API.patch(`${API_URL_POSTS}/${this.id}`, { data })
  }

  clearCache() {
    return API.delete(`${API_URL_POSTS}/${this.id}/cache`)
  }

  publish(id) {
    return API.post(`${API_URL_POSTS}/${id}/publish`)
  }

  unpublish(id) {
    return API.post(`${API_URL_POSTS}/${id}/unpublish`)
  }

  postpone({ id, publicationDate }) {
    return API.post(`${API_URL_POSTS}/${id}/postpone`, { data: { publicationDate } })
  }

  preview(id) {
    return API.get(`${API_URL_POSTS}/${id}/preview`)
  }

  approve({ id, approvedBy }) {
    const key = Object.keys(approvedBy)[0]
    return API.post(`${API_URL_POSTS}/${id}/approve`, { data: { approvedBy: key } })
  }

  createBlock({ groupId, before, data: { type, data } }) {
    const content = data != null ? JSON.stringify(data) : undefined

    return API.post(
      `${API_URL_POSTS}/${this.id}${
        groupId ? `${API_URL_GROUP_BLOCKS}/${groupId}` : ''
      }${API_URL_BLOCKS}`,
      {
        data: { type, content },
        meta: { before }
      }
    ).then(({ data: { data } }) => data)
  }

  saveBlock({ groupId, id: blockId, content, compiled = null, before }) {
    const dataToSave = { content }

    if (compiled) {
      dataToSave.compiled = compiled
    }
    dataToSave.compiled = null

    return API.patch(
      `${API_URL_POSTS}/${this.id}${
        groupId ? `${API_URL_GROUP_BLOCKS}/${groupId}` : ''
      }${API_URL_BLOCKS}/${blockId}`,
      {
        data: { ...dataToSave },
        meta: { before }
      }
    ).then(({ data: { data } }) => data)
  }

  saveBlocks(blocks) {
    return API.patch(`/posts/${this.id}/blocks`, { blocks })
  }

  deleteBlock(blockId, groupId) {
    return API.delete(
      `${API_URL_POSTS}/${this.id}${
        groupId ? `${API_URL_GROUP_BLOCKS}/${groupId}` : ''
      }${API_URL_BLOCKS}/${blockId}`
    )
  }

  lockBlock(blockId) {
    return API.post(`${API_URL_POSTS}/${this.id}${API_URL_BLOCKS}/${blockId}/lock`)
  }

  unlockBlock(blockId) {
    return API.post(`${API_URL_POSTS}/${this.id}${API_URL_BLOCKS}/${blockId}/unlock`)
  }

  unlockAllMyBlocks = () => {
    return API.post(`${API_URL_POSTS}/${this.id}${API_URL_BLOCKS}/unlockall`).then(
      ({ data: { data } }) => data
    )
  }

  moveBlock({ groupId, id: blockId = '', before }) {
    return API.patch(
      `${API_URL_POSTS}/${this.id}${
        groupId ? `${API_URL_GROUP_BLOCKS}/${groupId}` : ''
      }${API_URL_BLOCKS}/${blockId}`,
      {
        meta: { before }
      }
    )
  }

  createBlockGroup() {
    return API.post(`${API_URL_POSTS}/${this.id}${API_URL_GROUP_BLOCKS}`).then(
      ({ data: { data } }) => data
    )
  }

  deleteBlockGroup(groupId) {
    return API.delete(`${API_URL_POSTS}/${this.id}${API_URL_GROUP_BLOCKS}/${groupId}`)
  }

  unlockAllMyBlock = () => {
    return navigator.sendBeacon(
      `${process.env.API_ROOT}${API_URL_POSTS}/${this.id}${API_URL_BLOCKS}/unlockall`
    )
  }

  lockField = ({ field }) => {
    return API.post(`/posts/${this.id}/mainFields/${field}/lock`).then(({ data: { data } }) => data)
  }

  unlockField = ({ field }) => {
    return API.post(`/posts/${this.id}/mainFields/${field}/unlock`).then(
      ({ data: { data } }) => data
    )
  }

  changeFieldLockStatus = ({ field, action }) => {
    switch (action) {
      case 'lock':
        return this.lockField({ field })
      case 'unlock':
        return this.unlockField({ field })
      default:
        return null
    }
  }
}

export default new PublicationTransportLayer()
