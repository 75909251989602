import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { inject, observer } from 'mobx-react'

const PublicRoute = inject('UserStore')(
  observer(({ component: Component, restricted, ...rest }) => {
    const {
      UserStore: { isAuthenticated }
    } = rest
    return (
      // restricted = false meaning public route
      // restricted = true meaning restricted route
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? ( // && restricted ?
            <Redirect to="/" />
          ) : (
            <Component {...props} />
          )
        }
      />
    )
  })
)

export default PublicRoute
