import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'

import WhiteBlockWithShadow from '~components/WhiteBlockWithShadow'

import styles from './styles.scss'

import Pencil from '~assets/icons/pencil.svg'

@inject(stores => {
  const { setEditableTag } = stores.TagsStore

  return {
    setEditableTag
  }
})
@observer
class TagItem extends Component {
  handleClickItem = () => {
    const { tag, setEditableTag } = this.props

    setEditableTag(tag)
  }

  render() {
    const { tag } = this.props
    const { title, index } = tag

    return (
      <WhiteBlockWithShadow>
        <div className={styles.root} onClick={this.handleClickItem}>
          <div className={styles.ico}>
            <Pencil fill="#D3D3D3" />
          </div>

          <div className={styles.title}>{title}</div>

          <div className={styles.id}>ID {index}</div>
        </div>
      </WhiteBlockWithShadow>
    )
  }
}

export default TagItem

TagItem.propTypes = {
  setEditableTag: PropTypes.func,
  tag: PropTypes.object
}
