import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { get } from 'lodash'

import { Input, Button, Form } from 'antd'

import Modal from '~components/Modal'
import ItemWithLabel from '~components/ItemWithLabel'
import DeleteButton from '~components/DeleteButton'

import styles from './styles.scss'

const CREATE_TITLE = 'Создание нового отдела'
const UPDATE_TITLE = 'Редактирование отдела'

@inject(stores => {
  const {
    setHideModal,
    resetEditableDepartment,
    updateDepartment,
    createDepartment,
    deleteDepartment,
    editableDepartment,
    isShowModal,
    isActionModal
  } = stores.DepartmentsStore

  return {
    isShowModal,
    isActionModal,
    editableDepartment,
    resetEditableDepartment,
    updateDepartment,
    createDepartment,
    deleteDepartment,
    setHideModal
  }
})
@observer
class DepartmentsModal extends Component {
  handleSubmit = e => {
    e.preventDefault()

    const { form, updateDepartment, editableDepartment, createDepartment } = this.props
    const formData = form.getFieldsValue()

    const department = {
      data: formData
    }

    const action = editableDepartment ? updateDepartment : createDepartment

    action(department)
  }

  render() {
    const {
      isShowModal,
      setHideModal,
      editableDepartment,
      resetEditableDepartment,
      form,
      isActionModal,
      deleteDepartment
    } = this.props
    const { getFieldDecorator } = form

    const modalTitle = editableDepartment ? UPDATE_TITLE : CREATE_TITLE
    const initialValue = get(editableDepartment, 'name', '')

    return (
      <Modal
        destroyOnClose
        width={590}
        onClose={setHideModal}
        isVisible={isShowModal}
        title={modalTitle}
        afterClose={resetEditableDepartment}
      >
        <div className={styles.root}>
          <Form onSubmit={this.handleSubmit}>
            <ItemWithLabel label="Название отдела">
              <Form.Item>
                {getFieldDecorator('name', {
                  initialValue,
                  rules: [{ required: true, message: 'Обязательное поле!' }]
                })(<Input autoFocus disabled={isActionModal} placeholder="Введите название" />)}
              </Form.Item>
            </ItemWithLabel>

            <div className={styles.modalControls}>
              <Button
                loading={isActionModal}
                disabled={isActionModal}
                htmlType="submit"
                type="primary"
              >
                Сохранить изменения
              </Button>

              {editableDepartment && (
                <div className={styles.deleteBtnWrapper}>
                  <DeleteButton text="Удалить отдел" onDelete={deleteDepartment} />
                </div>
              )}
            </div>
          </Form>
        </div>
      </Modal>
    )
  }
}

const WrappedDepartmentForm = Form.create({ name: 'tagForm' })(DepartmentsModal)

export default WrappedDepartmentForm

DepartmentsModal.propTypes = {
  editableDepartment: PropTypes.any,
  form: PropTypes.any,
  isActionModal: PropTypes.bool,
  isShowModal: PropTypes.bool,
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  resetEditableDepartment: PropTypes.func,
  setHideModal: PropTypes.func,
  updateDepartment: PropTypes.func,
  deleteDepartment: PropTypes.func,
  createDepartment: PropTypes.func
}
