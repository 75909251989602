import { observable, action, runInAction, computed, toJS } from 'mobx'

import {
  T,
  cond,
  propEq,
  pipe,
  filter,
  map,
  ifElse,
  flatten,
  prop,
  apply,
  juxt,
  always,
  length,
  assoc,
  isNil,
  identity,
  omit,
  evolve,
  allPass,
  view,
  lensIndex,
  objOf,
  equals,
  invoker,
  tap
} from 'ramda'
import omitBy from 'lodash/omitBy'
import isNull from 'lodash/isNull'
import isString from 'lodash/isString'
import isEmpty from 'lodash/isEmpty'
import values from 'lodash/values'
import moment from 'moment'
import { message, notification } from 'antd'
import isNumeric from 'antd/lib/_util/isNumeric'

import { typeConstants } from '@dev/tabo-editor'
import {
  castByCoverBlockSchema,
  castByHeadBlockSchema,
  castBySettingsBlockSchema,
  castByTitleBlockSchema
} from '~schemas'
import API from '~services/api'
import getError from '~utils/getError'
import { convertFromRaw, normalize } from '~utils/publication'
import { resourceTypes } from '~constants/publication'
import { callAsync, promiseAll } from '~utils/functional'
import { deepToJS } from '~utils/toJS'

import Block from './BlockModel'
import BlockGroup from './BlockGroupModel'

import PriorityStore from './PostPriorityStore'
import CommentsStore from '../CommentsStore'
import UserStore from '../UserStore'
import OverlayStore from '../OverlayStore'

function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

const PUBLICATION_INTERVAL = 10000

class PublicationStore {
  /**
   *@type {PublicationTransportLayer}
   */
  apiLayer

  socket

  symbols = {
    mediaCaption: 0,
    subtitle: 0,
    title: 0,
    types: {}
  }

  publication = {}

  dataToSave = {}

  @observable initialized = false

  @observable saving = false

  @observable savingModal = false

  @observable savingError = false

  @observable isNew = false

  @action
  setInitialized = () => {
    this.initialized = true
  }

  @observable usersOnPage = []

  @observable isLoading = true

  @observable isShowSettingsDrawer = false

  @observable loadingMessage = ''

  @observable dictionaries = {
    dictionaries: { postTypes: [], postAdvTypes: [], postPriorities: [], postStatuses: [] },
    categories: [],
    sections: [],
    tags: [],
    authors: []
  }

  @observable resourceType = ''

  @observable rssTypes = {}

  @observable status = { color: '', ru: '', id: '' }

  @observable id = ''

  @observable updatedAt = ''

  @observable createdAt = ''

  @observable start = ''

  @observable end = ''

  @observable note = ''

  @observable publicationDate = ''

  @observable createdBy = { firstName: '', lastName: '', avatar: '' }

  @observable updatedBy = { firstName: '', lastName: '', avatar: '' }

  @observable validatedBy = { buildEditor: false, chiefEditor: false, corrector: false }

  @observable validatedAt = { buildEditor: '', chiefEditor: '', corrector: '' }

  @observable alias = ''

  @observable index = ''

  @observable type = ''

  @observable categories = []

  @observable section = ''

  @observable priority = ''

  @observable draftPriorities = { section: [], categories: [], tags: [] }

  @observable draftPrioritiesForServer = {}

  priorities = new PriorityStore(this)

  @observable tags = []

  @observable flows = []

  @observable authors = []

  @observable coauthors = []

  @observable advType = ''

  @observable flags = {
    commentsAllowed: true,
    advDisabled: false,
    pushAllowed: false,
    adultContent: false,
    criminalContent: false,
    blockedByRKN: false,
    darkSide: false,
    coverHidden: false,
    RSS: {
      brandAnalytics: false,
      exclusives: false,
      googleEditorsChoice: false,
      googleNews: false,
      googleNewsStand: false,
      instantFB: false,
      mailruNews: false,
      mainFeed: false,
      yandexDzen: false,
      yandexNews: false,
      yandexNewsSpb: false
    }
  }

  @observable token = '' // TODO: ?? зачем нужен тут токен предпросмотра

  @observable title = ''

  @observable subtitle = ''

  @observable cover = { url: '', _id: '', alt: '' }

  @observable coverTitle = ''

  @observable coverTitleHTML = ''
  /*

  @observable blocks = observable.array()
  */

  @observable locks = observable.array()

  @observable blockGroups = observable.array()

  @observable isOpenModalPriority = false

  @observable publicationBlocksContent = new Map([])

  @observable publicationBlocksOrder = observable.array()

  @observable fetchingRequests = observable.array()

  @observable publicationTimer = null

  @observable hasBadWords = false

  @computed.struct get blocks() {
    // console.log("blocks...", toJS(this.publicationBlocksContent))
    return [...this.publicationBlocksContent.values()].sort(
      ({ id: left }, { id: right }) =>
        this.publicationBlocksOrder.indexOf(left) - this.publicationBlocksOrder.indexOf(right)
    )
  }

  @action
  showModalPriority = () => {
    this.isOpenModalPriority = true
  }

  @action
  hideModalPriority = () => {
    this.isOpenModalPriority = false
  }

  constructor(apiLayer) {
    this.apiLayer = apiLayer

    // TODO: move this.socket to apiLayer, eg. >> this.apiLayer.onReceiveTodoUpdate(updatedTodo => this.updateTodoFromServer(updatedTodo));
    // this.socket = Socket
    // this.loadSettings()
  }

  @action
  unlockAllMyFields = () => {
    this.locks.replace(
      this.locks.filter(({ field, lockedBy }) => {
        if (lockedBy._id === UserStore.user._id) {
          return !navigator.sendBeacon(
            `${process.env.API_ROOT}/posts/${this.id}/mainFields/${field}/unlock`
          )
        }

        return true
      })
    )
  }

  getLockByField = ({ field }) => lock => lock.field === field

  isFieldLockedByMe = ({ field }) =>
    this.locks.filter(this.getLockByField({ field })).filter(this.lockedByMe).length > 0

  isFieldLocked = ({ field }) => this.locks.filter(this.getLockByField({ field })).length > 0

  shouldFetchWhileSaving = field => this.isFieldLockedByMe({ field }) && this.saving

  setLocks = locks => {
    this.locks.replace(locks)
  }

  @action
  lockField = ({ field }) => {
    if (!this.isFieldLocked({ field })) {
      this.setFetching(field)
      this.apiLayer
        .lockField({ field })
        .then(publication => {
          const locks = publication?.locks || []
          this.setLocks(locks)
          return Promise.resolve(publication)
        })
        .catch(e => {
          notification.error({ message: 'Ошибка блокировки', description: e.message })
          return Promise.reject(e)
        })
        .finally(() => {
          this.setFetchingComplete(field)
        })
    }
  }

  @action
  unlockField = ({ field }) => {
    this.setFetching(field)
    this.apiLayer
      .unlockField({ field })
      .then(publication => {
        const locks = publication?.locks || []
        this.setLocks(locks)
        return Promise.resolve(publication)
      })
      .catch(e => {
        notification.error({ message: 'Ошибка разблокировки', description: e.message })
        return Promise.reject(e)
      })
      .finally(() => {
        this.setFetchingComplete(field)
      })
  }

  /*
  @action
  fieldAction = ({ field, action, force = false }) => {
    try {
      const fieldInLocks = this.locks.find(f => f.field === field)
      // const isLockedToOld = fieldInLocks && +new Date(fieldInLocks.lockedTo) < +new Date()

      if (fieldInLocks != null && fieldInLocks.lockedBy._id === UserStore.user._id) {
        if (+new Date(fieldInLocks.lockedTo) < +new Date()) {
          API.post(`/posts/${this.id}/mainFields/${field}/lock`).catch(message.error)
        }
      } else if (fieldInLocks != null && !force) {
        return Promise.resolve()
      }

      // if (fieldInLocks != null && !isLockedToOld) return Promise.resolve()
      if (!action) return Promise.reject()

      if (fieldInLocks == null || (action === 'unlock' && force)) {
        return API.post(`/posts/${this.id}/mainFields/${field}/${action}`).catch(message.error)
      }

      return Promise.reject(new Error('Nothing to do'))
    } catch (e) {
      return Promise.reject(e)
    }
  }
  */

  @computed
  get fieldLocked() {
    if (this.locks.length === 0) return {}

    return this.locks.reduce((locks, { field = '', lockedBy = null, lockedTo = '' }) => {
      // eslint-disable-next-line no-param-reassign
      locks[field] = {
        blocked: lockedBy != null && (lockedBy._id || lockedBy) !== UserStore.user._id,
        isEditing: lockedBy != null && (lockedBy._id || lockedBy) === UserStore.user._id,
        lockedBy,
        lockedTo
      }

      return locks
    }, {})
  }

  @action
  init = async ({ type, id }) => {
    const msg = message.loading('Инициализация')

    await this.apiLayer.getDictionaries().then(dictionaries =>
      runInAction(() => {
        this.dictionaries = dictionaries
      })
    )

    await API.get('/settings/symbols').then(({ data: { data: symbols } }) => {
      runInAction(() => {
        this.symbols = symbols
      })
    })

    await API.get('/dictionaries/settings').then(({ data: { rssTypes } }) => {
      const rss = {}
      rssTypes.forEach(({ id, ru }) => {
        rss[id] = ru
      })

      runInAction(() => {
        this.rssTypes = rss
      })
    })

    await this.loadPublication({ id, type })
      .then(json => {
        this.apiLayer.id = json._id
        this.priorities.init()
        // this.initSocket(json._id)
        this.setPublicationData(json)
      })
      .then(() => {
        this.apiLayer.getEmployeesOnPage().then(e => {
          // console.log("init employees", e)
          this.usersOnPage = e
        })
        msg()
        this.isLoading = false
        this.initialized = true
      })

    this.startPublicationTimer()

    if (this.status.id === 'POST_STATUS_PUBLISHED') {
      await this.apiLayer.getPostPriority(this.id).then(d => {
        try {
          if (d.realPriority !== this.priority) {
            this.priority = d.realPriority
          }
        } catch (e) {
          console.error(e)
        }
      })

      await this.apiLayer.getPostPriorities(this.id).then(d => {
        try {
          // if (d.realPriority !== this.priority) {
          //   this.priority = d.realPriority
          // }
          this.priorities.update(d)
        } catch (e) {
          console.error(e)
        }
      })
    }
  }

  /*
  reinitSocket = () => {
    this.socket.close()
    this.initSocket(this.id)
  }
  */

  /*
  initSocket = id => {
    this.socket.init()
    this.socket.enterPost(id)

    this.socket.subscribe('POST_UPDATED', json => {
      this.updatePublicationFromServer(json)
    })

    this.socket.subscribe('EMPLOYEE_ENTERED_POST', ({ employeeId }) => {
      API.get(`/employees/${employeeId}`).then(({ data: { data: employee } }) => {
        if (!this.usersOnPage.find(e => e._id === employee._id)) {
          runInAction(() => {
            this.usersOnPage.push(employee)
          })
        }
      })
    })

    this.socket.subscribe('EMPLOYEE_EXITED_POST', ({ employeeId }) => {
      const user = this.usersOnPage.find(({ _id }) => _id === employeeId)
      if (user) this.usersOnPage.remove(user)
    })

    this.socket.subscribe('BLOCK_CREATED', block => {
      this.addBlock(block)
    })

    this.socket.subscribe('BLOCK_MOVED', ({ blocks }) => {
      this.reorderBlocks(blocks)
    })

    this.socket.subscribe('reconnect_attempt', event => {
      this.reinitSocket('reconnect_attempt', event)
    })
  }
  */

  /**
   * Fetches publication data from the server
   */
  @action
  loadPublication({ id, type }) {
    if (id === 'new') {
      this.isNew = true
      return this.apiLayer.createPublication(type).then(data => {
        this.createBlock({ before: null, type: typeConstants.TEXT_BLOCK })
        return data
      })
    }

    return this.apiLayer.fetchPublication(id)
  }

  @action
  refreshPublication = async () => {
    // console.log("refreshPublication...");
    try {
      if (!this.isFetching) {
        const publication = await this.apiLayer.fetchPublication(this.id)
        const users = await this.apiLayer.getEmployeesOnPage()
        if (!this.isFetching) {
          this.syncFromServer(publication)
          this.setUsersOnPage(users)
        }
      }
    } catch (e) {
      notification.error({ message: 'Ошибка обновления публикации', description: e.message })
    }

    this.startPublicationTimer()
  }

  @action
  syncFromServer = publication => {
    // console.log("syncFromServer...", publication);

    const { blocks = [], locks = [] } = publication

    const locksLockedByMe = locks.filter(this.lockedBySomeone).filter(this.lockedByMe)

    const isSettingsBlockLockedByMe = locksLockedByMe.find(lock =>
      ['settings'].includes(lock?.field)
    )
    const isCoverBlockLockedByMe = locksLockedByMe.find(lock =>
      ['cover', 'coverTitle'].includes(lock?.field)
    )
    const isTitleBlockLockedByMe = locksLockedByMe.find(lock =>
      ['title', 'subtitle'].includes(lock?.field)
    )

    const blocksContent = new Map([])
    const blocksOrder = blocks.map(this.getServerId)

    const blocksLockedByMe = blocks
      .filter(this.lockedBySomeone)
      .filter(this.lockedByMe)
      .map(this.setBlockDataFromStore)

    const blocksNotLocked = blocks.filter(this.lockedByNoone)

    const blocksLockedByOthers = blocks.filter(this.lockedBySomeone).filter(this.lockedByOthers)

    const blocksModel = [...blocksLockedByMe, ...blocksNotLocked, ...blocksLockedByOthers].map(
      this.createBlockModel
    )
    blocksModel.forEach(blockModel => blocksContent.set(this.getId(blockModel), blockModel))

    this.publicationBlocksContent.replace(blocksContent)
    this.publicationBlocksOrder.replace(blocksOrder)
    this.locks.replace(locks)

    const fieldsToRefresh = castByHeadBlockSchema(publication)

    if (!isSettingsBlockLockedByMe) {
      Object.assign(fieldsToRefresh, castBySettingsBlockSchema(publication))
    }

    if (!isCoverBlockLockedByMe) {
      Object.assign(fieldsToRefresh, castByCoverBlockSchema(publication))
    }

    if (!isTitleBlockLockedByMe) {
      Object.assign(fieldsToRefresh, castByTitleBlockSchema(publication))
    }

    Object.entries(fieldsToRefresh).forEach(([field, value]) => {
      this[field] = value
    })

    // console.log('blocks', blocks, toJS(this.blocks, { recurseEverything: true }))
    // console.log('blocksLockedByUser', blocksLockedByMe)
    // console.log('blocksLockedByNoOne', blocksNotLocked)
    // console.log('blocksLockedByOthers', blocksLockedByOthers)
  }

  /*
  @action
  updatePublicationFromServer = json => {
    const fullJson = {
      _id: this.id,
      index: this.index,
      status: this.status,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      publicationDate: this.publicationDate,
      updatedBy: this.updatedBy,
      validatedBy: this.validatedBy,
      validatedAt: this.validatedAt,
      start: this.start,
      end: this.end,
      note: this.note,

      advType: { id: this.advType },
      authors: this.authors,
      categories: this.categories.map(cat => ({ _id: cat })),
      coauthors: this.coauthors,
      priority: { id: this.priority },
      section: { _id: this.section },
      type: { id: this.type },

      alias: this.alias,
      cover: this.cover,
      coverTitle: this.coverTitle,
      coverTitleHTML: this.coverTitleHTML,
      flags: this.flags,
      resourceType: this.resourceType,
      subtitle: this.subtitle,
      tags: this.tags,
      flows: this.flows,
      title: this.title,

      ...json
    }

    this.setPublicationData(fullJson)
  }
  */

  @action
  setPublicationData = json => {
    const {
      _id = '',
      index = 0,
      status = { color: '', ru: '', id: '' },
      createdAt = null,
      updatedAt = null,
      publicationDate = null,
      updatedBy,
      validatedBy = { buildEditor: false, chiefEditor: false, corrector: false },
      validatedAt = { buildEditor: '', chiefEditor: '', corrector: '' },
      draftPriorities = { section: [], tags: [], categories: [] },
      blocks = [],
      blockGroups = [],
      start = '',
      end = '',
      note = ''
    } = json

    const publicationJson = convertFromRaw(omitBy(json, isNull))

    this.id = _id
    this.status = status
    this.index = index
    this.updatedBy = updatedBy
    this.createdAt = createdAt
    this.publicationDate = publicationDate
    this.updatedAt = updatedAt
    this.validatedBy = validatedBy
    this.validatedAt = validatedAt
    this.publication = publicationJson

    this.start = start
    this.end = end
    this.note = note

    Object.entries(publicationJson).forEach(([key, value]) => {
      this[key] = value
    })

    if (this.status.id !== 'POST_STATUS_PUBLISHED') {
      this.priorities.update({ draftPriorities })
    }

    const blocksContent = new Map([])
    const blocksOrder = blocks.map(this.getServerId)

    blocks.map(this.createBlockModel).forEach(block => blocksContent.set(this.getId(block), block))

    this.publicationBlocksContent.replace(blocksContent)
    this.publicationBlocksOrder.replace(blocksOrder)

    /*
    if (blocks.length > 0) {
      blocks.forEach(block => {
        this.setBlocksFromServer(block)
      })
    }
    */

    if (blockGroups.length > 0) {
      blockGroups.forEach(group => {
        this.setBlockGroupsFromServer(group)
      })
    }
  }

  @action
  setUsersOnPage(users = []) {
    this.usersOnPage = users
  }

  @action
  clear = () => {
    this.isLoading = true
    this.initialized = false
    this.isNew = false

    // this.socket.emit('EXIT_POST', this.id)

    this.usersOnPage = []
    this.publicationBlocksContent.clear()
    this.publicationBlocksOrder.clear()
    /*
    this.blocks.clear()
    */
    this.blockGroups.clear()
    this.setPublicationData({})
    this.clearPublicationTimer()
  }

  @action
  delete = () => {
    return this.apiLayer.deletePublication(this.id)
  }

  getModerationError = cond([
    [equals('bad words contained'), always('присутствуют запрещенные слова')],
    [T, identity]
  ])

  clearErrorModeration = () => {
    this.savings.moderation.isSaving = true
    this.savings.moderation.status = 'ok'
    this.savings.moderation.error = ''
  }

  errorModeration = e => {
    if (e.response) {
      const { data: { error: { message = '' } = {} } = {} } = e.response

      this.savings.moderation.isSaving = false
      this.savings.moderation.status = 'error'
      this.savings.moderation.error = `Ошибка модерации: ${this.getModerationError(message)}`
      this.setSavingError(true)
      this.setSavingModal(true)
    }
  }

  @action
  publishPublication = async () => {
    this.clearErrorModeration()
    await this.savePublication()
    return this.apiLayer
      .publish(this.id)
      .then(
        ({
          data: {
            data,
            data: { status }
          }
        }) => {
          runInAction(() => {
            this.status = status
          })
          return data
        }
      )
      .catch(e => {
        console.error(e)
        this.errorModeration(e)
      })
  }

  @action
  unpublishPublication = () => {
    return this.apiLayer
      .unpublish(this.id)
      .then(
        ({
          data: {
            data,
            data: { status }
          }
        }) => {
          runInAction(() => {
            this.status = status
          })
          return data
        }
      )
      .catch(e => {
        console.error(e)
      })
  }

  @action
  postponePublication = () => {
    this.clearErrorModeration()
    return this.apiLayer
      .postpone({ id: this.id, publicationDate: this.publicationDate })
      .then(
        ({
          data: {
            data,
            data: { status }
          }
        }) => {
          runInAction(() => {
            this.status = status
          })
          return data
        }
      )
      .catch(e => {
        console.error(e)
        this.errorModeration(e)
      })
  }

  @action
  previewPublication = () => {
    if (this.status.id === 'POST_STATUS_PUBLISHED') {
      return Promise.resolve(`${process.env.PUBLIC_ORIGIN}/p/${this.alias || this.index}`)
    }

    return this.apiLayer
      .preview(this.id)
      .then(({ data: { token } }) => {
        this.token = token
        return `${process.env.PUBLIC_ORIGIN}/p/${this.id}?token=${token}`
      })
      .catch(e => {
        console.error(e)
      })
  }

  // @computed
  // get savings() {
  //   return {
  //     cover: {
  //       isLoading: this.isSavingcover || this.isSavingcoverTitle,
  //       status: this.coverStatus === 'ok' || this.coverTitleStatus === 'ok',
  //       isCoverOk: this.coverStatus === 'ok',
  //       isCoverTitleOk: this.coverTitleStatus === 'ok'
  //     },
  //     title: {
  //       isLoading: this.isSavingtitle,
  //       status: this.titleStatus === 'ok'
  //     },
  //     subtitle: {
  //       isLoading: this.isSavingsubtitle,
  //       status: this.subtitleStatus === 'ok'
  //     }
  //   }
  // }

  @observable
  savings = {
    settings: { status: 'ok' },
    cover: { status: 'ok' },
    coverTitle: { status: 'ok' },
    title: { status: 'ok' },
    subtitle: { status: 'ok' },
    moderation: { status: 'ok' },
    blocks: {
      isSaving: false,
      errors: []
    }
  }

  @action
  restoreSavings = () => {
    this.savings = {
      settings: { status: 'ok' },
      cover: { status: 'ok' },
      coverTitle: { status: 'ok' },
      title: { status: 'ok' },
      subtitle: { status: 'ok' },
      moderation: { status: 'ok' },
      blocks: {
        isSaving: false,
        errors: []
      }
    }
  }

  @computed
  get coverSaving() {
    return {
      isSaving: this.savings.cover.isSaving || this.savings.coverTitle.isSaving,
      status:
        this.savings.cover.status === 'ok' && this.savings.coverTitle.status === 'ok'
          ? 'ok'
          : 'error',
      titleStatus: this.savings.coverTitle.status
    }
  }

  @action
  validatePublication = () => {}

  @action
  setSaving = saving => {
    const { setVisible, setText } = OverlayStore
    setText('Сохранение публикации')
    setVisible(saving)
    this.saving = saving
  }

  @action
  setSavingModal = savingModal => {
    this.savingModal = savingModal
  }

  @action
  setSavingError = saving => {
    this.savingError = saving
  }

  createPromiseSaveField = (field, data) =>
    new Promise((resolve, reject) => {
      this.setFetching(field)
      API.patch(`/posts/${this.id}/mainFields/${field}`, data)
        .then(d => {
          runInAction(() => {
            this.savings[field].isSaving = false
            this.savings[field].status = 'ok'
          })
          resolve(d)
        })
        .catch(e => {
          // TODOO: duplicates
          runInAction(() => {
            this.savings[field].isSaving = false
            this.savings[field].status = 'error'
            this.savings[field].error = getError(e)
          })
          reject(e)
        })
        .finally(() => {
          this.setFetchingComplete(field)
        })
    })

  createPromiseSaveAllBlocks = async blocks => {
    this.savings.blocks.isSaving = true

    try {
      await this.apiLayer.saveBlocks(blocks)
      await this.unlockAllMyBlocks()
    } catch (e) {
      const errorMessage = e?.response?.data?.error?.message ?? e.message ?? ''

      this.setSavingError(true)
      this.setSavingModal(true)
      this.savings.blocks.errors.push(`Ошибка сохранения блоков \n  ${errorMessage}`)

      message.error(`Ошибка сохранения блоков: ${e}, ${errorMessage}`)
    }

    runInAction(() => {
      this.savings.blocks.isSaving = false
    })
  }

  @action
  savePublication = async () => {
    const getAlias = cond([
      // TODO Заменить isNumeric
      [isNumeric, always(null)],
      [allPass([isString, isEmpty]), always(null)],
      [T, identity]
    ])

    const getField = pipe(prop('field'), field => objOf('data', this[field]))

    const getSettingsNormalizedData = pipe(
      normalize,
      evolve({
        flags: omit(['pushSent']) // иначе бек ругается на ключ pushSent, в дальнейшем на беке должны убрать это поле, т.к в админке мы его не используем
      }),
      assoc('draftPriorities', this.draftPrioritiesForServer)
    )

    const isExistsAuthors = pipe(prop('authors'), length, Boolean)

    const getFieldData = cond([
      [
        propEq('field', 'settings'),
        ({ field }) => {
          if (!isExistsAuthors(this)) {
            runInAction(() => {
              this.savings[field].isSaving = false
              this.savings[field].status = 'error'
              this.savings[field].error = 'Поле автор не заполнено'
            })

            this.setSavingError(true)
            this.setSavingModal(true)

            return null
          }

          this.alias = getAlias(this.alias)
          return { data: { ...getSettingsNormalizedData(this.asJson), alias: this.alias } }
        }
      ],
      // hard fix — если в cover послать data = { _id: '', ... } - бэк падает; поэтому зануляем (бэк оповещен)
      [
        propEq('field', 'cover'),
        pipe(
          getField,
          prop('data'),
          ifElse(pipe(prop('_id'), isEmpty), always(null), objOf('data'))
        )
      ],
      [
        propEq('field', 'coverTitle'),
        () => ({ data: this.coverTitle, coverTitleHTML: this.coverTitleHTML })
      ],
      [T, getField]
    ])

    const getPromiseSaveField = pipe(
      juxt([prop('field'), getFieldData]),
      ifElse(pipe(view(lensIndex(1)), isNil), always(null), apply(this.createPromiseSaveField))
    )

    const getSaveBlocks = pipe(
      ifElse(
        propEq('resourceType', resourceTypes.textTranslation),
        pipe(prop('blockGroups'), map(prop('blocks')), flatten),
        prop('blocks')
      ),
      tap(() => console.log('current Employee...', deepToJS(UserStore.user))),
      tap(blocks => console.log('all Blocks...', deepToJS(blocks))),
      filter(this.lockedByMe),
      tap(blocks => console.log('filtered Blocks...', deepToJS(blocks))),

      map(pipe(prop('save'), callAsync)),
      promiseAll
    )

    const getPromiseSaveBlocks = ifElse(
      length,
      blocks => this.createPromiseSaveAllBlocks(blocks),
      always(null)
    )

    if (this.saving) return null
    this.setSaving(true)
    // Wait until all blocks are saved and store are updated
    await timeout(2000)

    const promises = pipe(filter(this.lockedByMe), map(getPromiseSaveField))(this.locks)
    promises.push(getPromiseSaveBlocks(await getSaveBlocks(this)))

    await this.saveAll(promises)
    await this.unlockAllMyFields()

    console.log('Saved publication...', this.id)
    this.setSaving(false)

    await this.recompilePost()
    await this.refreshPublication()

    return true
  }

  /**
   * Нужно сохранять с пустым объектом, чтобы перекомпилировать с новыми блоками + очистка кэша
   */
  recompilePost = async () => {
    console.log('Start to compile publication...', this.id)
    const hideRecompileMessage = message.loading('Рекомпиляция...', 0)
    try {
      await this.apiLayer.save({ data: {} })
      await this.apiLayer.clearCache()
      console.log('Compiled publication...', this.id)
    } catch (e) {
      this.setSavingModal(true)
      this.savings.blocks.errors.push(`Ошибка рекомпиляции, обратитесь в поддержку!`)
    }
    hideRecompileMessage()
  }

  @action
  saveAll = (promises = []) => {
    if (!Array.isArray(promises) || promises.length < 1) {
      return Promise.reject(new Error('Not array or []'))
    }

    const hideSettingsMessage = message.loading('Сохранение публикации...', 0)

    // TODO: refactor duplicates
    return Promise.allSettled(promises)
      .then(res => {
        res.forEach(({ status, reason, value }) => {
          if (status === 'rejected' || value?.response?.status !== 200) {
            if (reason) {
              this.setSavingError(true)
              this.setSavingModal(true)
              // message.error(`Ошибка: ${reason || value?.response?.status}`)
            }
          }
        })
      })
      .then(() => {
        this.dataToSave = {}
        message.success('Сохранено')
      })
      .catch(e => {
        const errorMessage =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error.message
            : ''

        this.setSavingError(true)
        this.setSavingModal(true)

        message.error(`Ошибка: ${e}, ${errorMessage}`)
      })
      .finally(() => {
        this.setSaving(false)
        hideSettingsMessage()
      })
  }

  /**
   * */

  @computed get asJson() {
    return {
      resourceType: this.resourceType,
      type: this.type,
      alias: this.alias,
      categories: this.categories,
      section: this.section,
      priority: this.priority,
      tags: this.tags,
      flows: this.flows,
      authors: this.authors,
      coauthors: this.coauthors,
      advType: this.advType,
      flags: this.flags,
      // start: this.start, // TODO: вернуть, иначе будут проблемы с трансляциями
      // end: this.end,
      note: this.note
    }
  }

  @computed get settings() {
    return {
      type: this.type,
      categories: this.categories,
      section: this.section,
      priority: this.priority,
      tags: this.tags,
      flows: this.flows,
      authors: this.authors,
      coauthors: this.coauthors,
      advType: this.advType,
      flags: this.flags,
      alias: this.alias
    }
  }

  @computed get blocksArray() {
    return toJS(this.blocks, { recurseEverything: true })
  }

  @computed get isHereSmthToSave() {
    return this.locks.filter(this.lockedByMe).length > 0
  }

  @computed get isShowNotifyPlannedPost() {
    const dateFormat = 'DD.MM.YYYY HH:mm'

    const postDatwe = moment(this.publicationDate).format(dateFormat)
    const currentDate = moment().format(dateFormat)

    return moment(postDatwe).isBefore(currentDate)
  }

  @computed get flagsJson() {
    return toJS(this.flags)
  }

  @computed get rssJson() {
    return Object.entries(toJS(this.flags.RSS))
      .filter(([, value]) => value)
      .map(([key]) => key)
  }

  @computed get dictionariesList() {
    return {
      ...this.dictionaries,
      categories: [
        ...this.dictionaries.categories.filter(
          ({ _id, visible }) => this.categories.findIndex(id => id === _id) > -1 || visible
        )
      ],
      sections: [
        ...this.dictionaries.sections.filter(
          ({ _id, visible }) => (this.section || {})._id === _id || visible
        )
      ]
    }
  }

  @computed get flagsArray() {
    return Object.keys(this.flags).filter(flag => this.flags[flag])
  }

  @computed get textCount() {
    const customBlockType = ['card:image', 'person', 'card:text', 'card:quote']

    const blocksTextCount = this.blocks
      .map(({ data: { charactersCount = 0, customType, values: fieldValues = {}, type } }) => {
        if (type === 'WIDGET') {
          return 0
        }

        if (!customType) {
          return charactersCount
        }

        if (customBlockType.indexOf(customType) !== -1) {
          const counts = Object.keys(fieldValues).reduce((memo, key) => {
            const count = (fieldValues[key].blocks || [])
              .map(item => item.text.length)
              .reduce((acc, v) => acc + v, 0)

            return Number(memo) + Number(count)
          }, 0)

          return counts
        }

        if (customType === 'gigarama') {
          return values(fieldValues)
            .map(item => (typeof item === 'string' ? item.length : 0))
            .reduce((acc, v) => acc + v, 0)
        }

        return 0
      })
      .reduce((acc, v) => acc + v, 0)
    const titleTextCount = this.title.length
    const subtitleTextCount = this.subtitle.length

    let coverTitleTextCount = 0
    // TODO: refactor this crap
    try {
      const parsedCoverTitle =
        this.coverTitle[0] !== '{'
          ? { blocks: [{ text: this.coverTitle }] }
          : JSON.parse(this.coverTitle)
      coverTitleTextCount =
        this.coverTitle &&
        parsedCoverTitle.blocks.reduce((acc, { text = '' }) => acc + text.length, 0)
    } catch (e) {
      console.error(e)
    }

    return blocksTextCount + titleTextCount + subtitleTextCount + coverTitleTextCount
  }

  @computed
  get isFetching() {
    return (
      this.saving ||
      this.fetchingRequests.length > 0 ||
      [...this.publicationBlocksContent.values()].filter(blockModel => blockModel.lockProcessing)
        .length > 0
    )
  }

  /**
   * Blocks
   */

  /*
  setBlocksFromServer(json) {
    let block = this.findBlockById(json._id)
    if (!block) {
      block = new Block(this, json._id, { ...json })
      this.blocks.push(block)
    }
  }
  */

  setBlockGroupsFromServer(json) {
    let group = this.findBlockGroupById(json._id)
    if (!group) {
      group = new BlockGroup(this, json._id, { ...json })
      this.blockGroups.push(group)
    }
  }

  findBlockById(blockId) {
    return this.publicationBlocksContent.get(blockId)
  }

  findBlockGroupById(groupId) {
    return this.blockGroups.find(group => group.id === groupId)
  }

  @action
  save = data => {
    this.isLoading = true
    return this.apiLayer.save({ data, id: this.id }).finally(() => {
      this.isLoading = false
    })
  }

  delayedExecute = (f, delay) => {
    let timerId = null

    return value => {
      clearTimeout(timerId)

      timerId = setTimeout(() => {
        f(value)
      }, delay)
    }
  }

  saveDelayed = this.delayedExecute(this.save, 600)

  @action
  setPublicationDate = date => {
    this.publicationDate = date
  }

  @action
  setValidatedBy = validatedBy => {
    this.validatedBy = { ...this.validatedBy, ...validatedBy }
    // this.save({ validatedBy: this.validatedBy })
  }

  @action
  approve = approvedBy => {
    // eslint-disable-next-line no-param-reassign
    approvedBy = typeof approvedBy === 'string' ? approvedBy : Object.keys(approvedBy)[0]
    this.setValidatedBy({ [approvedBy]: true })
    this.apiLayer
      .approve({ id: this.id, approvedBy: { [approvedBy]: true } })
      .then(
        ({
          data: {
            data: { validatedBy }
          }
        }) => {
          this.setValidatedBy(Object.keys(validatedBy).map(key => ({ [key]: true })))
        }
      )
      .catch(() => {
        this.setValidatedBy({ [approvedBy]: false })
      })
  }

  @action
  setDraftPrioritiesToServer = async draftPriorities => {
    await this.lockField({ field: 'settings', action: 'lock' })
    runInAction(() => {
      this.draftPrioritiesForServer = draftPriorities
    })
  }

  @action
  setType = async type => {
    await this.lockField({ field: 'settings', action: 'lock' })
    runInAction(() => {
      this.type = type
    })
    // this.save({ type })
  }

  @action
  setCategories = async categories => {
    // const saveCategories = categories.map(({ _id }) => _id)
    await this.lockField({ field: 'settings', action: 'lock' })
    runInAction(() => {
      this.categories = categories
    })
    // this.save({ categories: saveCategories })
  }

  @action
  setSection = async section => {
    await this.lockField({ field: 'settings', action: 'lock' })
    runInAction(() => {
      this.priorities.setSectionPriority(0)
      notification.info({ message: 'Задайте приоритет для нового раздела' })
      if (typeof section === 'string') {
        this.section = section
      } else if (section.key) {
        this.section = section.key
        if (section.label === 'Дичь') {
          // this.flags.criminalContent = true
          this.flags.darkSide = true
        }
      }
      this.isOpenModalPriority = true
    })
  }

  @action
  setPriority = async priority => {
    await this.lockField({ field: 'settings', action: 'lock' })
    runInAction(() => {
      this.priority = priority
    })
    // this.save({ priority })
  }

  @action
  setTags = async tags => {
    await this.lockField({ field: 'settings', action: 'lock' })
    runInAction(() => {
      this.tags = tags
    })
    // this.save({ tags: tags.map(({ _id }) => _id) })
  }

  @action
  setFlows = async flows => {
    await this.lockField({ field: 'settings', action: 'lock' })
    runInAction(() => {
      this.flows = flows
    })
    // this.save({ tags: tags.map(({ _id }) => _id) })
  }

  @action
  setAuthors = async authors => {
    await this.lockField({ field: 'settings', action: 'lock' })
    runInAction(() => {
      this.authors = authors
    })
  }

  @action
  setCoauthors = async coauthors => {
    await this.lockField({ field: 'settings', action: 'lock' })
    runInAction(() => {
      this.coauthors = coauthors
    })
  }

  @action
  setAdvType = async advType => {
    await this.lockField({ field: 'settings', action: 'lock' })
    runInAction(() => {
      this.advType = advType
    })
    // this.save({ advType })
  }

  @action
  setRss = async rss => {
    await this.lockField({ field: 'settings', action: 'lock' })
    runInAction(() => {
      const flags = {
        ...this.flags,
        RSS: rss
      }
      this.flags = flags
    })
    // this.save({ flags })
  }

  @action
  set = async ({ name, value }) => {
    switch (name) {
      case 'alias':
        await this.lockField({ field: 'settings', action: 'lock' })
        runInAction(() => {
          this.alias = value
        })
        // this.saveDelayed({ alias: value })
        break
      case 'title':
        this.title = value.text
        // this.saveDelayed({ title: value.text })
        break
      case 'subtitle':
        this.subtitle = value.text
        // this.saveDelayed({ subtitle: value.text })
        break
      case 'cover':
        await this.lockField({ field: 'cover', action: 'lock' })
        runInAction(() => {
          this.cover = value
          this.coverTitle = value.alt || ''
          this.coverTitleHTML = value.altHTML || ''
        })
        // this.saveDelayed({ cover: value._id || null, coverTitle: value.alt })
        break
      case 'start':
        await this.lockField({ field: 'settings', action: 'lock' })
        runInAction(() => {
          this.start = value
        })
        break
      case 'end':
        await this.lockField({ field: 'settings', action: 'lock' })
        runInAction(() => {
          this.end = value
        })
        break
      case 'note':
        await this.lockField({ field: 'settings', action: 'lock' })
        runInAction(() => {
          this.note = value
        })
        break
      default:
        break
    }
  }

  @action onChangeFlags = async flags => {
    await this.lockField({ field: 'settings', action: 'lock' })
    runInAction(() => {
      const newFlags = toJS(this.flags)

      Object.keys(newFlags).forEach(key => {
        newFlags[key] = flags.indexOf(key) > -1
      })

      this.flags = { ...newFlags, coverHidden: this.flags.coverHidden, RSS: { ...this.flags.RSS } }
      // this.save({ flags: newFlags })
    })
  }

  @action toggleCoverHidden = async flag => {
    await this.lockField({ field: 'settings', action: 'lock' })
    runInAction(() => {
      this.flags = { ...this.flags, coverHidden: flag }
    })
  }

  /*
  reorderBlocks = (order = this.blocksOrder) => {
    this.blocks.replace(
      this.blocks.slice().sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id))
    )
  }
  */

  @action
  createBlock = ({ before, type, data = null, ...rest }, initialProps) => {
    return this.apiLayer
      .createBlock({ before, data: { type, data: data || initialProps || null, ...rest } })
      .then(({ _id, ...rest }) => {
        this.addBlock({ _id, before, ...rest })
      })
  }

  @action
  createBlocksFromArray = async (blocks, before) => {
    const placementIndex = this.publicationBlocksOrder.indexOf(before)

    // eslint-disable-next-line no-restricted-syntax
    for (const block of blocks) {
      // eslint-disable-next-line no-await-in-loop
      await this.apiLayer
        .createBlock({ before, data: { type: block.type, data: block } })
        .then(({ _id, ...rest }) => {
          this.addBlock({ _id, before, ...rest }, placementIndex)
        })
    }
  }

  insertBlockToOrder(blockId, beforeBlockId = null) {
    if (beforeBlockId === null) {
      this.publicationBlocksOrder.push(blockId)
    } else {
      const insertPosition = this.publicationBlocksOrder.indexOf(beforeBlockId)
      this.publicationBlocksOrder.splice(insertPosition, 0, blockId)
    }
  }

  removeBlockFromOrder(blockId) {
    const removePosition = this.publicationBlocksOrder.indexOf(blockId)
    this.publicationBlocksOrder.splice(removePosition, 1)
  }

  reorderBlock(blockId, beforeBlockId) {
    this.removeBlockFromOrder(blockId)
    this.insertBlockToOrder(blockId, beforeBlockId)
  }

  addBlock = ({ _id, before = null, ...rest }) => {
    const id = _id

    if (this.publicationBlocksContent.has(id)) return

    this.publicationBlocksContent.set(id, new Block(this, id, rest))
    this.insertBlockToOrder(id, before)

    /*
    const block = this.findBlockById(_id)
    if (!block) {
      const block = new Block(this, _id, rest)

      const newBlocksOrder = this.blocksOrder.slice()

      if (before == null) {
        newBlocksOrder.push(_id)
      } else {
        const placementIndex = newBlocksOrder.findIndex(id => id === before)
        newBlocksOrder.splice(atIndex || placementIndex, 0, _id)
      }

      const newBlocks = [...this.blocks, block]
      runInAction(() => {
        this.blocks = newBlocks.sort(
          (a, b) => newBlocksOrder.indexOf(a.id) - newBlocksOrder.indexOf(b.id)
        )
      })
    }
*/
  }

  @action
  updateBlock = async ({ id, data }) => {
    const block = this.findBlockById(id)
    if (block) {
      if (!block.lockedBy._id) {
        await block.lock()
      }
      block.setContent(data)
    } else {
      console.error('Block not found')
    }
  }

  @action
  saveBlock = blockId => {
    const block = this.findBlockById(blockId)
    if (block) {
      this.isLoading = true
      block.save().then(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
    } else {
      console.error('Block not found')
    }
  }

  @action
  deleteBlockById = blockId => {
    const block = this.findBlockById(blockId)
    if (block) block.delete()
    if (!this.isHereSmthToSave) {
      setTimeout(this.recompilePost, 5000)
    }
    // this.blocks.remove(block)
    // block.dispose()
  }

  @action
  removeBlock = block => {
    this.publicationBlocksContent.delete(block.id)
    /*
    this.blocks.remove(block)
    */
    block.dispose()
  }

  @action
  lockBlock = async blockId => {
    if (this.publicationBlocksContent.has(blockId)) {
      const block = this.publicationBlocksContent.get(blockId)
      const isLockedByMe = this.lockedByMe(block)

      if (!isLockedByMe) {
        try {
          await block.lock()
        } catch (e) {
          notification.error({ message: 'Ошибка блокировки блока', description: e.message })
        }
      }
    }
    /*
    const block = this.findBlockById(blockId)
    const isBlockLockedByMe =
      block.lockedBy && UserStore.user && block.lockedBy._id === UserStore.user._id
    if (block && !isBlockLockedByMe) {
      block.lock()
    }
    */
  }

  @action
  unlockBlock = async blockId => {
    /*
    const block = this.findBlockById(blockId)
    */
    const block = this.publicationBlocksContent.get(blockId)
    if (block && UserStore.user.role === 'EMPLOYEE_POSITION_CHIEF_EDITOR') {
      try {
        await block.unlock()
      } catch (e) {
        notification.error({ message: 'Ошибка разблокировки блока', description: e.message })
      }
    }
  }

  @action
  changeBlockPosition = async ({ id: blockId, before }) => {
    try {
      await this.apiLayer.moveBlock({ id: blockId, before })
      this.reorderBlock(blockId, before)
    } catch (e) {
      notification.error({ message: 'Ошибка сортировки блоков', description: e.message })
    }
    /*
    const newBlocksOrder = this.blocksOrder.slice()
    const draggableIndex = this.blocksOrder.findIndex(id => id === blockId)

    if (draggableIndex === -1) return

    newBlocksOrder.splice(draggableIndex, 1)
    if (before == null) {
      newBlocksOrder.push(blockId)
    } else {
      const placementIndex = newBlocksOrder.findIndex(id => id === before)
      newBlocksOrder.splice(placementIndex, 0, blockId)
    }

    const newBlocks = this.blocks.slice()

    this.blocks = newBlocks.sort(
      (a, b) => newBlocksOrder.indexOf(a.id) - newBlocksOrder.indexOf(b.id)
    )
    */

    // .then(() => {
    //   const elementRect = document.activeElement.getBoundingClientRect()
    //   const absoluteElementTop = elementRect.top + window.pageYOffset
    //   const middle = absoluteElementTop - window.innerHeight / 2
    //   window.scrollTo(0, middle)
    // })
  }

  @action
  createBlockGroup = () => {
    this.apiLayer.createBlockGroup().then(({ _id, ...rest }) => {
      const group = new BlockGroup(this, _id, rest)

      const newGroups = [group, ...this.blockGroups]
      runInAction(() => {
        this.blockGroups = newGroups
        // this.apiLayer.save({ id: this.id, data: {} })
      })
    })
  }

  @action
  removeGroup = group => {
    this.blockGroups.remove(group)
    // this.apiLayer.save({ id: this.id, data: {} })
  }

  @action
  openCommentsModal = () => {
    const { setOpenedPost } = CommentsStore

    setOpenedPost({
      id: this.id,
      title: this.title
    })
  }

  @action
  unlockAllMyBlock = () => {
    return this.apiLayer.unlockAllMyBlock()
  }

  @action
  unlockAllMyBlocks = async () => {
    await this.apiLayer.unlockAllMyBlocks()
    runInAction(() => {
      pipe(filter(this.lockedByMe), map(invoker(0, 'setLockedToNoone')))(this.blocks)
    })
  }

  @computed
  get isLockedBlockByMe() {
    const isLock = [...this.blocks, ...this.locks].findIndex(
      item => String(item.lockedBy._id) === String(UserStore.user._id)
    )

    return isLock !== -1
  }

  @action
  showSettingsDrawer = () => {
    this.isShowSettingsDrawer = true
  }

  @action
  hideSettingsDrawer = () => {
    this.isShowSettingsDrawer = false
  }

  @action
  setFetching = request => {
    console.log('setFetching', request)
    this.fetchingRequests.push(request)
  }

  @action
  setFetchingComplete = request => {
    console.log('setFetchingComplete', request)
    this.fetchingRequests.remove(request)
  }

  @action
  startPublicationTimer = () => {
    this.clearPublicationTimer()
    if (!isEmpty(this.id)) {
      this.publicationTimer = setTimeout(this.refreshPublication, PUBLICATION_INTERVAL)
    }
  }

  @action
  clearPublicationTimer = () => {
    clearInterval(this.publicationTimer)
  }

  @action
  findBadWords = () => {
    console.log(this.blocksArray)
  }

  getServerId = ({ _id }) => _id

  getId = ({ id }) => id

  lockedBySomeone = ({ lockedBy = {} }) => lockedBy !== null

  lockedByNoone = ({ lockedBy = {} }) => lockedBy === null

  lockedByMe = ({ lockedBy: user = {} }) =>
    this.getServerId(user) === this.getServerId(UserStore.user)

  lockedByOthers = ({ lockedBy: user = {} }) =>
    this.getServerId(user) !== this.getServerId(UserStore.user)

  createBlockModel = serverBlock => new Block(this, this.getServerId(serverBlock), serverBlock)

  setBlockDataFromStore = serverBlock => {
    const storeBlockLockedByMe = this.blocks.find(
      storeBlock => this.getServerId(serverBlock) === this.getId(storeBlock)
    )
    return {
      ...serverBlock,
      data: storeBlockLockedByMe?.data,
      content: storeBlockLockedByMe?.content
    }
  }
}

export default PublicationStore
