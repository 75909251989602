import { useEffect } from 'react'
// eslint-disable-next-line import/no-cycle
import { useFetchPaginationStore, useFilterParamsByListStore } from '~hooks'

export const useClearHandBooksStores = () => {
  const { resetPaginationParams } = useFetchPaginationStore()
  const { setFilterParams } = useFilterParamsByListStore()

  useEffect(() => {
    return () => {
      setFilterParams()
      resetPaginationParams()
    }
  }, [])
}
