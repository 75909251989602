import { array, mixed, object, string } from 'yup'
import { EMPTY_ARRAY, EMPTY_STRING } from '~constants/index'
import { compiledFlowSchema, compiledTagSchema, flagsSchema } from '~schemas'
import { makeDictionary } from '~schemas/transformations/makeDictionary'
import { normalizeCollection, normalizeOne } from '~schemas/transformations/normalize'
import { strippedSchemaWhenNull } from '~schemas/transformations/stripSchemaWhenNull'
import { castBySchema } from '~utils/castBySchema'

const RE_LATIN_CHARS_AND_DASH = /^[0-9a-zA-Z\\-]+$/

/** @type object */
export const settingsBlockSchema = object()
  .default(undefined)
  .shape({
    advType: mixed().transform(normalizeOne('id')),
    alias: string()
      .default(EMPTY_STRING)
      .defined()
      .min(1)
      .matches(RE_LATIN_CHARS_AND_DASH),
    authors: array()
      .default(EMPTY_ARRAY)
      .transform(makeDictionary('name')),
    categories: array()
      .default(EMPTY_ARRAY)
      .transform(normalizeCollection()),
    coauthors: array()
      .default(EMPTY_ARRAY)
      .transform(makeDictionary('name')),
    end: strippedSchemaWhenNull(string().default(EMPTY_STRING)),
    flags: flagsSchema,
    flows: array()
      .default(EMPTY_ARRAY)
      .of(compiledFlowSchema),
    note: string().default(EMPTY_STRING),
    priority: mixed().transform(normalizeOne('id')),
    section: mixed().transform(normalizeOne()),
    start: strippedSchemaWhenNull(string().default(EMPTY_STRING)),
    tags: array()
      .default(EMPTY_ARRAY)
      .of(compiledTagSchema),
    type: mixed().transform(normalizeOne('id'))
  })

export const castBySettingsBlockSchema = castBySchema(settingsBlockSchema, { stripUnknown: true })
