import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { DateUtils } from 'react-day-picker'
import { get } from 'lodash'
import moment from 'moment'

import PublicationItemList from '~components/Publications/PublicationItemList'
import PublicationItemGrid from '~components/Publications/PublicationItemGrid'
import PublicationsItemMobile from '~components/Publications/PublicationsItemMobile'
import GridWrapper from '~components/GridWrapper'
import ContentSpinner from '~components/ContentSpinner'
import EmptyContent from '~components/EmptyContent'
import { SideCalendar } from '~components/Schedule'

import styles from './styles.scss'

const ListCalendarRender = ({ data }) => {
  return data.map((date, index) => (
    <div key={index} className={styles.dateSectionDesktop}>
      <div className={styles.sectionDateTitle}>{date.date}</div>

      <div className={styles.dateList}>
        {date.posts.map(publication => (
          <PublicationItemList
            viewType="calendar"
            key={publication._id}
            publication={publication}
          />
        ))}
      </div>
    </div>
  ))
}

const ListRender = ({ data }) => {
  return data.map(publication => (
    <PublicationItemList key={publication._id} publication={publication} />
  ))
}

const GridRender = ({ data }) => {
  return (
    <GridWrapper gridSize={4} maxItemWidth={285}>
      {data.map(publication => (
        <PublicationItemGrid key={publication._id} publication={publication} />
      ))}
    </GridWrapper>
  )
}

const MobileRender = ({ data }) => {
  return data.map(publication => (
    <PublicationsItemMobile key={publication._id} publication={publication} />
  ))
}

const MobileRenderCalendar = ({ data }) => {
  return data.map((date, index) => (
    <div key={index} className={styles.dateSectionMobile}>
      <div className={styles.sectionDateTitle}>{date.date}</div>

      <div className={styles.dateList}>
        {date.posts.map(publication => (
          <PublicationsItemMobile key={publication._id} publication={publication} />
        ))}
      </div>
    </div>
  ))
}

@inject(stores => {
  const {
    publications,
    isFirstLoading,
    isEmptyPublications,
    isGrid,
    setIsFirstLoading,
    fetchPublications,
    setShowFilter,
    setFilterItemActive,
    selectedFilterItems,
    groupedData,
    viewType
  } = stores.PublicationsStore
  const { setFilterParams, filterParams, setFilterValue } = stores.FilterParamsByListStore
  const { resetPaginationParams } = stores.FetchPaginationStore

  return {
    publications,
    isFirstLoading,
    isEmptyPublications,
    isGrid,
    setFilterParams,
    setIsFirstLoading,
    filterParams,
    setFilterValue,
    resetPaginationParams,
    fetchPublications,
    setShowFilter,
    setFilterItemActive,
    selectedFilterItems,
    groupedData,
    viewType
  }
})
@observer
class PublicationList extends Component {
  handleDayClick = day => {
    const {
      resetPaginationParams,
      setIsFirstLoading,
      filterParams,
      setFilterValue,
      fetchPublications,
      setShowFilter,
      setFilterItemActive,
      selectedFilterItems
    } = this.props

    const dateArray = get(filterParams, 'filter.publicationDate', '').split(',')

    const startDate = get(dateArray, '0', undefined)
    const endDate = get(dateArray, '1', undefined)

    const range = DateUtils.addDayToRange(day, {
      from: startDate ? new Date(startDate) : undefined,
      to: endDate ? new Date(endDate) : undefined
    })

    let formatDateByfilter = `${moment(range.from).format('YYYY-MM-DD')}`

    if (range.to) {
      formatDateByfilter = `${formatDateByfilter},${moment(range.to).format('YYYY-MM-DD')}`
    }

    setFilterValue('filter.publicationDate', formatDateByfilter)

    if (range.to) {
      if (selectedFilterItems.findIndex(item => item.name === 'filter.publicationDate')) {
        setFilterItemActive({
          label: 'Дата публикации',
          type: 'dateRange',
          name: 'filter.publicationDate'
        })
      }
      setShowFilter()

      setIsFirstLoading(true)
      resetPaginationParams()
      fetchPublications()
    }
  }

  getDateRangeByCalendar = () => {
    const { filterParams } = this.props

    const dateArray = get(filterParams, 'filter.publicationDate', '').split(',')

    const startDate = get(dateArray, '0', undefined)
    const endDate = get(dateArray, '1', undefined)

    return {
      start: startDate ? new Date(startDate) : undefined,
      end: endDate ? new Date(endDate) : undefined
    }
  }

  render() {
    const { publications, isFirstLoading, isEmptyPublications, groupedData, viewType } = this.props
    const { start, end } = this.getDateRangeByCalendar()

    const isEmpty = isEmptyPublications && !isFirstLoading
    const isShowData = !isFirstLoading && !isEmptyPublications

    return (
      <div className={styles.root}>
        <div className={styles.list}>
          {isFirstLoading && <ContentSpinner />}
          {isEmpty && <EmptyContent text="Нет публикаций" />}

          {viewType === 'calendar' && isShowData && <ListCalendarRender data={groupedData} />}

          {viewType === 'list' && isShowData && <ListRender data={publications} />}

          {viewType === 'grid' && isShowData && <GridRender data={publications} />}

          {viewType !== 'calendar' && isShowData && <MobileRender data={publications} />}

          {viewType === 'calendar' && isShowData && <MobileRenderCalendar data={groupedData} />}
        </div>

        {viewType === 'calendar' && (
          <div className={styles.calendar}>
            <SideCalendar
              className="Range"
              selectedDays={[start, { from: start, to: end }]}
              modifiers={{
                weekend: {
                  daysOfWeek: [6, 0]
                },
                start,
                end
              }}
              onDayClick={this.handleDayClick}
            />
          </div>
        )}
      </div>
    )
  }
}

export default PublicationList

PublicationList.propTypes = {
  fetchPublications: PropTypes.func,
  filterParams: PropTypes.object,
  groupedData: PropTypes.array,
  isEmptyPublications: PropTypes.bool,
  isFirstLoading: PropTypes.bool,
  isGrid: PropTypes.bool,
  publications: PropTypes.array,
  resetPaginationParams: PropTypes.func,
  selectedFilterItems: PropTypes.func,
  setFilterItemActive: PropTypes.func,
  setFilterValue: PropTypes.func,
  setIsFirstLoading: PropTypes.func,
  setShowFilter: PropTypes.func,
  viewType: PropTypes.string
}

ListRender.propTypes = {
  data: PropTypes.any
}

GridRender.propTypes = {
  data: PropTypes.any
}
