import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { observer, inject } from 'mobx-react'

import Socials from '~components/Authors/Socials'
import AuthorInfo from '~components/Authors/AuthorItem/TopInfo'
import WhiteBlockWithShadow from '~components/WhiteBlockWithShadow'

import styles from './styles.scss'

import Pencil from '~assets/icons/pencil.svg'

@inject(stores => {
  const { setEditableAuthor } = stores.AuthorsStore

  return {
    setEditableAuthor
  }
})
@observer
class AuthorItem extends Component {
  handleClickItem = () => {
    const { author, setEditableAuthor } = this.props

    setEditableAuthor(author, 'edit')
  }

  handleSocialLink = e => {
    e.stopPropagation()
  }

  handleClickMoreInfo = e => {
    e.stopPropagation()
    const { author, setEditableAuthor } = this.props

    setEditableAuthor(author, 'info')
  }

  render() {
    const { author } = this.props

    const { socials } = author

    return (
      <WhiteBlockWithShadow>
        <div className={styles.root} onClick={this.handleClickItem}>
          <div className={styles.ico}>
            <Pencil fill="#D3D3D3" />
          </div>

          <AuthorInfo data={author} />

          <div className={styles.bottom}>
            <Socials data={socials} onClick={this.handleSocialLink} />

            <div onClick={this.handleClickMoreInfo} className={styles.readMore}>
              Прочитать полностью
            </div>
          </div>
        </div>
      </WhiteBlockWithShadow>
    )
  }
}

export default AuthorItem

AuthorItem.propTypes = {
  author: PropTypes.object,
  setEditableAuthor: PropTypes.func
}
