import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

import styles from './styles.scss'

@inject(stores => {
  const { isShowModalEdit, setShowModalInfo, setEditableEvent } = stores.ScheduleStore

  return {
    isShowModalEdit,
    setShowModalInfo,
    setEditableEvent
  }
})
@observer
class WeekEvent extends Component {
  handleShowModalInfo = () => {
    const { event, setEditableEvent, setShowModalInfo } = this.props

    setEditableEvent(event)
    setShowModalInfo()
  }

  render() {
    const { title } = this.props

    return (
      <div onClick={this.handleShowModalInfo} className={styles.root}>
        <div className={styles.title}>{title}</div>
      </div>
    )
  }
}

export default WeekEvent

WeekEvent.propTypes = {
  event: PropTypes.object,
  isShowModalEdit: PropTypes.bool,
  setEditableEvent: PropTypes.func,
  setShowModalInfo: PropTypes.func,
  title: PropTypes.string
}
