import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { get, noop } from 'lodash'

import { Input, Button, Form } from 'antd'

import Modal from '~components/Modal'
import ItemWithLabel from '~components/ItemWithLabel'
import DeleteButton from '~components/DeleteButton'
import { useBadWordsStore } from '~hooks/useBadWordsStore'

import styles from './styles.scss'

const CREATE_TITLE = 'Создание нового запрещенного слова'
const UPDATE_TITLE = 'Редактирование запрещенного слова'

export const BadWordModal = Form.create({ name: 'badWordForm' })(
  observer(props => {
    const {
      isShowModal,
      setHideModal,
      editableBadWord,
      resetEditableBadWord,
      isActionModal,
      deleteBadWord,
      updateBadWord,
      createBadWord
    } = useBadWordsStore()

    const { form } = props
    const { getFieldDecorator = noop, getFieldsValue = noop } = form

    const modalTitle = editableBadWord ? UPDATE_TITLE : CREATE_TITLE
    const initialValue = get(editableBadWord, 'name', '')

    const handleSubmit = e => {
      e.preventDefault()

      const formData = getFieldsValue()

      const badWord = {
        data: formData
      }

      const action = editableBadWord ? updateBadWord : createBadWord

      action(badWord)
    }

    return (
      <Modal
        destroyOnClose
        width={590}
        onClose={setHideModal}
        isVisible={isShowModal}
        title={modalTitle}
        afterClose={resetEditableBadWord}
      >
        <div className={styles.root}>
          <Form onSubmit={handleSubmit}>
            <ItemWithLabel label="Запрещенное слово">
              <Form.Item>
                {getFieldDecorator('name', {
                  initialValue,
                  rules: [{ required: true, message: 'Обязательное поле!' }]
                })(<Input autoFocus disabled={isActionModal} />)}
              </Form.Item>
            </ItemWithLabel>

            <div className={styles.modalControls}>
              <Button
                loading={isActionModal}
                disabled={isActionModal}
                htmlType="submit"
                type="primary"
              >
                Сохранить изменения
              </Button>

              {editableBadWord && (
                <div className={styles.deleteBtnWrapper}>
                  <DeleteButton text="Удалить запрещенное слово" onDelete={deleteBadWord} />
                </div>
              )}
            </div>
          </Form>
        </div>
      </Modal>
    )
  })
)

BadWordModal.propTypes = {
  editableBadWord: PropTypes.any,
  form: PropTypes.any,
  isActionModal: PropTypes.bool,
  isShowModal: PropTypes.bool,
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  resetEditableBadWord: PropTypes.func,
  setHideModal: PropTypes.func,
  updateBadWord: PropTypes.func,
  deleteBadWord: PropTypes.func,
  createBadWord: PropTypes.func
}
