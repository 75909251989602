import React from 'react'
// import PropTypes from 'prop-types'
import { inject } from 'mobx-react'

import Logo from '~assets/logo.svg'
import LoginForm from './components/LoginForm'

import styles from './styles.scss'

const LoginPage = inject('UserStore')(props => {
  const {
    UserStore: { performLogin }
  } = props

  return (
    <div className={styles.loginLayout}>
      <Logo fill="white" /> {/* TODO: svg dimensions, fix  logo size */}
      <LoginForm handleLogin={performLogin} />
      {/* TODO: form error from server */}
    </div>
  )
})

LoginPage.propTypes = {}

export default LoginPage
