import { observable, action, runInAction, reaction, toJS } from 'mobx'
import { v0 } from 'life-schema'

import UserStore from '../UserStore'

export default class Block {
  id = null

  /**
   * @type {PublicationStore}
   */
  store = null

  saveHandler = null

  lockTimer = null

  lockHandler = null

  autoSave = null

  autoSaveHandler = null

  @observable blocked = false

  @observable lockedBy = { _id: '' }

  @observable lockedTo = ''

  @observable createdAt = ''

  @observable updatedAt = ''

  @observable type = ''

  @observable content = ''

  @observable compiled = ''

  @observable data = {}

  @observable isError = false

  @observable isCorrect = true

  @observable isEditing = false

  @observable isFetching = false

  constructor(store, id, data) {
    this.store = store
    this.id = id

    this.type = data.type

    // TODO: refactor!
    let lockedById = ''
    let lockedByUser = { _id: '' }

    try {
      if (typeof data.lockedBy === 'object' && data.lockedBy != null) {
        lockedById = data.lockedBy._id || data.lockedBy.id || ''
        lockedByUser = data.lockedBy
      } else if (typeof data.lockedBy === 'string' && data.lockedBy !== '') {
        lockedById = data.lockedBy
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}

    try {
      const blocked = lockedById && lockedById !== UserStore.user._id
      const isEditing = lockedById === UserStore.user._id
      const lockedBy =
        lockedByUser ||
        (lockedById
          ? [...store.usersOnPage, UserStore.user].find(({ _id }) => _id === lockedById)
          : { _id: '' })

      // const dataToRestore = data.lockedBy && !blocked ? getLocalStorageItem(`block:${id}`) : null
      const dataToRestore = undefined

      const content =
        dataToRestore &&
        // if local changes after updated >>> get local
        new Date(data.updatedAt).getTime() - new Date(dataToRestore.timestamp).getTime() < 0
          ? dataToRestore.value || data.content
          : data.content || ''

      this.content = content
      this.data = JSON.parse(content || '{}')
      this.blocked = blocked
      this.isEditing = isEditing
      this.lockedBy = lockedBy
      this.lockedTo = data.lockedTo
      this.createdAt = data.createdAt
      this.updatedAt = data.updatedAt
      this.isCorrect =
        data.createdAt !== data.updatedAt
          ? new Date(store.validatedAt.corrector) - new Date(data.updatedAt) > 0
          : true
    } catch (e) {
      console.error(e)
    }

    this.correctorHandler = reaction(
      () => ({ validatedAt: this.store.validatedAt.corrector, updatedAt: this.updatedAt }),
      ({ validatedAt = '' }) => {
        this.isCorrect = new Date(validatedAt || null) - new Date(this.updatedAt || null) > 0 // Блок не только что создан и обновлен позднее последней проверки
      }
    )

    reaction(
      () => this.lockedBy,
      lockedBy => {
        this.blocked = (lockedBy && lockedBy._id && lockedBy._id !== UserStore.user._id) || false
        this.isEditing = (lockedBy && lockedBy._id && lockedBy._id === UserStore.user._id) || false
      },
      {
        name: 'lockedBy change reaction'
      }
    )

    reaction(
      () => ({
        isSaving: this.store.saving,
        isFetching: this.lockProcessing
      }),
      ({ isSaving, isFetching }) => {
        // console.log('isSaving', isSaving, 'isEditing', this.isEditing, 'isFetching', isFetching)
        this.isFetching = (isSaving && this.isEditing) || isFetching
      },
      {
        name: 'isFetching reaction'
      }
    )
  }

  @action
  setContent(data) {
    this.content = JSON.stringify(data)
    this.data = data
  }

  @observable lockProcessing = false

  /**
   *
   * @param isLocked {boolean}
   */
  @action
  setLockProcessing = isLocked => {
    // console.log('setLockProcessing', isLocked)
    this.lockProcessing = isLocked
  }

  @action
  setLockedToNoone = () => {
    this.lockedBy = { _id: '' }
    this.lockedTo = ''
  }

  @action
  lock() {
    if (this.lockProcessing) return Promise.resolve()
    this.setLockProcessing(true)
    return this.store.apiLayer
      .lockBlock(this.id)
      .then(
        ({
          data: {
            data: { lockedBy, lockedTo, content }
          }
        }) => {
          content && this.setContent(JSON.parse(content))
          runInAction(() => {
            this.lockedBy = lockedBy
            this.lockedTo = lockedTo
          })
        }
      )
      .catch(e => {
        return Promise.reject(e)
      })
      .finally(() => {
        this.setLockProcessing(false)
      })
  }

  @action
  unlock() {
    this.setLockProcessing(true)
    return this.store.apiLayer
      .unlockBlock(this.id)
      .then(() => {
        this.setLockedToNoone()
      })
      .finally(() => {
        this.setLockProcessing(false)
      })
  }

  checkBlocking = () => {
    const currentTime = new Date()
    const blockedTo = new Date(this.lockedTo)
    if (blockedTo - currentTime < 60000) {
      this.save()
    }
  }

  // WithoutLock for TEXT STREAM type publications. Where is no lock
  @action.bound
  async save() {
    console.log('Saving block...', this.id)
    const content = JSON.stringify(toJS(this.data))

    const dataToCompile = {
      _id: this.id,
      type: this.type,
      content,
      compiled: null
    }

    let compiled = null
    try {
      const convertResult = await v0.convertBlock(dataToCompile)
      compiled = convertResult?.compiled || null
    } catch (e) {
      console.error(e)
    }

    return {
      blockId: this.id,
      data: {
        content,
        compiled,
        type: this.type
      }
    }
  }

  delete() {
    this.setLockProcessing(true)
    this.store.apiLayer.deleteBlock(this.id).finally(() => {
      this.setLockProcessing(false)
    })
    this.remove()
  }

  remove() {
    this.store.removeBlock(this)
  }

  dispose() {
    typeof this.saveHandler === 'function' && this.saveHandler()
    typeof this.lockHandler === 'function' && this.lockHandler()
    typeof this.autoSaveHandler === 'function' && this.autoSaveHandler()
    typeof this.correctorHandler === 'function' && this.correctorHandler()
  }
}
