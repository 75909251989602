import React from 'react'
import PropTypes from 'prop-types'
import { hot } from 'react-hot-loader/root' // eslint-disable-line
import { inject } from 'mobx-react'

import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom'

import PrivateRoute from '~routes/PrivateRoute'
import PublicRoute from '~routes/PublicRoute'
import routes from '~routes'
import { getRoleExceptSome } from '~utils/roles'

import '~stylesheets/index.scss'
import PublicationsPage from '~pages/PublicationsPage'
import { Overlay } from '~components/Overlay'

@inject(({ UserStore: { token, appLoaded, setAppLoaded, getUserInfo } }) => ({
  token,
  appLoaded,
  setAppLoaded,
  getUserInfo
}))
class App extends React.Component {
  constructor(props) {
    super(props)

    const { token, setAppLoaded } = props
    if (!token) {
      setAppLoaded()
    }
  }

  componentDidMount() {
    const { token, getUserInfo } = this.props
    if (token) {
      getUserInfo()
    }
  }

  render() {
    const { appLoaded } = this.props
    if (appLoaded) {
      return (
        <Overlay>
          <Router>
            <Switch>
              {/* Redirect from "/" to "/publications" */}
              <Redirect exact from="/" to="/publications" />

              {/* Redicrect for edit chrome extension */}
              <Redirect exact from="/posts/:id/edit" to="/publications/:id" />
              <Redirect exact from="/posts/:id" to="/publications/:id" />

              {Object.entries(routes.public).map(([key, route]) => {
                const { component, path } = route
                return <PublicRoute key={key} component={component} path={path} exact />
              })}

              {Object.entries(routes.private).map(([key, route]) => {
                const {
                  submenu,
                  component,
                  path,
                  defaultFilter,
                  hideFilters = [],
                  showPostsHeader = false,
                  authority
                } = route
                if (submenu) {
                  return Object.entries(
                    submenu
                  ).map(([key, { component, path, defaultFilter, hideFilters, authority }]) => (
                    <PrivateRoute
                      key={key}
                      component={component}
                      path={path}
                      defaultFilter={defaultFilter}
                      hideFilters={hideFilters}
                      showPostsHeader={showPostsHeader}
                      authority={authority}
                    />
                  ))
                }
                return (
                  <PrivateRoute
                    key={key}
                    component={component}
                    path={path}
                    defaultFilter={defaultFilter}
                    hideFilters={hideFilters}
                    showPostsHeader={showPostsHeader}
                    exact
                    authority={authority}
                  />
                )
              })}

              {/* 404 — redirect to IndexPage */}
              <PrivateRoute component={PublicationsPage} authority={getRoleExceptSome([])} />
            </Switch>
          </Router>
        </Overlay>
      )
    }

    // TODO: make loader component
    return <div>Loading...</div>
  }
}

App.propTypes = {
  token: PropTypes.string,
  appLoaded: PropTypes.bool,
  setAppLoaded: PropTypes.func,
  getUserInfo: PropTypes.func
}

export default hot(App)
