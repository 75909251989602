import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'

import GridWrapper from '~components/GridWrapper'
import ContentSpinner from '~components/ContentSpinner'
import SymbolsItem from '~components/Symbols/SymbolsItem'

import s from './styles.scss'

// TODO: Fixme
const HADCODED_DICT = [
  {
    id: 'title',
    ru: 'Длина заголовка'
  },
  {
    id: 'subtitle',
    ru: 'Длина подзаголовка'
  },
  {
    id: 'mediaCaption',
    ru: 'Подписи к изображениям и видео'
  }
]

@inject('SymbolsStore')
@observer
class SymbolsList extends Component {
  componentDidMount() {
    const {
      SymbolsStore: { fetchSymbols, fetchDictionaries }
    } = this.props

    fetchSymbols()
    fetchDictionaries()
  }

  render() {
    const {
      SymbolsStore: { symbols, dictionaries, isFirstLoading }
    } = this.props

    const pages = symbols.types

    if (isFirstLoading) {
      return <ContentSpinner />
    }

    return (
      <>
        <GridWrapper gridSize={3} maxItemWidth={285}>
          {symbols &&
            Object.keys(symbols).map((key, idx) => {
              return key !== 'types' ? (
                <SymbolsItem
                  title={key}
                  value={symbols[key]}
                  dictionaries={HADCODED_DICT}
                  key={idx}
                />
              ) : null
            })}
        </GridWrapper>
        <h1 className={s.subtitle}>Страницы</h1>
        <GridWrapper gridSize={3} maxItemWidth={285}>
          {pages &&
            Object.keys(pages).map((key, idx) => (
              <SymbolsItem title={key} value={pages[key]} dictionaries={dictionaries} key={idx} />
            ))}
        </GridWrapper>
      </>
    )
  }
}

SymbolsList.propTypes = {
  SymbolsStore: PropTypes.any
}

export default SymbolsList
