import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { Input, Upload, message, Checkbox, Button, Form } from 'antd'

import DeleteButton from '~components/DeleteButton'
import ItemWithLabel from '~components/ItemWithLabel'
import Modal from '~components/Modal'
import s from './styles.scss'

const CREATE_TITLE = 'Создание нового раздела'
const UPDATE_TITLE = 'Редактирование раздела'

@inject('SectionsStore')
@observer
class SectionsModal extends Component {
  handleSubmit = e => {
    e.preventDefault()

    const {
      SectionsStore: { editableSection, updateSection, createSection },
      form
    } = this.props
    const formData = form.getFieldsValue()

    if (formData.cover && formData.cover._id) {
      formData.cover = formData.cover._id
    } else if (formData.cover && formData.cover.file && formData.cover.file.response) {
      formData.cover = formData.cover.file.response.data._id
    } else {
      formData.cover = null
    }

    const section = { data: { ...formData } }
    const action = editableSection ? updateSection : createSection

    action(section)
  }

  render() {
    const {
      SectionsStore: {
        setHideModal,
        isShowModal,
        resetEditableSection,
        editableSection,
        isActionModal,
        deleteSection
      },
      form
    } = this.props
    const { getFieldDecorator } = form
    const ModalTitle = editableSection ? UPDATE_TITLE : CREATE_TITLE
    const fileUploadProps = {
      name: 'file',
      action: `${process.env.API_ROOT}/files`,
      withCredentials: true,
      onChange(info) {
        if (info.file.status === 'done') {
          message.success(`${info.file.name} успешно загружен.`)
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} не получилось загрузить файл.`)
        }
      }
    }

    return (
      <Modal
        destroyOnClose
        width={590}
        onClose={setHideModal}
        isVisible={isShowModal}
        title={ModalTitle}
        afterClose={resetEditableSection}
      >
        <Form onSubmit={this.handleSubmit}>
          <ItemWithLabel label="Название раздела">
            <Form.Item>
              {getFieldDecorator('title', {
                initialValue: editableSection && editableSection.title,
                rules: [{ required: true, message: 'Обязательное поле!' }]
              })(<Input placeholder="Введите название" disabled={isActionModal} />)}
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="SEO заголовок">
            <Form.Item>
              {getFieldDecorator('seo.title', {
                initialValue: editableSection && editableSection.seo.title,
                rules: [{ required: false }]
              })(<Input placeholder="Введите SEO заголовок" disabled={isActionModal} />)}
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="SEO описание">
            <Form.Item>
              {getFieldDecorator('seo.description', {
                initialValue: editableSection && editableSection.seo.description,
                rules: [{ required: false }]
              })(<Input placeholder="Введите SEO описание" disabled={isActionModal} />)}
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="Обложка">
            <Form.Item>
              {getFieldDecorator('cover', {
                initialValue: editableSection && editableSection.cover
              })(
                <Upload {...fileUploadProps}>
                  <Button>Кликните чтобы загрузить файл</Button>
                </Upload>
              )}
            </Form.Item>
          </ItemWithLabel>

          <Form.Item>
            {getFieldDecorator('visible', {
              initialValue: (editableSection && editableSection.visible) || false,
              valuePropName: 'checked'
            })(<Checkbox>Опубликован на сайте</Checkbox>)}
          </Form.Item>

          <div className={s.modalControls}>
            <Button htmlType="submit" type="primary" disabled={isActionModal}>
              Сохранить изменения
            </Button>
            {editableSection && (
              <div className={s.deleteBtnWrapper}>
                <DeleteButton
                  text="Удалить раздел"
                  disabled={isActionModal}
                  onDelete={deleteSection}
                />
              </div>
            )}
          </div>
        </Form>
      </Modal>
    )
  }
}

SectionsModal.propTypes = {
  SectionsStore: PropTypes.any,
  form: PropTypes.any
}

export default Form.create({ name: 'sectionForm' })(SectionsModal)
