/* eslint-disable */
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Select } from 'antd'

import SliderWrapper from '../SliderWrapper'
import SliderItem from '../components/SliderItem'

const { Option } = Select

@inject(stores => {
  const { section, dictionariesList, priorities } = stores.PublicationStore
  const { sectionPriority, setSelectedSection, setSectionPriority } = priorities

  return {
    ...dictionariesList,
    section,
    sectionPriority,

    setSelectedSection,
    setSectionPriority
  }
})
@observer
class PrioritySections extends Component {
  render() {
    const {
      section,
      sectionPriority,
      label,
      sections,
      selectedSection,
      setSelectedSection,
      setSectionPriority
    } = this.props

    return (
      <SliderWrapper label={label}>
        <Select
          style={{ width: 150 }}
          placeholder="Выберите раздел"
          value={section}
          onChange={setSelectedSection}
        >
          {sections.map(({ title, _id }) => (
            <Option key={_id} value={_id}>
              {title}
            </Option>
          ))}
        </Select>
        <SliderItem
          value={sectionPriority}
          allowDelete={false}
          disabled={!section}
          onChange={setSectionPriority}
        />
      </SliderWrapper>
    )
  }
}

export default PrioritySections
