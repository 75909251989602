import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

import { Button, Dropdown } from 'antd'

import GridWrapper from '~components/GridWrapper'
import FilterDropDown from '~components/Publications/FilterDropDown'
import DynamicField from '~components/Publications/Filter/dynamicField'

import styles from './styles.scss'

@inject(stores => {
  const {
    filtersItem,
    selectedFilterItems,
    removeFileItem,
    fetchPublications,
    dictionaries,
    fetchDictionaries,
    setIsFirstLoading,
    setFixedFilter
  } = stores.PublicationsStore

  const { resetPaginationParams } = stores.FetchPaginationStore
  const { setFilterParams } = stores.FilterParamsByListStore
  const { resetOptions } = stores.SortingStore

  return {
    filtersItem,
    selectedFilterItems,
    removeFileItem,
    resetPaginationParams,
    fetchPublications,
    dictionaries,
    fetchDictionaries,
    setFilterParams,
    setIsFirstLoading,
    setFixedFilter,
    resetOptions
  }
})
@observer
class Filter extends Component {
  componentDidMount() {
    const { fetchDictionaries } = this.props

    fetchDictionaries()
  }

  handleSetFilter = () => {
    const {
      resetPaginationParams,
      fetchPublications,
      setIsFirstLoading,
      defaultFilter
    } = this.props

    setIsFirstLoading(true)
    resetPaginationParams()
    fetchPublications(defaultFilter)
  }

  handleResetFilter = () => {
    const {
      setFilterParams,
      setIsFirstLoading,
      fetchPublications,
      resetPaginationParams,
      resetOptions,
      defaultFilter
    } = this.props

    setFilterParams()
    resetPaginationParams()
    resetOptions()
    setIsFirstLoading(true)
    fetchPublications(defaultFilter)
  }

  render() {
    const {
      selectedFilterItems,
      removeFileItem,
      filtersItem,
      hideFilters,
      setFixedFilter
    } = this.props

    if (hideFilters.length) {
      let index = 3

      filtersItem.forEach(item => {
        if (item.fix === true && hideFilters.indexOf(item.name) > -1) {
          index += 1

          setFixedFilter(filtersItem[index])
        }
      })
    }

    const filteredFilter = filtersItem.filter(item => hideFilters.indexOf(item.name) === -1)

    return (
      <div className={styles.root}>
        <GridWrapper gridSize={5} maxItemWidth={285}>
          {filteredFilter
            .filter(item => !!item.fix)
            .map((item, index) => (
              <DynamicField key={index} item={item} />
            ))}

          {selectedFilterItems
            .filter(item => item.type !== 'bool')
            .map((item, index) => (
              <DynamicField onRemove={removeFileItem} key={index} item={item} />
            ))}
        </GridWrapper>

        <div className={styles.boolFilterWrapper}>
          {selectedFilterItems
            .filter(item => item.type === 'bool')
            .map((item, index) => (
              <DynamicField onRemove={removeFileItem} key={index} item={item} bool />
            ))}
        </div>

        <div className={styles.filterControls}>
          <Dropdown trigger={['click']} placement="bottomLeft" overlay={<FilterDropDown />}>
            <div className={styles.addNewItem}>
              <Button shape="circle" icon="plus" size="small" type="primary" />

              <span className={styles.addBtnText}>Добавить фильтр</span>
            </div>
          </Dropdown>

          <div className={styles.serviceControl}>
            <Button type="ghost" onClick={this.handleResetFilter}>
              Отменить
            </Button>
            <Button type="primary" onClick={this.handleSetFilter}>
              Применить
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default Filter

Filter.propTypes = {
  defaultFilter: PropTypes.string,
  dictionaries: PropTypes.any,
  fetchDictionaries: PropTypes.func,
  fetchPublications: PropTypes.func,
  filtersItem: PropTypes.object,
  hideFilters: PropTypes.any,
  removeFileItem: PropTypes.func,
  resetOptions: PropTypes.func,
  resetPaginationParams: PropTypes.func,
  selectedFilterItems: PropTypes.array,
  setFilterParams: PropTypes.func,
  setFixedFilter: PropTypes.any,
  setIsFirstLoading: PropTypes.func
}
