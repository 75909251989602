import React from 'react'
import PropTypes from 'prop-types'

import { Form, Input } from 'antd'
import Button from '~components/Button'

import styles from './styles.scss'

const LoginForm = props => {
  const {
    form: { getFieldDecorator, validateFields },
    handleLogin
  } = props

  const handleSubmit = e => {
    e.preventDefault()
    validateFields((err, values) => {
      if (!err) handleLogin(values)
    })
  }

  return (
    <Form onSubmit={handleSubmit} className={styles.loginForm}>
      <Form.Item>
        {getFieldDecorator('login', { rules: [{ required: true, message: ' ' }] })(
          <Input placeholder="Логин" className={styles.loginInput} />
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator('password', { rules: [{ required: true, message: ' ' }] })(
          <Input type="password" placeholder="Пароль" className={styles.loginInput} />
        )}
      </Form.Item>
      <Form.Item style={{ marginBottom: 0 }}>
        <Button size="large" type="primary" htmlType="submit" className={styles.loginFormButton}>
          Войти
        </Button>
      </Form.Item>
      <Form.Item style={{ marginBottom: 0 }}>
        <Button
          size="large"
          type="primary"
          onClick={() => {
            localStorage.clear()
            window.location = `${process.env.API_ROOT}/connect/life`
          }}
          className={styles.loginFormButton}
        >
          Войти через SSO
        </Button>
      </Form.Item>
    </Form>
  )
}

LoginForm.propTypes = {
  handleLogin: PropTypes.func,
  form: PropTypes.shape({ getFieldDecorator: PropTypes.func, validateFields: PropTypes.func })
}

export default Form.create({ name: 'login_form' })(LoginForm)
