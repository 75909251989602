import moment from 'moment'

const TIME_FORMAT = 'HH:mm'
const DATE_FORMAT = 'DD MMMM YYYY'

export default (date, format = DATE_FORMAT) => {
  const today = moment().startOf('day')
  const yesterday = moment()
    .subtract(1, 'days')
    .startOf('day')

  if (!date) return '-'

  if (moment(date).isSame(today, 'd')) {
    return `сегодня в ${moment(date).format(TIME_FORMAT)}`
  }

  if (moment(date).isSame(yesterday, 'd')) {
    return `вчера в ${moment(date).format(TIME_FORMAT)}`
  }

  return moment(date).format(`${format} в ${TIME_FORMAT}`)
}
