import { object, string } from 'yup'
import { EMPTY_STRING } from '~constants/index'
import { transformEmptyString } from '~schemas/transformations/transfomEmptyString'
import { castBySchema } from '~utils/castBySchema'

/** @type object */
export const titleBlockSchema = object()
  .default(undefined)
  .shape({
    title: string()
      .default(EMPTY_STRING)
      .required()
      .min(1),
    subtitle: string()
      .defined()
      .transform(transformEmptyString)
  })

export const castByTitleBlockSchema = castBySchema(titleBlockSchema, { stripUnknown: true })
