import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

import moment from 'moment'
import { Icon } from 'antd'
import classNames from 'classnames'

import styles from './styles.scss'

@inject(stores => {
  const { setCalendarType, calendarType, setSelectedDate } = stores.ScheduleStore

  return {
    calendarType,
    setCalendarType,
    setSelectedDate
  }
})
@observer
class ToolBar extends Component {
  getTitle = () => {
    const { calendarType, date } = this.props

    switch (calendarType) {
      case 'day': {
        return {
          title: moment(date).format('dddd'),
          subTitle: moment(date).format('YYYY')
        }
      }
      case 'week': {
        return {
          title: moment(date).format('YYYY'),
          subTitle: moment(date).format('MMMM, w неделя ')
        }
      }
      case 'month': {
        return {
          title: moment(date).format('YYYY'),
          subTitle: moment(date).format('MMMM')
        }
      }
      default:
        return {
          title: null,
          subTitle: moment(date).format('YYYY')
        }
    }
  }

  handleClickPrev = () => {
    const { calendarType, setSelectedDate, date } = this.props

    const prevDate = new Date(
      moment(date)
        .subtract(1, calendarType)
        .format()
    )

    setSelectedDate(prevDate)
  }

  handleClickNext = () => {
    const { calendarType, setSelectedDate, date } = this.props

    const prevDate = new Date(
      moment(date)
        .add(1, calendarType)
        .format()
    )

    setSelectedDate(prevDate)
  }

  render() {
    const dateTitle = this.getTitle()

    return (
      <div className={styles.root}>
        <div onClick={this.handleClickPrev} className={classNames(styles.arrow)}>
          <Icon type="left" />
        </div>

        <div className={styles.date}>
          {dateTitle.title && <div className={styles.title}>{dateTitle.title}</div>}
          <div className={styles.subTitle}>{dateTitle.subTitle}</div>
        </div>

        <div onClick={this.handleClickNext} className={classNames(styles.arrow)}>
          <Icon type="right" />
        </div>
      </div>
    )
  }
}

export default ToolBar

ToolBar.propTypes = {
  calendarType: PropTypes.string,
  date: PropTypes.any,
  setSelectedDate: PropTypes.func
}
