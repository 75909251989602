import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'

import WhiteBlockWithShadow from '~components/WhiteBlockWithShadow'
import { useBadWordsStore } from '~hooks/useBadWordsStore'

import styles from './styles.scss'

import Pencil from '~assets/icons/pencil.svg'

export const BadWordItem = observer(props => {
  const { badWord } = props
  const { name, index } = badWord
  const { setEditableBadWord } = useBadWordsStore()

  const handleClickItem = () => {
    setEditableBadWord(badWord)
  }

  return (
    <WhiteBlockWithShadow>
      <div className={styles.root} onClick={handleClickItem}>
        <div className={styles.ico}>
          <Pencil fill="#D3D3D3" />
        </div>
        <div className={styles.title}>{name}</div>
        <div className={styles.id}>ID {index}</div>
      </div>
    </WhiteBlockWithShadow>
  )
})

BadWordItem.propTypes = {
  setEditableBadWord: PropTypes.func,
  badWord: PropTypes.object
}
