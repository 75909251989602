import { observable, action, runInAction, computed } from 'mobx'

import API from '~api'
import ConfigureFetchUrlByListsStore from './configureFetchUrlByLists'
import FetchPaginationStore from './fetchPaginationStore'

const BAD_WORDS_URL = '/bad/words'
const BAD_WORD_URL = '/bad/word'

export class BadWordsStore {
  @observable isShowModal = false

  @observable isFirstLoading = true

  @observable isActionModal = false

  @observable editableBadWord = null

  @observable allItemsCount = 0

  @observable isLoadingMore = false

  @observable badWords = []

  @observable badWordsSearches = []

  @action
  fetchBadWordsBySearch = term => {
    let url = BAD_WORDS_URL

    if (term) {
      url = `${url}?search=${term}`
    }

    return API.get(url).then(res => {
      const { data } = res.data

      runInAction(() => {
        this.badWordsSearches = data
      })

      return data
    })
  }

  @action
  fetchBadWords = () => {
    const { offset } = FetchPaginationStore
    const { getPromiseUrl } = ConfigureFetchUrlByListsStore

    const promiseUrl = getPromiseUrl({
      url: BAD_WORDS_URL
    })

    const fetchBadWordsPromise = API.get(promiseUrl)

    this.setIsLoadingMore(true)
    return fetchBadWordsPromise
      .then(res => {
        const { data, meta } = res.data

        if (offset === 0) {
          this.setBadWords(data)
        } else {
          this.setBadWords([...this.badWords, ...data])
        }

        this.setAllItemsCount(meta.count)
        this.setIsFirstLoading(false)
        this.setIsLoadingMore(false)
      })
      .catch(() => {
        this.setIsFirstLoading(false)
        this.setIsLoadingMore(false)
      })
  }

  @action
  updateBadWord = badWord => {
    runInAction(() => {
      this.isActionModal = true
    })

    const { _id } = this.editableBadWord

    const updateBadWordPromise = API.patch(`${BAD_WORD_URL}/${_id}`, badWord)

    return updateBadWordPromise
      .then(res => {
        const { data } = res.data

        const editableBadWordIndex = this.badWords.indexOf(this.editableBadWord)

        this.setHideModal()
        runInAction(() => {
          this.isActionModal = false
          this.badWords[editableBadWordIndex] = data
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @action
  createBadWord = badWord => {
    runInAction(() => {
      this.isActionModal = true
    })

    const createBadWordPromise = API.post(BAD_WORD_URL, badWord)

    return createBadWordPromise
      .then(res => {
        const { data } = res.data
        this.setHideModal()

        this.setAllItemsCount(this.allItemsCount + 1)

        runInAction(() => {
          this.isActionModal = false
          this.badWords.unshift(data)
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @action
  deleteBadWord = () => {
    runInAction(() => {
      this.isActionModal = true
    })

    const { _id } = this.editableBadWord

    const deleteAuthorPromise = API.delete(`${BAD_WORD_URL}/${_id}`)

    return deleteAuthorPromise
      .then(() => {
        this.badWords.remove(this.editableBadWord)

        this.resetEditableBadWord()
        this.setHideModal()
        this.setAllItemsCount(this.allItemsCount - 1)

        runInAction(() => {
          this.isActionModal = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @computed
  get isEmptyBadWords() {
    return !this.badWords.length
  }

  @action
  setEditableBadWord = value => {
    this.editableBadWord = value

    this.setShowModal()
  }

  @action
  resetEditableBadWord = () => {
    this.editableBadWord = null
  }

  @action
  setShowModal = () => {
    this.isShowModal = true
  }

  @action
  setHideModal = () => {
    this.isShowModal = false
  }

  @action
  setIsFirstLoading = value => {
    this.isFirstLoading = value
  }

  @action
  setIsLoadingMore = value => {
    this.isLoadingMore = value
  }

  @action
  setBadWords = data => {
    this.badWords = data
  }

  @action
  setAllItemsCount = count => {
    this.allItemsCount = count
  }

  @computed
  get badWordsLength() {
    return this.badWords.length
  }
}

export default new BadWordsStore()
