import { observable, action, computed, runInAction, set, reaction, toJS } from 'mobx'

import moment from 'moment'
import { get } from 'lodash'

import API from '~api'
import ConfigureFetchUrlByListsStore from './configureFetchUrlByLists'
import FetchPaginationStore from './fetchPaginationStore'
import FilterParamsByListStore from './filterParamsByList'
import getIsGridValue from '~utils/getIsGridValue'

const EVENTS_URL = '/events'
const EVENT_TYPE_URL = '/eventTypes'

// const getIsGridValue = () => {
//   if (!localStorage.getItem('isGridViewEvents')) return false
//
//   return localStorage.getItem('isGridViewEvents') === 'yes'
// }

class ScheduleStore {
  @observable calendarType = 'day'

  @observable selectedDate = new Date()

  @observable selectedDateEnd = new Date()

  @observable selectedEventsDate = ''

  @observable isFirstLoading = true

  @observable selectedEvents = []

  @observable isGrid = getIsGridValue('isGridViewEvents')

  @observable isShowFilter = this.getIsShowFilterFromUrlParams()

  @observable allItemsCount = 0

  @observable isLoadingMore = false

  @observable events = []

  @observable editableEvent = null

  @observable isShowModalEdit = false

  @observable isShowModalInfo = false

  @observable isDuplicateEvent = false

  @observable eventTypesSearches = []

  @observable isCalendarViewLoading = false

  @observable selectedFilterItems = []

  @observable isActionModal = false

  @observable filtersItem = [
    {
      label: 'Тип события',
      type: 'select',
      name: 'filter.eventType',
      fix: true
    },
    {
      label: 'Место',
      type: 'string',
      name: 'filter.address',
      fix: true
    }
  ]

  constructor() {
    this.setDefaultSelectedDate()

    reaction(
      () => this.selectedDate,
      () => {
        const { resetPaginationParams } = FetchPaginationStore

        if (this.isGrid) {
          resetPaginationParams()
          this.setEvents([])
          this.fetchEventsByCalendar()
        }
      }
    )
  }

  @action
  setDefaultSelectedDate = () => {
    const { filterParams } = FilterParamsByListStore

    const dateByUrl = get(filterParams, 'filter.start', '').split(',')

    const start = get(dateByUrl, '0', null)
    const end = get(dateByUrl, '1', null)

    const startDate = moment(start || new Date())
      .startOf('day')
      .utc()
      .format()
    const endDate = moment(end || new Date())
      .startOf('day')
      .utc()
      .format()

    if (!end) {
      this.isGrid = true
      localStorage.setItem('isGridViewEvents', 'yes')
    }

    this.setSelectedDate(new Date(startDate))
    this.setSelectedDateEnd(new Date(endDate))
  }

  @action
  getIsShowFilterFromUrlParams = () => {
    const { filterParams } = FilterParamsByListStore

    const filter = Object.keys(filterParams).filter(item => item !== 'search')

    return !!filter.length
  }

  @action
  fetchEventsTypesBySearch = term => {
    let url = EVENT_TYPE_URL

    if (term) {
      url = `${url}?search=${term}`
    }

    return API.get(url).then(res => {
      const { data } = res.data

      runInAction(() => {
        this.eventTypesSearches = data
      })

      return data
    })
  }

  @action
  fetchEventsByList = () => {
    const { offset } = FetchPaginationStore
    const { getPromiseUrl, FILTER_PARAMS_FOR_BACK } = ConfigureFetchUrlByListsStore
    const { setFilterValue } = FilterParamsByListStore

    if (this.selectedDateEnd) {
      const startDate = moment(this.selectedDate)
        .startOf('day')
        .utc()
        .format()

      const endDate = moment(this.selectedDateEnd)
        .endOf('day')
        .utc()
        .format()

      setFilterValue('filter.start', endDate)
      setFilterValue('filter.end', startDate)

      set(FILTER_PARAMS_FOR_BACK, {
        'filter.start': params => {
          return `lte(${params})`
        },
        'filter.end': params => {
          return `gte(${params})`
        }
      })
    }

    const promiseUrl = getPromiseUrl({
      url: EVENTS_URL
    })

    const fetchEventsPromise = API.get(promiseUrl)

    this.setIsLoadingMore(true)
    return fetchEventsPromise
      .then(res => {
        const { data, meta } = res.data

        if (offset === 0) {
          this.setEvents(data)
        } else {
          this.setEvents([...this.events, ...data])
        }

        this.setAllItemsCount(meta.count)
        this.setIsFirstLoading(false)
        this.setIsLoadingMore(false)
      })
      .catch(() => {
        this.setIsFirstLoading(false)
        this.setIsLoadingMore(false)
      })
  }

  @action
  fetchEventsByCalendar = () => {
    const { offset } = FetchPaginationStore
    const { getPromiseUrl, FILTER_PARAMS_FOR_BACK } = ConfigureFetchUrlByListsStore
    const { setFilterValue } = FilterParamsByListStore

    const type = this.calendarType === 'week' ? 'month' : this.calendarType

    const startDate = moment(this.selectedDate)
      .startOf(type)
      .utc()
      .format()
    const endDate = moment(this.selectedDate)
      .endOf(type)
      .utc()
      .format()

    setFilterValue('filter.start', endDate)
    setFilterValue('filter.end', startDate)

    set(FILTER_PARAMS_FOR_BACK, {
      'filter.start': params => {
        return `lte(${params})`
      },
      'filter.end': params => {
        return `gte(${params})`
      },
      'filter.types': params => {
        return `in([${params.map(param => `"${param}"`)}])`
      }
    })

    const promiseUrl = getPromiseUrl({
      url: EVENTS_URL
    })

    const fetchEventsPromise = API.get(promiseUrl)

    return fetchEventsPromise
      .then(res => {
        const { data, meta } = res.data

        if (offset === 0) {
          this.setEvents(data)
        } else {
          this.setEvents([...this.events, ...data])
        }

        this.setAllItemsCount(meta.count)
      })
      .catch(() => {})
  }

  @action
  updateEvent = event => {
    this.isActionModal = true

    const { _id } = this.editableEvent

    const updateEventPromise = API.patch(`${EVENTS_URL}/${_id}`, event)

    return updateEventPromise
      .then(res => {
        const { data } = res.data

        const editableEventIndex = this.events.indexOf(this.editableEvent)

        this.setHideModalEdit()

        runInAction(() => {
          this.isActionModal = false
          this.events[editableEventIndex] = data

          if (this.isGrid) {
            this.events = [...this.events]
          }
        })
      })
      .catch(() => {
        this.isActionModal = false
      })
  }

  @action
  createEvent = event => {
    runInAction(() => {
      this.isActionModal = true
    })

    const createEventPromise = API.post(EVENTS_URL, event)

    return createEventPromise
      .then(res => {
        const { data } = res.data
        const currentStartDate = moment(this.selectedDate).format('YYYY.MM.DD')
        const newStartDate = moment(data.start).format('YYYY.MM.DD')
        const isRedirect = currentStartDate === newStartDate

        if (isRedirect) {
          this.events.unshift(data)
          this.events = [...this.events]
        }

        runInAction(() => {
          this.isActionModal = false
        })

        this.setHideModalEdit()
        this.setAllItemsCount(this.allItemsCount + 1)
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @action
  deleteEvent = () => {
    runInAction(() => {
      this.isActionModal = true
    })

    const { _id } = this.editableEvent

    const deleteEventPromise = API.delete(`${EVENTS_URL}/${_id}`)

    return deleteEventPromise
      .then(() => {
        this.events.remove(this.editableEvent)

        if (this.isGrid) {
          this.events = [...this.events]
        }

        this.resetEditableEvent()
        this.setHideModalEdit()
        this.setAllItemsCount(this.allItemsCount - 1)

        runInAction(() => {
          this.isActionModal = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @action
  setDuplicateEvent = event => {
    const newEvent = toJS(event)

    this.isDuplicateEvent = true

    newEvent.start = null
    newEvent.end = null
    newEvent.checkOutDate = null

    this.setEditableEvent(newEvent)

    this.setShowModalEdit()
  }

  @action
  setCalendarType = type => {
    this.calendarType = type
  }

  @action
  setSelectedDate = date => {
    this.selectedDate = date
  }

  @action
  setSelectedDateEnd = date => {
    this.selectedDateEnd = date
  }

  @action
  setSelectedEvents = (events, date) => {
    this.selectedEvents = events
    this.selectedEventsDate = date || ''
  }

  @action
  onToggleView = () => {
    const { setFilterValue } = FilterParamsByListStore

    this.isGrid = !this.isGrid
    localStorage.setItem('isGridViewEvents', this.isGrid ? 'yes' : 'no')

    if (this.isGrid) {
      setFilterValue('filter.start', new Date())
      this.setSelectedDate(new Date())
    } else {
      setFilterValue('filter.start')
      setFilterValue('filter.end')
      this.setSelectedDate(new Date())
      this.setSelectedDateEnd(new Date())
    }
  }

  @action
  setAllItemsCount = count => {
    this.allItemsCount = count
  }

  @action
  setIsLoadingMore = value => {
    this.isLoadingMore = value
  }

  @action
  setIsFirstLoading = value => {
    this.isFirstLoading = value
  }

  @action
  setEvents = data => {
    this.events = data
  }

  @action
  setEditableEvent = value => {
    this.editableEvent = value
  }

  @action
  resetEditableEvent = () => {
    this.editableEvent = null
    this.isDuplicateEvent = false
  }

  @action
  setShowModalEdit = () => {
    this.isShowModalEdit = true
  }

  @action
  setShowModalInfo = () => {
    this.isShowModalInfo = true
  }

  @action
  setHideModalInfo = () => {
    this.isShowModalInfo = false
    this.isDuplicateEvent = false
  }

  @action
  setHideModalEdit = () => {
    this.isShowModalEdit = false
  }

  @action
  toggleIsShowFilter = () => {
    this.isShowFilter = !this.isShowFilter
  }

  @action
  setFilterItemActive = item => {
    this.selectedFilterItems.push(item)
  }

  @action
  removeFileItem = item => {
    this.selectedFilterItems.remove(item)
  }

  @computed
  get isShowModal() {
    return !!this.selectedEvents.length
  }

  @computed
  get eventsLength() {
    return this.events.length
  }

  @computed
  get isEmptyEvents() {
    return !this.events.length
  }
}

export default new ScheduleStore()
