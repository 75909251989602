import { observable, action } from 'mobx'

export class FilterParamsByListStore {
  @observable filterParams = {}

  @action
  setFilterParams = () => {
    Object.keys(this.filterParams).forEach(key => {
      delete this.filterParams[key]
    })
  }

  @action
  setFilterValue = (fieldName, value) => {
    if (value) {
      this.filterParams[fieldName] = value
    } else {
      delete this.filterParams[fieldName]
    }
  }
}

export default new FilterParamsByListStore()
