import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

// eslint-disable-next-line import/no-cycle
import CommentItem from './index'

import styles from './styles.scss'

@inject(stores => {
  const { deleteReplies, blockUser } = stores.CommentsStore

  return {
    deleteReplies,
    blockUser
  }
})
@observer
class RepliesList extends Component {
  handleDeleteComment = comment => {
    const { deleteReplies } = this.props

    return deleteReplies(comment)
  }

  handleBlockUser = comment => {
    const { blockUser } = this.props

    return blockUser(comment)
  }

  render() {
    const { data } = this.props

    return (
      <div className={styles.repliesWrapper}>
        {data.map(item => (
          <CommentItem
            onDelete={this.handleDeleteComment}
            onBlockUser={this.handleBlockUser}
            key={item._id}
            comment={item}
          />
        ))}
      </div>
    )
  }
}

export default RepliesList

RepliesList.propTypes = {
  blockUser: PropTypes.func,
  data: PropTypes.any,
  deleteReplies: PropTypes.any
}
