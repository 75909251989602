import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

import { noop, get, debounce } from 'lodash'

import { Select } from 'antd'

const { Option } = Select

@inject(stores => {
  const { fetchTagsBySearch, tagsSearches } = stores.TagsStore
  const {
    fetchAuthorsBySearch,
    authorsSearches,
    fetchCategoriesBySearch,
    categoriesSearches,
    sectionsSearches,
    fetchSectionsBySearch
  } = stores.AuthorsStore
  const { dictionaries } = stores.PublicationsStore

  const { fetchDepartmentBySearch, departmentsSearches } = stores.DepartmentsStore
  const { fetchEventsTypesBySearch, eventTypesSearches } = stores.ScheduleStore
  const { fetchEmployeesBySearch, employeesSearches, dictionariesEmployees } = stores.EmployeesStore

  return {
    fetchTagsBySearch,
    fetchAuthorsBySearch,
    fetchCategoriesBySearch,
    fetchSectionsBySearch,
    fetchEventsTypesBySearch,
    fetchEmployeesBySearch,
    employeesSearches,
    eventTypesSearches,
    fetchDepartmentBySearch,
    tagsSearches,
    authorsSearches,
    dictionaries,
    categoriesSearches,
    departmentsSearches,
    sectionsSearches,
    dictionariesEmployees
  }
})
@observer
class SelectRender extends Component {
  componentDidMount() {
    const { filter } = this.props
    const { fetch } = this.getFetchDataByType()

    fetch(false, filter)
  }

  handleSearch = debounce(term => {
    const { filter } = this.props
    const { fetch } = this.getFetchDataByType()

    fetch(term, filter)
  }, 300)

  getFetchDataByType = () => {
    const {
      item,
      fetchTagsBySearch,
      tagsSearches,
      dictionaries,
      authorsSearches,
      fetchAuthorsBySearch,
      categoriesSearches,
      fetchCategoriesBySearch,
      fetchDepartmentBySearch,
      departmentsSearches,
      sectionsSearches,
      fetchSectionsBySearch,
      fetchEventsTypesBySearch,
      eventTypesSearches,
      fetchEmployeesBySearch,
      employeesSearches,
      dictionariesEmployees
    } = this.props

    if (item.name === 'filter.tags') {
      return {
        fetch: fetchTagsBySearch,
        options: tagsSearches,
        key: '_id',
        label: 'title'
      }
    }

    if (item.name === 'filter.priority') {
      return {
        fetch: noop,
        options: get(dictionaries, 'postPriorities', []),
        key: 'id',
        label: 'ru'
      }
    }

    if (item.name === 'filter.type') {
      return {
        fetch: noop,
        options: get(dictionaries, 'postTypes', []),
        key: 'id',
        label: 'ru'
      }
    }

    if (item.name === 'filter.position') {
      return {
        fetch: noop,
        options: get(dictionariesEmployees, 'employeePositions', []),
        key: 'id',
        label: 'ru'
      }
    }

    if (item.name === 'filter.status') {
      return {
        fetch: noop,
        options: get(dictionaries, 'postStatuses', []),
        key: 'id',
        label: 'ru'
      }
    }

    if (item.name === 'filter.authors') {
      return {
        fetch: fetchAuthorsBySearch,
        options: authorsSearches,
        key: '_id',
        label: 'name'
      }
    }

    if (item.name === 'filter.categories') {
      return {
        fetch: fetchCategoriesBySearch,
        options: categoriesSearches,
        key: '_id',
        label: 'title'
      }
    }

    if (item.name === 'filter.section') {
      return {
        fetch: fetchSectionsBySearch,
        options: sectionsSearches,
        key: '_id',
        label: 'title'
      }
    }

    if (item.name === 'filter.department') {
      return {
        fetch: fetchDepartmentBySearch,
        options: departmentsSearches,
        key: '_id',
        label: 'name'
      }
    }

    if (item.name === 'filter.eventType') {
      return {
        fetch: fetchEventsTypesBySearch,
        options: eventTypesSearches,
        key: '_id',
        label: 'title'
      }
    }

    if (item.name === 'employees') {
      return {
        fetch: fetchEmployeesBySearch,
        options: employeesSearches,
        key: '_id',
        label: 'name'
      }
    }

    if (item.name === 'filter.employee') {
      return {
        fetch: fetchEmployeesBySearch,
        options: employeesSearches.map(item => ({
          id: item._id,
          name: `${item.firstName} ${item.lastName}`
        })),
        key: 'id',
        label: 'name'
      }
    }

    if (item.name === 'filter.resourceType') {
      return {
        fetch: noop,
        options: [
          { id: 1, name: '1' },
          { id: 2, name: '2' },
          { id: 3, name: '3' }
        ],
        key: 'id',
        label: 'name'
      }
    }

    if (item.name === 'filter.validatedBy.corrector') {
      return {
        fetch: noop,
        options: [
          { id: 1, name: 'Без вычитки' },
          { id: 2, name: 'Вычитано' },
          { id: 3, name: 'Вычитать повторно' }
        ],
        key: 'id',
        label: 'name'
      }
    }

    return {}
  }

  handleSelectChange = value => {
    const { onChange } = this.props
    const { fetch } = this.getFetchDataByType()

    onChange(value)

    if (!value) {
      fetch()
    }
  }

  render() {
    const { onChange, value, optionsChildren, ...props } = this.props

    const { options, key, label } = this.getFetchDataByType()

    return (
      <Select
        style={{
          width: '100%'
        }}
        showSearch
        allowClear
        optionFilterProp="children"
        onChange={this.handleSelectChange}
        value={value}
        placeholder="Выбрать"
        onSearch={this.handleSearch}
        {...props}
      >
        {options.map(option => {
          return (
            <Option key={option[key]}>
              {optionsChildren ? optionsChildren(option) : option[label]}
            </Option>
          )
        })}
      </Select>
    )
  }
}

export default SelectRender

SelectRender.propTypes = {
  authorsSearches: PropTypes.array,
  categoriesSearches: PropTypes.any,
  departmentsSearches: PropTypes.array,
  dictionaries: PropTypes.array,
  dictionariesEmployees: PropTypes.any,
  employeesSearches: PropTypes.any,
  eventTypesSearches: PropTypes.any,
  fetchAuthorsBySearch: PropTypes.func,
  fetchCategoriesBySearch: PropTypes.func,
  fetchDepartmentBySearch: PropTypes.func,
  fetchEmployeesBySearch: PropTypes.any,
  fetchEventsTypesBySearch: PropTypes.any,
  fetchSectionsBySearch: PropTypes.any,
  fetchTagsBySearch: PropTypes.func,
  filter: PropTypes.string,
  item: PropTypes.object,
  onChange: PropTypes.func,
  options: PropTypes.array,
  optionsChildren: PropTypes.any,
  sectionsSearches: PropTypes.any,
  tagsSearches: PropTypes.array,
  value: PropTypes.any
}
