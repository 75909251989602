import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { get } from 'lodash'

import { Input, Button, Form } from 'antd'

import Modal from '~components/Modal'
import ItemWithLabel from '~components/ItemWithLabel'
import DeleteButton from '~components/DeleteButton'
import AvatarUpload from '~components/AvatarUpload'
import SelectRender from '~components/Publications/Filter/SelectRender'

import styles from './styles.scss'

const CREATE_TITLE = 'Создание нового сотрудника'
const UPDATE_TITLE = 'Редактирование сотрудника'

@inject(stores => {
  const {
    isShowModal,
    setHideModal,
    editableEmployee,
    resetEditableEmployee,
    updateEmployee,
    isActionModal,
    createEmployee,
    deleteEmployee
  } = stores.EmployeesStore

  return {
    isShowModal,
    setHideModal,
    editableEmployee,
    resetEditableEmployee,
    updateEmployee,
    isActionModal,
    createEmployee,
    deleteEmployee
  }
})
@observer
class EmployeesModal extends Component {
  handleSubmit = e => {
    e.preventDefault()

    const { form, updateEmployee, editableEmployee, createEmployee } = this.props
    const formData = form.getFieldsValue()

    const employee = {
      data: {
        ...formData,
        department: formData.department._id,
        position: formData.position.id,
        avatar: formData.avatar._id || null
      }
    }

    const action = editableEmployee ? updateEmployee : createEmployee

    action(employee)
  }

  toggleBlockUser = e => {
    e.preventDefault()

    const { updateEmployee, editableEmployee } = this.props

    const employee = {
      data: {
        active: !editableEmployee.active
      }
    }

    updateEmployee(employee)
  }

  normFile = e => {
    const file = get(e, 'file.response.data', null)
    const status = get(e, 'file.status', null)

    return {
      ...file,
      status
    }
  }

  handleClearAvatar = () => {
    const { form } = this.props
    const { setFieldsValue } = form

    setFieldsValue({
      avatar: {
        url: ''
      }
    })
  }

  render() {
    const {
      isShowModal,
      setHideModal,
      editableEmployee,
      resetEditableEmployee,
      form,
      isActionModal
    } = this.props
    const { getFieldDecorator } = form

    const modalTitle = editableEmployee ? UPDATE_TITLE : CREATE_TITLE

    return (
      <Modal
        destroyOnClose
        width={790}
        onClose={setHideModal}
        isVisible={isShowModal}
        title={modalTitle}
        afterClose={resetEditableEmployee}
      >
        <div className={styles.root}>
          <Form onSubmit={this.handleSubmit}>
            <Form.Item>
              {getFieldDecorator('avatar', {
                initialValue: { url: get(editableEmployee, 'avatar.url', '') },
                getValueFromEvent: this.normFile
              })(<AvatarUpload onClearAvatar={this.handleClearAvatar} />)}
            </Form.Item>

            <ItemWithLabel label="Имя сотрудника">
              <Form.Item>
                {getFieldDecorator('firstName', {
                  initialValue: get(editableEmployee, 'firstName', ''),
                  rules: [{ required: true, message: 'Обязательное поле!' }]
                })(<Input disabled={isActionModal} />)}
              </Form.Item>
            </ItemWithLabel>

            <ItemWithLabel label="Фамилия сотрудника">
              <Form.Item>
                {getFieldDecorator('lastName', {
                  initialValue: get(editableEmployee, 'lastName', ''),
                  rules: [{ required: true, message: 'Обязательное поле!' }]
                })(<Input disabled={isActionModal} />)}
              </Form.Item>
            </ItemWithLabel>

            <ItemWithLabel label="E-mail сотрудника">
              <Form.Item>
                {getFieldDecorator('email', {
                  initialValue: get(editableEmployee, 'email', ''),
                  rules: [{ required: true, message: 'Обязательное поле!' }]
                })(<Input disabled={isActionModal} />)}
              </Form.Item>
            </ItemWithLabel>

            <ItemWithLabel label="Выбрать отдел">
              <Form.Item>
                {getFieldDecorator('department._id', {
                  initialValue: get(editableEmployee, 'department._id', ''),
                  rules: [{ required: true, message: 'Обязательное поле!' }]
                })(
                  <SelectRender
                    item={{
                      type: 'select',
                      name: 'filter.department'
                    }}
                  />
                )}
              </Form.Item>
            </ItemWithLabel>

            <ItemWithLabel label="Позиция">
              <Form.Item>
                {getFieldDecorator('position.id', {
                  initialValue: get(editableEmployee, 'position.id', '')
                })(
                  <SelectRender
                    item={{
                      name: 'filter.position'
                    }}
                  />
                )}
              </Form.Item>
            </ItemWithLabel>

            <ItemWithLabel label="Добавить автора">
              <Form.Item>
                {getFieldDecorator('authors', {
                  initialValue: get(editableEmployee, 'authors', []).map(item => item._id),
                  rules: [{ required: true, message: 'Обязательное поле!' }]
                })(
                  <SelectRender
                    mode="multiple"
                    item={{
                      type: 'select',
                      name: 'filter.authors'
                    }}
                  />
                )}
              </Form.Item>
            </ItemWithLabel>

            <div className={styles.modalControls}>
              <Button disabled={isActionModal} htmlType="submit" type="primary">
                Сохранить изменения
              </Button>

              {editableEmployee && (
                <div className={styles.deleteBtnWrapper}>
                  <DeleteButton
                    text={`${
                      !editableEmployee.active ? 'Разблокировать' : 'Заблокировать'
                    } сотрудника`}
                    onDelete={this.toggleBlockUser}
                  />
                </div>
              )}
            </div>
          </Form>
        </div>
      </Modal>
    )
  }
}

const WrappedEmployeesForm = Form.create({ name: 'employeesForm' })(EmployeesModal)

export default WrappedEmployeesForm

EmployeesModal.propTypes = {
  createEmployee: PropTypes.func,
  deleteEmployee: PropTypes.func,
  editableEmployee: PropTypes.any,
  form: PropTypes.any,
  isActionModal: PropTypes.bool,
  isShowModal: PropTypes.bool,
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  resetEditableEmployee: PropTypes.func,
  setHideModal: PropTypes.func,
  updateEmployee: PropTypes.func
}
