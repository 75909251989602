import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

import { Button } from 'antd'

import GridWrapper from '~components/GridWrapper'
import DynamicField from '~components/Publications/Filter/dynamicField'

import styles from '~components/Publications/Filter/styles.scss'

@inject(stores => {
  const {
    filtersItem,
    selectedFilterItems,
    removeFileItem,
    fetchChangeLog,
    setIsFirstLoading
  } = stores.ChangeLogStore

  const { resetPaginationParams } = stores.FetchPaginationStore
  const { setFilterParams } = stores.FilterParamsByListStore
  const { resetOptions } = stores.SortingStore

  return {
    filtersItem,
    selectedFilterItems,
    removeFileItem,
    resetPaginationParams,
    fetchChangeLog,
    setFilterParams,
    setIsFirstLoading,
    resetOptions
  }
})
@observer
class ChangeLogFilter extends Component {
  handleSetFilter = () => {
    const { resetPaginationParams, fetchChangeLog, setIsFirstLoading } = this.props

    setIsFirstLoading(true)
    resetPaginationParams()
    fetchChangeLog()
  }

  handleResetFilter = () => {
    const {
      setFilterParams,
      setIsFirstLoading,
      fetchChangeLog,
      resetPaginationParams,
      resetOptions
    } = this.props

    setFilterParams()
    resetPaginationParams()
    resetOptions()
    setIsFirstLoading(true)
    fetchChangeLog()
  }

  render() {
    const { selectedFilterItems, removeFileItem, filtersItem } = this.props

    return (
      <div className={styles.root}>
        <GridWrapper gridSize={5} maxItemWidth={285}>
          {filtersItem
            .filter(item => !!item.fix)
            .map((item, index) => (
              <DynamicField key={index} item={item} />
            ))}

          {selectedFilterItems
            .filter(item => item.type !== 'bool')
            .map((item, index) => (
              <DynamicField onRemove={removeFileItem} key={index} item={item} />
            ))}
        </GridWrapper>

        <div className={styles.filterControls}>
          <div />

          <div className={styles.serviceControl}>
            <Button type="ghost" onClick={this.handleResetFilter}>
              Отменить фильтрацию
            </Button>
            <Button type="primary" onClick={this.handleSetFilter}>
              Применить
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default ChangeLogFilter

ChangeLogFilter.propTypes = {
  fetchChangeLog: PropTypes.func,
  filtersItem: PropTypes.any,
  removeFileItem: PropTypes.func,
  resetOptions: PropTypes.func,
  resetPaginationParams: PropTypes.func,
  selectedFilterItems: PropTypes.func,
  setFilterParams: PropTypes.bool,
  setIsFirstLoading: PropTypes.func
}
