import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'

import { Input, Button } from 'antd'

import SearchIco from '~assets/icons/search.svg'

import styles from './styles.scss'

@inject(stores => {
  const { FilterParamsByListStore, FetchPaginationStore } = stores

  const { filterParams, setFilterValue } = FilterParamsByListStore
  const { resetPaginationParams } = FetchPaginationStore

  return {
    filterParams,
    setFilterValue,
    resetPaginationParams
  }
})
@observer
class HeaderPageSearchBar extends Component {
  componentWillUnmount() {
    const { setFilterValue, searchField } = this.props

    setFilterValue(searchField, '')
  }

  handleKeyPress = event => {
    const { onSearch, resetPaginationParams } = this.props

    if (event.key === 'Enter') {
      resetPaginationParams()
      onSearch()
    }
  }

  handleChangeSearchTerm = e => {
    const { value } = e.target
    const { setFilterValue, onSearch, resetPaginationParams, searchField } = this.props

    setFilterValue(searchField, value)

    if (value.length <= 0) {
      resetPaginationParams()
      onSearch()
    }
  }

  render() {
    const {
      searchButtonText,
      createButtonText,
      isGrid,
      onToggleView,
      onCreateElement,
      filterParams,
      onSearch,
      gridIconType,
      searchField,
      customButtons
    } = this.props
    const viewIco = isGrid ? 'bars' : gridIconType || 'appstore'

    return (
      <div className={styles.root}>
        <div className={styles.left}>
          <Input
            value={filterParams[searchField]}
            className={styles.input}
            prefix={<SearchIco fill="#9A9A9A" width={24} height={24} />}
            placeholder="Найти"
            onChange={this.handleChangeSearchTerm}
            onKeyPress={this.handleKeyPress}
          />

          <Button onClick={onSearch} className={styles.dskSearchButton} type="primary">
            {searchButtonText}
          </Button>

          <Button
            type="primary"
            className={styles.mobSearchButton}
            icon="search"
            onClick={onToggleView}
          />

          {customButtons && <div className={styles.toogleViewButton}>{customButtons}</div>}

          {onToggleView && (
            <div className={styles.toogleViewButton}>
              <Button
                className={styles.toggleButton}
                shape="circle"
                icon={viewIco}
                onClick={onToggleView}
              />
            </div>
          )}
        </div>

        {onCreateElement && (
          <div className={styles.right}>
            <div>
              {customButtons && (
                <div className={styles.toogleViewButtonMobile}>{customButtons}</div>
              )}
            </div>
            <div>
              <span className={styles.createButtonText}>{createButtonText}</span>
              <span className={styles.createButtonTextMobile}>Создать</span>
              <Button onClick={onCreateElement} type="primary" icon="plus" shape="circle" />
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default HeaderPageSearchBar

HeaderPageSearchBar.propTypes = {
  createButtonText: PropTypes.string,
  filterParams: PropTypes.any,
  isGrid: PropTypes.bool,
  searchField: PropTypes.string,
  onCreateElement: PropTypes.func,
  onSearch: PropTypes.any,
  onToggleView: PropTypes.func,
  customButtons: PropTypes.any,
  resetPaginationParams: PropTypes.func,
  searchButtonText: PropTypes.string,
  searchTerm: PropTypes.string,
  gridIconType: PropTypes.string,
  setFilterValue: PropTypes.func,
  setSearchTerm: PropTypes.func
}

HeaderPageSearchBar.defaultProps = {
  createButtonText: 'Создать',
  searchButtonText: 'Найти',
  searchField: 'search'
}
