import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { Input, Button, Form } from 'antd'

import ItemWithLabel from '~components/ItemWithLabel'
import Modal from '~components/Modal'
import s from './styles.scss'

@inject('SymbolsStore')
@observer
class SymbolsModal extends Component {
  handleSubmit = e => {
    e.preventDefault()

    const {
      SymbolsStore: { updateSymbol, editableSymbol },
      form
    } = this.props

    const formData = form.getFieldsValue()
    const value = { data: { ...formData } }

    updateSymbol({
      [editableSymbol.title]: Number(value.data.count)
    })
  }

  render() {
    const {
      SymbolsStore: { setHideModal, isShowModal, resetEditableSymbol, editableSymbol },
      form
    } = this.props
    const { getFieldDecorator } = form

    return (
      <Modal
        destroyOnClose
        width={590}
        onClose={setHideModal}
        isVisible={isShowModal}
        title="Редактирование количества символов"
        afterClose={resetEditableSymbol}
      >
        <Form onSubmit={this.handleSubmit}>
          <ItemWithLabel label="Введите количество символов">
            <Form.Item>
              {getFieldDecorator('count', {
                initialValue: editableSymbol && editableSymbol.value,
                rules: [{ required: true, message: 'Обязательное поле!' }]
              })(<Input type="number" placeholder="Количество символов" />)}
            </Form.Item>
          </ItemWithLabel>

          <div className={s.modalControls}>
            <Button htmlType="submit" type="primary">
              Сохранить изменения
            </Button>
          </div>
        </Form>
      </Modal>
    )
  }
}

SymbolsModal.propTypes = {
  SymbolsStore: PropTypes.any,
  form: PropTypes.any
}

export default Form.create({ name: 'symbolsForm' })(SymbolsModal)
