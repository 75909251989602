import PropTypes from 'prop-types'
import React, { Component } from 'react'

import classNames from 'classnames'

import styles from './styles.scss'

class TabsItem extends Component {
  handleClick = () => {
    const { data, onClick } = this.props

    onClick(data)
  }

  render() {
    const { data, isActive } = this.props

    return (
      <div
        onClick={this.handleClick}
        className={classNames(styles.tabItem, isActive && styles.active)}
      >
        {data.label}
      </div>
    )
  }
}

export default TabsItem

TabsItem.propTypes = {
  data: PropTypes.object,
  isActive: PropTypes.bool,
  onClick: PropTypes.func
}
