// eslint-disable-next-line import/no-cycle
// import PromiseRender from './PromiseRender';

const checkPermissions = (authority, currentAuthority, target, Exception) => {
  // Retirement authority, return target;
  if (!authority) {
    return target
  }
  // array
  if (Array.isArray(authority)) {
    if (Array.isArray(currentAuthority)) {
      if (currentAuthority.some(item => authority.includes(item))) {
        return target
      }
    } else if (authority.includes(currentAuthority)) {
      return target
    }
    return Exception
  }
  // string
  if (typeof authority === 'string') {
    if (Array.isArray(currentAuthority)) {
      if (currentAuthority.some(item => authority === item)) {
        return target
      }
    } else if (authority === currentAuthority) {
      return target
    }
    return Exception
  }
  // Promise
  if (authority instanceof Promise) {
    return null
    // return <PromiseRender ok={target} error={Exception} promise={authority} />;
  }
  // Function
  if (typeof authority === 'function') {
    // eslint-disable-next-line no-useless-catch
    try {
      const bool = authority(currentAuthority)
      // Promise
      if (bool instanceof Promise) {
        return null
        // return <PromiseRender ok={target} error={Exception} promise={bool} />;
      }
      if (bool) {
        return target
      }
      return Exception
    } catch (error) {
      throw error
    }
  }
  throw new Error('unsupported parameters')
}

export default checkPermissions
