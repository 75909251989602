import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

import TagItem from '~components/Tags/TagItem'
import GridWrapper from '~components/GridWrapper'
import ContentSpinner from '~components/ContentSpinner'
import EmptyContent from '~components/EmptyContent'

@inject(stores => {
  const { tags, isFirstLoading, isEmptyTags } = stores.TagsStore

  return {
    tags,
    isFirstLoading,
    isEmptyTags
  }
})
@observer
class TagList extends Component {
  render() {
    const { tags, isFirstLoading, isEmptyTags } = this.props

    if (isFirstLoading) {
      return <ContentSpinner />
    }

    if (isEmptyTags) return <EmptyContent text="Нет тегов" />

    return (
      <GridWrapper gridSize={5} maxItemWidth={285}>
        {tags.map(tag => (
          <TagItem key={tag._id} tag={tag} />
        ))}
      </GridWrapper>
    )
  }
}

export default TagList

TagList.propTypes = {
  isFirstLoading: PropTypes.bool,
  isEmptyTags: PropTypes.bool,
  tags: PropTypes.array
}
