import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Avatar } from 'antd'

import ChangeSection from '../ChangeSection'
import getTimeByString from '~utils/getTimeByString'

import styles from './styles.scss'

import Globus from '~assets/icons/globus.svg'

class ChangeLogItem extends Component {
  getType = () => {
    const { changeType } = this.props.data

    switch (changeType) {
      case 'HISTORY_CHANGE_TYPE_CREATE':
        return 'создана'

      case 'HISTORY_CHANGE_TYPE_UPDATE':
        return 'обновлена'

      default:
        return 'удалена'
    }
  }

  render() {
    const { data } = this.props
    const { index, userAgent, ip, uri, changes, changeType, updatedAt, employee } = data
    const { avatar, firstName, lastName, email } = employee || { firstName: 'Робот cron' }

    const typeChange = this.getType()
    const updateDate = getTimeByString(updatedAt)

    return (
      <div className={styles.root}>
        <div className={styles.header}>
          <div className={styles.person}>
            <Avatar size={48} src={avatar}>
              {firstName[0]}
            </Avatar>
            <div className={styles.personInfo}>
              <div className={styles.name}>
                {firstName} {lastName}
              </div>

              <div className={styles.email}>{email}</div>
            </div>
          </div>

          <div className={styles.serviceInfo}>
            <div className={styles.ip}>{ip}</div>
            <div className={styles.path}>{uri}</div>
          </div>

          <div className={styles.userAgent}>
            <Globus fill="#9A9A9A" />

            <span className={styles.userAgentInfo}>{userAgent}</span>
          </div>
        </div>

        <div className={styles.body}>
          <div className={styles.titles}>
            <div className={styles.state}>
              <div className={styles.sateTitle}>Состояние контента #{index}</div>

              <div className={styles.sateAction}>Действие над моделью: {typeChange}</div>
            </div>

            <div className={styles.updateDate}>изменено {updateDate}</div>
          </div>

          {changes.map((item, idx) => (
            <ChangeSection key={idx} index={index} type={changeType} data={item} />
          ))}
        </div>
      </div>
    )
  }
}

export default ChangeLogItem

ChangeLogItem.propTypes = {
  data: PropTypes.object
}
