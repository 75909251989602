import { boolean, object, string } from 'yup'
import { EMPTY_STRING } from '~constants'
import { employeeSchema } from '~schemas/employee'
import { statusSchema } from '~schemas/status'
import { strippedSchemaWhenNull } from '~schemas/transformations/stripSchemaWhenNull'
import { transformBoolean } from '~schemas/transformations/transformBoolean'
import { castBySchema } from '~utils/castBySchema'

/** @type object */
export const headBlockSchema = object()
  .default(undefined)
  .shape({
    createdAt: string()
      .defined()
      .default(null),
    end: strippedSchemaWhenNull(string().default(EMPTY_STRING)),
    note: string().default(EMPTY_STRING),
    /*
    publicationDate: string()
      .defined()
      .default(null),
    */
    start: strippedSchemaWhenNull(string().default(EMPTY_STRING)),
    status: statusSchema,
    updatedAt: string()
      .defined()
      .default(null),
    updatedBy: employeeSchema.defined().default(null),
    validatedAt: object()
      .default(undefined)
      .shape({
        buildEditor: string()
          .defined()
          .default(null),
        chiefEditor: string()
          .defined()
          .default(null),
        corrector: string()
          .defined()
          .default(null)
      }),
    validatedBy: object()
      .default(undefined)
      .shape({
        buildEditor: boolean()
          .transform(transformBoolean)
          .default(false),
        chiefEditor: boolean()
          .transform(transformBoolean)
          .default(false),
        corrector: boolean()
          .transform(transformBoolean)
          .default(false)
      })
  })

export const castByHeadBlockSchema = castBySchema(headBlockSchema, { stripUnknown: true })
