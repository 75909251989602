import { message } from 'antd'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'

import CheckIcon from '~assets/icons/check.svg'
import { useUserStore } from '~hooks/useUserStore'
import { isEditor } from '~utils/roles'
import styles from './styles.scss'

const getColor = (isValidated = false, validatedAt = '') =>
  // eslint-disable-next-line no-nested-ternary
  isValidated ? '#34C759' : validatedAt ? '#ff9500' : '#9D9D9D'

const ValidItem = ({ isMobile, label, isValid, name, color = '', onClick = () => {} }) => (
  <div
    className={classNames(styles.item, isMobile && styles.isMobile)}
    style={{ color }}
    onClick={() => {
      onClick(name)
    }}
  >
    {isValid && !isMobile && (
      <span className={styles.icon}>
        <CheckIcon fill={color} />
      </span>
    )}
    {label}
  </div>
)

const Validated = observer(props => {
  const { user } = useUserStore()

  const {
    buildEditor,
    chiefEditor,
    corrector,
    validatedAt = {
      chiefEditor: '',
      corrector: '',
      buildEditor: ''
    },
    onClick = () => {},
    isMobile
  } = props

  const toggle = name => {
    onClick({ [name]: !props[name] })
  }

  const toggleEditor = name => {
    if (isEditor(user?.role)) {
      onClick({ [name]: !props[name] })
    } else {
      message.error('Изменение доступно только главным редакторам', 5)
    }
  }

  return (
    <div className={styles.root}>
      <ValidItem
        isValid={chiefEditor}
        color={getColor(chiefEditor, validatedAt.chiefEditor)}
        onClick={toggleEditor}
        name="chiefEditor"
        label="Редактор"
        isMobile={isMobile}
      />
      <ValidItem
        isValid={corrector}
        color={getColor(corrector, validatedAt.corrector)}
        onClick={toggle}
        name="corrector"
        label="Корректор"
        isMobile={isMobile}
      />
      <ValidItem
        isValid={buildEditor}
        color={getColor(buildEditor, validatedAt.buildEditor)}
        onClick={toggle}
        name="buildEditor"
        label="Бильд"
        isMobile={isMobile}
      />
    </div>
  )
})

export default Validated

Validated.propTypes = {
  onClick: PropTypes.func,
  validatedAt: PropTypes.object,
  buildEditor: PropTypes.bool,
  chiefEditor: PropTypes.bool,
  corrector: PropTypes.bool
}

Validated.propTypes = {
  isMobile: PropTypes.bool
}

ValidItem.propTypes = {
  isMobile: PropTypes.bool,
  onClick: PropTypes.func,
  name: PropTypes.string,
  isValid: PropTypes.bool,
  label: PropTypes.string,
  color: PropTypes.string
}
