import React from 'react'
import propTypes from 'prop-types'
import { message, Upload } from 'antd'

import Button from '~components/Button'

const ButtonUpload = ({ onChange, uploadProps = {} }) => {
  const fileUploadProps = {
    name: 'file',
    action: `${process.env.API_ROOT}/files`,
    withCredentials: true,
    multiple: false,
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} успешно загружен!`)
        onChange(info.file.response.data)
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} не загружен!`)
      }
    }
  }

  return (
    <Upload {...fileUploadProps} {...uploadProps}>
      <Button>Кликните чтобы загрузить файл</Button>
    </Upload>
  )
}

ButtonUpload.propTypes = {
  media: propTypes.object,
  uploadProps: propTypes.object,
  onChange: propTypes.func
}

export default ButtonUpload
