import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { Button } from 'antd'

import { withErrorBoundary } from '~components/ErrorBoundary'
import Modal from '~components/Modal'
import PrioritySections from './Sections'
import PriorityCategory from './Category'
import PriorityTags from './Tags'

import styles from './styles.scss'

const MODAL_TITLE = 'Управление приоритетатми'

@inject('PublicationStore')
@observer
class PriorityModal extends Component {
  // componentDidUpdate(prevProps) {
  //   const { isOpenModalPriority } = this.props.PublicationStore
  //
  //   if(isOpenModalPriority)  {
  //     this.props.PublicationStore.draftPriorities.init()
  //   }
  // }

  render() {
    const {
      PublicationStore: { isOpenModalPriority, hideModalPriority, priorities }
    } = this.props
    const { savePriority, resetPriority } = priorities

    return (
      <Modal
        destroyOnClose
        width={'90%'}
        onClose={hideModalPriority}
        isVisible={isOpenModalPriority}
        title={MODAL_TITLE}
      >
        <div className={styles.root}>
          <PrioritySections label="Раздел" />
          <PriorityCategory label="Категории" />
          <PriorityTags label="Теги" />
        </div>

        <div className={styles.controls}>
          <Button onClick={resetPriority}>Сбросить</Button>

          <Button
            type="primary"
            style={{ borderColor: '#ff0a0a', backgroundColor: '#ff0a0a' }}
            onClick={savePriority}
          >
            Сохранить изменения и закрыть
          </Button>
        </div>
      </Modal>
    )
  }
}

PriorityModal.propTypes = {
  PublicationStore: PropTypes.any
}

export default withErrorBoundary(PriorityModal)
