import { observable, computed, action, toJS, runInAction } from 'mobx'

import Block from './BlockModel'

export default class BlockGroup {
  id = null

  store = null

  @observable blocks = observable.array()

  @observable createdAt = ''

  @observable createdBy = {}

  @observable author = {}

  @observable post = ''

  @observable updatedAt = ''

  constructor(store, id, data) {
    this.store = store
    this.id = id

    this.createdAt = data.createdAt
    this.createdBy = data.createdBy
    this.author = data.author
    this.post = data.post
    this.updatedAt = data.updatedAt

    if (data.blocks.length > 0) {
      data.blocks.forEach(block => {
        this.setBlocksFromServer(block)
      })
    }

    // this.store.socket.subscribe('BLOCK_GROUP_UPDATED', ({ _id, updatedAt, ...rest }) => {
    //   if (_id === this.id) {
    //     try {
    //       if (rest.blocks.length > 0) {
    //         rest.blocks.forEach(block => {
    //           this.setBlocksFromServer(block)
    //         })
    //       }
    //       // this.setContent(JSON.parse(content))
    //
    //       this.updatedAt = updatedAt
    //     } catch (e) {
    //       console.error(e)
    //     }
    //   }
    // })

    // this.store.socket.subscribe('BLOCK_REMOVED', ({ blockId }) => {
    //   if (blockId === this.id) {
    //     this.remove()
    //   }
    // })
  }

  findBlockById(blockId) {
    return this.blocks.find(block => block.id === blockId)
  }

  setBlocksFromServer(json) {
    let block = this.findBlockById(json._id)
    if (!block) {
      block = new Block(this.store, json._id, { ...json })
      this.blocks.push(block)
    }
  }

  @computed get blocksArray() {
    return toJS(this.blocks)
  }

  @computed get blocksOrder() {
    return toJS(this.blocks).map(({ id }) => id)
  }

  @action
  createBlock = ({ before, type, data = null }, initialProps) => {
    return this.store.apiLayer
      .createBlock({ groupId: this.id, before, data: { type, data: data || initialProps || null } })
      .then(({ _id, ...rest }) => {
        const block = new Block(this.store, _id, rest)

        const newBlocksOrder = this.blocksOrder.slice()

        if (before == null) {
          newBlocksOrder.push(_id)
        } else {
          const placementIndex = newBlocksOrder.findIndex(id => id === before)
          newBlocksOrder.splice(placementIndex, 0, _id)
        }

        const newBlocks = [...this.blocks, block]
        runInAction(() => {
          this.blocks = newBlocks.sort(
            (a, b) => newBlocksOrder.indexOf(a.id) - newBlocksOrder.indexOf(b.id)
          )
        })
      })
  }

  @action
  updateBlock = ({ id, data }) => {
    const block = this.findBlockById(id)
    if (block) {
      block.setContent(data)
    } else {
      console.error('Block not found')
    }
  }

  @action
  removeBlock = blockId => {
    this.store.apiLayer.deleteBlock(blockId, this.id).then(() => {
      const block = this.findBlockById(blockId)
      this.blocks.remove(block)
    })
  }

  @action
  lockBlock = async blockId => {
    const block = this.findBlockById(blockId)
    if (block) {
      block.lock()
    }
  }

  @action
  unlockBlock = async blockId => {
    const block = this.findBlockById(blockId)
    if (block) {
      block.unlock()
    }
  }

  @action
  changeBlockPosition = ({ id: blockId, before }) => {
    const newBlocksOrder = this.blocksOrder.slice()
    const draggableIndex = this.blocksOrder.findIndex(id => id === blockId)

    if (draggableIndex === -1) return

    newBlocksOrder.splice(draggableIndex, 1)
    if (before == null) {
      newBlocksOrder.push(blockId)
    } else {
      const placementIndex = newBlocksOrder.findIndex(id => id === before)
      newBlocksOrder.splice(placementIndex, 0, blockId)
    }

    const newBlocks = this.blocks.slice()

    this.blocks = newBlocks.sort(
      (a, b) => newBlocksOrder.indexOf(a.id) - newBlocksOrder.indexOf(b.id)
    )

    this.store.apiLayer.moveBlock({ groupId: this.id, id: blockId, before })
  }

  @action
  setCharacterCount(count) {
    this.characterCount = count
  }

  destroy = () => {
    this.store.apiLayer.deleteBlockGroup(this.id)
    this.store.removeGroup(this)
  }

  @computed get asJson() {
    return {
      id: this.id,
      type: this.type,
      data: this.data
    }
  }
}
