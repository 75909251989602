import React from 'react'
import ContentWrapper from '~components/ContentWrapper'
import { Navigation, NavigationItem } from '~components/Navigation'

const SettingsNavigation = () => (
  <ContentWrapper>
    <Navigation title="Выберите страницу с настройками">
      <NavigationItem path="/settings/common" text="Общие" />
      <NavigationItem path="/settings/social" text="Соцсети" />
      <NavigationItem path="/settings/emails" text="E-mail адреса" />
      <NavigationItem path="/settings/categories_and_sections" text="Разделы и категории" />
      <NavigationItem path="/settings/timers" text="Таймеры" />
      <NavigationItem path="/settings/infopages" text="Инфостраницы" />
      <NavigationItem path="/settings/symbols" text="Символы" />
      <NavigationItem path="/settings/playlists" text="Плейлисты" />
      <NavigationItem path="/settings/specprojects" text="Спец.проекты" />
    </Navigation>
  </ContentWrapper>
)

export default SettingsNavigation
