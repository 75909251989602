import { observable, action, runInAction } from 'mobx'
import API from '~api'

const SETTINGS_GLOBAL_URL = '/settings/global'
const SETTINGS_POST_FOOTERL_URL = '/settings/postFooter'

class SettingsGlobalStore {
  @observable globalSettings = {}

  @observable postId = ''

  @observable isFirstLoading = true

  @action
  fetchGlobalSettings = () => {
    const promiseGlobal = API.get(SETTINGS_GLOBAL_URL)
    const promisePostId = API.get(SETTINGS_POST_FOOTERL_URL)

    const promises = Promise.all([promiseGlobal, promisePostId])

    return promises
      .then(res => {
        const { data } = res[0].data
        const { data: post } = res[1].data

        runInAction(() => {
          this.globalSettings = data
          this.isFirstLoading = false
          this.postId = post.post
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isFirstLoading = false
        })
      })
  }

  @action
  updateGlobalSettings = (name, checked) => {
    const promise = API.patch(SETTINGS_GLOBAL_URL, { data: { [name]: checked } })
    return promise.then(res => {
      const { data } = res.data

      this.globalSettings = data
    })
  }

  updateIdPost = () => {
    const promise = API.patch(SETTINGS_POST_FOOTERL_URL, { data: { post: this.postId } })

    return promise
  }

  @action
  setPostId = value => {
    this.postId = value
  }
}

export default new SettingsGlobalStore()
