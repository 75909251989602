import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { Input, message } from 'antd'

import { debounce } from 'lodash'

import ItemWithLabel from '~components/ItemWithLabel'

import s from './styles.scss'

const INPUT_LABEL = 'ID для подвала публикаций'
const INPUT_PLACEHOLDER = 'Вставьте id публикации'

@inject(stores => {
  const { updateIdPost, setPostId, postId } = stores.SettingsGlobalStore

  return {
    updateIdPost,
    setPostId,
    postId
  }
})
@observer
class SettingInputPostId extends Component {
  handleChangeInput = event => {
    event.persist()

    const { value } = event.target
    const { updateIdPost, setPostId } = this.props

    setPostId(value)

    if (!this.debouncedFn) {
      this.debouncedFn = debounce(() => {
        updateIdPost()
          .then(() => {
            message.success('Удачно')
          })
          .catch(() => {
            message.error('Ошибка')
          })
      }, 1000)
    }

    this.debouncedFn()
  }

  render() {
    const { postId } = this.props

    return (
      <ItemWithLabel outerClass={s.root} label={INPUT_LABEL}>
        <Input value={postId} onChange={this.handleChangeInput} placeholder={INPUT_PLACEHOLDER} />
      </ItemWithLabel>
    )
  }
}

SettingInputPostId.propTypes = {
  updateIdPost: PropTypes.func,
  setPostId: PropTypes.func,
  postId: PropTypes.string
}

export default SettingInputPostId
