import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Button, Tag } from 'antd'
import moment from 'moment'
import { get } from 'lodash'

import { inject, observer } from 'mobx-react'
import Person from '~components/Person'

import styles from './styles.scss'

const Item = ({ title, children }) => {
  return (
    <div className={styles.rootItem}>
      <div className={styles.rootItemTitle}>{title}:</div>
      <div className={styles.rootItemContent}>{children}</div>
    </div>
  )
}

@inject(stores => {
  const {
    setHideModalInfo,
    setShowModalEdit,
    setDuplicateEvent,
    isDuplicateEvent
  } = stores.ScheduleStore

  return {
    setHideModalInfo,
    setShowModalEdit,
    setDuplicateEvent,
    isDuplicateEvent
  }
})
@observer
class ModalWrapper extends Component {
  handleEditEvent = () => {
    const { setHideModalInfo, setShowModalEdit } = this.props

    setHideModalInfo()
    setShowModalEdit()
  }

  handleDuplicateEvent = () => {
    const { data, setDuplicateEvent, setHideModalInfo } = this.props

    setHideModalInfo()
    setDuplicateEvent(data)
  }

  render() {
    const { data } = this.props
    const {
      title,
      start,
      end,
      description,
      checkOutDate,
      format,
      address,
      car,
      equipment,
      push,
      operator,
      participants,
      types
    } = data

    const startDate = moment(start).format('DD.MM.YY HH:mm')
    const endDate = moment(end).format('DD.MM.YY HH:mm')
    const checkOutD = moment(checkOutDate).format('DD.MM.YY HH:mm')

    return (
      <div className={styles.root}>
        <Item title="Название">{title}</Item>

        <Item title="Дата и время начала">{startDate}</Item>

        <Item title="Дата и время окончания">{endDate}</Item>

        <Item title="Дата и время выезда">{checkOutD}</Item>

        <Item title="Описание">{description}</Item>

        <Item title="Формат">{format}</Item>

        <Item title="Место">{address}</Item>

        <Item title="Автомобиль">{car}</Item>

        <Item title="Оборудование">{equipment}</Item>

        <Item title="Push уведомления">{push ? 'Да' : 'Нет'}</Item>

        {!!types.length && (
          <Item title="Тип события">
            {types.map(event => (
              <Tag key={event._id} color={event.color}>
                {event.title}
              </Tag>
            ))}
          </Item>
        )}

        {operator && (
          <Item title="Оператор">
            <Person
              src={get(operator, 'avatar', '')}
              name={`${operator.firstName}  ${operator.lastName}`}
              id={operator.index}
            />
          </Item>
        )}

        <Item title="Участники">
          {participants.map((item, index) => (
            <div key={index}>
              {index + 1}. {item.name} / {item.phone} / {item.comment}
            </div>
          ))}
        </Item>

        <div className={styles.editWrapper}>
          <Button size="small" type="link" onClick={this.handleEditEvent}>
            Редактировать
          </Button>

          <Button size="small" type="link" onClick={this.handleDuplicateEvent}>
            Создать дубликат
          </Button>
        </div>
      </div>
    )
  }
}

export default ModalWrapper

ModalWrapper.propTypes = {
  data: PropTypes.object,
  isDuplicateEvent: PropTypes.any,
  setDuplicateEvent: PropTypes.any,
  setHideModalInfo: PropTypes.func,
  setShowModalEdit: PropTypes.func
}

Item.propTypes = {
  children: PropTypes.any,
  title: PropTypes.any
}
