import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { withRouter, NavLink } from 'react-router-dom'
import { Menu, Icon } from 'antd'

import routes from '~routes'

import styles from './styles.scss'

// eslint-disable-next-line react/prop-types
const SubmenuTitle = ({ id: key, title, icon, onClick }) => {
  const handleClick = () => {
    onClick(key)
  }
  return (
    <span className={styles.menuItem} onClick={handleClick}>
      <Icon type={icon} style={{ fontSize: 20, marginRight: 10 }} />
      <span>{title}</span>
    </span>
  )
}

const AppMenu = props => {
  const handleClick = () => {
    // TODO: refactor open/close mobile menu
    if (document) document.scrollingElement.style.overflow = 'auto'
  }

  const allMenuItems = {}

  Object.entries(routes.private).forEach(([parentKey, menuItem]) => {
    allMenuItems[parentKey] = menuItem
    if (menuItem.submenu) {
      Object.entries(menuItem.submenu).forEach(([key, submenuItem]) => {
        allMenuItems[key] = { ...submenuItem, parent: parentKey }
      })
    }
  })

  const selectedKeys = Object.entries(allMenuItems)
    .filter(([, route]) => {
      if (route.submenu) {
        // Собираем все роуты из подменю и ищем в них текущий location.pathname
        return (
          Object.values(route.submenu)
            .map(({ path }) => path)
            .indexOf(props.location.pathname) > -1
        )
      }

      return route.path === props.location.pathname
    })
    .map(([key]) => key)

  const defaultOpenKeys = Object.entries(routes.private)
    .filter(([, route]) => route.submenu)
    .map(([key]) => key)

  const onSubmenuTitleClick = key => {
    if (document) document.scrollingElement.style.overflow = 'auto'
    props.history.push(allMenuItems[key].path)
  }

  return (
    <Menu
      className={styles.appMenu}
      onClick={handleClick}
      selectedKeys={[...selectedKeys, 'Settings']}
      defaultOpenKeys={defaultOpenKeys}
      mode={'inline'}
      inlineIndent={0}
    >
      {Object.entries(routes.private)
        .filter(([, { inMenu }]) => inMenu)
        .map(([key, route]) =>
          route.submenu ? (
            <Menu.ItemGroup
              key={key}
              className={cn(styles.itemGroup, selectedKeys.indexOf(key) > -1 && styles.active)}
              title={
                <SubmenuTitle
                  id={key}
                  title={route.title}
                  icon={route.icon}
                  onClick={onSubmenuTitleClick}
                />
              }
            >
              {Object.entries(route.submenu)
                .filter(([, { inMenu }]) => inMenu)
                .map(([key, route]) => (
                  <Menu.Item key={key} className={cn(styles.menuItem, styles.sub)}>
                    {route.icon && <Icon type={route.icon} style={{ fontSize: 20 }} />}
                    {route.title}
                    <NavLink to={route.path} />
                  </Menu.Item>
                ))}
            </Menu.ItemGroup>
          ) : (
            <Menu.Item key={key} className={cn(styles.menuItem)}>
              {route.icon && <Icon type={route.icon} style={{ fontSize: 20 }} />}
              {route.title}
              <NavLink to={route.path} />
            </Menu.Item>
          )
        )}
    </Menu>
  )
}

AppMenu.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}

export default withRouter(AppMenu)
