import React from 'react'
import PropTypes from 'prop-types'

import AttachFileIcon from '~assets/icons/attachFile.svg'

import styles from './styles.scss'

const AttachmentFileLink = ({ file }) => (
  <a className={styles.root} href={file.url} target="_blank" rel="noopener noreferrer">
    <AttachFileIcon className={styles.icon} />
    <span className={styles.filename}>{file.name}</span>
  </a>
)

AttachmentFileLink.propTypes = {
  file: PropTypes.string
}

export default AttachmentFileLink
