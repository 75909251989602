import PropTypes from 'prop-types'
import React from 'react'

import { Empty } from 'antd'

import styles from './styles.scss'

const EmptyContent = ({ text }) => (
  <div className={styles.root}>
    <Empty description={text} />
  </div>
)

export default EmptyContent

EmptyContent.propTypes = {
  text: PropTypes.string
}
