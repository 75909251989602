import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { observer, inject } from 'mobx-react'

import ContentWrapper from '~components/ContentWrapper'
import { TagList, TagsModal } from '~components/Tags'
import HeaderPageSearchBar from '~components/HeaderPageSearchBar'
import InfinityScroll from '~components/InfinityScroll'

const PAGE_TITLE = 'Все теги'
const COUNT_DECLINATIONS = ['тег', 'тега', 'тегов']
const SEARCH_BTN_TEXT = 'Найти в тегах'
const CREATE_BTN_TEXT = 'Добавить тег'

@inject('TagsStore', 'FilterParamsByListStore', 'FetchPaginationStore')
@observer
class TagsPage extends Component {
  componentWillUnmount() {
    const { FilterParamsByListStore, FetchPaginationStore } = this.props

    FilterParamsByListStore.setFilterParams()
    FetchPaginationStore.resetPaginationParams()
  }

  handleSearchPublications = () => {
    const { fetchTags, setIsFirstLoading } = this.props.TagsStore

    setIsFirstLoading(true)
    fetchTags()
  }

  render() {
    const { TagsStore } = this.props
    const {
      setShowModal,
      isLoadingMore,
      isFirstLoading,
      allItemsCount,
      fetchTags,
      tagsLength
    } = TagsStore

    return (
      <Fragment>
        <HeaderPageSearchBar
          searchButtonText={SEARCH_BTN_TEXT}
          createButtonText={CREATE_BTN_TEXT}
          onCreateElement={setShowModal}
          onSearch={this.handleSearchPublications}
        />
        <ContentWrapper
          title={PAGE_TITLE}
          countItems={allItemsCount}
          countDeclinations={COUNT_DECLINATIONS}
        >
          <InfinityScroll
            loadItems={fetchTags}
            isEndLoading={allItemsCount <= tagsLength}
            isFirstLoading={isFirstLoading}
            isLoadMore={isLoadingMore}
          >
            <TagList />
          </InfinityScroll>
        </ContentWrapper>

        <TagsModal />
      </Fragment>
    )
  }
}

export default TagsPage

TagsPage.propTypes = {
  FetchPaginationStore: PropTypes.any,
  FilterParamsByListStore: PropTypes.any,
  TagsStore: PropTypes.any
}
