import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'

import RssItem from './RssItem'
import GridWrapper from '~components/GridWrapper'
import ContentSpinner from '~components/ContentSpinner'

@inject('RssStore')
@observer
class Rss extends Component {
  componentDidMount() {
    this.props.RssStore.fetchDictionaries()
    this.props.RssStore.fetchRss()
  }

  render() {
    const {
      RssStore: { rss, dictionaries, isFirstLoading }
    } = this.props

    if (isFirstLoading) {
      return <ContentSpinner />
    }

    return (
      <GridWrapper gridSize={2}>
        {dictionaries.map(item => (
          <RssItem key={item.id} item={item} value={rss[item.id]} />
        ))}
      </GridWrapper>
    )
  }
}

Rss.propTypes = {
  RssStore: PropTypes.any
}

export default Rss
