import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { observable } from 'mobx'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'

import ImageLazyLoad from '~components/ImageLazyLoad'
import StatusLabel from '~components/StatusLabel'
import ClickAndViewButton from '~components/ClickAndViewButton'
import Person from '~components/Person'
import Validated from '~components/Publications/Validated'

import getTimeByString from '~utils/getTimeByString'
import getShortText from '~utils/getShortText'

import styles from './styles.scss'

const SHORT_TITLE_LENGTH = 60

@inject(stores => {
  const { setEditableAuthor } = stores.AuthorsStore
  const { getPreviewToken, setIsLoadingView, isLoadingView } = stores.PublicationsStore

  return {
    setEditableAuthor,
    getPreviewToken,
    setIsLoadingView,
    isLoadingView
  }
})
@observer
class PublicationItemGrid extends Component {
  @observable isLoadingView = false

  handleClickPerson = () => {
    const { publication, setEditableAuthor, history } = this.props

    const firstAuthor = get(publication, 'author', {})

    setEditableAuthor(firstAuthor, 'info')
    history.push('/authors')
  }

  onClickEdit = () => {
    const {
      publication: { _id },
      history,
      match: { path }
    } = this.props
    history.push(`${path}/${_id}`)
  }

  onClickView = () => {
    const {
      publication: { _id, status, alias, index },
      getPreviewToken
    } = this.props

    if (status.id === 'POST_STATUS_PUBLISHED') {
      window.open(`${process.env.PUBLIC_ORIGIN}/p/${alias || index}`, '_blank')
    } else {
      this.isLoadingView = true
      getPreviewToken(_id)
        .then(res => {
          const { token } = res.data

          window.open(`${process.env.PUBLIC_ORIGIN}/p/${alias || index}?token=${token}`, '_blank')

          this.isLoadingView = false
        })
        .catch(() => (this.isLoadingView = false))
    }
  }

  render() {
    const { publication } = this.props

    const {
      title,
      updatedAt,
      publicationDate,
      validatedBy,
      validatedAt,
      cover,
      status,
      author = {},
      authorsCount = 1,
      type,
      index
    } = publication

    const shortTitle = getShortText(title, SHORT_TITLE_LENGTH)
    const updateDate = getTimeByString(updatedAt)
    const publicationDateString = getTimeByString(publicationDate)
    const coverUrl = get(cover, 'url', '')
    const firstAuthor = author
    const authorUrl = get(firstAuthor, 'avatar.url', '')
    const moreAuthorsCount = authorsCount - 1

    return (
      <div className={styles.root}>
        <div className={styles.imgWrapper}>
          <ImageLazyLoad src={coverUrl} />

          <div className={styles.statusWrapper}>
            <StatusLabel color={status.color} label={status.ru} />
          </div>
        </div>

        <div className={styles.contentWrapper}>
          <div className={styles.title}>{shortTitle}</div>

          <div className={styles.updateTime}>Изменено {updateDate}</div>
          {publicationDate && (
            <div className={styles.updateTime}>Публикация {publicationDateString}</div>
          )}
        </div>

        <div className={styles.contentWrapper}>
          {firstAuthor && (
            <div className={styles.personWrapper}>
              <Person
                onClick={this.handleClickPerson}
                src={authorUrl}
                name={firstAuthor.name}
                id={index}
                type={type.ru}
              />

              {moreAuthorsCount > 0 && (
                <div className={styles.moreAuthorsCount}>и еще +{moreAuthorsCount}</div>
              )}
            </div>
          )}

          <div className={styles.validatedWrapper}>
            <Validated {...validatedBy} validatedAt={validatedAt} />
          </div>
        </div>

        <ClickAndViewButton
          editLink={`/publications/${publication && publication._id}`}
          onView={this.onClickView}
          isLoadingView={this.isLoadingView}
        />
      </div>
    )
  }
}

export default withRouter(PublicationItemGrid)

PublicationItemGrid.propTypes = {
  history: PropTypes.any,
  match: PropTypes.any,
  publication: PropTypes.object,
  getPreviewToken: PropTypes.func,
  setEditableAuthor: PropTypes.func
}
