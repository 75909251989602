/* eslint-disable */

import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import ArrowRight from '~assets/icons/arrowRight.svg'

import styles from './styles.scss'

const Header = props => {
  const { history } = props

  const onClickExit = () => {
    history.push('/broadcasts/video')
  }

  return (
    <div className={styles.editorHeader}>
      <div style={{ display: 'inline-block' }} onClick={onClickExit}>
        <div className={styles.backButton}>
          <ArrowRight fill={'currentColor'} style={{ transform: 'rotate(180deg) scale(.5)' }} />
          <span>Выйти из редактирования</span>
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {}

export default withRouter(Header)
