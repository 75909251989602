import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

import { get } from 'lodash'
import { Form } from 'antd'

import ItemWithLabel from '~components/ItemWithLabel'

@inject(stores => {
  const { editableAuthor, isActionModal } = stores.AuthorsStore

  return {
    editableAuthor,
    isActionModal
  }
})
@observer
class FormItem extends Component {
  render() {
    const {
      label,
      fieldName,
      children,
      getFieldDecorator,
      editableAuthor,
      isActionModal,
      required
    } = this.props
    return (
      <ItemWithLabel label={label}>
        <Form.Item>
          {getFieldDecorator(fieldName, {
            initialValue: get(editableAuthor, fieldName, ''),
            rules: [{ required, message: 'Обязательное поле!' }]
          })(<children.type {...children.props} disabled={isActionModal} />)}
        </Form.Item>
      </ItemWithLabel>
    )
  }
}

export default FormItem

FormItem.propTypes = {
  children: PropTypes.element,
  editableAuthor: PropTypes.any,
  fieldName: PropTypes.string,
  getFieldDecorator: PropTypes.any,
  isActionModal: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string
}
