const JOURNALIST = 'EMPLOYEE_POSITION_JOURNALIST'
const EDITOR = 'EMPLOYEE_POSITION_EDITOR'
const COMM_EDITOR = 'EMPLOYEE_POSITION_COMM_EDITOR'
const CHIEF_EDITOR = 'EMPLOYEE_POSITION_CHIEF_EDITOR'
const BUILD_EDITOR = 'EMPLOYEE_POSITION_BUILD_EDITOR'
const CORRECTOR = 'EMPLOYEE_POSITION_CORRECTOR'
const CHIEF_PRODUCER = 'EMPLOYEE_POSITION_CHIEF_PRODUCER'
const PRODUCER = 'EMPLOYEE_POSITION_PRODUCER'
const STREAM_PROVIDER = 'STREAM_PROVIDER'
const INTERN = 'EMPLOYEE_POSITION_INTERN'

export const role = {
  JOURNALIST,
  EDITOR,
  COMM_EDITOR,
  CHIEF_EDITOR,
  BUILD_EDITOR,
  CORRECTOR,
  CHIEF_PRODUCER,
  PRODUCER,
  STREAM_PROVIDER,
  INTERN
}

export const roles = [
  JOURNALIST,
  EDITOR,
  COMM_EDITOR,
  CHIEF_EDITOR,
  BUILD_EDITOR,
  CORRECTOR,
  CHIEF_PRODUCER,
  PRODUCER,
  STREAM_PROVIDER,
  INTERN
]

export function getRoleExceptSome(exception) {
  if (Array.isArray(exception)) {
    const filteredRoles = []
    roles.forEach(function filterRoles(role) {
      if (exception.indexOf(role) === -1) {
        filteredRoles.push(role)
      }
    })

    return filteredRoles
  }

  return roles.filter(function filterRole(role) {
    return role !== exception
  })
}

export const isEditor = role => [CHIEF_EDITOR].includes(role)

export default roles
