import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { withRouter } from 'react-router-dom'

import { Button } from 'antd'

import styles from './styles.scss'

const ClickAndViewButton = ({ history, editLink, onView, clickButtonText, isLoadingView }) => {
  const handleClick = event => {
    if (event.ctrlKey || event.metaKey || (event.button && event.button === 1)) {
      window.open(editLink)
    } else {
      history.push(editLink)
    }

    return null
  }

  return (
    <div className={styles.root}>
      <Button
        className={styles.clickButton}
        type="primary"
        onClick={handleClick}
        onMouseUp={handleClick}
      >
        {clickButtonText}
      </Button>

      {onView && (
        <Fragment>
          <div className={styles.divider} />
          <Button
            className={styles.viewButton}
            loading={isLoadingView}
            type="primary"
            onClick={onView}
            icon="eye"
          />
        </Fragment>
      )}
    </div>
  )
}

export default withRouter(ClickAndViewButton)

ClickAndViewButton.propTypes = {
  clickButtonText: PropTypes.string,
  editLink: PropTypes.string,
  onView: PropTypes.func,
  isLoadingView: PropTypes.bool,
  history: PropTypes.any
}

ClickAndViewButton.defaultProps = {
  clickButtonText: 'Редактировать'
}
