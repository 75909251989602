import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'

import { Avatar, Icon, Popover, Button } from 'antd'
import { get } from 'lodash'

// eslint-disable-next-line import/no-cycle
import RepliesList from './RepliesList'

import getTimeByString from '~utils/getTimeByString'

import styles from './styles.scss'

@inject(stores => {
  const { deleteComment } = stores.CommentsStore

  return {
    deleteComment
  }
})
@observer
class CommentItem extends Component {
  @observable isDelete = false

  @observable isBlock = false

  handleDeleteComment = () => {
    const { comment, onDelete } = this.props

    this.isDelete = true
    onDelete(comment).then(() => (this.isDelete = false))
  }

  handleBlockUser = () => {
    this.isBlock = true
    const { comment, onBlockUser } = this.props

    onBlockUser(comment).then(() => (this.isBlock = false))
  }

  render() {
    const { comment } = this.props

    const { content, createdAt, replies, createdBy } = comment

    const name = get(createdBy, 'name', '')
    const avatar = get(createdBy, 'avatar', '')
    const createDate = getTimeByString(createdAt)

    return (
      <div className={styles.root}>
        <div className={styles.avatarWrapper}>
          <Avatar size={44} src={avatar}>
            {name[0]}
          </Avatar>
        </div>

        <div className={styles.content}>
          <div className={styles.top}>
            <div className={styles.title}>{name}</div>

            <div className={styles.date}>{createDate}</div>

            <div className={styles.control}>
              <Popover
                content={
                  <div className={styles.btnsWrapper}>
                    <Button
                      size="small"
                      loading={this.isDelete}
                      className={styles.controlBtn}
                      onClick={this.handleDeleteComment}
                    >
                      Удалить комментарий
                    </Button>
                    <Button
                      size="small"
                      type="danger"
                      loading={this.isBlock}
                      className={styles.controlBtn}
                      onClick={this.handleBlockUser}
                    >
                      Заблокировать пользователя
                    </Button>
                  </div>
                }
                trigger="click"
              >
                <span className={styles.iconWrapper}>
                  <Icon type="ellipsis" />
                </span>
              </Popover>
            </div>
          </div>

          <div className={styles.bottom}>{content}</div>

          {!!(replies || []).length && <RepliesList data={replies} />}
        </div>
      </div>
    )
  }
}

export default CommentItem

CommentItem.propTypes = {
  comment: PropTypes.any,
  onBlockUser: PropTypes.func,
  onDelete: PropTypes.func
}
