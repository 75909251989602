import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { Input, Upload, message, Checkbox, Button, Form } from 'antd'

import DeleteButton from '~components/DeleteButton'
import ItemWithLabel from '~components/ItemWithLabel'
import Modal from '~components/Modal'
import s from './styles.scss'

const CREATE_TITLE = 'Создание новой категории'
const UPDATE_TITLE = 'Редактирование категории'

@inject('CategoriesStore')
@observer
class CategoriesModal extends Component {
  handleSubmit = e => {
    e.preventDefault()

    const {
      CategoriesStore: { editableCategory, updateCategory, createCategory },
      form
    } = this.props
    const formData = form.getFieldsValue()

    if (formData.cover && formData.cover._id) {
      formData.cover = formData.cover._id
    } else if (formData.cover && formData.cover.file && formData.cover.file.response) {
      formData.cover = formData.cover.file.response.data._id
    } else {
      formData.cover = null
    }

    const category = { data: { ...formData } }
    const action = editableCategory ? updateCategory : createCategory

    action(category)
  }

  render() {
    const {
      CategoriesStore: {
        setHideModal,
        isShowModal,
        resetEditableCategory,
        editableCategory,
        isActionModal,
        deleteCategory
      },
      form
    } = this.props
    const { getFieldDecorator } = form
    const ModalTitle = editableCategory ? UPDATE_TITLE : CREATE_TITLE
    const fileUploadProps = {
      name: 'file',
      action: `${process.env.API_ROOT}/files`,
      withCredentials: true,
      onChange(info) {
        if (info.file.status === 'done') {
          message.success(`${info.file.name} успешно загружен.`)
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} не получилось загрузить файл.`)
        }
      }
    }

    return (
      <Modal
        destroyOnClose
        width={590}
        onClose={setHideModal}
        isVisible={isShowModal}
        title={ModalTitle}
        afterClose={resetEditableCategory}
      >
        <Form onSubmit={this.handleSubmit}>
          <ItemWithLabel label="Название категории">
            <Form.Item>
              {getFieldDecorator('title', {
                initialValue: editableCategory && editableCategory.title,
                rules: [{ required: true, message: 'Обязательное поле!' }]
              })(<Input placeholder="Введите название" disabled={isActionModal} />)}
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="Псевдоним категории (латиница)">
            <Form.Item>
              {getFieldDecorator('alias', {
                initialValue: editableCategory && editableCategory.alias,
                rules: [{ required: true, message: 'Обязательное поле!' }]
              })(<Input placeholder="Введите псевдоним" disabled={isActionModal} />)}
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="SEO заголовок">
            <Form.Item>
              {getFieldDecorator('seo.title', {
                initialValue: editableCategory && editableCategory.seo.title,
                rules: [{ required: false }]
              })(<Input placeholder="Введите SEO заголовок" disabled={isActionModal} />)}
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="SEO описание">
            <Form.Item>
              {getFieldDecorator('seo.description', {
                initialValue: editableCategory && editableCategory.seo.description,
                rules: [{ required: false }]
              })(<Input placeholder="Введите SEO описание" disabled={isActionModal} />)}
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="Обложка">
            <Form.Item>
              {getFieldDecorator('cover', {
                initialValue: editableCategory && editableCategory.cover
              })(
                <Upload {...fileUploadProps}>
                  <Button>Кликните чтобы загрузить файл</Button>
                </Upload>
              )}
            </Form.Item>
          </ItemWithLabel>

          <Form.Item>
            {getFieldDecorator('visible', {
              initialValue: (editableCategory && editableCategory.visible) || false,
              valuePropName: 'checked'
            })(<Checkbox>Опубликована на сайте</Checkbox>)}
          </Form.Item>

          <div className={s.modalControls}>
            <Button htmlType="submit" type="primary" disabled={isActionModal}>
              Сохранить изменения
            </Button>
            {editableCategory && (
              <div className={s.deleteBtnWrapper}>
                <DeleteButton
                  text="Удалить категорию"
                  disabled={isActionModal}
                  onDelete={deleteCategory}
                />
              </div>
            )}
          </div>
        </Form>
      </Modal>
    )
  }
}

CategoriesModal.propTypes = {
  CategoriesStore: PropTypes.any,
  form: PropTypes.any
}

export default Form.create({ name: 'categoryForm' })(CategoriesModal)
