import React, { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import { Icon } from 'antd'
import editorPackage from '@dev/tabo-editor/package.json'
import schemaPackage from 'life-schema/package.json'
import ErrorBoundary from '~components/ErrorBoundary'
import PostsPageGlobalHeader from '~components/PostsPageGlobalHeader'
import Logo from '~assets/logo.svg'
import Menu from './components/Menu'
import User from '~components/User'
import Button from '~components/Button'

import adminPackage from '../../../package.json'

import Burger from '~assets/icons/menu.svg'

import styles from './styles.scss'

// @observer
const AppLayout = inject('UserStore')(
  observer(props => {
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
      if (props.UserStore.user.name === '') props.UserStore.getUserInfo()
    }, [props.UserStore.user.name])

    const {
      children: [, children],
      UserStore: { performLogout, user },
      showPostsHeader
    } = props // TODO: check why props.children = [' ', {component}]?

    const toggleBurger = () => {
      if (document && document.scrollingElement)
        document.scrollingElement.style.overflow = isOpen ? 'auto' : 'hidden'

      setIsOpen(!isOpen)
    }

    return (
      <div className={styles.appLayout}>
        <aside className={cn(styles.appSidebar, isOpen && styles.open)}>
          {showPostsHeader && <PostsPageGlobalHeader />}

          <div className={cn(styles.header, showPostsHeader && styles.showPostsHeader)}>
            <Link to={'/'} style={{ display: 'flex' }}>
              <Logo fill="#fff" />
            </Link>

            <div onClick={toggleBurger} className={styles.burger}>
              <Burger fill="#fff" />
            </div>
          </div>

          <div className={styles.content}>
            <Menu />

            <div className={styles.footer}>
              <User person={user} />
              <Button type="link" className={styles.logoutButton} onClick={performLogout}>
                <Icon type="logout" style={{ fontSize: 20, width: 20, height: 20 }} />
                Выйти
              </Button>
              <p />
              <p className={styles.version}>{`Версия life-admin: ${adminPackage.version}`}</p>
              <p className={styles.version}>{`Версия life-editor: ${editorPackage.version}`}</p>
              <p className={styles.version}>{`Версия life-schema: ${schemaPackage.version}`}</p>
            </div>
          </div>

          <div className={cn(styles.overlay, isOpen && styles.open)} onClick={toggleBurger} />
        </aside>
        <div className={styles.layoutContent}>
          <ErrorBoundary>
            <children.type {...children.props} />
          </ErrorBoundary>
        </div>
      </div>
    )
  })
)

AppLayout.displayName = 'AppLayout'
export default AppLayout
