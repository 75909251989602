const SOCIALS_TYPE = {
  VKONTAKTE: 'vk',
  ODNOKLASSNIKI: 'ok',
  TELEGRAM: 'telegram',
  TWITTER: 'twitter',
  INSTAGRAM: 'instagram',
  FACEBOOK: 'facebook'
}

export default SOCIALS_TYPE
