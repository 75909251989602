import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { get } from 'lodash'

import { Input, Button, Form } from 'antd'

import Modal from '~components/Modal'
import ItemWithLabel from '~components/ItemWithLabel'
import DeleteButton from '~components/DeleteButton'

import styles from './styles.scss'

const CREATE_TITLE = 'Создание нового тега'
const UPDATE_TITLE = 'Редактирование тега'

@inject(stores => {
  const {
    isShowModal,
    setHideModal,
    editableTag,
    resetEditableTag,
    updateTag,
    createTag,
    deleteTag,
    isActionModal
  } = stores.TagsStore

  return {
    isShowModal,
    setHideModal,
    editableTag,
    resetEditableTag,
    updateTag,
    createTag,
    deleteTag,
    isActionModal
  }
})
@observer
class TagsModal extends Component {
  handleSubmit = e => {
    e.preventDefault()

    const { form, updateTag, editableTag, createTag } = this.props
    const formData = form.getFieldsValue()

    const department = {
      data: formData
    }

    const action = editableTag ? updateTag : createTag

    action(department)
  }

  render() {
    const {
      isShowModal,
      setHideModal,
      editableTag,
      resetEditableTag,
      form,
      isActionModal,
      deleteTag
    } = this.props
    const { getFieldDecorator } = form

    const modalTitle = editableTag ? UPDATE_TITLE : CREATE_TITLE
    const initialValue = get(editableTag, 'title', '')

    return (
      <Modal
        destroyOnClose
        width={590}
        onClose={setHideModal}
        isVisible={isShowModal}
        title={modalTitle}
        afterClose={resetEditableTag}
      >
        <div className={styles.root}>
          <Form onSubmit={this.handleSubmit}>
            <ItemWithLabel label="Название тега (значение не должно содержать решетку)">
              <Form.Item>
                {getFieldDecorator('title', {
                  initialValue,
                  rules: [{ required: true, message: 'Обязательное поле!' }]
                })(<Input addonBefore="#" autoFocus disabled={isActionModal} />)}
              </Form.Item>
            </ItemWithLabel>

            <div className={styles.modalControls}>
              <Button
                loading={isActionModal}
                disabled={isActionModal}
                htmlType="submit"
                type="primary"
              >
                Сохранить изменения
              </Button>

              {editableTag && (
                <div className={styles.deleteBtnWrapper}>
                  <DeleteButton text="Удалить тег" onDelete={deleteTag} />
                </div>
              )}
            </div>
          </Form>
        </div>
      </Modal>
    )
  }
}

const WrappedTagForm = Form.create({ name: 'tagForm' })(TagsModal)

export default WrappedTagForm

TagsModal.propTypes = {
  editableTag: PropTypes.any,
  form: PropTypes.any,
  isActionModal: PropTypes.bool,
  isShowModal: PropTypes.bool,
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  resetEditableTag: PropTypes.func,
  setHideModal: PropTypes.func,
  updateTag: PropTypes.func,
  deleteTag: PropTypes.func,
  createTag: PropTypes.func
}
