import React from 'react'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import cn from 'classnames'
import { Button, Modal, message } from 'antd'

// components

// assets
import PropTypes from 'prop-types'
import IconClose from '~assets/icons/close.svg'
import styles from './styles.scss'

@inject('VideoStore')
@observer
class Actions extends React.Component {
  state = {
    isOpenSettings: false
  }

  onClickPublish = () => {
    const { publish, unpublish, isPublished } = this.props.VideoStore

    if (isPublished) {
      unpublish().then(() => {
        message.success('Видео успешно снято с публикации!')
      })
    } else {
      publish().then(() => {
        message.success('Видео успешно опубликовано!')
      })
    }
  }

  onClickSave = () => {
    this.props.VideoStore.save()
      .then(() => {
        message.success('Видео успешно сохранено!')
      })
      .catch(err => {
        message.error(`Произошла ошибка! \n${JSON.stringify(err)}`)
      })
  }

  streamControl = () => {
    const { VideoStore } = this.props
    const { startStream, streamStatus, stopStream } = VideoStore

    if (streamStatus === 'STREAM_STATUS_NOT_STARTED') {
      startStream().catch(err => {
        message.error(`Произошла ошибка! \n${JSON.stringify(err)}`)
      })
    }

    if (streamStatus === 'STREAM_STATUS_RECORDING') {
      stopStream().catch(err => {
        message.error(`Произошла ошибка! \n${JSON.stringify(err)}`)
      })
    }
  }

  onClickDelete = () => {
    const { VideoStore, history } = this.props

    Modal.confirm({
      title: 'Вы действительно хотите удалить видео?',
      onOk: () => {
        VideoStore.delete().then(() => {
          message.success('Видео успешно удалено!')
          history.push('/broadcasts/video')
        })
      }
    })
  }

  getBtnStreamPropsFromStatus = () => {
    const { streamStatus } = this.props.VideoStore

    let btnProps = {}

    switch (streamStatus) {
      case 'STREAM_STATUS_NOT_STARTED':
        btnProps = {
          title: 'Начать запись',
          ico: 'play-circle',
          styles: styles.save,
          disabled: false
        }
        break
      case 'STREAM_STATUS_RECORDING':
        btnProps = {
          title: 'Завершить запись',
          ico: 'stop',
          styles: null,
          disabled: false
        }
        break
      default:
        btnProps = {
          title: 'Запись завершена',
          ico: 'check',
          styles: null,
          disabled: true
        }
        break
    }

    return btnProps
  }

  render() {
    const { type } = this.props.VideoStore // TODO: вернуть если запустим стримы
    const btnProps = this.getBtnStreamPropsFromStatus() // TODO: вернуть если запустим стримы

    return (
      <div className={styles.buttons}>
        <div className={styles.left}>
          <Button type="primary" className={styles.button} onClick={this.onClickPublish}>
            {this.props.VideoStore.isPublished ? 'Снять с публикации' : 'Опубликовать'}
          </Button>

          <Button
            type="link"
            className={cn(styles.button, styles.delete)}
            onClick={this.onClickDelete}
          >
            <IconClose style={{ fill: 'currentColor' }} /> Удалить видео
          </Button>

          {/* TODO: вернуть если запустим стримы */}
          {type === 'VIDEO_TYPE_STREAM' && (
            <Button
              disabled={btnProps.disabled}
              onClick={this.streamControl}
              icon={btnProps.ico}
              type="primary"
              className={cn(styles.button, btnProps.styles)}
            >
              {btnProps.title}
            </Button>
          )}
          {/* TODO: вернуть если запустим стримы */}
        </div>

        <Button
          type="primary"
          className={cn(styles.button, styles.save)}
          onClick={this.onClickSave}
        >
          Сохранить
        </Button>
      </div>
    )
  }
}

Actions.propTypes = {
  VideoStore: PropTypes.object,
  history: PropTypes.object
}

export default withRouter(Actions)
