import React from 'react'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { message } from 'antd'

// components
import PropTypes from 'prop-types'
import ContentWrapper from '../../components/ContentWrapper'
import StatusLabel from '../../components/StatusLabel'
import FilterToggleControl from '../../components/FilterToggleControl'
import Header from './components/Header'
import VideoEditor from './components/VideoEditor'
import Actions from './components/Actions'
import Settings from './components/Settings'

// assets
import styles from './styles.scss'

@inject('VideoStore')
@observer
class VideoPage extends React.Component {
  state = {
    isOpenSettings: true
  }

  componentDidMount() {
    const {
      match: {
        params: { id }
      },
      VideoStore,
      history
    } = this.props

    VideoStore.init(id)
      .then(() => {})
      .catch(() => {
        message.error(`Видео ${id} не найдено!`)
        history.replace('/broadcasts/video')
      })
  }

  componentWillUnmount() {
    this.props.VideoStore.clear()
  }

  toggleSettings = () => {
    this.setState(({ isOpenSettings }) => ({ isOpenSettings: !isOpenSettings }))
  }

  render() {
    const { id, status } = this.props.VideoStore
    const isNew = id == null

    return (
      <>
        <Header />
        <ContentWrapper
          title={`${isNew ? 'Создание' : 'Редактирование'} видео`}
          countItems={<StatusLabel color={status.color} label={status.ru} />}
          rightContent={
            <FilterToggleControl
              label="Все настройки"
              isActive={this.state.isOpenSettings}
              onClick={this.toggleSettings}
            />
          }
        >
          <div className={styles.content}>
            {this.state.isOpenSettings && <Settings />}
            <Actions />
            <VideoEditor />
          </div>
        </ContentWrapper>
      </>
    )
  }
}

VideoPage.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  VideoStore: PropTypes.object
}

export default withRouter(VideoPage)
