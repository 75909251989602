import React from 'react'
import ReactDOM from 'react-dom/server'
import { Icon } from 'antd'

export const InputsTypes = {
  input: 'input',
  upload: 'upload',
  textarea: 'textarea',
  richInput: 'richInput'
}

export const BlockTypes = {
  gigarama: 'gigarama',
  person: 'person',
  cardText: 'card:text',
  cardImage: 'card:image',
  cardVideo: 'card:video',
  cardQuote: 'card:quote'
}

const cardInputs = {
  title: {
    name: 'title',
    label: 'Заголовок',
    type: InputsTypes.richInput
  },
  text: {
    name: 'text',
    label: 'Текст',
    type: InputsTypes.richInput
  },
  content: {
    name: 'content',
    label: 'Контент',
    type: InputsTypes.richInput
  },
  description: {
    name: 'description',
    label: 'Описание',
    type: InputsTypes.richInput
  },
  copyright: {
    name: 'copyright',
    label: 'Copyright',
    type: InputsTypes.richInput
  },
  cite: {
    name: 'cite',
    label: 'Подпись',
    type: InputsTypes.richInput
  },
  media: {
    name: 'image',
    label: 'Медиа',
    type: InputsTypes.upload
  }
}

// const defaultCardInputs = [
//   {
//     name: 'title',
//     label: 'Заголовок',
//     type: InputsTypes.richInput
//   },
//   {
//     name: 'text',
//     label: 'Текст',
//     type: InputsTypes.richInput
//   },
//   {
//     name: 'content',
//     label: 'Контент',
//     type: InputsTypes.richInput
//   },
//   {
//     name: 'description',
//     label: 'Описание',
//     type: InputsTypes.richInput
//   },
//   {
//     name: 'copyright',
//     label: 'Copyright',
//     type: InputsTypes.richInput
//   },
//   {
//     name: 'cite',
//     label: 'Подпись',
//     type: InputsTypes.richInput
//   },
//   {
//     name: 'image',
//     label: 'Медиа',
//     type: InputsTypes.upload
//   }
// ]

export default [
  {
    title: 'Гигарама',
    icon: ReactDOM.renderToString(
      <Icon type="codepen" style={{ width: 28, color: 'rgba(0,0,0,.3)' }} />
    ),
    customType: BlockTypes.gigarama,
    inputs: [
      {
        name: 'title',
        label: 'Заголовок',
        type: InputsTypes.input
      },
      {
        name: 'button_text',
        label: 'Текст на кнопке',
        type: InputsTypes.input
      },
      {
        name: 'gigarama_url',
        label: 'Ссылка на гигараму',
        type: InputsTypes.input
      },
      {
        name: 'background',
        label: 'Фоновое изображение',
        type: InputsTypes.upload
      }
    ]
  },
  {
    title: 'Карточка персоны',
    icon: ReactDOM.renderToString(
      <Icon type="idcard" style={{ width: 28, color: 'rgba(0,0,0,.3)' }} />
    ),
    customType: BlockTypes.person,
    inputs: [
      {
        name: 'name',
        label: 'Имя',
        type: InputsTypes.richInput
      },
      {
        name: 'description',
        label: 'Описание',
        type: InputsTypes.richInput
      },
      {
        name: 'avatar',
        label: 'Аватар',
        type: InputsTypes.upload
      }
    ]
  },
  {
    title: 'Карточка. Текст',
    icon: ReactDOM.renderToString(
      <Icon type="font-size" style={{ width: 28, color: 'rgba(0,0,0,.3)' }} />
    ),
    customType: BlockTypes.cardText,
    inputs: [cardInputs.title, cardInputs.text, cardInputs.copyright, cardInputs.media]
  },
  {
    title: 'Карточка. Картинка',
    icon: ReactDOM.renderToString(
      <Icon type="pic-left" style={{ width: 28, color: 'rgba(0,0,0,.3)' }} />
    ),
    customType: BlockTypes.cardImage,
    inputs: [cardInputs.text, cardInputs.copyright, cardInputs.media]
  },
  // {
  //   title: 'Карточка. Видео',
  //   icon: ReactDOM.renderToString(
  //     <Icon type="video-camera" style={{ width: 28, color: 'rgba(0,0,0,.3)' }} />
  //   ),
  //   customType: BlockTypes.cardVideo,
  //   inputs: defaultCardInputs
  // },
  {
    title: 'Карточка. Цитата',
    icon: ReactDOM.renderToString(
      <Icon type="message" style={{ width: 28, color: 'rgba(0,0,0,.3)' }} />
    ),
    customType: BlockTypes.cardQuote,
    inputs: [
      // cardInputs.title,
      { ...cardInputs.content, label: 'Цитата' },
      { ...cardInputs.cite, label: 'Автор' },
      cardInputs.media
    ]
  }
]
