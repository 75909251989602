import { observable, computed, action, runInAction } from 'mobx'
import API from '~api'

export class UserStore {
  constructor() {
    const isAuthFlow = /connect|oauth/.test(window.location.pathname)

    console.log(new Date(), 'UserStore constructor', window.location.pathname, isAuthFlow)
    if (isAuthFlow) {
      return
    }

    this.getUserInfo()
  }

  @observable appLoaded = false

  @observable user = JSON.parse(
    localStorage.getItem('user:me') ||
      JSON.stringify({
        name: '',
        position: '',
        role: '',
        avatar: '',
        _id: ''
      })
  )

  @action
  setAppLoaded = () => {
    this.appLoaded = true
  }

  @action
  performLogin = ({ login, password }) => {
    API.post('/employees/login', { login, password }).then(() => {
      this.getUserInfo()
    })
  }

  @action
  getUserInfo = () => {
    return API.get('/employees/me')
      .then(({ data: { data: user } }) => {
        runInAction(() => {
          this.user = {
            ...user,
            name: `${user.firstName} ${user.lastName}`,
            position: user.position.ru,
            role: user.position.id,
            avatar: (user.avatar && user.avatar.url) || ''
          }
          localStorage.setItem('user:me', JSON.stringify(user))
        })
      })
      .catch(() => {
        this.user = {
          _id: ''
        }
        localStorage.clear()
      })
      .finally(() => {
        this.setAppLoaded()
      })
  }

  @action
  performLogout = () => {
    this.user = {
      _id: ''
    }
    this.token = ''
    localStorage.clear()
    API.post('/employees/logout')
  }

  @computed
  get isAuthenticated() {
    // why not only user id? before: this.token && this.user._id
    return this.user._id
  }
}

export default new UserStore()
