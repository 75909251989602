import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { DatePicker, TimePicker } from 'antd'
import moment from 'moment'

import CurrentTime from '../CurrentTime'

import styles from './styles.scss'

const PostponeInput = inject('PublicationStore')(
  observer(({ PublicationStore: { publicationDate, setPublicationDate } }) => {
    const setDate = date => {
      setPublicationDate(date.toISOString())
    }

    const date = useMemo(() => (publicationDate ? moment(publicationDate) : moment()), [
      publicationDate
    ])

    return (
      <div>
        <div className={styles.row}>
          <b>Сейчас: </b>
          <CurrentTime />
        </div>
        <div className={styles.row}>
          <b>Опубликовать:</b>
          <DatePicker
            className={styles.input}
            placeholder="Выберите дату"
            allowClear={false}
            value={date}
            onChange={setDate}
            format={'DD.MM.YYYY'}
            size={'small'}
          />
          <TimePicker
            className={styles.input}
            placeholder="Выберите время"
            allowClear={false}
            defaultValue={moment().add(30, 'minutes')}
            value={date}
            onChange={setDate}
            format={'HH:mm'}
            size={'small'}
          />
        </div>
      </div>
    )
  })
)

PostponeInput.propTypes = {
  publicationDate: PropTypes.string,
  setPublicationDate: PropTypes.func
}

export default PostponeInput
