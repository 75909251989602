import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

import ChangeLogItem from '~components/ChangeLogs/ChangeLogItem'
import GridWrapper from '~components/GridWrapper'
import ContentSpinner from '~components/ContentSpinner'
import EmptyContent from '~components/EmptyContent'

import styles from './styles.scss'

@inject(stores => {
  const { changeLogs, isFirstLoading, isEmptyChangeLog } = stores.ChangeLogStore

  return {
    changeLogs,
    isFirstLoading,
    isEmptyChangeLog
  }
})
@observer
class ChangeLogList extends Component {
  render() {
    const { changeLogs, isFirstLoading, isEmptyChangeLog } = this.props

    if (isFirstLoading) {
      return <ContentSpinner />
    }

    if (isEmptyChangeLog) return <EmptyContent text="Нет данных" />

    return (
      <div className={styles.root}>
        <GridWrapper gridSize={2}>
          {changeLogs.map(changeLog => (
            <ChangeLogItem key={changeLog._id} data={changeLog} />
          ))}
        </GridWrapper>
      </div>
    )
  }
}

export default ChangeLogList

ChangeLogList.propTypes = {
  isFirstLoading: PropTypes.bool,
  isEmptyChangeLog: PropTypes.bool,
  changeLogs: PropTypes.array
}
