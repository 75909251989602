import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import PropTypes from 'prop-types'
import FeedbackFilterItem from '~components/Feedbacks/FeedbackFilterItem'

import styles from './styles.scss'

@inject('FeedbackStore')
@observer
class FeedbackFilter extends Component {
  render() {
    const {
      FeedbackStore: { dictionaries }
    } = this.props
    const allTypes = [{ id: '', ru: 'Все письма' }, ...dictionaries]

    return (
      <ul className={styles.navigation}>
        {allTypes && allTypes.map((type, i) => <FeedbackFilterItem key={i} type={type} />)}
      </ul>
    )
  }
}

FeedbackFilter.propTypes = {
  FeedbackStore: PropTypes.any
}

export default FeedbackFilter
