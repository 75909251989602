import React, { Component } from 'react'

import styles from './styles.scss'

class ChangeLogFilter extends Component {
  /* eslint-disable class-methods-use-this */
  render() {
    return (
      <div className={styles.root}>
        <div className={styles.value}>Мой изменения</div>
      </div>
    )
  }
}

export default ChangeLogFilter
