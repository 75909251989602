import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'

import FeedbackItem from '~components/Feedbacks/FeedbackItem'
import GridWrapper from '~components/GridWrapper'
import ContentSpinner from '~components/ContentSpinner'
import EmptyContent from '~components/EmptyContent'

@inject('FeedbackStore')
@observer
class FeedbackList extends Component {
  render() {
    const { FeedbackStore } = this.props
    const { feedbacks, dictionaries, isFirstLoading, feedbacksLength } = FeedbackStore

    if (isFirstLoading) {
      return <ContentSpinner />
    }

    if (!feedbacksLength) return <EmptyContent text="Нет писем" />

    return (
      <GridWrapper gridSize={2}>
        {feedbacks &&
          feedbacks.map(feedback => (
            <FeedbackItem key={feedback._id} dictionaries={dictionaries} feedback={feedback} />
          ))}
      </GridWrapper>
    )
  }
}

FeedbackList.propTypes = {
  FeedbackStore: PropTypes.any,
  isFirstLoading: PropTypes.bool,
  feedbacksLength: PropTypes.bool,
  feedbacks: PropTypes.array
}

export default FeedbackList
