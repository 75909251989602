import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { observer, inject } from 'mobx-react'

import AppLayout from '~layouts/AppLayout'
import Authorized from '~components/Authorized/Authorized'

const PrivateRoute = inject('UserStore')(
  observer(props => {
    const {
      component: Component,
      UserStore: { isAuthenticated },
      showPostsHeader,
      authority = [],
      ...rest
    } = props

    const {
      position: { id: role }
    } = JSON.parse(localStorage.getItem('user:me') || JSON.stringify({ position: { id: '' } }))

    return isAuthenticated ? (
      // Show the component only when the user is logged in
      // Otherwise, redirect the user to /login page
      <Route
        {...rest}
        render={() => (
          <AppLayout showPostsHeader={showPostsHeader}>
            {' '}
            {/* TODO: select which keys needed from rest */}
            <Authorized role={role} authority={authority}>
              <Component {...props} />
            </Authorized>
          </AppLayout>
        )}
      />
    ) : (
      <>
        {`${isAuthenticated}`}
        <Redirect to="/login" />
      </>
    )
  })
)

export default PrivateRoute
