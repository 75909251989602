import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

import AuthorItem from '~components/Authors/AuthorItem'
import GridWrapper from '~components/GridWrapper'
import ContentSpinner from '~components/ContentSpinner'
import EmptyContent from '~components/EmptyContent'

@inject(stores => {
  const { authors, isFirstLoading, isEmptyAuthors } = stores.AuthorsStore

  return {
    authors,
    isFirstLoading,
    isEmptyAuthors
  }
})
@observer
class AuthorList extends Component {
  render() {
    const { authors, isFirstLoading, isEmptyAuthors } = this.props

    if (isFirstLoading) {
      return <ContentSpinner />
    }

    if (isEmptyAuthors) return <EmptyContent text="Нет авторов" />

    return (
      <GridWrapper gridSize={2} maxItemWidth={590}>
        {authors.map(author => (
          <AuthorItem key={author._id} author={author} />
        ))}
      </GridWrapper>
    )
  }
}

export default AuthorList

AuthorList.propTypes = {
  isFirstLoading: PropTypes.bool,
  isEmptyAuthors: PropTypes.bool,
  authors: PropTypes.array
}
