import React from 'react'

import { Spin } from 'antd'

import styles from './styles.scss'

const ContentSpinner = () => (
  <div className={styles.root}>
    <Spin size="large" />
  </div>
)

export default ContentSpinner
