import { observable, action } from 'mobx'

class OverlayStore {
  @observable isVisible = false

  @observable text = 'Сохранение'

  @action
  setVisible = isVisible => {
    this.isVisible = isVisible
  }

  @action
  setText = text => {
    this.text = text
  }
}

export default new OverlayStore()
