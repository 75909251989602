import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'

import ContentWrapper from '~components/ContentWrapper'
import HeaderPageSearchBar from '~components/HeaderPageSearchBar'
import InfinityScroll from '~components/InfinityScroll'
import { FeedbackList, FeedbackFilter } from '~components/Feedbacks'
import Sorting from '~components/Sorting'
import styles from './styles.scss'

const SORTING = [
  {
    label: 'По дате отправления',
    type: 'createdAt'
  },
  {
    label: 'По названию',
    type: 'subject'
  }
]

@inject('FeedbackStore', 'FetchPaginationStore')
@observer
class FeedbackPage extends Component {
  componentDidMount() {
    this.props.FeedbackStore.fetchDictionaries()
  }

  handleSearchPublications = () => {
    const { fetchFeedbacks, setIsFirstLoading } = this.props.FeedbackStore
    const { resetPaginationParams } = this.props.FetchPaginationStore

    setIsFirstLoading(true)
    resetPaginationParams()
    fetchFeedbacks()
  }

  render() {
    const { FeedbackStore } = this.props
    const {
      dictionaries,
      fetchFeedbacks,
      allItemsCount,
      feedbacksLength,
      isFirstLoading,
      isLoadingMore
    } = FeedbackStore

    return (
      <>
        <HeaderPageSearchBar
          searchButtonText="Найти в письмах"
          onSearch={this.handleSearchPublications}
        />
        <ContentWrapper
          title="Обратная связь"
          countItems={allItemsCount}
          countDeclinations={['письмо', 'письма', 'писем']}
        >
          <FeedbackFilter />
          <Sorting
            className={styles.sorting}
            onSort={this.handleSearchPublications}
            data={SORTING}
          />
          <InfinityScroll
            loadItems={fetchFeedbacks}
            isEndLoading={allItemsCount <= feedbacksLength}
            isFirstLoading={isFirstLoading}
            isLoadMore={isLoadingMore}
          >
            <FeedbackList dictionaries={dictionaries} />
          </InfinityScroll>
        </ContentWrapper>
      </>
    )
  }
}

FeedbackPage.propTypes = {
  FeedbackStore: PropTypes.any,
  FetchPaginationStore: PropTypes.any
}

export default FeedbackPage
