import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './styles.scss'

@inject('FeedbackStore', 'FilterParamsByListStore', 'FetchPaginationStore')
@observer
class FeedbackFilterItem extends Component {
  handleClick = () => {
    const { FetchPaginationStore, FilterParamsByListStore, FeedbackStore, type } = this.props

    FetchPaginationStore.resetPaginationParams()
    FilterParamsByListStore.setFilterValue('filter.type', type.id)
    FeedbackStore.setCurrentFeedbackType(type.id)
    FeedbackStore.fetchFeedbacks()
  }

  render() {
    const {
      FeedbackStore: { currentType },
      type
    } = this.props

    return (
      <li className={styles.navigationItem}>
        <span
          className={cn(styles.navigationItemText, {
            [styles.navigationItemTextActive]: type.id === currentType
          })}
          onClick={this.handleClick}
        >
          {type.ru}
        </span>
      </li>
    )
  }
}

FeedbackFilterItem.propTypes = {
  FeedbackStore: PropTypes.any,
  FilterParamsByListStore: PropTypes.any,
  FetchPaginationStore: PropTypes.any,
  type: PropTypes.object
}

export default FeedbackFilterItem
