import React from 'react'
import propTypes from 'prop-types'
import { Input } from 'antd'

import { BlockWrapper, RichInput } from '@dev/tabo-editor'

// components
import ItemWithLabel from '~components/ItemWithLabel'
import ButtonUpload from '~components/ButtonUpload'

// assets
import { InputsTypes } from './blocks.config.example'
import styles from './styles.scss'

// helpers
const onEventChange = f => e => f({ name: e.target.name, value: e.target.value })
const onNameChange = (f, name) => value => f({ name, value })

const CustomInput = ({ type, value, name, label, onChange }) => {
  switch (type) {
    case InputsTypes.richInput:
      return (
        <ItemWithLabel label={label}>
          <RichInput
            value={value}
            onChange={({ raw: value, plainText, html }) => {
              onChange({ name, value, plainText, html })
            }}
          />
        </ItemWithLabel>
      )
    case InputsTypes.input:
      return (
        <ItemWithLabel label={label}>
          <Input name={name} value={value} onChange={onEventChange(onChange)} />
        </ItemWithLabel>
      )

    case InputsTypes.textarea:
      return (
        <ItemWithLabel label={label}>
          <Input.TextArea rows={4} name={name} value={value} onChange={onEventChange(onChange)} />
        </ItemWithLabel>
      )

    case InputsTypes.upload:
      return (
        <ItemWithLabel label={label}>
          {value && value.url && <img className={styles.image} src={value.url} alt="" />}
          <ButtonUpload
            src={value}
            onChange={onNameChange(onChange, name)}
            uploadProps={{ showUploadList: false }}
          />
        </ItemWithLabel>
      )

    default:
      return (
        <div>
          Инпут {name} с типом {type} недоступен! Сообщите технической поддержке. Вы можете
          продолжить работу
        </div>
      )
  }
}

CustomInput.propTypes = {
  type: propTypes.string,
  value: propTypes.string,
  name: propTypes.string,
  label: propTypes.string,

  onChange: propTypes.func
}

class CustomBlock extends React.Component {
  handleChange = ({ name, value }) => {
    const { template, inputs, customType } = this.props
    const values = { ...this.props.values, [name]: value }

    this.props.onChange({
      values,
      template,
      inputs,
      customType
    })
  }

  render() {
    const {
      inputs,
      values,
      blockProps,
      blockProps: { title }
    } = this.props

    return (
      <BlockWrapper title={title} {...this.props} {...blockProps}>
        <div className={styles.container}>
          {(inputs || []).map(input => (
            <div className={styles.input} key={input.name}>
              <CustomInput
                type={input.type}
                name={input.name}
                label={input.label}
                value={values[input.name]}
                onChange={this.handleChange}
              />
            </div>
          ))}
        </div>
      </BlockWrapper>
    )
  }
}

CustomBlock.propTypes = {
  blockProps: propTypes.object,

  template: propTypes.string,
  inputs: propTypes.array,
  values: propTypes.object,
  customType: propTypes.string,

  onChange: propTypes.func,
  onFocus: propTypes.func
}

CustomBlock.defaultProps = {
  values: {},
  customType: ''
}

export default CustomBlock
