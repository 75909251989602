import { observable, action, runInAction, computed } from 'mobx'

import moment from 'moment'
import API from '~api'
import ConfigureFetchUrlByListsStore from './configureFetchUrlByLists'
import FetchPaginationStore from './fetchPaginationStore'
import FilterParamsByListStore from './filterParamsByList'

const VIDEOS_URL = '/videos'

class VideosStore {
  constructor() {
    const { SET_FILTER_PARAMS_FOR_BACK } = ConfigureFetchUrlByListsStore

    SET_FILTER_PARAMS_FOR_BACK({
      'filter.updatedAt': params => this.getDateRangeFilterParams(params),
      'filter.createdAt': params => this.getDateRangeFilterParams(params),
      'filter.publicationDate': params => this.getDateRangeFilterParams(params)
    })

    const startUrl = new URLSearchParams(window.location.search)

    startUrl.forEach((value, key) => {
      const filterFromUrl = this.filtersItem.find(item => item.name === key && !item.fix)

      if (filterFromUrl) {
        this.setFilterItemActive(filterFromUrl)
      }
    })
  }

  @observable isShowModal = false

  @observable isFirstLoading = true

  @observable isActionModal = false

  @observable editableTag = null

  @observable allItemsCount = 0

  @observable isLoadingMore = false

  @observable videos = []

  @observable isShowFilter = this.getIsShowFilterFromUrlParams()

  @observable selectedFilterItems = []

  @observable dictionariesVideo = []

  @observable filtersItem = [
    {
      label: 'Приоритет',
      type: 'select',
      name: 'filter.priority',
      fix: true
    },
    {
      label: 'Статус',
      type: 'select',
      name: 'filter.status',
      fix: true
    },
    {
      label: 'Дата создания',
      type: 'dateRange',
      name: 'filter.createdAt',
      fix: true
    },
    {
      label: 'Дата изменения',
      type: 'dateRange',
      name: 'filter.updatedAt',
      fix: true
    },
    {
      label: 'Дата публикации',
      type: 'dateRange',
      name: 'filter.publicationDate'
    },
    {
      label: 'Контент «18+»',
      type: 'bool',
      name: 'filter.flags.adultContent'
    },
    {
      label: 'Отключение рекламы',
      type: 'bool',
      name: 'filter.flags.advAllowed'
    },
    {
      label: 'Блокировка РКН',
      type: 'bool',
      name: 'filter.flags.blockedByRKN'
    },
    {
      label: 'Контент «Клик»',
      type: 'bool',
      name: 'filter.flags.clickContent'
    },
    {
      label: 'Комментирование публикации',
      type: 'bool',
      name: 'filter.flags.commentsAllowed'
    },
    {
      label: 'Контент «Шок»',
      type: 'bool',
      name: 'filter.flags.shockContent'
    },
    {
      label: '18+ криминал',
      type: 'bool',
      name: 'filter.flags.criminalContent'
    },
    {
      label: 'Темная сторона',
      type: 'bool',
      name: 'filter.flags.darkSide'
    },
    {
      label: 'Пуш уведомление',
      type: 'bool',
      name: 'filter.flags.pushAllowed'
    }
  ]

  getDateRangeFilterParams = params => {
    const dateArray = params.split(',')

    const startDate = moment(dateArray[0])
      .startOf('day')
      .utc()
      .format()

    const endDate = moment(dateArray[1])
      .endOf('day')
      .utc()
      .format()

    return `between(["${startDate}","${endDate}"])`
  }

  @action
  getIsShowFilterFromUrlParams = () => {
    const { filterParams } = FilterParamsByListStore

    const filter = Object.keys(filterParams).filter(item => item !== 'search')

    return !!filter.length
  }

  @action
  fetchDictionaries = () => {
    const promise = API.get('/dictionaries/video')

    return promise.then(res => {
      const { data } = res

      this.setDictionaries(data)
    })
  }

  @action
  fetchVideos = () => {
    const { offset } = FetchPaginationStore
    const { getPromiseUrl } = ConfigureFetchUrlByListsStore

    const promiseUrl = getPromiseUrl({
      url: VIDEOS_URL
    })

    const fetchVideosPromise = API.get(promiseUrl)

    this.setIsLoadingMore(true)
    return fetchVideosPromise
      .then(res => {
        const { data, meta } = res.data

        if (offset === 0) {
          this.setVideos(data)
        } else {
          this.setVideos([...this.videos, ...data])
        }

        this.setAllItemsCount(meta.count)
        this.setIsFirstLoading(false)
        this.setIsLoadingMore(false)
      })
      .catch(() => {
        this.setIsFirstLoading(false)
        this.setIsLoadingMore(false)
      })
  }

  @action
  updateVideo = video => {
    runInAction(() => {
      this.isActionModal = true
    })

    const { _id } = this.editableTag

    const updateTagPromise = API.patch(`${VIDEOS_URL}/${_id}`, video)

    return updateTagPromise
      .then(res => {
        const { data } = res.data

        const editableTagIndex = this.videos.indexOf(this.editableTag)

        this.setHideModal()
        runInAction(() => {
          this.isActionModal = false
          this.videos[editableTagIndex] = data
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @action
  createTag = video => {
    runInAction(() => {
      this.isActionModal = true
    })

    const createTagPromise = API.post(VIDEOS_URL, video)

    return createTagPromise
      .then(res => {
        const { data } = res.data
        this.setHideModal()

        this.setAllItemsCount(this.allItemsCount + 1)

        runInAction(() => {
          this.isActionModal = false
          this.videos.unshift(data)
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @action
  deleteTag = () => {
    runInAction(() => {
      this.isActionModal = true
    })

    const { _id } = this.editableTag

    const deleteAuthorPromise = API.delete(`${VIDEOS_URL}/${_id}`)

    return deleteAuthorPromise
      .then(() => {
        this.videos.remove(this.editableTag)

        this.resetEditableTag()
        this.setHideModal()
        this.setAllItemsCount(this.allItemsCount - 1)

        runInAction(() => {
          this.isActionModal = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @computed
  get isEmptyVideos() {
    return !this.videos.length
  }

  @action
  setDictionaries = data => {
    this.dictionariesVideo = data
  }

  @action
  setEditableTag = value => {
    this.editableTag = value

    this.setShowModal()
  }

  @action
  resetEditableTag = () => {
    this.editableTag = null
  }

  @action
  setShowModal = () => {
    this.isShowModal = true
  }

  @action
  setHideModal = () => {
    this.isShowModal = false
  }

  @action
  setIsFirstLoading = value => {
    this.isFirstLoading = value
  }

  @action
  setIsLoadingMore = value => {
    this.isLoadingMore = value
  }

  @action
  setVideos = data => {
    this.videos = data
  }

  @action
  setAllItemsCount = count => {
    this.allItemsCount = count
  }

  @action
  toggleIsShowFilter = () => {
    this.isShowFilter = !this.isShowFilter
  }

  @action
  setHideFilter = () => {
    this.isShowFilter = false
  }

  @action
  setFilterItemActive = item => {
    this.selectedFilterItems.push(item)
  }

  @action
  removeFileItem = item => {
    this.selectedFilterItems.remove(item)
  }

  @computed
  get videosLength() {
    return this.videos.length
  }
}

export default new VideosStore()
