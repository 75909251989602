import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import cn from 'classnames'

import SortingItem from './SortingItem'

import styles from './styles.scss'

@inject(stores => {
  const { options, setOptions, toggleOptions, setParamsFromUrl } = stores.SortingStore

  return {
    options,
    setOptions,
    toggleOptions,
    setParamsFromUrl
  }
})
@observer
class Sorting extends Component {
  componentDidMount() {
    const { setOptions, data } = this.props

    setOptions(data)
  }

  componentWillUnmount() {
    const { setOptions } = this.props

    setOptions([])
  }

  handleClick = (item, value) => {
    const { onSort, toggleOptions } = this.props

    toggleOptions(item, value)

    onSort()
  }

  render() {
    const { options, className } = this.props

    return (
      <div className={cn(styles.root, className)}>
        <span className={styles.label}>Сортировать:</span>

        {options.map((sortItem, index) => (
          <SortingItem onClick={this.handleClick} key={index} data={sortItem} />
        ))}
      </div>
    )
  }
}

export default Sorting

Sorting.propTypes = {
  data: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  onSort: PropTypes.func.isRequired,
  setOptions: PropTypes.func,
  toggleOptions: PropTypes.func,
  className: PropTypes.string
}
