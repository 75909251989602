import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

import DepartmentItem from '~components/Departments/DepartmentItem'
import GridWrapper from '~components/GridWrapper'
import ContentSpinner from '~components/ContentSpinner'
import EmptyContent from '~components/EmptyContent'

@inject(stores => {
  const { departments, isFirstLoading, isEmptyTags } = stores.DepartmentsStore

  return {
    departments,
    isFirstLoading,
    isEmptyTags
  }
})
@observer
class DepartmentList extends Component {
  render() {
    const { departments, isFirstLoading, isEmptyTags } = this.props

    if (isFirstLoading) {
      return <ContentSpinner />
    }

    if (isEmptyTags) return <EmptyContent text="Нет отделов" />

    return (
      <GridWrapper gridSize={5} maxItemWidth={285}>
        {departments.map(department => (
          <DepartmentItem key={department._id} department={department} />
        ))}
      </GridWrapper>
    )
  }
}

export default DepartmentList

DepartmentList.propTypes = {
  isFirstLoading: PropTypes.bool,
  isEmptyTags: PropTypes.bool,
  departments: PropTypes.array
}
