import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { Popconfirm } from 'antd'
import DeleteIcon from '~assets/icons/close.svg'
import styles from './styles.scss'

@inject('FeedbackStore')
@observer
class FeedbackDelete extends Component {
  confirm = () => {
    this.props.FeedbackStore.deleteFeedback(this.props.feedback)
  }

  cancel = () => {
    return null
  }

  render() {
    return (
      <Popconfirm
        title="Вы уверены что хотите удалить это письмо?"
        onConfirm={this.confirm}
        onCancel={this.cancel}
        okText="Да"
        cancelText="Отмена"
      >
        <DeleteIcon className={styles.deleteIcon} />
      </Popconfirm>
    )
  }
}

FeedbackDelete.propTypes = {
  FeedbackStore: PropTypes.any,
  feedback: PropTypes.object
}

export default FeedbackDelete
