import React, { Component } from 'react'
import { Tabs } from 'antd'

import SettingsNavigation from '~components/SettingsNavigation'
import ContentWrapper from '~components/ContentWrapper'
import { CategoriesList, CategoriesModal } from '~components/Categories'
import { SectionsList, SectionsModal } from '~components/Sections'
import s from './styles.scss'

const PAGE_TITLE = 'Настройка разделов и категорий на сайте'
const { TabPane } = Tabs

class SettingsCategoriesSectionsPage extends Component {
  render() {
    return (
      <>
        <SettingsNavigation />
        <ContentWrapper title={PAGE_TITLE}>
          <div className={s.title}>Выберете раздел</div>
          <Tabs type="card">
            <TabPane tab="Категории" key="1">
              <CategoriesList />
            </TabPane>
            <TabPane tab="Разделы" key="2">
              <SectionsList />
            </TabPane>
          </Tabs>
        </ContentWrapper>
        <CategoriesModal />
        <SectionsModal />
      </>
    )
  }
}

export default SettingsCategoriesSectionsPage
