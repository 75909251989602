import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'

import { get } from 'lodash'
import { Icon } from 'antd'

import ImageLazyLoad from '~components/ImageLazyLoad'
import ClickAndViewButton from '~components/ClickAndViewButton'

import getShortText from '~utils/getShortText'
import getTimeByString from '~utils/getTimeByString'

import styles from './styles.scss'

const SHORT_TITLE_LENGTH = 60

@observer
class VideoItem extends Component {
  render() {
    const { video } = this.props
    const { cover, title, createdAt } = video

    const coverUrl = get(cover, 'url', '')
    const shortTitle = getShortText(title, SHORT_TITLE_LENGTH)
    const createDate = getTimeByString(createdAt)

    return (
      <div className={styles.root}>
        <ImageLazyLoad src={coverUrl} />

        <div className={styles.contentWrapper}>
          <div className={styles.createDate}>
            <Icon type="calendar" /> {createDate}
          </div>

          <div className={styles.title}>{shortTitle}</div>
        </div>

        <ClickAndViewButton editLink={`/broadcasts/video/edit/${this.props.video._id}`} />
      </div>
    )
  }
}

export default withRouter(VideoItem)

VideoItem.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  setEditableTag: PropTypes.func,
  tag: PropTypes.object,
  video: PropTypes.any
}
