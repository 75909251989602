import { observable, action, runInAction, computed } from 'mobx'

import API from '~api'
import FetchPaginationStore from './fetchPaginationStore'
import ConfigureFetchUrlByListsStore from './configureFetchUrlByLists'

const EMPLOYEES_URL = '/employees'

class EmployeesStore {
  @observable isShowModal = false

  @observable isFirstLoading = true

  @observable isActionModal = false

  @observable isLoadingMore = false

  @observable editableEmployee = null

  @observable allItemsCount = 0

  @observable employees = []

  @observable employeesSearches = []

  @observable dictionariesEmployees = []

  @action
  fetchEmployeesBySearch = (term, filter) => {
    let url = EMPLOYEES_URL

    if (term) {
      url = `${url}?search=${term}`
    }

    if (filter) {
      const prefix = term ? '&' : '?'

      url = `${url}${prefix}${filter}`
    }

    return API.get(url).then(res => {
      const { data } = res.data

      runInAction(() => {
        this.employeesSearches = data
      })

      return data
    })
  }

  @action
  fetchEmployees = () => {
    const { offset } = FetchPaginationStore
    const { getPromiseUrl } = ConfigureFetchUrlByListsStore

    const promiseUrl = getPromiseUrl({
      url: EMPLOYEES_URL
    })

    const fetchEmployeesPromise = API.get(promiseUrl)

    this.setIsLoadingMore(true)
    return fetchEmployeesPromise
      .then(res => {
        const { data, meta } = res.data

        if (offset === 0) {
          this.setEmployees(data)
        } else {
          this.setEmployees([...this.employees, ...data])
        }

        this.setAllItemsCount(meta.count)
        this.setIsFirstLoading(false)
        this.setIsLoadingMore(false)
      })
      .catch(() => {
        this.setIsFirstLoading(false)
        this.setIsLoadingMore(false)
      })
  }

  @action
  fetchEmployeePositions = () => {
    const promise = API.get('/dictionaries/employee')

    return promise.then(res => {
      const { data } = res

      this.setDictionaries(data)
    })
  }

  @action
  setDictionaries = data => {
    this.dictionariesEmployees = data
  }

  @action
  updateEmployee = employee => {
    runInAction(() => {
      this.isActionModal = true
    })

    const { _id } = this.editableEmployee

    const updateAuthorPromise = API.patch(`${EMPLOYEES_URL}/${_id}`, employee)

    return updateAuthorPromise
      .then(res => {
        const { data } = res.data
        this.setHideModal()

        const editableAuthorIndex = this.employees.indexOf(this.editableEmployee)

        runInAction(() => {
          this.isActionModal = false
          this.employees[editableAuthorIndex] = data
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @action
  createEmployee = employee => {
    runInAction(() => {
      this.isActionModal = true
    })

    const createEmployeePromise = API.post(EMPLOYEES_URL, employee)

    return createEmployeePromise
      .then(res => {
        const { data } = res.data
        this.setHideModal()

        this.setAllItemsCount(this.allItemsCount + 1)

        runInAction(() => {
          this.isActionModal = false
          this.employees.unshift(data)
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @action
  deleteEmployee = () => {
    runInAction(() => {
      this.isActionModal = true
    })

    const { _id } = this.editableEmployee

    const deleteEmployeePromise = API.delete(`${EMPLOYEES_URL}/${_id}`)

    return deleteEmployeePromise
      .then(() => {
        this.employees.remove(this.editableEmployee)

        this.resetEditableEmployee()
        this.setHideModal()
        this.setAllItemsCount(this.allItemsCount - 1)

        runInAction(() => {
          this.isActionModal = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @computed
  get isEmptyEmployees() {
    return !this.employees.length
  }

  @action
  setEditableEmployee = value => {
    this.editableEmployee = value

    this.setShowModal()
  }

  @action
  resetEditableEmployee = () => {
    this.editableEmployee = null
  }

  @action
  setShowModal = () => {
    this.isShowModal = true
  }

  @action
  setHideModal = () => {
    this.isShowModal = false
  }

  @action
  setIsFirstLoading = value => {
    this.isFirstLoading = value
  }

  @action
  setIsLoadingMore = value => {
    this.isLoadingMore = value
  }

  @action
  setEmployees = data => {
    this.employees = data
  }

  @action
  setAllItemsCount = count => {
    this.allItemsCount = count
  }

  @computed
  get employeesLength() {
    return this.employees.length
  }
}

export default new EmployeesStore()
