import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import cn from 'classnames'

import { Checkbox, Select, Input, Tooltip, Icon } from 'antd'

import { withErrorBoundary } from '~components/ErrorBoundary'
import Labeled from '~components/ItemWithLabel'
import User from '~components/User'

import styles from './styles.scss'

const { Group: CheckboxGroup } = Checkbox

const { Option } = Select
const inputStyle = {
  width: '100%'
}

const flagTitle = {
  commentsAllowed: 'Комментарии в посте',
  advDisabled: 'Отключить рекламу в публикации',
  pushAllowed: 'Пуш уведомления',
  darkSide: 'Тёмная сторона',
  adultContent: 'Adult (18+)',
  criminalContent: 'Criminal (18+)',
  blockedByRKN: 'Блокировка РКН'
}
const flagsOptions = Object.entries(flagTitle).map(([key, value]) => ({ value: key, label: value }))

const findById = (array, id) => array.find(({ _id }) => _id === id)

const makeOptions = (selected, list) => [
  ...selected.map(({ key, label }) => ({ _id: key, title: label })),
  ...list.filter(({ _id }) => selected.map(({ key }) => key).indexOf(_id) === -1)
]

const SearchSelect = ({ label, placeholder, value, options, onChange, onSearch }) => (
  <Labeled label={label}>
    <Select
      style={inputStyle}
      mode="multiple"
      maxTagCount={1}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onSearch={onSearch}
      labelInValue
      optionFilterProp={'label'}
    >
      {options.map(({ _id, title }) => (
        <Option key={_id} label={title}>
          {title}
        </Option>
      ))}
    </Select>
  </Labeled>
)

SearchSelect.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.array,
  options: PropTypes.array,
  onChange: PropTypes.func,
  onSearch: PropTypes.func
}

@inject('PublicationStore', 'TagsStore', 'FlowsStore', 'AuthorsStore')
@observer
class Settings extends React.Component {
  @observable isShowAliasPop = false

  componentDidMount() {
    const {
      TagsStore: { fetchTagsBySearch },
      FlowsStore: { fetchFlowsBySearch },
      AuthorsStore: { fetchAuthorsBySearch }
    } = this.props

    fetchTagsBySearch && fetchTagsBySearch('')
    fetchFlowsBySearch && fetchFlowsBySearch('')
    fetchAuthorsBySearch && fetchAuthorsBySearch('')
  }

  render() {
    const {
      PublicationStore: {
        rssJson,
        resourceType,
        dictionariesList: { dictionaries, categories, sections },
        type,
        categories: selectedCategoriesIds,
        section,
        priority,
        tags,
        flows,
        authors,
        coauthors,
        advType,
        flagsArray,
        alias,
        rssTypes,

        set,
        setType,
        setCategories,
        setSection,
        setPriority,
        setTags,
        setFlows,
        setAuthors,
        setCoauthors,
        setAdvType,
        setRss,
        status,

        fieldLocked,
        onChangeFlags,
        // fieldAction,
        unlockField,
        showModalPriority
      },

      TagsStore: { fetchTagsBySearch, tagsSearches },
      FlowsStore: { fetchFlowsBySearch, flowsSearches },

      AuthorsStore: { fetchAuthorsBySearch, authorsSearches: allAuthors }
    } = this.props

    const authorsSearches = allAuthors.map(author => ({ ...author, title: author.name }))

    const selectedCategories = selectedCategoriesIds.map(categoryId => ({
      key: categoryId,
      label: (findById(categories, categoryId) || { title: '' }).title
    }))
    const onChangeCategories = categories => {
      setCategories(categories.map(({ key }) => key))
    }

    const selectedSection = sections.find(({ _id }) => _id === section)

    const selectedTags = tags.map(({ _id = '', title = '' }) => ({
      key: _id,
      label: title
    }))
    const onChangeTags = tags => {
      setTags(tags.map(({ key, label }) => ({ _id: key, title: label })))
    }

    const selectedFlows = flows.map(({ _id = '', title = '' }) => ({
      key: _id,
      label: title
    }))
    const onChangeFlows = flows => {
      setFlows(flows.map(({ key, label }) => ({ _id: key, title: label })))
    }

    const selectedAuthors = authors.map(({ _id = '', title = '', name = '' }) => ({
      key: _id,
      label: title || name
    }))
    const onChangeAuthors = authors => {
      setAuthors(authors.map(({ key, label }) => ({ _id: key, title: label })))
    }

    const selectedCoauthors = coauthors.map(({ _id = '', title = '', name = '' }) => ({
      key: _id,
      label: title || name
    }))
    const onChangeCoauthors = authors => {
      setCoauthors(authors.map(({ key, label }) => ({ _id: key, title: label })))
    }

    const rssOptions = Object.entries(rssTypes)
      .map(([key, value]) => ({ key, label: value }))
      .sort((a, b) => rssJson.indexOf(b.key) - rssJson.indexOf(a.key))
    const rss = rssJson.map(key => ({ key, label: rssTypes[key] }))
    const onChangeRss = rss => {
      const newRss = {}
      Object.keys(rssTypes).forEach(key => {
        newRss[key] = false
      })
      rss.forEach(({ key }) => {
        newRss[key] = true
      })
      setRss(newRss)
    }

    const onChangeAlias = ({ target: { value } }) => {
      const formatText = value
        .replace(/_|–|—/g, '-')
        .replace(/[а-яА-Я]/g, '')
        .replace(/\s/g, '')
        .replace(/`/g, '')

      set({ name: 'alias', value: formatText })
    }

    const onFocusAlias = () => {
      const { id } = status

      const isShowPop = id === 'POST_STATUS_UNPUBLISHED' || id === 'POST_STATUS_PUBLISHED'

      if (isShowPop) {
        this.isShowAliasPop = true
      }
    }

    const onBlurAlias = () => {
      this.isShowAliasPop = false
    }

    const { blocked: isBlocked, lockedBy } = (fieldLocked && fieldLocked.settings) || {}

    return (
      <div className={cn(styles.wrapper, isBlocked && styles.blocked)}>
        {isBlocked && (
          <div className={styles.overlay}>
            {lockedBy && (
              <div>
                <User
                  person={{
                    ...lockedBy,
                    name: `${lockedBy.firstName} ${lockedBy.lastName}`,
                    position: lockedBy.email
                  }}
                />
                <div
                  className={styles.unlock}
                  onClick={() => unlockField({ field: 'settings', action: 'unlock', force: true })}
                >
                  Разблокировать
                </div>
              </div>
            )}
          </div>
        )}
        {resourceType !== 'RESOURCE_TYPE_TEXT_STREAM' && (
          <Labeled label={'Тип'}>
            <Select style={inputStyle} placeholder={'Выберите тип'} value={type} onChange={setType}>
              {dictionaries.postTypes.map(type => (
                <Option key={type.id} value={type.id}>
                  {type.ru}
                </Option>
              ))}
            </Select>
          </Labeled>
        )}

        <Labeled label={'Категории'}>
          <Select
            style={inputStyle}
            mode="multiple"
            placeholder="Выберите категории"
            value={selectedCategories}
            onChange={onChangeCategories}
            labelInValue
            maxTagCount={1}
          >
            {categories.map(({ _id, title }) => (
              <Option key={_id} label={title}>
                {title}
              </Option>
            ))}
          </Select>
        </Labeled>

        <Labeled label={'Раздел'}>
          <Select
            style={inputStyle}
            placeholder={'Выберите раздел'}
            value={
              selectedSection
                ? { key: selectedSection._id, label: selectedSection.title }
                : undefined
            }
            onChange={setSection}
            labelInValue
          >
            {sections.map(({ title, _id }) => (
              <Option key={_id} value={_id}>
                {title}
              </Option>
            ))}
          </Select>
        </Labeled>

        <Labeled
          label={
            <div onClick={showModalPriority} className={styles.prioritySettingsBtn}>
              Приоритет поста <Icon type="setting" />
            </div>
          }
        >
          <Select
            style={inputStyle}
            placeholder={'Выберите приоритет'}
            value={priority}
            onChange={setPriority}
          >
            {dictionaries.postPriorities.map(priority => (
              <Option key={priority.id} value={priority.id}>
                {priority.ru}
              </Option>
            ))}
          </Select>
        </Labeled>

        <SearchSelect
          label="Теги"
          placeholder="Поиск тегов"
          value={selectedTags}
          options={makeOptions(selectedTags, tagsSearches)}
          onChange={onChangeTags}
          onSearch={fetchTagsBySearch}
        />

        <SearchSelect
          label="Авторы"
          placeholder="Выберите автора"
          value={selectedAuthors}
          options={makeOptions(
            selectedAuthors,
            authorsSearches.filter(
              ({ _id }) => selectedCoauthors.map(({ key }) => key).indexOf(_id) === -1
            )
          )}
          onChange={onChangeAuthors}
          onSearch={fetchAuthorsBySearch}
        />

        <SearchSelect
          label="Соавторы"
          placeholder="Выберите соавтора"
          value={selectedCoauthors}
          options={makeOptions(
            selectedCoauthors,
            authorsSearches.filter(
              ({ _id }) => selectedAuthors.map(({ key }) => key).indexOf(_id) === -1
            )
          )}
          onChange={onChangeCoauthors}
          onSearch={fetchAuthorsBySearch}
        />

        <SearchSelect
          label="Потоки"
          placeholder="Поиск потока"
          value={selectedFlows}
          options={makeOptions(selectedFlows, flowsSearches)}
          onChange={onChangeFlows}
          onSearch={fetchFlowsBySearch}
        />

        <Labeled label={'Особый тип'}>
          <Select style={inputStyle} placeholder={'Выберите'} value={advType} onChange={setAdvType}>
            {dictionaries.postAdvTypes.map(type => (
              <Option key={type.id} value={type.id}>
                {type.ru}
              </Option>
            ))}
          </Select>
        </Labeled>

        <Labeled label={'RSS'}>
          <Select
            style={inputStyle}
            mode="multiple"
            placeholder="Выберите RSS"
            value={rss}
            onChange={onChangeRss}
            optionFilterProp="label"
            labelInValue
            maxTagCount={1}
            defaultActiveFirstOption={false}
          >
            {rssOptions.map(({ key, label }) => (
              <Option key={`rss-${key}`} value={key} label={label}>
                {label}
              </Option>
            ))}
          </Select>
        </Labeled>

        <Labeled label={'Алиас'} outerClass={styles.dNone}>
          <Tooltip
            visible={this.isShowAliasPop}
            placement="topLeft"
            title="Изменение алиаса у опубликованного материала пессимизирует его индекасацию в поисковых ресурсах"
          >
            <Input
              style={inputStyle}
              placeholder={'Введите алиас'}
              value={alias}
              onChange={onChangeAlias}
              onFocus={onFocusAlias}
              onBlur={onBlurAlias}
              disabled={true}
            />
          </Tooltip>
        </Labeled>

        {resourceType === 'RESOURCE_TYPE_TEXT_STREAM' && <div />}

        <CheckboxGroup
          className={styles.flags}
          options={flagsOptions}
          onChange={onChangeFlags}
          value={flagsArray}
        />
      </div>
    )
  }
}

Settings.propTypes = {
  onChange: PropTypes.func,
  PublicationStore: PropTypes.any,
  TagsStore: PropTypes.any,
  FlowsStore: PropTypes.any,
  AuthorsStore: PropTypes.any
}

export default withErrorBoundary(Settings)
