/* eslint-disable no-param-reassign */
/* eslint-disable func-names */

import transform from 'lodash/transform'
import isEqual from 'lodash/isEqual'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'

export function difference(object, base) {
  function changes(object, base) {
    return transform(object, function(result, value, key) {
      if (!isEqual(value, base[key])) {
        result[key] =
          !isArray(value) && isObject(value) && isObject(base[key])
            ? changes(value, base[key])
            : value
      }
    })
  }
  return changes(object, base)
}

// import { reourceTypes } from '~constants/publication'

export function normalize(json) {
  return {
    ...json,
    // cover: json.cover && json.cover._id ? json.cover._id : null,
    authors:
      json.authors && json.authors.length
        ? json.authors.map(author => (typeof author === 'string' ? author : author._id))
        : [],
    coauthors:
      json.coauthors && json.coauthors.length
        ? json.coauthors.map(coauthor => (typeof coauthor === 'string' ? coauthor : coauthor._id))
        : [],
    tags:
      json.tags && json.tags.length
        ? json.tags.map(tag => (typeof tag === 'string' ? tag : tag._id))
        : [],
    flows:
      json.flows && json.flows.length
        ? json.flows.map(flow => (typeof flow === 'string' ? flow : flow._id))
        : []
  }
}

export const convertFromRaw = ({
  advType = { id: '' },
  alias = '',
  authors = [],
  categories = [],
  coauthors = [],
  cover = { url: '', _id: '', alt: '' },
  coverTitle = '',
  end = '',
  flags = {
    RSS: {}
  },
  note = '',
  priority = { id: '' },
  resourceType = '',
  section = { _id: '' },
  start = '',
  subtitle = '',
  tags = [],
  flows = [],
  title = '',
  type = { id: '' },

  locks = []
}) => {
  advType = advType.id
  authors = authors.map(author => ({ ...author, title: author.name }))
  categories = categories.map(({ _id }) => _id)
  coauthors = coauthors.map(author => ({ ...author, title: author.name }))
  priority = priority.id
  section = section._id
  // tags = tags.map(({ _id }) => _id)
  type = type.id

  return {
    advType,
    alias,
    authors,
    categories,
    coauthors,
    cover,
    coverTitle,
    flags,
    priority,
    resourceType,
    section,
    subtitle,
    tags,
    flows,
    title,
    type,
    start,
    end,
    note,

    locks
  }
}
