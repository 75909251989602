import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { observer, inject } from 'mobx-react'

import ContentWrapper from '~components/ContentWrapper'
import { AuthorList, AuthorModal, AuthorModalInfo } from '~components/Authors'
import HeaderPageSearchBar from '~components/HeaderPageSearchBar'
import InfinityScroll from '~components/InfinityScroll'

const PAGE_TITLE = 'Все авторы'
const COUNT_DECLINATIONS = ['автор', 'автора', 'авторов']
const SEARCH_BTN_TEXT = 'Найти в авторах'
const CREATE_BTN_TEXT = 'Добавить автора'

@inject('AuthorsStore', 'FilterParamsByListStore', 'FetchPaginationStore')
@observer
class AuthorsPage extends Component {
  componentWillUnmount() {
    const { FilterParamsByListStore, FetchPaginationStore } = this.props

    FilterParamsByListStore.setFilterParams()
    FetchPaginationStore.resetPaginationParams()
  }

  handleSearchPublications = () => {
    const { fetchAuthors, setIsFirstLoading } = this.props.AuthorsStore

    setIsFirstLoading(true)
    fetchAuthors()
  }

  render() {
    const { AuthorsStore } = this.props
    const {
      setShowModalEdit,
      fetchAuthors,
      allItemsCount,
      authorsLength,
      isFirstLoading,
      isLoadingMore
    } = AuthorsStore

    return (
      <Fragment>
        <HeaderPageSearchBar
          searchButtonText={SEARCH_BTN_TEXT}
          createButtonText={CREATE_BTN_TEXT}
          onCreateElement={setShowModalEdit}
          onSearch={this.handleSearchPublications}
        />
        <ContentWrapper
          title={PAGE_TITLE}
          countItems={allItemsCount}
          countDeclinations={COUNT_DECLINATIONS}
        >
          <InfinityScroll
            loadItems={fetchAuthors}
            isEndLoading={allItemsCount <= authorsLength}
            isFirstLoading={isFirstLoading}
            isLoadMore={isLoadingMore}
          >
            <AuthorList />
          </InfinityScroll>
        </ContentWrapper>

        <AuthorModal />
        <AuthorModalInfo />
      </Fragment>
    )
  }
}

export default AuthorsPage

AuthorsPage.propTypes = {
  AuthorsStore: PropTypes.any,
  FetchPaginationStore: PropTypes.any,
  FilterParamsByListStore: PropTypes.any
}
