import React, { Component } from 'react'
import ReactDOM from 'react-dom/server'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { Icon } from 'antd'

import Editor, { defaultToolbar, defaultBlockComponents, typeConstants } from '@dev/tabo-editor'

import { withErrorBoundary } from '~components/ErrorBoundary'
import Stream from '../Blocks/StreamBlock'
import Flow from '../Blocks/FlowBlock'
import Incut from '../Blocks/PostInlineBlock'
import CustomBlock from '../Blocks/CustomBlock'
import customBlocks from '../Blocks/CustomBlock/blocks.config.example'

const INCUT_BLOCK_TYPE = 'INCUT'
const IncutBlock = {
  component: Incut,
  type: INCUT_BLOCK_TYPE,
  icon: ReactDOM.renderToString(
    <Icon type="select" style={{ width: 28, color: 'rgba(0,0,0,.3)' }} />
  ),
  blockProps: {
    title: 'Врезка'
  }
}
const STREAM_BLOCK_TYPE = 'STREAM'
const StreamBlock = {
  component: Stream,
  type: STREAM_BLOCK_TYPE,
  icon: ReactDOM.renderToString(
    <Icon type="video-camera" style={{ width: 28, color: 'rgba(0,0,0,.3)' }} />
  ),
  blockProps: {
    title: 'Стрим'
  }
}
const FLOW_BLOCK_TYPE = 'FLOW'
const FlowBlock = {
  component: Flow,
  type: FLOW_BLOCK_TYPE,
  icon: ReactDOM.renderToString(
    <Icon type="branches" style={{ width: 28, color: 'rgba(0,0,0,.3)' }} />
  ),
  blockProps: {
    title: 'Поток'
  }
}
const CUSTOM_BLOCK_TYPE = 'CUSTOM'
const customBlocksToBlockComponent = (customBlocks = []) => {
  return customBlocks.map(({ title, icon, customType, ...rest }) => ({
    component: CustomBlock,
    type: CUSTOM_BLOCK_TYPE,
    icon,
    initialProps: {
      customType,
      ...rest
    },
    blockProps: { title }
  }))
}

@inject('PublicationStore')
@observer
class EditorPublication extends Component {
  onFocusBlock = data => {
    this.props.PublicationStore.lockBlock(data.id)
  }

  onDragBlock = ({ id, before }) => {
    const { changeBlockPosition } = this.props.PublicationStore
    changeBlockPosition({ id, before })
  }

  handleUnblock = data => {
    const { id } = data
    this.props.PublicationStore.unlockBlock(id)
  }

  render() {
    const {
      onEditImage,
      onKeyDown,
      PublicationStore: {
        blocksArray,
        createBlock,
        createBlocksFromArray,
        updateBlock,
        deleteBlockById,
        symbols
      }
    } = this.props

    return (
      <Editor
        blocks={blocksArray}
        // TODO: refactor and rename:
        handleUnblock={this.handleUnblock}
        onCreateBlock={createBlock}
        createBlocksFromArray={createBlocksFromArray}
        onFocusBlock={this.onFocusBlock}
        onChangeBlock={updateBlock}
        onDrag={this.onDragBlock}
        onDeleteBlock={deleteBlockById}
        onEditImage={onEditImage}
        onKeyDown={onKeyDown}
        uploadPlaceholder={require('~assets/uploadPlaceholder.jpg')}
        uploadUrl={`${process.env.API_ROOT}/files/`}
        toolbar={[
          ...defaultToolbar,
          STREAM_BLOCK_TYPE,
          FLOW_BLOCK_TYPE,
          INCUT_BLOCK_TYPE,
          CUSTOM_BLOCK_TYPE
        ]}
        blockComponents={[
          ...defaultBlockComponents,
          StreamBlock,
          FlowBlock,
          IncutBlock,
          ...customBlocksToBlockComponent(customBlocks)
        ]}
        maxSize={{
          [typeConstants.HEADER_BLOCK]: symbols.title,
          [typeConstants.IMAGE_BLOCK]: symbols.mediaCaption,
          [typeConstants.VIDEO_BLOCK]: symbols.mediaCaption
        }}
      />
    )
  }
}

EditorPublication.propTypes = {
  onEditImage: PropTypes.func,
  onKeyDown: PropTypes.func,
  PublicationStore: PropTypes.any
}

EditorPublication.defaultProps = {
  onEditImage: () => {},
  onKeyDown: () => {}
}

export default withErrorBoundary(EditorPublication)
