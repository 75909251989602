import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Form, Icon, Input } from 'antd'

import styles from './styles.scss'

class ParticipantsItem extends Component {
  componentDidMount() {
    const { validateFields } = this.props.form

    validateFields()
  }

  handleDeleteParticipants = () => {
    const { onDeleteParticipant, index } = this.props

    onDeleteParticipant(index)
  }

  render() {
    const { getFieldDecorator, index, isShowDeleteButton, item, form, disabled } = this.props

    const { isFieldTouched, getFieldError } = form

    const participantsError =
      isFieldTouched(`participants[${index}].name`) && getFieldError(`participants[${index}].name`)

    return (
      <Input.Group compact style={{ display: 'flex' }}>
        <Form.Item
          validateStatus={participantsError ? 'error' : ''}
          help={participantsError || ''}
          style={{ width: '60%', marginBottom: 8 }}
        >
          {getFieldDecorator(`participants[${index}].name`, {
            initialValue: item.name,
            validateTrigger: ['onChange', 'onBlur'],
            rules: [{ required: true, message: 'Обязательное поле!' }]
          })(<Input disabled={disabled} placeholder="Имя участника *" />)}
        </Form.Item>
        <Form.Item style={{ width: '60%', marginBottom: 8 }}>
          {getFieldDecorator(`participants[${index}].phone`, {
            initialValue: item.phone
          })(<Input disabled={disabled} placeholder="Номер телефона" style={{ width: '100%' }} />)}
        </Form.Item>
        <Form.Item style={{ width: '100%', marginBottom: 8 }}>
          {getFieldDecorator(`participants[${index}].comment`, {
            initialValue: item.comment
          })(<Input disabled={disabled} placeholder="Примечание" style={{ width: '100%' }} />)}
        </Form.Item>

        {isShowDeleteButton > 0 && (
          <Form.Item style={{ marginBottom: 8 }}>
            <div className={styles.deleteParticipants} onClick={this.handleDeleteParticipants}>
              <Icon type="close" />
            </div>
          </Form.Item>
        )}
      </Input.Group>
    )
  }
}

export default ParticipantsItem

ParticipantsItem.propTypes = {
  disabled: PropTypes.any,
  form: PropTypes.any,
  getFieldDecorator: PropTypes.any,
  index: PropTypes.number,
  isShowDeleteButton: PropTypes.bool,
  item: PropTypes.object,
  onDeleteParticipant: PropTypes.func
}
