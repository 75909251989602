import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { get } from 'lodash'

import { inject, observer } from 'mobx-react'
import declOfNum from '~utils/declOfNum'

import CommentList from '../CommentList'
import InfinityScroll from '~components/InfinityScroll'

import styles from './styles.scss'

@inject(stores => {
  const {
    fetchComments,
    openedPost,
    allItemsCount,
    isFirstLoading,
    isLoadingMore,
    commentsLength,
    setIsFirstLoading
  } = stores.CommentsStore

  return {
    fetchComments,
    openedPost,
    allItemsCount,
    isFirstLoading,
    isLoadingMore,
    commentsLength,
    setIsFirstLoading
  }
})
@observer
class ModalWrapper extends Component {
  componentDidMount() {
    const { setIsFirstLoading } = this.props

    setIsFirstLoading(true)
  }

  render() {
    const {
      openedPost,
      allItemsCount,
      fetchComments,
      isFirstLoading,
      isLoadingMore,
      commentsLength
    } = this.props

    const title = get(openedPost, 'title')

    const countDeclination = declOfNum(allItemsCount, [
      'комментарий',
      'комментария',
      'комментириев'
    ])

    return (
      <div>
        <div className={styles.title}>{title}</div>

        {allItemsCount > 0 && (
          <div className={styles.counts}>
            {allItemsCount} {countDeclination}
          </div>
        )}

        <div className={styles.listWrapper} id="commentList">
          <InfinityScroll
            loadItems={fetchComments}
            isEndLoading={allItemsCount <= commentsLength}
            isFirstLoading={isFirstLoading}
            isLoadMore={isLoadingMore}
            containerName="commentList"
          >
            <CommentList />
          </InfinityScroll>
        </div>
      </div>
    )
  }
}

export default ModalWrapper

ModalWrapper.propTypes = {
  allItemsCount: PropTypes.any,
  commentsLength: PropTypes.any,
  fetchComments: PropTypes.func,
  isFirstLoading: PropTypes.bool,
  isLoadingMore: PropTypes.bool,
  openedPost: PropTypes.any,
  setIsFirstLoading: PropTypes.func
}
