import { bool, object } from 'yup'
import { isValidBySchema } from '~utils/isValidBySchema'

/** @type object */
export const rssSchema = object()
  .default(undefined)
  .shape({
    mainFeed: bool(),
    brandAnalytics: bool(),
    exclusives: bool(),
    instantFB: bool(),
    googleNews: bool(),
    googleNewsStand: bool(),
    googleEditorsChoice: bool(),
    yandexDzen: bool(),
    yandexNews: bool(),
    yandexNewsSpb: bool(),
    mailruNews: bool(),
    mailruInformer: bool(),
    facebookInstant: bool()
  })

export const isValidRss = isValidBySchema(rssSchema)
