import { observable, action, runInAction, computed } from 'mobx'

import API from '~api'
import ConfigureFetchUrlByListsStore from './configureFetchUrlByLists'
import FetchPaginationStore from './fetchPaginationStore'

const TAGS_URL = '/tags'

class TagsStore {
  @observable isShowModal = false

  @observable isFirstLoading = true

  @observable isActionModal = false

  @observable editableTag = null

  @observable allItemsCount = 0

  @observable isLoadingMore = false

  @observable tags = []

  @observable tagsSearches = []

  @action
  fetchTagsBySearch = term => {
    let url = TAGS_URL

    if (term) {
      url = `${url}?search=${term}`
    }

    return API.get(url).then(res => {
      const { data } = res.data

      runInAction(() => {
        this.tagsSearches = data
      })

      return data
    })
  }

  @action
  fetchTags = () => {
    const { offset } = FetchPaginationStore
    const { getPromiseUrl } = ConfigureFetchUrlByListsStore

    const promiseUrl = getPromiseUrl({
      url: TAGS_URL
    })

    const fetchTagsPromise = API.get(promiseUrl)

    this.setIsLoadingMore(true)
    return fetchTagsPromise
      .then(res => {
        const { data, meta } = res.data

        if (offset === 0) {
          this.setTags(data)
        } else {
          this.setTags([...this.tags, ...data])
        }

        this.setAllItemsCount(meta.count)
        this.setIsFirstLoading(false)
        this.setIsLoadingMore(false)
      })
      .catch(() => {
        this.setIsFirstLoading(false)
        this.setIsLoadingMore(false)
      })
  }

  @action
  updateTag = tag => {
    runInAction(() => {
      this.isActionModal = true
    })

    const { _id } = this.editableTag

    const updateTagPromise = API.patch(`${TAGS_URL}/${_id}`, tag)

    return updateTagPromise
      .then(res => {
        const { data } = res.data

        const editableTagIndex = this.tags.indexOf(this.editableTag)

        this.setHideModal()
        runInAction(() => {
          this.isActionModal = false
          this.tags[editableTagIndex] = data
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @action
  createTag = tag => {
    runInAction(() => {
      this.isActionModal = true
    })

    const createTagPromise = API.post(TAGS_URL, tag)

    return createTagPromise
      .then(res => {
        const { data } = res.data
        this.setHideModal()

        this.setAllItemsCount(this.allItemsCount + 1)

        runInAction(() => {
          this.isActionModal = false
          this.tags.unshift(data)
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @action
  deleteTag = () => {
    runInAction(() => {
      this.isActionModal = true
    })

    const { _id } = this.editableTag

    const deleteAuthorPromise = API.delete(`${TAGS_URL}/${_id}`)

    return deleteAuthorPromise
      .then(() => {
        this.tags.remove(this.editableTag)

        this.resetEditableTag()
        this.setHideModal()
        this.setAllItemsCount(this.allItemsCount - 1)

        runInAction(() => {
          this.isActionModal = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @computed
  get isEmptyTags() {
    return !this.tags.length
  }

  @action
  setEditableTag = value => {
    this.editableTag = value

    this.setShowModal()
  }

  @action
  resetEditableTag = () => {
    this.editableTag = null
  }

  @action
  setShowModal = () => {
    this.isShowModal = true
  }

  @action
  setHideModal = () => {
    this.isShowModal = false
  }

  @action
  setIsFirstLoading = value => {
    this.isFirstLoading = value
  }

  @action
  setIsLoadingMore = value => {
    this.isLoadingMore = value
  }

  @action
  setTags = data => {
    this.tags = data
  }

  @action
  setAllItemsCount = count => {
    this.allItemsCount = count
  }

  @computed
  get tagsLength() {
    return this.tags.length
  }
}

export default new TagsStore()
