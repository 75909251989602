import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import styles from './styles.scss'

const User = props => {
  const { person, onlyAvatar } = props
  if (!person.name) return null

  return (
    <div className={cn(styles.userWrapper, props.wrapperClassName)}>
      <div className={cn(styles.avatar, props.avatarWrapperClassName)}>
        <img src={person.avatar} alt="" />
      </div>

      {!onlyAvatar && (
        <div className={cn(props.infoWrapperClassName)}>
          <div className={styles.name}>{person.name}</div>
          <div className={styles.position}>{person.position}</div>
        </div>
      )}
    </div>
  )
}

User.propTypes = {
  wrapperClassName: PropTypes.string,
  avatarWrapperClassName: PropTypes.string,
  infoWrapperClassName: PropTypes.string,
  onlyAvatar: PropTypes.boolean,
  person: PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.string,
    position: PropTypes.string
  })
}

User.defaultProps = {
  person: {
    avatar: '',
    name: 'Name',
    position: 'position'
  }
}

User.displayName = 'User'

export default User
