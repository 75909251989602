import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import styles from './styles.scss'

const GridWrapper = props => {
  const { listView, gridSize, maxItemWidth } = props
  return (
    <div
      className={cn(styles.grid, listView && styles.list)}
      style={{ '--grid-size': gridSize, '--max-item-width': maxItemWidth }}
    >
      {props.children}
    </div>
  )
}

GridWrapper.propTypes = {
  children: PropTypes.element,
  listView: PropTypes.bool,
  gridSize: PropTypes.number,
  maxItemWidth: PropTypes.string // TODO: make compatible with numbers
}

GridWrapper.defaultProps = {
  listView: false,
  gridSize: 4,
  maxItemWidth: '1fr'
}

export default GridWrapper
