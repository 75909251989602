import io from 'socket.io-client'

// socket.emit('POST_CREATED', json)
// socket.emit('ENTER_POST', id)
// socket.on('POST_UPDATED', json => callback(json))
// socket.on('POST_DELETED', (id) => callback(id))
// socket.emit('EXIT_POST', id)

class Socket {
  socket

  constructor(url) {
    this.url = url
  }

  init() {
    this.socket = io(this.url)
    console.log('init socket!!!')
  }

  emit(event, data) {
    this.socket.emit(event, data)
  }

  subscribe(event, handler) {
    this.socket.on(event, handler)
  }

  enterPost(id) {
    this.emit('ENTER_POST', id)
  }

  close() {
    this.socket.close()
  }
}

export default new Socket(process.env.SOCKETIO_ROOT)
