import { MobXProviderContext } from 'mobx-react'
import React from 'react'
// eslint-disable-next-line no-unused-vars
import { FilterParamsByListStore } from '~store/filterParamsByList'

/**
 * @returns {FilterParamsByListStore}
 */
export const useFilterParamsByListStore = () =>
  React.useContext(MobXProviderContext)?.FilterParamsByListStore || {}
