import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { sortableContainer, sortableElement } from 'react-sortable-hoc'

import GridWrapper from '~components/GridWrapper'
import ContentSpinner from '~components/ContentSpinner'
import CategoriesItem from '~components/Categories/CategoriesItem'
import AddNew from '~components/AddNew'

const SortableItem = sortableElement(({ category }) => <CategoriesItem data={category} />)

const SortableContainer = sortableContainer(({ children }) => {
  return (
    <GridWrapper gridSize={3} maxItemWidth={285}>
      {children}
    </GridWrapper>
  )
})

@inject('CategoriesStore')
@observer
class CategoriesList extends Component {
  componentDidMount() {
    const {
      CategoriesStore: { fetchCategories }
    } = this.props

    fetchCategories()
  }

  render() {
    const {
      CategoriesStore: { categories, setShowModal, isFirstLoading, changeOrdering }
    } = this.props

    if (isFirstLoading) {
      return <ContentSpinner />
    }

    return (
      <SortableContainer distance={1} axis="xy" onSortEnd={changeOrdering}>
        {categories.map(category => (
          <SortableItem key={category._id} category={category} index={category.order} />
        ))}
        <AddNew onClick={setShowModal} />
      </SortableContainer>
    )
  }
}

CategoriesList.propTypes = {
  CategoriesStore: PropTypes.any
}

export default CategoriesList
