import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'

import WhiteBlockWithShadow from '~components/WhiteBlockWithShadow'
import Image from '~components/Image'

import s from './styles.scss'

@inject('SectionsStore')
@observer
class SectionsItem extends Component {
  handleClick = () => {
    const {
      SectionsStore: { setEditableSection },
      data
    } = this.props

    setEditableSection(data)
  }

  render() {
    const {
      data: { title, visible, cover }
    } = this.props

    return (
      <WhiteBlockWithShadow>
        <div className={s.card} onClick={this.handleClick}>
          <h2 className={s.cardTitle}>{title}</h2>
          <div className={s.cardItem}>
            {cover && <Image className={s.cardItemCover} url={cover.url} alt="Cover" />}
            <div>
              <h3 className={s.cardItemTitle}>Опубликована на сайте:</h3>
              <p className={s.cardItemValue}>{visible ? 'Да' : 'Нет'}</p>
            </div>
          </div>
        </div>
      </WhiteBlockWithShadow>
    )
  }
}

SectionsItem.propTypes = {
  SectionsStore: PropTypes.any,
  data: PropTypes.object
}

export default SectionsItem
