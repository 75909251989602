import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import { withRouter } from 'react-router-dom'

import ContentWrapper from '~components/ContentWrapper'
import { PublicationList, FilterControl, Filter, ViewToggleButton } from '~components/Publications'
import HeaderPageSearchBar from '~components/HeaderPageSearchBar'
import InfinityScroll from '~components/InfinityScroll'
import Sorting from '~components/Sorting'

const PAGE_TITLE = 'Все публикации'
export const COUNT_DECLINATIONS = ['публикация', 'публикации', 'публикаций']
const SEARCH_BTN_TEXT = 'Найти в публикациях'
const CREATE_BTN_TEXT = 'Создать публикацию'

const SORTING = [
  {
    label: 'По дате создания',
    type: 'createdAt'
  },
  {
    label: 'По дате публикации',
    type: 'publicationDate'
  },
  {
    label: 'По названию',
    type: 'title'
  }
]

@inject('PublicationsStore', 'FetchPaginationStore', 'FilterParamsByListStore', 'UserStore')
@observer
class PublicationsPage extends Component {
  componentDidMount() {
    const { PublicationsStore } = this.props

    PublicationsStore.initFilterParams()
    PublicationsStore.setDefaultFilterByCalendarView()
  }

  componentWillUnmount() {
    const { FilterParamsByListStore, FetchPaginationStore, PublicationsStore } = this.props

    FilterParamsByListStore.setFilterParams({})
    FetchPaginationStore.resetPaginationParams()
    PublicationsStore.resetFilters()
    PublicationsStore.setHideFilter()
  }

  fetchDidMount = () => {
    const { setIsFirstLoading } = this.props.PublicationsStore

    setIsFirstLoading(true)
  }

  handleSearchPublications = () => {
    const { defaultFilter } = this.props
    const { fetchPublications, setIsFirstLoading } = this.props.PublicationsStore
    const { resetPaginationParams } = this.props.FetchPaginationStore

    setIsFirstLoading(true)
    resetPaginationParams()
    fetchPublications(defaultFilter)
  }

  handleToggleMyPost = () => {
    const { defaultFilter } = this.props
    const { fetchPublications, setIsFirstLoading } = this.props.PublicationsStore
    const { resetPaginationParams } = this.props.FetchPaginationStore
    const { setFilterValue, setFilterParams, filterParams } = this.props.FilterParamsByListStore
    const { user } = this.props.UserStore

    const isAllPost = filterParams['filter.createdBy'] === user._id

    setIsFirstLoading(true)
    setFilterParams()
    resetPaginationParams()

    if (isAllPost) {
      setFilterValue('filter.createdBy')
    } else {
      setFilterValue('filter.createdBy', user._id)
    }

    fetchPublications(defaultFilter)
  }

  handleCreate = () => {
    this.props.history.push(`${this.props.location.pathname}/new`)
  }

  render() {
    const {
      PublicationsStore,
      defaultFilter,
      FilterParamsByListStore,
      UserStore,
      hideFilters = []
    } = this.props

    const {
      fetchPublications,
      allItemsCount,
      publicationLength,
      isFirstLoading,
      isLoadingMore,
      isGrid,
      toggleIsShowFilter,
      isShowFilter
    } = PublicationsStore

    const { filterParams } = FilterParamsByListStore
    const { user } = UserStore

    return (
      <Fragment>
        <HeaderPageSearchBar
          searchField={'filter.title'}
          searchButtonText={SEARCH_BTN_TEXT}
          createButtonText={CREATE_BTN_TEXT}
          onCreateElement={this.handleCreate}
          customButtons={<ViewToggleButton defaultFilter={defaultFilter} />}
          isGrid={isGrid}
          onSearch={this.handleSearchPublications}
        />

        <ContentWrapper
          title={PAGE_TITLE}
          countItems={allItemsCount}
          countDeclinations={COUNT_DECLINATIONS}
          rightContent={
            <FilterControl
              onToggleMyPost={this.handleToggleMyPost}
              isShowFilter={isShowFilter}
              onToggleShowFilter={toggleIsShowFilter}
              isAllPost={filterParams['filter.createdBy'] === user._id}
            />
          }
        >
          {isShowFilter && <Filter hideFilters={hideFilters} defaultFilter={defaultFilter} />}

          <Sorting onSort={this.handleSearchPublications} data={SORTING} />

          <InfinityScroll
            defaultFilter={defaultFilter}
            loadItems={fetchPublications}
            isEndLoading={allItemsCount <= publicationLength}
            isFirstLoading={isFirstLoading}
            isLoadMore={isLoadingMore}
            onScrollDidMount={this.fetchDidMount}
          >
            <PublicationList />
          </InfinityScroll>
        </ContentWrapper>
      </Fragment>
    )
  }
}

export default withRouter(PublicationsPage)

PublicationsPage.propTypes = {
  FetchPaginationStore: PropTypes.any,
  FilterParamsByListStore: PropTypes.any,
  PublicationsStore: PropTypes.any,
  UserStore: PropTypes.any,
  defaultFilter: PropTypes.string,
  hideFilters: PropTypes.any,
  history: PropTypes.object,
  location: PropTypes.object
}
