import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'

import { Tooltip } from 'antd'
import Validated from '~components/Publications/Validated'
import ArrowRight from '~assets/icons/arrowRight.svg'

import styles from './styles.scss'

import Actions from '../Actions/Actions'

const UserOnPage = ({ firstName, lastName, avatar }) => (
  <Tooltip placement="bottom" title={`${firstName} ${lastName}`} arrowPointAtCenter>
    <div className={styles.user}>
      {avatar ? (
        <img src={avatar.url} alt={`${firstName} ${lastName}`} className={styles.userAvatar} />
      ) : (
        <span className={styles.userLetter}>{firstName[0]}</span>
      )}
    </div>
  </Tooltip>
)

UserOnPage.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  avatar: PropTypes.object
}

@inject('PublicationStore')
@observer
class FixedMenu extends Component {
  handleClickExit = () => {
    const { history, match } = this.props

    history.push(match.path.slice(0, -4))
  }

  render() {
    const {
      PublicationStore: { validatedBy, validatedAt, approve, usersOnPage, textCount = '' }
    } = this.props

    return (
      <div id="postFixedMenu" className={styles.root}>
        <div className={styles.backButton} onClick={this.handleClickExit}>
          <ArrowRight fill={'currentColor'} style={{ transform: 'rotate(180deg) scale(.5)' }} />
          <span>Выйти</span>
        </div>

        <span className={styles.textCount} title="Количество символов">
          Симв.: {textCount}
        </span>

        <Validated {...validatedBy} validatedAt={validatedAt} onClick={approve} />

        <div className={styles.users}>
          {/* eslint-disable-next-line react/jsx-key */}
          {React.Children.toArray(usersOnPage.map(user => <UserOnPage {...user} />))}
        </div>

        <Actions noMargin buttonSize="small" type="fixed" />
      </div>
    )
  }
}

export default withRouter(FixedMenu)

FixedMenu.propTypes = {
  PublicationStore: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any
}
