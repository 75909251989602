import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { sortableContainer, sortableElement } from 'react-sortable-hoc'

import GridWrapper from '~components/GridWrapper'
import AddNew from '~components/AddNew'
import ContentSpinner from '~components/ContentSpinner'
import SectionsItem from '~components/Sections/SectionsItem'

const SortableItem = sortableElement(({ section }) => <SectionsItem data={section} />)

const SortableContainer = sortableContainer(({ children }) => {
  return (
    <GridWrapper gridSize={3} maxItemWidth={285}>
      {children}
    </GridWrapper>
  )
})

@inject('SectionsStore')
@observer
class SectionsList extends Component {
  componentDidMount() {
    const {
      SectionsStore: { fetchSections }
    } = this.props

    fetchSections()
  }

  render() {
    const {
      SectionsStore: { sections, setShowModal, isFirstLoading, changeOrdering }
    } = this.props

    if (isFirstLoading) {
      return <ContentSpinner />
    }

    return (
      <SortableContainer distance={1} axis="xy" onSortEnd={changeOrdering}>
        {sections.map(section => (
          <SortableItem key={section._id} section={section} index={section.order} />
        ))}
        <AddNew onClick={setShowModal} />
      </SortableContainer>
    )
  }
}

SectionsList.propTypes = {
  SectionsStore: PropTypes.any
}

export default SectionsList
