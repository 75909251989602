import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'

import { get } from 'lodash'

import Modal from '~components/Modal'
import Socials from '~components/Authors/Socials'
import AuthorInfo from '~components/Authors/AuthorItem/TopInfo'

import styles from './styles.scss'

@inject(stores => {
  const {
    isShowModalInfo,
    setHideModalInfo,
    editableAuthor,
    resetEditableAuthor
  } = stores.AuthorsStore

  return {
    isShowModalInfo,
    setHideModalInfo,
    editableAuthor,
    resetEditableAuthor
  }
})
@observer
class AuthorModalInfo extends Component {
  render() {
    const { isShowModalInfo, setHideModalInfo, resetEditableAuthor, editableAuthor } = this.props

    const socials = get(editableAuthor, 'socials')
    const bio = get(editableAuthor, 'bio')

    return (
      <Modal
        destroyOnClose
        width={770}
        onClose={setHideModalInfo}
        isVisible={isShowModalInfo}
        afterClose={resetEditableAuthor}
      >
        <div className={styles.root}>
          <div className={styles.header}>
            <AuthorInfo data={editableAuthor} />
          </div>

          <div className={styles.content}>
            <h2 className={styles.title}>Биография автора</h2>
            <div className={styles.bio}>{bio}</div>
          </div>

          <Socials data={socials} />
        </div>
      </Modal>
    )
  }
}

export default AuthorModalInfo

AuthorModalInfo.propTypes = {
  isShowModalInfo: PropTypes.string,
  resetEditableAuthor: PropTypes.func,
  editableAuthor: PropTypes.object,
  setHideModalInfo: PropTypes.func
}
