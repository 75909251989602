import { string, object, boolean, array } from 'yup'
import { transformEmptyString } from '~schemas/transformations/transfomEmptyString'

/** @type object */
export const employeeSchema = object()
  .default(undefined)
  .shape({
    _id: string().required(),
    active: boolean(),
    authors: array(),
    avatar: string().defined(),
    department: string().defined(),
    email: string().defined(),
    firstName: string().transform(transformEmptyString),
    lastName: string().transform(transformEmptyString),
    position: string()
  })
  .noUnknown()
