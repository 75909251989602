import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import { withRouter, Prompt } from 'react-router-dom'
import { throttle, noop } from 'lodash'

import { Input, message, Spin, Drawer } from 'antd'
import { HeadingBlock, CoverBlock } from '@dev/tabo-editor'

import FilterToggleControl from '~components/FilterToggleControl'
import SavingModal from './components/SavingModal'
import PriorityModal from './components/PriorityModal'
import ContentWrapper from '~components/ContentWrapper'
import StatusLabel from '~components/StatusLabel'
import ImageEditor from '~components/ImageEditor'
import { CommentsModal } from '~components/Comments'

import Header from './components/Header'
import Settings from './components/Settings'
import Actions from './components/Actions'
import FixedMenu from './components/FixedMenu'
import EditorPublication from './components/EditorPublication'
import EditorTextTranslation from './components/EditorTextTranslation'
import TranslationInputs from './components/TranslationInputs'

import { format } from '~utils/date'
import getTimeByString from '~utils/getTimeByString'
import { resourceTypes } from '~constants/publication'

import styles from './styles.scss'
import User from '~components/User'
import Validated from '~components/Publications/Validated'
import Labeled from '~components/ItemWithLabel'

import EditingIcon from '~assets/icons/pencil-edit.svg'

require('@dev/tabo-editor/lib/index.css')

// const { confirm } = Modal

@inject('PublicationStore')
@observer
class EditorPage extends Component {
  loadingIndicator

  state = {
    isOpenSettings: true,
    isOpenImageEditor: false,
    editableImage: '',
    previewUrl: ''
  }

  checkedShowFixMEnu = React.createRef()

  componentDidMount() {
    const {
      match: {
        path,
        params: { id }
      },
      PublicationStore: { init }
    } = this.props

    init({ type: path.slice(0, -4), id })

    document.addEventListener('keydown', this.onDocumentKeyDown)

    const cont = window

    cont.addEventListener('scroll', this.throttledScrollHandle)
    window.addEventListener('beforeunload', this.onbeforeunload)
    window.addEventListener('unload', this.unload, false)
  }

  componentDidUpdate() {
    if (!this.props.PublicationStore.isLoading) {
      this.loadingIndicator != null && this.loadingIndicator()
    } else {
      this.loadingIndicator = message.loading('Загрузка')
    }
  }

  componentWillUnmount() {
    const {
      PublicationStore: { clear, isLockedBlockByMe, unlockAllMyBlock, unlockAllMyFields }
    } = this.props

    if (isLockedBlockByMe) {
      unlockAllMyFields()
      unlockAllMyBlock()
    }

    clear && clear()

    document.removeEventListener('keydown', this.onDocumentKeyDown)

    document.removeEventListener('beforeunload', this.onbeforeunload)
    document.removeEventListener('unload', this.unload)
  }

  onbeforeunload = e => {
    const { PublicationStore } = this.props

    const { isLockedBlockByMe } = PublicationStore

    if (isLockedBlockByMe) {
      e.preventDefault()
      e.returnValue = true
    }
  }

  unload = () => {
    const { PublicationStore } = this.props

    PublicationStore.unlockAllMyFields()
    PublicationStore.unlockAllMyBlock()
  }

  scrollHandle = () => {
    const elem = document.getElementById('postFixedMenu')

    if (!elem) return null
    if (
      this.checkedShowFixMEnu.current &&
      this.checkedShowFixMEnu.current.getBoundingClientRect().top < 0
    ) {
      elem.style.display = 'flex'
    } else {
      elem.style.display = 'none'
    }

    return null
  }

  throttledScrollHandle = throttle(this.scrollHandle, 200)

  toggleSettings = () => {
    this.setState(({ isOpenSettings }) => ({ isOpenSettings: !isOpenSettings }))
  }

  onChange = ({ meta: { blockId }, meta, ...rest }) => {
    const { set } = this.props.PublicationStore
    set && set({ name: blockId, value: rest })
  }

  onChangeNote = ({ target: { value } }) => {
    this.props.PublicationStore.set({ name: 'note', value })
  }

  openImageEditor = (url, editHandler) => {
    this.setState({ isOpenImageEditor: true, editableImage: url, editHandler })
  }

  closeImageEditor = () => {
    this.setState({ isOpenImageEditor: false, editableImage: '' })
  }

  onKeyDown = (e, blockId) => {
    if (e.key.length === 1) {
      this.props.PublicationStore.lockBlock(blockId)
    }
  }

  onDocumentKeyDown = e => {
    if (e.key === 'F9') {
      const {
        PublicationStore: { isFetching = false, savePublication = () => {} }
      } = this.props
      if (isFetching) {
        message.error('Сохранение недоступно. Повторите попытку позднее')
      } else {
        savePublication()
      }
    }
  }

  render() {
    const {
      id,
      resourceType,
      status,
      isNew,

      // saving,
      savingModal,
      savings,
      coverSaving,
      // savingError,
      setSavingError,
      // setSaving,
      setSavingModal,
      restoreSavings,

      createdAt,
      updatedAt,
      updatedBy,
      validatedBy,
      validatedAt,
      approve,

      title,
      subtitle,
      note,
      cover,
      coverTitle,
      textCount,
      symbols,
      publicationDate,
      flags: { coverHidden },
      toggleCoverHidden,
      isShowSettingsDrawer,
      hideSettingsDrawer,
      initialized,
      isLockedBlockByMe,

      // fieldAction = () => {},
      lockField,
      unlockField,
      fieldLocked = {},
      fetchingRequests = [],
      shouldFetchWhileSaving = noop
    } = this.props.PublicationStore

    const formatPublicationDate = getTimeByString(publicationDate)
    const { isEditing: isSettingsEditing = false } = fieldLocked.settings || {}

    const isFetchingSelector = (...requests) => {
      return (
        fetchingRequests.some(request => requests.includes(request)) ||
        requests.findIndex(shouldFetchWhileSaving) > -1
      )
    }

    if (!initialized) {
      return (
        <div>
          <Spin /> Loading...
        </div>
      )
    }

    const savingData = [
      {
        title: 'Настройки',
        isSaving: savings?.settings.isSaving,
        status: savings?.settings.status,
        error: savings?.settings.error
      },
      {
        title: 'Обложка',
        isSaving: coverSaving?.isSaving,
        status: coverSaving?.status,
        error: [savings?.cover.error, savings?.coverTitle.error]
      },
      {
        title: 'Заголовок',
        isSaving: savings?.title.isSaving,
        status: savings?.title.status,
        error: savings?.title.error
      },
      {
        title: 'Подзаголовок',
        isSaving: savings?.subtitle.isSaving,
        status: savings?.subtitle.status,
        error: savings?.subtitle.error
      },
      {
        title: 'Блоки',
        isSaving: savings?.blocks.isSaving,
        status: savings?.blocks.errors.length > 0 ? 'error' : 'ok',
        error: savings?.blocks.errors
      },
      {
        title: 'Модерация',
        isSaving: savings?.moderation.isSaving,
        status: savings?.moderation.status,
        error: savings?.moderation.error
      }
    ]

    return (
      <>
        <SavingModal
          visible={savingModal}
          onCancel={() => {
            restoreSavings()
            setSavingModal(false)
            setSavingError(false)
          }}
          data={savingData}
        />

        <FixedMenu />

        {isLockedBlockByMe && (
          <Prompt
            when
            message="Часть блоков заблокирована вами и не сохранена. При выходе блоки будут разблокированы а изменения отменены!"
          />
        )}

        <Header validatedBy={validatedBy} validatedAt={validatedAt} onClick={approve} />

        <ContentWrapper
          hideMobileTitle
          title={`${isNew ? 'Создание' : 'Редактирование'} ${
            resourceType === resourceTypes.publication ? 'публикации' : 'трансляции'
          }`}
          countItems={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <StatusLabel color={status.color} label={status.ru} />
              {publicationDate && <div style={{ marginLeft: 15 }}>{formatPublicationDate}</div>}
            </div>
          }
          rightContent={
            <div style={{ display: 'flex' }}>
              {isSettingsEditing && (
                <EditingIcon width={12} style={{ marginRight: 5, fill: '#ff0a0a' }} />
              )}
              <FilterToggleControl
                label="Все настройки"
                isActive={this.state.isOpenSettings}
                onClick={this.toggleSettings}
                isHideMobile
                wrapperStyle={isSettingsEditing ? { color: '#ff0a0a' } : {}}
              />
            </div>
          }
        >
          <div className={styles.mainInfo}>
            {updatedBy != null && (
              <User
                person={{
                  avatar: `${process.env.API_ROOT}/files/${updatedBy.avatar}/content?height=100&width=100`,
                  name: `${updatedBy.firstName} ${updatedBy.lastName}`
                }}
              />
            )}
            <span className={styles.text}>
              <span>{format(createdAt, 'DD MMMM YYYY в HH:mm')},</span>
              <span>{` изменено ${format(updatedAt, 'DD MMMM YYYY HH:mm')}`}</span>
            </span>

            <span className={styles.textCount}>Символов: {textCount}</span>

            <div className={styles.review}>
              <Validated {...validatedBy} validatedAt={validatedAt} onClick={approve} />
            </div>
          </div>

          {this.state.isOpenSettings && (
            <div className={styles.mobileSettings}>
              <Spin spinning={isFetchingSelector('settings')}>
                <Settings />
              </Spin>
            </div>
          )}

          <Actions />

          <div ref={this.checkedShowFixMEnu} />

          <div className={styles.inputsWrapper}>
            {resourceType === resourceTypes.textTranslation && <TranslationInputs />}

            <Labeled label="Примечание" outerClass={styles.note}>
              <Input value={note} onChange={this.onChangeNote} />
            </Labeled>
          </div>

          <Spin spinning={isFetchingSelector('cover', 'coverTitle')}>
            <CoverBlock
              id="cover"
              blockProps={{
                title: 'Обложка',
                ...(fieldLocked.cover || {}),
                onMouseUp: () => {
                  lockField({ field: 'cover', action: 'lock' })
                  lockField({ field: 'coverTitle', action: 'lock' })
                },
                handleUnblock: ({ force = false }) => {
                  unlockField({ field: 'cover', action: 'unlock', force })
                  unlockField({ field: 'coverTitle', action: 'unlock', force })
                }
              }}
              uploadPlaceholder={require('~assets/uploadPlaceholder.jpg')}
              uploadUrl={`${process.env.API_ROOT}/files`}
              {...toJS(cover)}
              alt={coverTitle}
              onChange={this.onChange}
              isHidden={coverHidden}
              toggleHidden={toggleCoverHidden}
              onEdit={this.openImageEditor}
            />
          </Spin>

          <Spin spinning={isFetchingSelector('title')}>
            <HeadingBlock
              id="title"
              level={1}
              text={title}
              charactersCount={title && title.length}
              onChange={this.onChange}
              onFocus={() => lockField({ field: 'title' })}
              blockProps={{
                title: 'Заголовок',
                maxSize: symbols.title,
                ...(fieldLocked.title || {}),
                onMouseUp: () => lockField({ field: 'title' }),
                handleUnblock: ({ force = false }) => unlockField({ field: 'title', force })
              }}
              richEditor={false}
            />
          </Spin>

          <Spin spinning={isFetchingSelector('subtitle')}>
            <HeadingBlock
              id="subtitle"
              level={-1}
              placeholder={'Добавить подзаголовок'}
              blockProps={{
                title: 'Подзаголовок',
                maxSize: symbols.subtitle,
                ...(fieldLocked.subtitle || {}),
                onMouseUp: () => lockField({ field: 'subtitle' }),
                handleUnblock: ({ force = false }) =>
                  unlockField({ field: 'subtitle', action: 'unlock', force })
              }}
              text={subtitle}
              charactersCount={subtitle && subtitle.length}
              onChange={this.onChange}
              onFocus={() => lockField({ field: 'subtitle' })}
              richEditor={false}
            />
          </Spin>

          <ImageEditor
            postId={id}
            shown={this.state.isOpenImageEditor}
            src={this.state.editableImage}
            onClose={this.closeImageEditor}
            onChange={this.state.editHandler}
          />

          {resourceType === resourceTypes.publication ? (
            <EditorPublication onEditImage={this.openImageEditor} onKeyDown={this.onKeyDown} />
          ) : (
            <EditorTextTranslation onEditImage={this.openImageEditor} onKeyDown={this.onKeyDown} />
          )}
        </ContentWrapper>

        <CommentsModal />
        <PriorityModal />

        <Drawer
          title="Настройки публикации"
          placement="bottom"
          closable={true}
          onClose={hideSettingsDrawer}
          visible={isShowSettingsDrawer}
          bodyStyle={{
            overflowY: 'scroll',
            height: 'calc(80vh - 55px)'
          }}
          height="80vh"
        >
          <Settings />
        </Drawer>
      </>
    )
  }
}

EditorPage.propTypes = {
  confirm: PropTypes.bool,
  history: PropTypes.object,
  match: PropTypes.object,

  PublicationStore: PropTypes.object
}

export default withRouter(EditorPage)
